import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";

export default class HowToUse extends Base {
    constructor(props) {
        super(props);

    }

    public componentDidMount() {

    }

    public render() {

        let template = (<React.Fragment>
            <Header />
            <section className="containerMinHeight borderBtm">
                <div className="becomeLayer2 howToUse">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-7"></div>
                            <div className="col-12 col-sm-5">
                                <div className="cardCotent p-5">
                                    <div className="layerOne">1. Discover Services</div>
                                    <div className="layerTwo mt-2">Browse through our extensive range of service categories to find what you need. Whether it's home improvement, beauty services, photography, or anything in between, we have a diverse array of offerings to cater to your specific requirements.</div>
                                    <div className="layerOne mt-3">2. Find Trusted Professionals</div>
                                    <div className="layerTwo mt-2">Each service provider listed on our platform undergoes a rigorous vetting process to ensure that they are qualified and trustworthy. We conduct identity and background checks to enhance your safety and peace of mind.</div>
                                    <div className="layerOne mt-3">3. Read Reviews and Ratings</div>
                                    <div className="layerTwo mt-2">Make informed decisions by reading reviews and ratings from previous customers. We encourage honest and unbiased feedback to help you choose the perfect match for your needs.</div>
                                    <div className="layerOne mt-3">4. Request Quotes or Book Services</div>
                                    <div className="layerTwo mt-2">Once you find a service provider that fits your requirements, you can request quotes or book the service directly through our platform. </div>
                                    <div className="layerOne mt-3">5. Get Quality Services</div>
                                    <div className="layerTwo mt-2">Experience hassle-free services delivered by experienced professionals. We continuously strive to maintain high service standards and ensure that our customers are satisfied with every interaction.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>);

        return template;
    }
}