import {
    Avatar, Box, Checkbox, FormControlLabel, Grid, Card, CardHeader, CardContent, CardActions, IconButton, Dialog, DialogTitle,
    DialogContent, DialogActions, Typography, DialogContentText, Button, CardMedia, Snackbar, Alert, Rating, LinearProgress, Autocomplete, TextField, Stack,
} from "@mui/material"; //Tab, Tabs, 

import React from "react";
import Base from "src/common/Base";
import { IAutoCompleteOption, ProgressBar, TabPanel } from "src/common/Components";
import Constants from "src/common/Constants";
// import Footer from "src/common/Footer";
// import Header from "src/common/Header";
import Validator from "src/common/Validator";
import DeactivateAccount from './DeactivateAccount';
import { red } from "@mui/material/colors";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SendOutlined from '@mui/icons-material/SendOutlined';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import ReplyIcon from '@mui/icons-material/Reply';

export default class Account extends Base {
    private formValidators: Validator[];
    private socialFormValidators: Validator[];
    private licenseFormValidators: Validator[];
    private fileUploadFormValidators: Validator[];
    private portfolioMediaPhotoFormValidators: Validator[];
    private portfolioMediaUrlFormValidators: Validator[];
    private portfolioProjectFormValidators: Validator[];
    private commentReplyFormValidators: Validator[];

    private validator: Validator;

    // private map: google.maps.Map;
    // private mapCenter: google.maps.LatLngLiteral = { lat: 30, lng: -110 };

    constructor(props) {
        super(props);

        this.state = {
            loading: true, ActiveTab: 0, ActiveProfileTab: (this.props.index ? Number(this.props.index) : 0), FirstName: '', LastName: '', Email: '', Phone: '', BusinessName: '', Description: '', Address: '', CityId: '', CityName: '',
            StateId: '', StateName: '', States: [], Cities: [], Pins: [], FilteredStates: [], FilteredCities: [], FilteredPins: [], Pin: '', PinAddress: '', PinPlaceId: '', Role: '', Year: '', WebSiteUrl: '',
            ProfileId: '', IsActive: true, ProfileImage: '', HasMasterCard: false, HasVisa: false, HasPayPal: false, TwitterUrl: '', FacebookUrl: '', InstagramUrl: '', YouTubeUrl: '', LinkedInUrl: '',
            SocialMediaLinks: [], Payments: [], Licenses: [], Certificates: [], Portfolio: null, FAQs: [], Reviews: [], DisplayName: '',
            showGeneralProgress: false, showSocialMediaProgress: false, showPaymentsProgress: false, showLicenseProgress: false, showPortfolioProgress: false, showNewProjectDialog: false,
            ShowLicenseDialog: false, LicenseItem: null, showNewProtfolioMediaDialog: false, PortfolioVideoUrl: '', DialogMode: '', ProjectServiceName: '',
            LicenseId: 0, LicenseName: '', LicenseDescription: '', LicenseInstitution: '', LicenseStartDate: '', LicenseEndDate: '', File: null, FileName: '',
            showConfirmation: false, ConfirmationMessage: '', showFileUploadDialog: false, PaymentTypes: [], PortfolioMedia: [], FeaturedProjects: [], OverallRating: 0.0, showRatingClear: false,
            Message: '', MessageType: 'success', ShowMessage: false, Services: [], Years: [], ProjectTitle: '', ProjectService: '', ProjectYear: '', ProjectPrice: '', ProjectDescription: '',
            ProjectPhotos: [], ProjectFiles: [], ProjectId: 0, TotalReviewsCount: 0, showReplyId: '0', ReplyText: '', ReviewParameters: [], SelectedPin: null,
            ProfileRatings: [], ProfileComments: [], HighRatedParameters: [], ProfileRootComments: [], ParentCommentId: '0', showReviewsProgress: false, showAskForReviewDialog: false, Emails: '',
            Recipients: null, ReviewRecipients: [], ConfirmationType: '', Latitude: '', Longitude: '', PlaceName: '', ItemType: '', UserEmail: '', UserAddress: ''
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
        this.socialFormValidators = new Array<Validator>();
        this.licenseFormValidators = new Array<Validator>();
        this.fileUploadFormValidators = new Array<Validator>();
        this.portfolioMediaPhotoFormValidators = new Array<Validator>();
        this.portfolioMediaUrlFormValidators = new Array<Validator>();
        this.portfolioProjectFormValidators = new Array<Validator>();
        this.commentReplyFormValidators = new Array<Validator>();
    }

    public componentDidMount() {
        if (this.IsUserValid) {
            // get saved categories and pins
            this.getStateMaster().then((states) => {
                this.getCityMaster().then((cities) => {
                    this.getPinsMaster().then((pins) => {
                        this.db.getProfileServices(this.User.Profile.Id).then((services) => {
                            this.getReviewParameterMaster().then((reviewParams) => {
                                let _states: IAutoCompleteOption[] = [];
                                if (states && states.length > 0) {
                                    states.forEach((cat) => {
                                        _states.push({ key: cat.Name, text: cat.Name, value: cat.Id });
                                    });
                                }

                                let _cities: IAutoCompleteOption[] = [];
                                if (cities && cities.length > 0) {
                                    cities.forEach((cat) => {
                                        _cities.push({ key: cat.Name, text: cat.Name, value: cat.Id });
                                    });
                                }

                                let _pins: IAutoCompleteOption[] = [];
                                let _selectedPin = null;

                                if (pins && pins.length > 0) {
                                    pins.forEach((pin) => {
                                        _pins.push({ key: pin.Name, text: pin.Name, value: pin.Pin });
                                    });
                                }

                                let _services: IAutoCompleteOption[] = [];
                                if (services && services.length > 0) {
                                    services.forEach((service) => {
                                        _services.push({ key: service.Category.Name, text: service.Category.Name, value: service.Category.Id });
                                    });
                                }

                                let _reviewParams: IAutoCompleteOption[] = [];
                                if (reviewParams && reviewParams.length > 0) {
                                    reviewParams.forEach((rParam) => {
                                        _reviewParams.push({ key: rParam.Name, text: rParam.Name, value: rParam.Id });
                                    });
                                }

                                let _years: IAutoCompleteOption[] = [];
                                let _currentYear = new Date().getFullYear();
                                for (var i = 0; i <= 10; i++) {
                                    let year = (_currentYear - i).toString();
                                    _years.push({ key: year, text: year, value: year });
                                }

                                this.db.getUserProfile(this.LoginName, this.AppRole).then((profile) => {
                                    if (profile) {
                                        _selectedPin = pins.find((p) => { return (p.Pin === profile.Pin); });

                                        this.setState({
                                            loading: false, States: states, Cities: cities, Pins: pins, FilteredStates: _states, FilteredCities: _cities, FilteredPins: _pins, Services: _services, Years: _years,
                                            ProfileId: profile.Id, FirstName: profile.User ? profile.User.FirstName : '', LastName: profile.User ? profile.User.LastName : '', HighRatedParameters: profile.HighRatedParameters,
                                            BusinessName: profile.BusinessName, Phone: profile.Phone,
                                            ProfileImage: profile.ProfileImage,

                                            Email: (this.props.mode === 'A' ? (profile.User && profile.User.Email ? profile.User.Email : '') : (profile.Email ? profile.Email : '')),
                                            Address: (this.props.mode === 'A' ? (profile.User && profile.User.Address ? profile.User.Address : '') : (profile.Address ? profile.Address : '')),
                                            CityId: (this.props.mode === 'A' ? (profile.User && profile.User.City ? profile.User.City.Id : '') : (profile.City ? profile.City.Id : '')),
                                            CityName: (this.props.mode === 'A' ? (profile.User && profile.User.City ? profile.User.City.Name : '') : (profile.City ? profile.City.Name : '')),
                                            Pin: (this.props.mode === 'A' ? (profile.User && profile.User.Pin ? profile.User.Pin : '') : (profile.Pin ? profile.Pin : '')),
                                            PinAddress: (this.props.mode === 'A' ? (profile.User && profile.User.PinAddress ? profile.User.PinAddress : '') : (profile.PinAddress ? profile.PinAddress : '')),
                                            Latitude: (this.props.mode === 'A' ? (profile.User && profile.User.Latitude ? profile.User.Latitude : '') : (profile.Latitude ? profile.Latitude : '')),
                                            Longitude: (this.props.mode === 'A' ? (profile.User && profile.User.Longitude ? profile.User.Longitude : '') : (profile.Longitude ? profile.Longitude : '')),

                                            StateId: (this.props.mode === 'A' ? (profile.User && profile.User.State ? profile.User.State.Id : '') : (profile.State ? profile.State.Id : '')),
                                            StateName: (this.props.mode === 'A' ? (profile.User && profile.User.State ? profile.User.State.Name : '') : (profile.State ? profile.State.Name : '')),
                                            StateCode: (this.props.mode === 'A' ? (profile.User && profile.User.State ? profile.User.State.Code : '') : (profile.State ? profile.State.Code : '')),

                                            IsActive: profile.IsActive, UserAddress: profile.User ? profile.User.UserAddress : '',
                                            WebSiteUrl: profile.WebSiteUrl, Year: profile.YearFounded, Description: profile.Description, DisplayName: profile.User ? profile.User.DisplayName : '',
                                            Role: (this.AppRole.toLowerCase() === Constants.UserRoles.Pro.toLowerCase() ? Constants.UserRoles.Pro : (this.AppRole.toLowerCase() === Constants.UserRoles.Customer.toLowerCase() ? Constants.UserRoles.Customer : '')),
                                            OverallRating: profile.Rating, TotalReviewsCount: profile.Comments, ReviewParameters: _reviewParams, SelectedPin: _selectedPin
                                        }, () => {
                                            // this.addGoogleMapScriptTag();
                                            this.initializePlacesAutoComplete();
                                        });

                                        if (this.props.index > 0) {
                                            this.handleProfileTabClick(this.props.index);
                                        }
                                    }
                                    else {
                                        this.setState({
                                            loading: false,
                                            States: states, Cities: cities, Pins: pins, FilteredStates: _states, FilteredCities: _cities, FilteredPins: _pins,
                                            Role: (this.AppRole.toLowerCase() === Constants.UserRoles.Pro.toLowerCase() ? Constants.UserRoles.Pro : (this.AppRole.toLowerCase() === Constants.UserRoles.Customer.toLowerCase() ? Constants.UserRoles.Customer : '')),
                                        });
                                    }
                                });
                            });
                        });
                    });
                });
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    // private addGoogleMapScriptTag() {
    //     let scriptElems = document.getElementsByTagName("script");
    //     let elemExists = false;
    //     for (var i = 0; i < scriptElems.length; i++) {
    //         if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
    //             elemExists = true;
    //     }

    //     if (!elemExists) {
    //         const script = document.createElement("script");
    //         script.src = Constants.Google.PlacesScriptPath;
    //         //script.id = "scriptGoogleMaps";
    //         script.setAttribute('async', '');
    //         script.setAttribute('defer', '');

    //         document.getElementsByTagName("head")[0].appendChild(script);
    //     }
    // }

    private initializePlacesAutoComplete() {

        const input = document.getElementById("zip") as HTMLInputElement;
        const autoCompleteOptions = {
            fields: ["formatted_address", "address_components", "geometry", "name", "place_id"],
            // strictBounds: false,
            types: ["(regions)"],
            componentRestrictions: { country: "us" }
        };

        const autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);

        autocomplete.addListener("place_changed", () => {
            let place: any = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "No details available for input: '" + place.name + "'", ConfirmationType: 'A' });
                return;
            }

            let hasPostalCode = false;
            let zipCode = '';
            let state = '';
            let sCode = '';
            let city = '';

            if (place.address_components) {
                for (var i = 0; i < place.address_components.length; i++) {
                    if (place.address_components[i].types) {
                        for (var j = 0; j < place.address_components[i].types.length; j++) {
                            if (place.address_components[i].types[j] === "postal_code") {
                                if (!hasPostalCode) {
                                    hasPostalCode = true;
                                    zipCode = place.address_components[i].long_name;
                                }
                            }

                            if (place.address_components[i].types[j] === "administrative_area_level_1") {
                                if (!state) {
                                    state = place.address_components[i].long_name;
                                    sCode = place.address_components[i].short_name;
                                }
                            }

                            if (place.address_components[i].types[j] === "locality") {
                                if (!city) {
                                    city = place.address_components[i].long_name;
                                }
                            }
                        }
                    }
                }
            }

            if (!hasPostalCode) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "Invalid Zip code. Please start your search with a Zip code.", ConfirmationType: 'A' });
                return;
            }

            let formatted_address = place.formatted_address;
            let placeId = place.place_id;
            let lat = place.geometry?.location?.lat();
            let lon = place.geometry?.location?.lng();

            this.setState({
                Pin: zipCode, PinAddress: formatted_address, PinPlaceId: placeId, PlaceName: place?.name, StateName: state, StateCode: sCode, CityName: city, Latitude: lat, Longitude: lon
            });
        });
    }

    public componentWillUnmount() {

    }

    public handleSaveAccountClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showGeneralProgress: true });
            const { FirstName, LastName, Email, Address, Phone, CityName, StateName, Pin, Description, WebSiteUrl, Year, ProfileId, BusinessName, StateCode, Latitude, Longitude, PinAddress, PlaceName } = this.state;

            let itemToUpdate: any = {
                pId: ProfileId.toString(), uId: this.User.Id.toString(), fName: FirstName, lName: LastName, email: Email,
                address: Address, sId: StateName.toString(), cId: CityName.toString(),
                pin: Pin.toString(), desc: Description, phone: Phone.toString(), siteUrl: WebSiteUrl ? WebSiteUrl : '', year: Year.toString(),
                bName: BusinessName, pRole: this.AppRole, sCode: StateCode, lat: Latitude.toString(), lon: Longitude.toString(), pAddress: PinAddress, pName: PlaceName, mode: this.props.mode
            };

            this.db.updateUserProfile(itemToUpdate).then((updated) => {
                if (updated) {
                    this.successMessage((this.props.mode === 'A' ? 'Account' : 'Profile') + ' details saved.');
                    this.props.onSubmitClick();
                }
                else {
                    this.errorMessage((this.props.mode === 'A' ? 'Account' : 'Profile') + ' details could not be saved.');
                }

                this.setState({ showGeneralProgress: false });
            });
        }
    }

    public handleSavePassword() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ loading: true });
            this.db.changePassword(this.User.Id, this.encodeString(this.state.NewPassword)).then((changed) => {
                if (changed) {
                    this.successMessage('Password changed sucessfully.');
                }
                else {
                    this.errorMessage('Password cannot be changed.');
                }

                this.setState({ loading: false });
            });
        }
    }

    // private handleTabChange(tabId) {
    //     this.formValidators = new Array<Validator>();
    //     this.setState({ ActiveTab: tabId });
    // }

    // private filterCities(e) {
    //     if (e && e.value) {
    //         let stateId = e.value;
    //         let _filteredResult = this.state.Cities.filter((c) => { return (c.State.Id === Number(stateId)); });
    //         let _filteredCities: any[] = [];
    //         let _filteredPins: any[] = [];

    //         if (_filteredResult && _filteredResult.length > 0) {
    //             _filteredResult.forEach((_c) => {
    //                 _filteredPins.push({ key: _c.Pin.toString(), text: _c.Pin.toString(), value: _c.Pin.toString() });
    //                 _filteredCities.push({ key: _c.Name.toString(), text: _c.Name.toString(), value: _c.Id.toString() });
    //             });
    //         }

    //         this.setState({ FilteredCities: _filteredCities, CityId: '', CityName: '', Pin: '', FilteredPins: _filteredPins });
    //     }
    // }

    // private filterPins(e) {
    //     if (e && e.value) {
    //         let cityId = e.value;
    //         let _filteredResult = this.state.Cities.filter((c) => { return (c.Id === Number(cityId)); });
    //         let _filteredPins: any[] = [];

    //         if (_filteredResult && _filteredResult.length > 0) {
    //             _filteredResult.forEach((_c) => {
    //                 _filteredPins.push({ key: _c.Pin.toString(), text: _c.Pin.toString(), value: _c.Pin.toString() });
    //             });
    //         }

    //         this.setState({ FilteredPins: _filteredPins, Pin: '' });
    //     }
    // }

    // private setCityAndState(e) {

    // }

    private handleNotificationChange(e, mode) {

    }

    private handleProfileTabClick(index) {
        this.setState({ ActiveProfileTab: index }, () => {
            if (index === 1) {
                this.getSocialMediaLinks();
            }
            else if (index === 2) {
                this.getPayments();
            }
            else if (index === 3) {
                this.getLicenses();
            }
            else if (index === 4 || index === 5) {
                this.getPortfolio();
            }
            else if (index === 6) {
                this.getReviews();
            }
            else if (index === 7) {
                this.getFAQs();
            }
        });
    }

    private getSocialMediaLinks() {
        let _result = this.state.SocialMediaLinks;

        if (_result && _result.length <= 0) {
            this.setState({ showSocialMediaProgress: true });
            this.db.getSocialMediaLinks(this.state.ProfileId).then((result) => {
                let _twitter = '';
                let _facebook = '';
                let _insta = '';
                let _youtube = '';
                let _linkedin = '';

                if (result) {
                    result.forEach((link) => {
                        if (link.LinkType === Constants.SocialMediaLinkType.Twitter)
                            _twitter = link.LinkUrl;
                        else if (link.LinkType === Constants.SocialMediaLinkType.Facebook)
                            _facebook = link.LinkUrl;
                        else if (link.LinkType === Constants.SocialMediaLinkType.Instagram)
                            _insta = link.LinkUrl;
                        else if (link.LinkType === Constants.SocialMediaLinkType.LinkedIn)
                            _linkedin = link.LinkUrl;
                        else if (link.LinkType === Constants.SocialMediaLinkType.YouTube)
                            _youtube = link.LinkUrl;
                    });
                }

                this.setState({ showSocialMediaProgress: false, SocialMediaLinks: result, TwitterUrl: _twitter, FacebookUrl: _facebook, Instagramurl: _insta, YouTubeUrl: _youtube, LinkedInUrl: _linkedin });
            });
        }
    }

    private getLicenses() {
        this.setState({ showLicenseProgress: true });
        this.db.getLicenses(this.state.ProfileId).then((result) => {
            if (result) {
                result.forEach((_l) => {
                    _l.StartDate = this.getFormattedDate(new Date(this.getDateNumber(_l.StartDate)), Constants.IDateTimeFormat.yyyyMMdd, '-');
                    _l.EndDate = this.getFormattedDate(new Date(this.getDateNumber(_l.EndDate)), Constants.IDateTimeFormat.yyyyMMdd, '-');
                });
            }

            this.setState({ showLicenseProgress: false, Licenses: result });
        });
    }

    private getPayments() {
        this.setState({ showPaymentsProgress: true });
        this.getPaymentsTypes().then((paymentTypes) => {
            let _paymentTypes = paymentTypes;
            this.db.getPaymentModes(this.state.ProfileId).then((result) => {
                let _temp: any = [];

                _paymentTypes.forEach((_pType) => {
                    let checked = false;
                    let upId = 0;
                    if (result) {
                        let _userHasMode = result.find((_uType) => { return (_pType.Name === _uType.Mode); });
                        if (_userHasMode) {
                            checked = true;
                            upId = _userHasMode.Id;
                        }
                    }

                    _temp.push({ Checked: checked, UPId: upId, Id: _pType.Id, Name: _pType.Name, Mode: (checked ? 'E' : '') });
                });

                this.setState({ showPaymentsProgress: false, PaymentTypes: paymentTypes, Payments: _temp });
            });
        });
    }

    private getFAQs() {
        let _result = this.state.FAQs;

        if (_result && _result.length <= 0) {
            this.db.getFAQs(this.state.ProfileId).then((result) => {
                if (result) {
                    this.setState({ FAQs: result });
                }
            });
        }
    }

    private getPortfolio() {
        this.setState({ showPortfolioProgress: true });
        this.db.getPortfolio(this.state.ProfileId).then((result) => {
            this.setState({ showPortfolioProgress: false, Portfolio: result, PortfolioMedia: result ? result.Media : [], FeaturedProjects: result ? result.FeaturedProjects : [] });
        });
    }

    private getReviews() {
        this.setState({ showReviewsProgress: true });
        this.db.getRecipients(this.state.ProfileId, 'P').then((recipients) => {
            this.db.getProfileRatings(this.state.ProfileId).then((profileRatings) => {
                this.db.getProfileComments(this.state.ProfileId).then((profileComments) => {
                    let rootComments = [];
                    if (profileComments) {
                        rootComments = profileComments.filter((c) => { return (c.ParentId.toString() === '0'); });
                    }

                    this.setState({ showReviewsProgress: false, ProfileRatings: profileRatings, ProfileComments: profileComments, ProfileRootComments: rootComments, ReviewRecipients: recipients });
                });
            });
        });
    }

    private getPaymentsTypes(): Promise<any> {
        return new Promise((resolve, reject) => {
            let paymentTypes = this.state.PaymentTypes;
            if (!paymentTypes || paymentTypes.length <= 0) {
                this.db.getPaymentTypes().then((result) => {
                    if (result) {
                        resolve(result);
                    }
                });
            }
            else {
                resolve(paymentTypes);
            }
        });
    }

    private handleSaveSocialMediaClick() {
        let isValid = this.validator.validateForm(this.socialFormValidators);
        if (isValid) {
            this.setState({ showSocialMediaProgress: true });

            let { SocialMediaLinks } = this.state;
            if (SocialMediaLinks && SocialMediaLinks.length > 0) {
                let _counter = 0;
                let _updatedCount = 0;

                SocialMediaLinks.forEach((_l) => {
                    this.db.updateSocialMediaLink(_l.Id, _l.ProfileId, _l.LinkType, _l.LinkUrl).then((updated) => {
                        _counter++;

                        if (updated) {
                            _updatedCount++;
                        }

                        if (_counter === SocialMediaLinks.length) {
                            this.setState({ showSocialMediaProgress: false });

                            if (_updatedCount === SocialMediaLinks.length) {
                                this.successMessage('Social Media details updated.');
                                this.props.onCancelClick();
                            }
                            else {
                                this.errorMessage('Some Social Media details could not be updated.');
                            }
                        }
                    });
                });
            }
        }
    }

    private setSocialMediaLink(linkType, linkUrl) {
        let _socialMediaLinks = this.state.SocialMediaLinks;
        if (!_socialMediaLinks) {
            _socialMediaLinks = [];
        }

        if (_socialMediaLinks.length > 0) {
            let _link = _socialMediaLinks.find((_l) => { return (_l.LinkType === linkType); });
            if (_link)
                _link.LinkUrl = linkUrl;
            else {
                _socialMediaLinks.push({ Id: 0, ProfileId: this.state.ProfileId, LinkType: linkType, LinkUrl: linkUrl });
            }
        }
        else {
            _socialMediaLinks.push({ Id: 0, ProfileId: this.state.ProfileId, LinkType: linkType, LinkUrl: linkUrl });
        }

        this.setState({ SocialMediaLinks: _socialMediaLinks });
    }

    private handlePaymentSaveClick() {
        this.setState({ showPaymentsProgress: true });

        let { Payments, ProfileId } = this.state;
        if (Payments && Payments.length > 0) {
            let _counter = 0;
            let _updatedCount = 0;

            Payments.forEach((_l) => {
                if (_l.Mode === 'N') {
                    this.db.updatePaymentMode(_l.Id, ProfileId, _l.Name).then((updated) => {
                        _counter++;

                        if (updated) {
                            _updatedCount++;
                        }

                        if (_counter === Payments.length) {
                            this.setState({ showPaymentsProgress: false });
                            this.successMessage('Payment details updated.');
                            this.props.onCancelClick();
                        }
                    });
                }
                else if (_l.Mode === 'D') {
                    this.db.deletePaymentMode(_l.UPId).then((deleted) => {
                        _counter++;

                        if (deleted) {
                            _updatedCount++;
                        }

                        if (_counter === Payments.length) {
                            this.setState({ showPaymentsProgress: false });
                            this.successMessage('Payment details updated.');
                            this.props.onCancelClick();
                        }
                    });
                }
                else {
                    _counter++;
                    //_updatedCount++;

                    if (_counter === Payments.length) {
                        this.setState({ showPaymentsProgress: false });

                        if (_updatedCount === Payments.length) {
                            this.successMessage('Payment details updated.');
                            this.props.onCancelClick();
                        }
                    }
                }
            });
        }
    }

    private handleLicenseEdit(item) {
        this.setState({
            LicenseName: item.Name, LicenseDescription: item.Description, LicenseInstitution: item.Institution, LicenseStartDate: item.StartDate,
            LicenseEndDate: item.EndDate, File: null, FileName: '', ShowLicenseDialog: true, LicenseId: item.Id
        });
    }

    private handleLicenseDelete(item) {
        this.setState({ showConfirmation: true, LicenseId: item.Id, callBackMethod: this.deleteLicense.bind(this), ConfirmationMessage: "Are you sure you want to delete this license?" });
    }

    private deleteLicense() {
        this.setState({ showLicenseProgress: true, showConfirmation: false });
        this.db.deleteLicense(this.state.LicenseId).then((deleted) => {
            if (deleted) {
                this.successMessage('License deleted.');
                this.setState({ ConfirmationMessage: '', LicenseId: 0, callBackMethod: null });
                this.getLicenses();
            }
            else {
                this.errorMessage('License could not be deleted.');
                this.setState({ showLicenseProgress: false, ConfirmationMessage: '', LicenseId: 0, callBackMethod: null });
            }
        });
    }

    private uploadFile(type: string, file?): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.state.File) {
                this.db.uploadFile(this.state.File, this.User.Id, this.state.FileName, type).then((uploaded) => {
                    resolve(uploaded);
                });
            }
            else if (file) {
                this.db.uploadFile(file, this.User.Id, file.name, type).then((uploaded) => {
                    resolve(uploaded);
                });
            }
            else {
                resolve(true);
            }
        });
    }

    private handleLicenseSaveClick() {
        let isValid = this.validator.validateForm(this.licenseFormValidators);
        if (isValid) {
            this.setState({ showLicenseProgress: true, ShowLicenseDialog: false });
            this.uploadFile('licenses').then((uploaded) => {
                if (uploaded) {
                    let { ProfileId, LicenseId, LicenseName, LicenseDescription, LicenseInstitution, LicenseStartDate, LicenseEndDate, FileName } = this.state;
                    let fileUrl = '';
                    if (this.state.File)
                        fileUrl = '/files/' + this.User.Id + '/licenses/' + FileName;

                    this.db.updateLicense(LicenseId, ProfileId, LicenseName, LicenseDescription, LicenseStartDate, LicenseEndDate, LicenseInstitution, fileUrl).then((updated) => {
                        if (updated) {
                            this.successMessage('License details saved.');
                            this.setState({ ShowLicenseDialog: false, LicenseId: 0, LicenseName: '', LicenseDescription: '', LicenseInstitution: '', LicenseStartDate: '', LicenseEndDate: '', File: null, FileName: '' });

                            this.getLicenses();
                            this.licenseFormValidators = new Array<Validator>();
                        }
                        else {
                            this.errorMessage('License details could not be saved.');

                            this.licenseFormValidators = new Array<Validator>();
                            this.setState({ showLicenseProgress: false, ShowLicenseDialog: false, LicenseId: 0, LicenseName: '', LicenseDescription: '', LicenseInstitution: '', LicenseStartDate: '', LicenseEndDate: '', File: null, FileName: '' });
                        }
                    });
                }
                else {
                    this.errorMessage('File could not be uploaded.');

                    this.licenseFormValidators = new Array<Validator>();
                    this.setState({ showLicenseProgress: false, ShowLicenseDialog: false, LicenseId: 0, LicenseName: '', LicenseDescription: '', LicenseInstitution: '', LicenseStartDate: '', LicenseEndDate: '', File: null, FileName: '' });
                }
            });
        }
    }

    private handleProfilePicUpload() {
        let isValid = this.validator.validateForm(this.fileUploadFormValidators);
        if (isValid) {
            this.setState({ showFileUploadDialog: false, showGeneralProgress: true });
            this.uploadFile('profile').then((uploaded) => {
                if (uploaded) {
                    this.db.updateProfileImage(0, this.state.ProfileId, '/files/' + this.User.Id + '/profile/' + this.state.FileName).then((updated) => {
                        if (updated) {
                            this.successMessage('Profile picture updated.');
                            this.setState({ showGeneralProgress: false, File: null, FileName: '', ProfileImage: '/files/' + this.User.Id + '/profile/' + this.state.FileName });
                        }
                        else {
                            this.errorMessage('Profile picture could not be updated.');
                            this.setState({ showGeneralProgress: false, File: null, FileName: '' });
                        }
                    });
                }
                else {
                    this.errorMessage('Profile picture could not be uploaded.');
                    this.setState({ showGeneralProgress: false, File: null, FileName: '' });
                }
            });
        }
    }

    private handlePortfolioMediaDelete(item) {
        this.setState({ showConfirmation: true, LicenseId: item.Id, ItemType: item.Type, callBackMethod: this.deletePortfolioMedia.bind(this), ConfirmationMessage: "Are you sure you want to delete this " + (item.Type === 'P' ? 'photo' : 'video') + "?" });
    }

    private deletePortfolioMedia() {
        this.setState({ showPortfolioProgress: true, showConfirmation: false });
        this.db.deletePortfolioMedia(this.state.LicenseId).then((deleted) => {
            if (deleted) {
                this.successMessage((this.state.ItemType === 'P' ? 'Photo' : 'Video') + ' deleted.');
                this.setState({ ConfirmationMessage: '', LicenseId: 0, ItemType: '', callBackMethod: null });
                this.getPortfolio();
            }
            else {
                this.errorMessage((this.state.ItemType === 'P' ? 'Photo' : 'Video') + ' could not be deleted.');
                this.setState({ showPortfolioProgress: false, ItemType: '', ConfirmationMessage: '', LicenseId: 0, callBackMethod: null });
            }
        });
    }

    private handlePortfolioMediaFileUpload(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // let isValid = this.validator.validateForm(this.portfolioMediaPhotoFormValidators);
            // if (isValid) {
            // this.setState({ showNewProtfolioMediaDialog: false, showPortfolioProgress: true });
            if (this.state.ProjectFiles && this.state.ProjectFiles.length > 0) {
                let counter = 0;
                this.state.ProjectFiles.forEach((pFile) => {
                    this.uploadFile('portfolio/photos', pFile).then((uploaded) => {
                        if (uploaded) {
                            this.db.updatePortfolioMedia(this.state.ProfileId, '/files/' + this.User.Id + '/portfolio/photos/' + pFile.name, 'P').then((updated) => {
                                if (updated) {
                                    counter++;
                                    if (counter === this.state.ProjectFiles.length) {
                                        //this.successMessage('Photo added in your portfolio.');
                                        this.setState({ File: null, FileName: '', ProjectFiles: [] });
                                        //this.getPortfolio();

                                        resolve(true);
                                    }
                                }
                                else {
                                    counter++;
                                    if (counter === this.state.ProjectFiles.length) {
                                        this.errorMessage('Photo could not be added to your portfolio.');
                                        this.setState({ showPortfolioProgress: false, File: null, FileName: '', ProjectFiles: [] });

                                        resolve(false);
                                    }
                                }
                            });
                        }
                        else {
                            counter++;
                            if (counter === this.state.ProjectFiles.length) {
                                this.errorMessage('Photo could not be uploaded.');
                                this.setState({ showPortfolioProgress: false, File: null, FileName: '', ProjectFiles: [] });

                                resolve(false);
                            }
                        }
                    });
                });
            }
            else {
                resolve(true);
            }
            // }
        });
    }

    private checkMediaFileExists(fileName, type, index?) {
        let exists = null;
        if (fileName && this.state.PortfolioMedia && this.state.PortfolioMedia.length > 0) {
            exists = this.state.PortfolioMedia.find((_m: any) => {
                return ((type === 'P' && _m.Name.toLowerCase() === fileName.toLowerCase()) || (type === 'V' && _m.Url.toLowerCase() === fileName.toLowerCase()));
            });
        }

        if (!exists && type === 'P' && this.state.ProjectFiles && this.state.ProjectFiles.length > 0) {
            exists = this.state.ProjectFiles.find((_m: any, indx) => {
                return (_m.name.toLowerCase() === fileName.toLowerCase() && index !== indx && _m.mode !== 'D');
            });
        }

        return (exists ? '' : '1');
    }

    private handlePortfolioMediaVideoUpload(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // let isValid = this.validator.validateForm(this.portfolioMediaUrlFormValidators);
            // if (isValid) {
            // this.setState({ showNewProtfolioMediaDialog: false, showPortfolioProgress: true });
            if (this.state.PortfolioVideoUrl) {
                this.db.updatePortfolioMedia(this.state.ProfileId, this.state.PortfolioVideoUrl, 'V').then((updated) => {
                    this.portfolioMediaPhotoFormValidators = new Array<Validator>();
                    this.portfolioMediaUrlFormValidators = new Array<Validator>();

                    if (updated) {
                        //this.successMessage('Video added in your portfolio.');
                        this.setState({ PortfolioVideoUrl: '' });
                        //this.getPortfolio();

                        resolve(true);
                    }
                    else {
                        this.errorMessage('Video could not be added to your portfolio.');
                        this.setState({ showPortfolioProgress: false, PortfolioVideoUrl: '' });

                        resolve(false);
                    }
                });
            }
            else {
                resolve(true);
            }
            // }
        });
    }

    private handlePhotoVideoSave() {
        let isValid = this.validator.validateForm(this.portfolioMediaPhotoFormValidators);
        if (isValid) {
            isValid = this.validator.validateForm(this.portfolioMediaUrlFormValidators);
            if (isValid) {
                this.setState({ showNewProtfolioMediaDialog: false, showPortfolioProgress: true });
                this.handlePortfolioMediaFileUpload().then((media) => {
                    if (media) {
                        this.handlePortfolioMediaVideoUpload().then((video) => {
                            if (video) {
                                this.successMessage('Photo and video added in your portfolio.');
                            }

                            this.getPortfolio();
                        });
                    }
                });
            }
        }
    }

    private uploadProjectPhotos(projectId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let counter = 0;

            this.state.ProjectFiles.forEach((pFile) => {
                if (pFile.mode === 'N') {
                    this.db.uploadProjectPhoto(projectId, pFile.name, '/files/' + this.User.Id + '/portfolio/projects/' + projectId + '/photos/' + pFile.name, pFile).then((updated) => {
                        if (updated) {
                            counter++;
                            if (counter === this.state.ProjectFiles.length) {
                                resolve(true);
                            }
                        }
                        else {
                            counter++;
                            if (counter === this.state.ProjectFiles.length) {
                                resolve(false);
                            }
                        }
                    });
                }
                else if (pFile.mode === 'D' && pFile.id > 0) {
                    this.db.deleteProjectPhoto(projectId, pFile.id, '/files/' + this.User.Id + '/portfolio/projects/' + projectId + '/photos/' + pFile.name).then((updated) => {
                        if (updated) {
                            counter++;
                            if (counter === this.state.ProjectFiles.length) {
                                resolve(true);
                            }
                        }
                        else {
                            counter++;
                            if (counter === this.state.ProjectFiles.length) {
                                resolve(false);
                            }
                        }
                    });
                }
                else {
                    counter++;
                    if (counter === this.state.ProjectFiles.length) {
                        resolve(true);
                    }
                }
            });
        });
    }

    private handleProjectSaveClick() {
        let isValid = this.validator.validateForm(this.portfolioProjectFormValidators);
        if (isValid) {
            this.setState({ showNewProjectDialog: false, showPortfolioProgress: true });
            let { ProfileId, ProjectId, ProjectTitle, ProjectDescription, ProjectPrice, ProjectService, ProjectYear } = this.state;
            let itemtoUpdate: any = { pId: ProfileId, pTitle: ProjectTitle, pDesc: ProjectDescription, pPrice: ProjectPrice, pService: ProjectService, pYear: ProjectYear };

            if (ProjectId === 0) {
                this.db.addFeaturedProject(itemtoUpdate).then((projectId) => {
                    if (projectId > 0) {
                        this.uploadProjectPhotos(projectId).then((uploaded) => {
                            if (uploaded) {
                                this.successMessage('Featured project added in your portfolio.');
                                this.setState({ ProjectTitle: '', ProjectDescription: '', ProjectService: '', ProjectYear: '', ProjectPrice: '', ProjectFiles: [] });
                                this.getPortfolio();
                            }
                            else {
                                this.errorMessage('Featured project could not be added to your portfolio.');
                                this.setState({ showPortfolioProgress: false, ProjectTitle: '', ProjectDescription: '', ProjectService: '', ProjectYear: '', ProjectPrice: '', ProjectFiles: [] });
                            }
                        });
                    }
                    else {
                        this.errorMessage('Featured project could not be added to your portfolio.');
                        this.setState({ showPortfolioProgress: false, ProjectTitle: '', ProjectDescription: '', ProjectService: '', ProjectYear: '', ProjectPrice: '', ProjectFiles: [] });
                    }
                });
            }
            else {
                itemtoUpdate.itemId = ProjectId;

                this.db.updateFeaturedProject(itemtoUpdate).then((updated) => {
                    if (updated) {
                        this.uploadProjectPhotos(ProjectId).then((updated) => {
                            if (updated) {
                                this.successMessage('Featured project updated.');
                                this.setState({ ProjectTitle: '', ProjectDescription: '', ProjectService: '', ProjectYear: '', ProjectPrice: '', ProjectFiles: [], ProjectId: 0 });
                                this.getPortfolio();
                            }
                            else {
                                this.errorMessage('Featured project could not be updated.');
                                this.setState({ showPortfolioProgress: false, ProjectTitle: '', ProjectDescription: '', ProjectService: '', ProjectYear: '', ProjectPrice: '', ProjectFiles: [], ProjectId: 0 });
                            }
                        });
                    }
                    else {
                        this.errorMessage('Featured project could not be updated.');
                        this.setState({ showPortfolioProgress: false, ProjectTitle: '', ProjectDescription: '', ProjectService: '', ProjectYear: '', ProjectPrice: '', ProjectFiles: [], ProjectId: 0 });
                    }
                });
            }
        }
    }

    private checkProjectFileExists(fileName, index) {
        let exists = null;
        if (fileName && this.state.ProjectPhotos && this.state.ProjectPhotos.length > 0) {
            exists = this.state.ProjectPhotos.find((_m: any) => {
                return (_m.Name.toLowerCase() === fileName.toLowerCase());
            });
        }

        if (!exists) {
            exists = this.state.ProjectFiles.find((_m: any, indx) => {
                return (_m.name.toLowerCase() === fileName.toLowerCase() && index !== indx && _m.mode !== 'D');
            });
        }

        return (exists ? '' : '1');
    }

    private editFeaturedProject(item) {
        let _pFiles = item.Media;
        let projectFiles: any[] = [];

        if (_pFiles && _pFiles.length > 0) {
            _pFiles.forEach((pFile) => {
                projectFiles.push({ id: pFile.Id, name: pFile.Name, url: pFile.LinkUrl, mode: '' });
            });
        }

        this.setState({
            ProjectId: item.Id, ProjectFiles: projectFiles, ProjectTitle: item.Name, ProjectDescription: item.Description, ProjectPrice: item.Price,
            ProjectService: (item.Service ? item.Service.Id.toString() : ''), ProjectServiceName: (item.Service ? item.Service.Name.toString() : ''),
            ProjectYear: item.Year.toString(), showNewProjectDialog: true, DialogMode: ''
        });
    }

    private viewFeaturedProject(item) {
        let _pFiles = item.Media;
        let projectFiles: any[] = [];

        if (_pFiles && _pFiles.length > 0) {
            _pFiles.forEach((pFile) => {
                projectFiles.push({ id: pFile.Id, name: pFile.Name, url: pFile.LinkUrl, mode: '' });
            });
        }

        this.setState({
            ProjectId: item.Id, ProjectFiles: projectFiles, ProjectTitle: item.Name, ProjectDescription: item.Description, ProjectPrice: item.Price,
            ProjectService: (item.Service ? item.Service.Id.toString() : ''), ProjectServiceName: (item.Service ? item.Service.Name.toString() : ''),
            ProjectYear: item.Year.toString(), showNewProjectDialog: true, DialogMode: 'V'
        });
    }

    private handleFeaturedProjectDelete(item) {
        this.setState({ showConfirmation: true, LicenseId: item.Id, callBackMethod: this.deleteFeaturedProject.bind(this), ConfirmationMessage: "Are you sure you want to delete this featured project?" });
    }

    private deleteFeaturedProject() {
        this.setState({ showPortfolioProgress: true, showConfirmation: false });
        this.db.deleteFeaturedProject(this.state.LicenseId, '/files/' + this.User.Id + '/portfolio/projects/' + this.state.LicenseId + '/photos').then((deleted) => {
            if (deleted) {
                this.successMessage('Featured project deleted from the portfolio.');
                this.setState({ ConfirmationMessage: '', LicenseId: 0, callBackMethod: null });
                this.getPortfolio();
            }
            else {
                this.errorMessage('Featured project could not be deleted.');
                this.setState({ showPortfolioProgress: false, ConfirmationMessage: '', LicenseId: 0, callBackMethod: null });
            }
        });
    }

    private getHighRatedFor() {
        let highRated = this.state.HighRatedParameters;
        let template = null;

        if (highRated && highRated.length > 0) {
            template = highRated.map((hR, indx) => {
                if (indx < highRated.length - 1)
                    return (<span className="highRatedParameter">{hR.Name}, </span>);
                else
                    return (<span className="highRatedParameter"> and {hR.Name}.</span>);
            });
        }

        return template;
    }

    private postComment(parentComment) {
        const { ProfileId, ReplyText } = this.state;

        let isValid = this.validator.validateForm(this.commentReplyFormValidators);
        if (isValid) {
            this.setState({ showReviewsProgress: true });
            this.db.addProfileComment(ProfileId, ReplyText, parentComment.Id, this.User.DisplayName, this.User.Email, 0).then((added) => {
                if (added) {
                    this.successMessage('Comment posted successfully.');
                    this.setState({ showReplyId: '0', ParentCommentId: '0', ReplyText: '' });
                    this.getReviews();
                }
                else {
                    this.errorMessage('Comment could not be posted.');
                    this.setState({ showReviewsProgress: false });
                }
            });
        }
    }

    private getChildComments(comment) {
        const { showReplyId, ProfileComments, ReplyText } = this.state;
        let childComments = ProfileComments.filter((pC) => { return (pC.ParentId === comment.Id); });

        if (childComments && childComments.length > 0) {
            let template = null;
            template = childComments.map((cmt) => {
                return (<div className="row mt-2">
                    <div className="col-12 col-sm-12">
                        <div className="commentReplyContainer">
                            <div className="row">
                                <div className="col-12 col-sm-8 pt-1">
                                    <span className="commentAuthor">{cmt.UserName}'s reply</span>
                                </div>
                                <div className="col-12 col-sm-2 text-right pt-1">
                                    <span className="commentDate">{this.getFormattedDate(new Date(this.getDateNumber(cmt.CreatedOn)), Constants.IDateTimeFormat.ddMMMyyyy, '/')}</span>
                                </div>
                                <div className="col-12 col-sm-2 text-right pr-0">
                                    {showReplyId !== cmt.Id.toString() ? (<Typography variant="caption"><IconButton onClick={(e) => { this.setState({ showReplyId: cmt.Id.toString() }); }} title="Reply"><ReplyIcon /></IconButton></Typography>) : (null)}
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span className="commentBody">{cmt.CommentText}</span>
                                </div>
                                {showReplyId === cmt.Id.toString() ? (
                                    <div className="row mt-2">
                                        <div className="col-12 col-sm-12">
                                            <div className="commentReplyContainer">
                                                <div className="row">
                                                    <div className="col-12 col-sm-10">
                                                        <input key={"txtReply_" + cmt.Id.toString()} id={"txtReply_" + cmt.Id.toString()} name={"txtReply_" + cmt.Id.toString()} className="form-control" defaultValue={ReplyText} autoFocus={true} onBlur={(e) => { this.setState({ ReplyText: e.target.value }); }} />
                                                        <Validator key={"valReplyText_" + cmt.Id.toString()} id={"valReplyText_" + cmt.Id.toString()} type="required" errorMessage="Provide reply text." controlId={"txtReply_" + cmt.Id.toString()} cssClass="error" valueToValidate={ReplyText} formValidators={this.commentReplyFormValidators}></Validator>
                                                    </div>
                                                    <div className="col-12 col-sm-2 text-right">
                                                        <IconButton key={"iconPostBtn_" + cmt.Id.toString()} onClick={(e) => { this.postComment(cmt); }} title="Post">
                                                            <SendOutlined />
                                                        </IconButton>
                                                        <IconButton key={"iconCancelBtn_" + cmt.Id.toString()} onClick={(e) => { this.setState({ showReplyId: '0', ParentCommentId: '0', ReplyText: '' }); }} title="Cancel">
                                                            <CancelOutlined />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>) : (null)}
                                <React.Fragment>
                                    {this.getChildComments(cmt)}
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>);
            });

            return template;
        }
        else {
            return (null);
        }
    }

    private handleAskForReviewsClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid && this.state.Recipients && this.state.Recipients.length > 0) {
            //let _emails: string = '';

            let emailTemplate = Constants.Email.AskForReviews;
            let subject = emailTemplate.Subject.replace('{0}', this.state.BusinessName);
            let body = emailTemplate.Body;

            let counter = 0;

            this.state.Recipients.forEach((cust, indx) => {
                body = body.replace("[username]", cust.Profile.User.FirstName);
                body = body.replace("[businessname]", this.state.BusinessName);
                body = body.replace("[rid]", this.state.ProfileId.toString());
                body = body.replace("[em]", this.state.Email);

                if (cust.Profile.User.Email) {
                    this.db.sendEmail(cust.Profile.User.Email, subject, body).then((emailSent) => {
                        counter++;
                        if (counter === this.state.Recipients.length) {
                            this.successMessage('Review request sent successfully.');
                            this.formValidators = new Array<Validator>();
                            this.setState({ showAskForReviewDialog: false, Recipients: [] });
                        }
                    });
                }
            });

            // this.state.Recipients.forEach((cust, indx) => {
            //     if (indx < this.state.Recipients.length - 1)
            //         _emails += cust.Profile.User.Email + ",";
            //     else
            //         _emails += cust.Profile.User.Email;
            // });

            // this.db.askForReviews(_emails, this.state.ProfileId).then((asked) => {
            //     if (asked) {
            //         this.successMessage('Review request sent successfully.');
            //         this.formValidators = new Array<Validator>();
            //         this.setState({ showAskForReviewDialog: false, Recipients: [], ReviewRecipients: [] });
            //     }
            //     else {
            //         this.errorMessage('Review request could not be sent.');
            //     }
            // });
        }
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {

        let { loading, ActiveTab, ActiveProfileTab, Description, FirstName, LastName, Email, Phone, Address, ProfileImage, DisplayName,
            Pin, Year, WebSiteUrl, Payments, FeaturedProjects, PortfolioMedia, ConfirmationType,
            CurrentPassword, NewPassword, ConfirmPassword, Role, BusinessName, IsActive, ProfileId, TwitterUrl, FacebookUrl, InstagramUrl, YouTubeUrl, LinkedInUrl,
            showGeneralProgress, showSocialMediaProgress, showPaymentsProgress, showLicenseProgress, showPortfolioProgress, showConfirmation, showNewProjectDialog,
            ConfirmationMessage, Licenses, ShowLicenseDialog, showFileUploadDialog, showNewProtfolioMediaDialog, PortfolioVideoUrl,
            LicenseName, LicenseDescription, LicenseInstitution, LicenseStartDate, LicenseEndDate, FileName, DialogMode, ProjectServiceName, OverallRating,
            TotalReviewsCount, showReviewsProgress, showReplyId, ReplyText, ProfileRatings, HighRatedParameters, ProfileRootComments,
            ShowMessage, Message, MessageType, Services, Years, ProjectTitle, ProjectService, ProjectYear, ProjectPrice, ProjectDescription, ProjectFiles,
            showAskForReviewDialog, Recipients, ReviewRecipients, } = this.state; //Pins,SelectedPin 

        let template: any = null;//<Loader open={loading} onClick={(e) => { }} />;

        // if (!loading) {
        if (!this.IsUserValid) {
            window.location.href = '/login';
        }
        else {
            template = (<React.Fragment>
                {/* <Header /> */}
                <section className=""> {/* accountSettingContainer borderBtm */}
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                {/* <h5>Account Settings</h5> */}
                                <div className="row">
                                    {/* <div className="col-12 col-sm-3">
                                        <div className="accountNavigation">
                                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                <a className={"nav-link " + (ActiveTab === 0 ? "active" : "")} id="v-pills-home-tab" aria-disabled={!IsActive} onClick={(e) => { window.location.href = '/profile'; }}>Profile</a>
                                                {!IsActive ? (null) : (<React.Fragment> <a className={"nav-link " + (ActiveTab === 1 ? "active" : "")} id="v-pills-profile-tab" onClick={(e) => { this.handleTabChange(1); }}>Password</a>
                                                    <a className={"nav-link " + (ActiveTab === 2 ? "active" : "")} id="v-pills-messages-tab" onClick={(e) => { this.handleTabChange(2); }}>Notification</a>
                                                    <a className={"nav-link " + (ActiveTab === 3 ? "active" : "")} id="v-pills-deactivate-tab" onClick={(e) => { this.handleTabChange(3); }}>Deactivate</a>
                                                </React.Fragment>)}
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12 col-sm-12">
                                        <div className="tab-content" id="v-pills-tabContent">
                                            <div className={"tab-pane fade " + (ActiveTab === 0 ? "show active" : "")} id="v-pills-home">
                                                {ActiveTab !== 0 ? (null) : (<div className="profileContainer">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <div className="formContainer">
                                                                {/* <div className="headingTxt">Profile</div> */}
                                                                {!IsActive ? (<div className="switchControlContainter mt-1">
                                                                    <div className="box">
                                                                        <div className="custom-control custom-switch">
                                                                            <span>Your account has been deactivated. </span><a href='/reactivate?id='>Click here</a> to submit a request to re-activate your account.
                                                                        </div>
                                                                    </div>
                                                                </div>) : (<React.Fragment>
                                                                    {loading ? (<ProgressBar />) : (
                                                                        <Box sx={{ width: '100%' }}>
                                                                            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                                                 <Tabs value={ActiveProfileTab} onChange={(e, v) => { this.handleProfileTabClick(v); }} aria-label="Profile" variant="standard"
                                                                                    scrollButtons={true} allowScrollButtonsMobile>
                                                                                    <Tab label="General" id="tb0" aria-controls="tbp0" />
                                                                                    <Tab label="Social" id="tb1" aria-controls="tbp1" hidden={Role !== Constants.UserRoles.Pro} />
                                                                                    <Tab label="Payments" id="tb2" aria-controls="tbp2" hidden={Role !== Constants.UserRoles.Pro} />
                                                                                    <Tab label="Accreditations" id="tb3" aria-controls="tbp3" hidden={Role !== Constants.UserRoles.Pro} />
                                                                                    <Tab label="Portfolio" id="tb4" aria-controls="tbp4" hidden={Role !== Constants.UserRoles.Pro} />
                                                                                    <Tab label="Reviews" id="tb5" aria-controls="tbp5" hidden={Role !== Constants.UserRoles.Pro} />
                                                                                </Tabs>
                                                                            </Box> */}
                                                                            <TabPanel value={ActiveProfileTab} index={0} id={"tbp0"}>
                                                                                {showGeneralProgress ? (<ProgressBar />) : (<div className="mt-3">
                                                                                    {Role === Constants.UserRoles.Pro ? (<React.Fragment>
                                                                                        <div className="row">
                                                                                            <div className="col-4 col-sm-2">
                                                                                                <div className="form-group">
                                                                                                    <Stack direction={"row"}>
                                                                                                        <Avatar alt={DisplayName} src={ProfileImage ? ProfileImage : '/images/profile.png'} variant="square" sx={{ width: 100, height: 100 }}></Avatar>
                                                                                                        <div className="editPhoto">
                                                                                                            <IconButton onClick={(e) => { this.setState({ showFileUploadDialog: true }); }} className="p-0"><EditIcon fontSize="small"></EditIcon></IconButton>
                                                                                                        </div>
                                                                                                    </Stack>
                                                                                                </div>
                                                                                            </div>
                                                                                            {this.props.mode === 'A' ? (<>
                                                                                                <div className="col-4 col-sm-5">
                                                                                                    <div className="form-group">
                                                                                                        <label>First name</label>
                                                                                                        <input id="fname" name="fname" type="text" className="form-control" placeholder="" defaultValue={FirstName} onBlur={(e) => { this.setState({ FirstName: e.target.value }); }} />
                                                                                                        <Validator id="valFName" type='required' cssClass="error" errorMessage="Provide first name." controlId="fname" valueToValidate={FirstName} formValidators={this.formValidators}></Validator>
                                                                                                        <Validator id="valFNameLength" type='max_length' cssClass="error" errorMessage="First name cannot exceed 45 characters." maxLength={45} controlId="fname" valueToValidate={ActiveTab === 0 ? FirstName : '1'} formValidators={this.formValidators}></Validator>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-sm-5">
                                                                                                    <div className="form-group">
                                                                                                        <label>Last name</label>
                                                                                                        <input id="lname" name="lname" type="text" className="form-control" placeholder="" defaultValue={LastName} onBlur={(e) => { this.setState({ LastName: e.target.value }); }} />
                                                                                                        {/* <Validator id="valLName" type='required' cssClass="error" errorMessage="Provide last name." controlId="lname" valueToValidate={LastName} formValidators={this.formValidators}></Validator> */}
                                                                                                        <Validator id="valLNameLength" type='max_length' cssClass="error" errorMessage="Last name cannot exceed 45 characters." maxLength={45} controlId="lname" valueToValidate={ActiveTab === 0 ? LastName : '1'} formValidators={this.formValidators}></Validator>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>) : (<>
                                                                                                <div className="col-8 col-sm-10">
                                                                                                    <div className="form-group">
                                                                                                        <label>Business name</label>
                                                                                                        <input id="bName" name="bName" type="text" className="form-control" placeholder="" defaultValue={BusinessName} onBlur={(e) => { this.setState({ BusinessName: e.target.value }); }} />
                                                                                                        <Validator id="valBName" type='required' cssClass="error" errorMessage="Provide business name." controlId="bName" valueToValidate={BusinessName} formValidators={this.formValidators}></Validator>
                                                                                                        <Validator id="valBName" type='max_length' cssClass="error" errorMessage="Business name cannot exceed 100 characters." maxLength={100} controlId="bName" valueToValidate={BusinessName} formValidators={this.formValidators}></Validator>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>)}
                                                                                        </div>
                                                                                        {this.props.mode === 'P' ? (<div className="form-group">
                                                                                            <label>Introduction</label>
                                                                                            <textarea id="intro" rows={4} className="form-control" placeholder="" defaultValue={Description} onBlur={(e) => { this.setState({ Description: e.target.value }); }} />
                                                                                            <Validator id="valIntro" type='required' cssClass="error" errorMessage="Provide introduction." controlId="intro" valueToValidate={Description} formValidators={this.formValidators}></Validator>
                                                                                            <Validator id="valIntroLength" type='max_length' cssClass="error" errorMessage="Introduction cannot exceed 500 characters." maxLength={500} controlId="intro" valueToValidate={Description} formValidators={this.formValidators}></Validator>
                                                                                        </div>) : (null)}
                                                                                    </React.Fragment>) : (<>
                                                                                        <div className="row">
                                                                                            <div className="col-4 col-sm-2">
                                                                                                <div className="form-group">
                                                                                                    <Stack direction={"row"}>
                                                                                                        <Avatar alt={DisplayName} src={ProfileImage ? ProfileImage : '/images/profile.png'} variant="square" sx={{ width: 100, height: 100 }}></Avatar>
                                                                                                        <div className="editPhoto">
                                                                                                            <IconButton onClick={(e) => { this.setState({ showFileUploadDialog: true }); }} className="p-0"><EditIcon fontSize="small"></EditIcon></IconButton>
                                                                                                        </div>
                                                                                                    </Stack>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-4 col-sm-5">
                                                                                                <div className="form-group">
                                                                                                    <label>First name</label>
                                                                                                    <input id="fname" name="fname" type="text" className="form-control" placeholder="" defaultValue={FirstName} onBlur={(e) => { this.setState({ FirstName: e.target.value }); }} />
                                                                                                    <Validator id="valFName" type='required' cssClass="error" errorMessage="Provide first name." controlId="fname" valueToValidate={FirstName} formValidators={this.formValidators}></Validator>
                                                                                                    <Validator id="valFNameLength" type='max_length' cssClass="error" errorMessage="First name cannot exceed 45 characters." maxLength={45} controlId="fname" valueToValidate={ActiveTab === 0 ? FirstName : '1'} formValidators={this.formValidators}></Validator>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-4 col-sm-5">
                                                                                                <div className="form-group">
                                                                                                    <label>Last name</label>
                                                                                                    <input id="lname" name="lname" type="text" className="form-control" placeholder="" defaultValue={LastName} onBlur={(e) => { this.setState({ LastName: e.target.value }); }} />
                                                                                                    {/* <Validator id="valLName" type='required' cssClass="error" errorMessage="Provide last name." controlId="lname" valueToValidate={LastName} formValidators={this.formValidators}></Validator> */}
                                                                                                    <Validator id="valLNameLength" type='max_length' cssClass="error" errorMessage="Last name cannot exceed 45 characters." maxLength={45} controlId="lname" valueToValidate={ActiveTab === 0 ? LastName : '1'} formValidators={this.formValidators}></Validator>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className="row">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group">
                                                                                                    <label>Introduction</label>
                                                                                                    <textarea id="intro" rows={4} className="form-control" placeholder="" defaultValue={Description} onBlur={(e) => { this.setState({ Description: e.target.value }); }} />
                                                                                                    <Validator id="valIntro" type='required' cssClass="error" errorMessage="Provide introduction." controlId="intro" valueToValidate={Description} formValidators={this.formValidators}></Validator>
                                                                                                    <Validator id="valIntroLength" type='max_length' cssClass="error" errorMessage="Introduction cannot exceed 500 characters." maxLength={500} controlId="intro" valueToValidate={Description} formValidators={this.formValidators}></Validator>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                    </>
                                                                                    )}
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className="form-group">
                                                                                                <label>Email</label>
                                                                                                <input id="email" type="email" className="form-control" placeholder="" defaultValue={Email} onBlur={(e) => { this.setState({ Email: e.target.value }); }} />
                                                                                                <Validator id="valEmail" type='required' cssClass="error" errorMessage="Provide email." controlId="email" valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                                                                                <Validator id="valEmailFormat" type='email' cssClass="error" controlId="email" errorMessage="Invalid email." valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                                                                                <Validator id="valEmailLength" type='max_length' cssClass="error" errorMessage="Email cannot exceed 45 characters." maxLength={45} controlId="email" valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className="form-group">
                                                                                                <label>Phone Number (optional)</label>
                                                                                                <input id="phone" type="text" className="form-control" placeholder="" defaultValue={Phone} onBlur={(e) => { this.setState({ Phone: e.target.value }); }} />
                                                                                                {/* <Validator id="valPhone" type='required' cssClass="error" errorMessage="Provide phone number." controlId="phone" valueToValidate={Phone} formValidators={this.formValidators}></Validator> */}
                                                                                                <Validator id="valPhoneFormat" type='phone' cssClass="error" controlId="phone" errorMessage="Invalid phone number." valueToValidate={Phone} formValidators={this.formValidators}></Validator>
                                                                                                <Validator id="valPhoneLength" type='max_length' cssClass="error" errorMessage="Phone number cannot exceed 12 characters." maxLength={12} controlId="phone" valueToValidate={Phone} formValidators={this.formValidators}></Validator>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Address</label>
                                                                                        <textarea id="address" rows={3} className="form-control" placeholder="" defaultValue={Address} onBlur={(e) => { this.setState({ Address: e.target.value }); }} />
                                                                                        {/* <Validator id="valAddress" type='required' cssClass="error" errorMessage="Provide address." controlId="address" valueToValidate={Address} formValidators={this.formValidators}></Validator> */}
                                                                                        <Validator id="valAddressLength" type='max_length' cssClass="error" errorMessage="Address cannot exceed 100 characters." maxLength={100} controlId="address" valueToValidate={Address} formValidators={this.formValidators}></Validator>
                                                                                    </div>
                                                                                    {/* <div className="row">
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className="form-group">
                                                                                                <label>State</label>
                                                                                                <AutoComplete id="state" onChange={(e) => { this.setState({ StateId: (e && e.value ? e.value : ''), StateName: (e && e.key ? e.key : '') }); this.filterCities(e); }} className="form-control" key="account_state" defaultValue={StateName} data={FilteredStates && FilteredStates.length > 0 ? FilteredStates : []} />
                                                                                                <Validator id="valState" type='required' cssClass="error" errorMessage="Provide state." controlId="state" valueToValidate={StateName} formValidators={this.formValidators}></Validator>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className="form-group">
                                                                                                <label>City</label>
                                                                                                <AutoComplete id="city" onChange={(e) => { this.setState({ CityId: (e && e.value ? e.value : ''), CityName: (e && e.key ? e.key : '') }); this.filterPins(e); }} className="form-control" key="account_city" defaultValue={CityName} data={FilteredCities && FilteredCities.length > 0 ? FilteredCities : []} />
                                                                                                <Validator id="valCity" type='required' cssClass="error" errorMessage="Provide city." controlId="city" valueToValidate={CityName} formValidators={this.formValidators}></Validator>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}
                                                                                    {Role === Constants.UserRoles.Pro ? (<React.Fragment>
                                                                                        <div className="row">
                                                                                            <div className={"col-12 " + (this.props.mode === 'P' ? "col-sm-6" : "col-sm-12")}>
                                                                                                <div className="form-group">
                                                                                                    <label>Zip code</label>
                                                                                                    <input id="zip" name="zip" type="text" className="form-control" defaultValue={Pin} />
                                                                                                    <div id="map"></div>
                                                                                                    {/* <Autocomplete multiple={false} id="pin" className="autoComplete" options={Pins && Pins.length > 0 ? Pins : []} getOptionLabel={(option) => { return (option && option.Pin ? option.Pin : ''); }}
                                                                                                        onChange={(e, newVal) => { this.setState({ SelectedPin: newVal ? newVal : null, Pin: newVal ? newVal.Pin.toString() : '', CityId: newVal ? newVal.Id.toString() : '', StateId: newVal && newVal.State ? newVal.State.Id.toString() : '' }); }} renderOption={(props, option) => (
                                                                                                            <Box component="li" {...props}>
                                                                                                                <Typography variant="subtitle2" >{option.Pin}</Typography>
                                                                                                                <Typography variant="caption" color="text.secondary" className="commentBody mr-2">{" - " + option.Name + ", " + option.State.Code}</Typography>
                                                                                                            </Box>
                                                                                                        )} defaultValue={SelectedPin} filterSelectedOptions={true} renderInput={(params) => (<TextField name="pin" {...params} label="" placeholder="" />)} /> */}
                                                                                                    {/* <AutoComplete id="pin" onChange={(e) => { this.setState({ Pin: (e && e.value ? e.value : '') }); this.setCityAndState(e); }} className="form-control" key="account_pin" defaultValue={Pin} data={FilteredPins && FilteredPins.length > 0 ? FilteredPins : []} /> */}
                                                                                                    <Validator id="valPin" type='required' cssClass="error" errorMessage="Provide zip code." controlId="pin" valueToValidate={Pin} formValidators={this.formValidators}></Validator>
                                                                                                    <Validator id="valPinFormat" type='number' cssClass="error" controlId="pin" errorMessage="Invalid zip code." valueToValidate={Pin} formValidators={this.formValidators}></Validator>
                                                                                                </div>
                                                                                            </div>
                                                                                            {this.props.mode === 'P' ? (<div className="col-12 col-sm-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Year Founded</label>
                                                                                                    <input id="year" name="year" type="text" className="form-control" defaultValue={Year} onBlur={(e) => { this.setState({ Year: e.target.value }); }} />
                                                                                                    <Validator id="valYear" type='required' cssClass="error" errorMessage="Provide year." controlId="year" valueToValidate={Year} formValidators={this.formValidators}></Validator>
                                                                                                </div>
                                                                                            </div>) : (null)}
                                                                                        </div>
                                                                                        {this.props.mode === 'P' ? (<div className="form-group">
                                                                                            <label>Website (optional)</label>
                                                                                            <input id="siteurl" type="url" className="form-control" placeholder="" defaultValue={WebSiteUrl} onBlur={(e) => { this.setState({ WebSiteUrl: e.target.value }); }} />
                                                                                            <Validator id="valUrlFormat" type='url' cssClass="error" controlId="siteurl" errorMessage="Invalid pin." valueToValidate={WebSiteUrl} formValidators={this.formValidators}></Validator>
                                                                                        </div>) : (null)}
                                                                                    </React.Fragment>
                                                                                    ) : (
                                                                                        <div className="form-group">
                                                                                            <label>Zip code</label>
                                                                                            <input id="zip" name="zip" key={"zip_" + Pin} type="text" className="form-control" defaultValue={Pin} />
                                                                                            <div id="map"></div>
                                                                                            {/* <Autocomplete multiple={false} id="pin" className="autoComplete" options={Pins && Pins.length > 0 ? Pins : []} getOptionLabel={(option) => { return (option && option.Pin ? option.Pin : ''); }}
                                                                                                onChange={(e, newVal) => { this.setState({ SelectedPin: newVal ? newVal : null, Pin: newVal ? newVal.Pin.toString() : '', CityId: newVal ? newVal.Id.toString() : '', StateId: newVal && newVal.State ? newVal.State.Id.toString() : '' }); }} renderOption={(props, option) => (
                                                                                                    <Box component="li" {...props}>
                                                                                                        <Typography variant="subtitle2" >{option.Pin}</Typography>
                                                                                                        <Typography variant="caption" color="text.secondary" className="commentBody mr-2"> {" - " + option.Name + ", " + option.State.Code}</Typography>
                                                                                                    </Box>
                                                                                                )} defaultValue={SelectedPin} filterSelectedOptions={true} renderInput={(params) => (<TextField name="pin" {...params} label="" placeholder="" />)} /> */}
                                                                                            {/* <AutoComplete id="pin" onChange={(e) => { this.setState({ Pin: (e && e.value ? e.value : '') }); this.setCityAndState(e); }} className="form-control" key="account_pin" defaultValue={Pin} data={FilteredPins && FilteredPins.length > 0 ? FilteredPins : []} /> */}
                                                                                            <Validator id="valPin" type='required' cssClass="error" errorMessage="Provide zip code." controlId="pin" valueToValidate={Pin} formValidators={this.formValidators}></Validator>
                                                                                            <Validator id="valPinFormat" type='number' cssClass="error" controlId="pin" errorMessage="Invalid zip code." valueToValidate={Pin} formValidators={this.formValidators}></Validator>
                                                                                        </div>
                                                                                    )}
                                                                                    <div className="formButtonContainer text-right">
                                                                                        <button type="submit" className="btn btn-cancel" onClick={(e) => { this.props.onCancelClick(); }}>Cancel</button>
                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" onClick={(e) => { this.handleSaveAccountClick(); }}>Save</button>
                                                                                    </div>
                                                                                    <Dialog keepMounted TransitionComponent={this.SlideDownTransition} open={showFileUploadDialog} onClose={(e) => { this.fileUploadFormValidators = new Array<Validator>(); }} aria-labelledby={"dialogFile"}
                                                                                        aria-describedby={"dialogFileDesc"} fullWidth={true} maxWidth={"sm"} >
                                                                                        <DialogTitle id={"dialogEditLicenseTitle"} className="modalTitle">{"Upload Profile Picture"}
                                                                                            <IconButton aria-label="close" onClick={(e) => { this.setState({ showFileUploadDialog: false, File: null, FileName: '' }); }} className="btnCloseDialog">
                                                                                                <CloseIcon />
                                                                                            </IconButton>
                                                                                        </DialogTitle>
                                                                                        <DialogContent dividers={true}>
                                                                                            <div className="profileContainer">
                                                                                                <div className="formContainer">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Upload profile photo</label>
                                                                                                                <div>
                                                                                                                    <input id="fFile" name="fFile" accept=".png,.jpg" type="file" key={'fUpload_' + ProfileId} disabled={FileName !== null && FileName !== ''} className="inputfile" multiple={false} onChange={(e) => { if (e.target.files && e.target.files.length > 0) { let file: any = e.target.files[0]; this.setState({ File: file, FileName: file.name }); e.target.value = ''; } }} />
                                                                                                                    <label htmlFor="fFile">
                                                                                                                        <span aria-disabled={FileName !== null && FileName !== ''} className="btn btn-primary cutmBtnLogin">Choose file</span>
                                                                                                                    </label>
                                                                                                                    <div>
                                                                                                                        <Validator id="valFile" type='required' cssClass="error" errorMessage="Provide photo." controlId="fFile" valueToValidate={FileName} formValidators={this.fileUploadFormValidators}></Validator>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <Validator id="valffFileFileNameLength" type='max_length' maxLength={50} cssClass="error" errorMessage="File name (including extension) cannot exceed 50 characters." controlId="fLicense" valueToValidate={FileName} formValidators={this.fileUploadFormValidators}></Validator>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {FileName ? (
                                                                                                                <div className="form-group mt-1">
                                                                                                                    <DeleteIcon onClick={(e) => { this.setState({ File: null, FileName: '' }); }} /> {FileName}
                                                                                                                </div>) : (null)}
                                                                                                            {/* <div className="form-group">
                                                                                                                <label>Upload Photo</label>
                                                                                                                <input id="fFile" name="fFile" accept=".png,.jpg,.jpeg" type="file" key={'fUpload_' + ProfileId} className="form-control" placeholder="" multiple={false} onChange={(e) => { if (e.target.files && e.target.files.length > 0) { let file = e.target.files[0]; this.setState({ File: file, FileName: file.name }); } }} />
                                                                                                                <Validator id="valFile" type='required' cssClass="error" errorMessage="Provide photo." controlId="fFile" valueToValidate={FileName} formValidators={this.fileUploadFormValidators}></Validator>
                                                                                                                <Validator id="valFileNameLength" type='max_length' maxLength={50} cssClass="error" errorMessage="File name (including extension) cannot exceed 50 characters." controlId="fFile" valueToValidate={FileName} formValidators={this.fileUploadFormValidators}></Validator>
                                                                                                            </div> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </DialogContent>
                                                                                        <DialogActions>
                                                                                            <div className="formButtonContainer ">
                                                                                                <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.setState({ showFileUploadDialog: false, File: null, FileName: '' }); }}>Cancel</button>
                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handleProfilePicUpload(); }}>Upload</button>
                                                                                            </div>
                                                                                        </DialogActions>
                                                                                    </Dialog>
                                                                                </div>)}
                                                                            </TabPanel>
                                                                            {Role === Constants.UserRoles.Pro && this.props.mode === 'P' ? (<React.Fragment>
                                                                                <TabPanel value={ActiveProfileTab} index={1} id={"tbp1"}>
                                                                                    {showSocialMediaProgress ? (<ProgressBar />) : (<div className="mt-3">
                                                                                        <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>
                                                                                            Social media
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2" color="text.secondary">
                                                                                            Let your customers have more options to connect with you socially.
                                                                                        </Typography>
                                                                                        <div className="form-group mt-3">
                                                                                            <input id="tIcon" name="tIcon" type="text" className="form-control twitterIcon" placeholder="" defaultValue={TwitterUrl} onBlur={(e) => { this.setState({ TwitterUrl: e.target.value }); this.setSocialMediaLink(Constants.SocialMediaLinkType.Twitter, e.target.value); }} />
                                                                                            <Validator id="valTUrl" type='max_length' cssClass="error" errorMessage="Twitter url cannot exceed 1000 characters." maxLength={1000} controlId="tIcon" valueToValidate={TwitterUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                            <Validator id="valTUrlType" type='url' cssClass="error" errorMessage="Invalid url." controlId="tIcon" valueToValidate={TwitterUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                        </div>
                                                                                        <div className="form-group mt-3">
                                                                                            <input id="fIcon" name="fIcon" type="text" className="form-control facebookIcon" placeholder="" defaultValue={FacebookUrl} onBlur={(e) => { this.setState({ FacebookUrl: e.target.value }); this.setSocialMediaLink(Constants.SocialMediaLinkType.Facebook, e.target.value); }} />
                                                                                            <Validator id="valFUrl" type='max_length' cssClass="error" errorMessage="Facebook url cannot exceed 1000 characters." maxLength={1000} controlId="fIcon" valueToValidate={FacebookUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                            <Validator id="valFUrlType" type='url' cssClass="error" errorMessage="Invalid url." controlId="fIcon" valueToValidate={FacebookUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                        </div>
                                                                                        <div className="form-group mt-3">
                                                                                            <input id="lIcon" name="lIcon" type="text" className="form-control linkedInIcon" placeholder="" defaultValue={LinkedInUrl} onBlur={(e) => { this.setState({ LinkedInUrl: e.target.value }); this.setSocialMediaLink(Constants.SocialMediaLinkType.LinkedIn, e.target.value); }} />
                                                                                            <Validator id="valLUrl" type='max_length' cssClass="error" errorMessage="LinkedIn url cannot exceed 1000 characters." maxLength={1000} controlId="lIcon" valueToValidate={LinkedInUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                            <Validator id="valLUrlType" type='url' cssClass="error" errorMessage="Invalid url." controlId="lIcon" valueToValidate={LinkedInUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                        </div>
                                                                                        <div className="form-group mt-3">
                                                                                            <input id="YTIcon" name="YTIcon" type="text" className="form-control youTubeIcon" placeholder="" defaultValue={YouTubeUrl} onBlur={(e) => { this.setState({ YouTubeUrl: e.target.value }); this.setSocialMediaLink(Constants.SocialMediaLinkType.YouTube, e.target.value); }} />
                                                                                            <Validator id="valYTUrl" type='max_length' cssClass="error" errorMessage="YouTube url cannot exceed 1000 characters." maxLength={1000} controlId="YTIcon" valueToValidate={YouTubeUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                            <Validator id="valYTUrlType" type='url' cssClass="error" errorMessage="Invalid url." controlId="YTIcon" valueToValidate={YouTubeUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                        </div>
                                                                                        <div className="form-group mt-3">
                                                                                            <input id="INIcon" name="INIcon" type="text" className="form-control instagramIcon" placeholder="" defaultValue={InstagramUrl} onBlur={(e) => { this.setState({ InstagramUrl: e.target.value }); this.setSocialMediaLink(Constants.SocialMediaLinkType.Instagram, e.target.value); }} />
                                                                                            <Validator id="valINUrl" type='max_length' cssClass="error" errorMessage="Instagram url cannot exceed 1000 characters." maxLength={1000} controlId="INIcon" valueToValidate={InstagramUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                            <Validator id="valINUrlType" type='url' cssClass="error" errorMessage="Invalid url." controlId="INIcon" valueToValidate={InstagramUrl} formValidators={this.socialFormValidators}></Validator>
                                                                                        </div>
                                                                                        <div className="formButtonContainer text-right">
                                                                                            <button type="submit" className="btn btn-cancel" onClick={(e) => { this.props.onCancelClick(); }}>Cancel</button>
                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin ml-2" onClick={(e) => { this.handleSaveSocialMediaClick(); }}>Save</button>
                                                                                        </div>
                                                                                    </div>)}
                                                                                </TabPanel>
                                                                                <TabPanel value={ActiveProfileTab} index={2} id={"tbp2"}>
                                                                                    {showPaymentsProgress ? (<ProgressBar />) : (<div className="mt-3">
                                                                                        <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>
                                                                                            Payment methods accepted
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2" color="text.secondary">
                                                                                            Choose from a wide variety of payment methods.
                                                                                        </Typography>
                                                                                        {Payments && Payments.length > 0 ? (
                                                                                            <React.Fragment>
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        {Payments.map((item, index) => {
                                                                                                            return (<div className="col-12 col-sm-3">
                                                                                                                <FormControlLabel
                                                                                                                    control={<Checkbox checked={item.Checked} onChange={(e) => { if (item.Checked && !e.target.checked) { item.Mode = (item.Mode === 'E' ? 'D' : ''); } else if (!item.Checked && e.target.checked) { item.Mode = (item.Mode === '' ? 'N' : 'E'); } item.Checked = e.target.checked; this.setState({ Payments: Payments }); }}
                                                                                                                        value={item.Name} key={item.Id} title={item.Name} />} label={<Typography variant='subtitle2'>{item.Name}</Typography>} />
                                                                                                            </div>);
                                                                                                        })}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="formButtonContainer text-right">
                                                                                                    <button type="submit" className="btn btn-cancel" onClick={(e) => { this.props.onCancelClick(); }}>Cancel</button>
                                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin ml-2" onClick={(e) => { this.handlePaymentSaveClick(); }}>Save</button>
                                                                                                </div>
                                                                                            </React.Fragment>) : (null)}
                                                                                    </div>)}
                                                                                </TabPanel>
                                                                                <TabPanel value={ActiveProfileTab} index={3} id={"tbp3"}>
                                                                                    {showLicenseProgress ? (<ProgressBar />) : (<div className="mt-3">
                                                                                        <div className="form-group">
                                                                                            <div className="row">
                                                                                                <div className="col-9 col-sm-10">
                                                                                                    <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>
                                                                                                        Share details of your accreditation
                                                                                                    </Typography>
                                                                                                    <Typography variant="subtitle2" color="text.secondary">
                                                                                                        Increase your chances of getting hired and boost customer confidence by adding accreditations.
                                                                                                    </Typography>
                                                                                                </div>
                                                                                                <div className="col-3 col-sm-2">
                                                                                                    <div className="formButtonContainer text-right">
                                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ ShowLicenseDialog: true }); }}><AddIcon /></button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    <Box sx={{ flexGrow: 1 }}>
                                                                                                        <Grid container item spacing={3} columns={12}>
                                                                                                            {Licenses && Licenses.length > 0 ? (
                                                                                                                <React.Fragment>
                                                                                                                    {Licenses.map((item) => {
                                                                                                                        let subHeader = (<React.Fragment><div>{item.Institution}</div><div>{this.getFormattedDate(item.StartDate, Constants.IDateTimeFormat.MMddyyyy, '/') + " - " + this.getFormattedDate(item.EndDate, Constants.IDateTimeFormat.MMddyyyy, '/')}</div></React.Fragment>);
                                                                                                                        return (<Grid key={'grdItem_' + item.Id} item xs={12}>
                                                                                                                            <React.Fragment>
                                                                                                                                <Card key={'card_' + item.Id} variant="outlined" className="licenseCard">
                                                                                                                                    <CardHeader avatar={<Avatar sx={{ bgcolor: red[500] }}>{item.Name ? item.Name.charAt(0).toUpperCase() : ''}</Avatar>}
                                                                                                                                        title={item.Name} subheader={subHeader} />
                                                                                                                                    <CardContent className="licenseCardContentMaxHeight">
                                                                                                                                        <Typography variant="body2" color="text.secondary">
                                                                                                                                            {item.Description}
                                                                                                                                        </Typography>
                                                                                                                                    </CardContent>
                                                                                                                                    <CardActions disableSpacing>
                                                                                                                                        <IconButton aria-label="Edit license" onClick={(e) => { this.handleLicenseEdit(item); }}>
                                                                                                                                            <EditIcon />
                                                                                                                                        </IconButton>
                                                                                                                                        <IconButton aria-label="Delete license" onClick={(e) => { this.handleLicenseDelete(item); }}>
                                                                                                                                            <DeleteIcon />
                                                                                                                                        </IconButton>
                                                                                                                                        {item.Url ? (
                                                                                                                                            <IconButton aria-label="Download license" onClick={(e) => { window.open(item.Url); }}>
                                                                                                                                                <DownloadIcon />
                                                                                                                                            </IconButton>) : (null)}
                                                                                                                                    </CardActions>
                                                                                                                                </Card>
                                                                                                                            </React.Fragment>
                                                                                                                        </Grid>);
                                                                                                                    })}
                                                                                                                </React.Fragment>
                                                                                                            ) : (null)}
                                                                                                        </Grid>
                                                                                                    </Box>
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    <div className="formButtonContainer text-right">
                                                                                                        <button type="submit" className="btn btn-cancel" onClick={(e) => { this.props.onCancelClick(); }}>Back</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {!ShowLicenseDialog ? (null) : (
                                                                                                <Dialog keepMounted TransitionComponent={this.SlideDownTransition} open={ShowLicenseDialog} onClose={(e) => { this.licenseFormValidators = new Array<Validator>(); this.setState({ ShowLicenseDialog: false, LicenseId: 0, LicenseName: '', LicenseDescription: '', LicenseInstitution: '', LicenseStartDate: '', LicenseEndDate: '', File: null, FileName: '' }); }} aria-labelledby={"dialogEditLicenseTitle"}
                                                                                                    aria-describedby={"dialogEditLicenseDesc"} fullWidth={true} maxWidth={"sm"} >
                                                                                                    <DialogTitle id={"dialogEditLicenseTitle"} className="modalTitle">{LicenseName ? LicenseName : 'New Accreditation'}
                                                                                                        <IconButton aria-label="close" onClick={(e) => { this.setState({ ShowLicenseDialog: false, LicenseId: 0, LicenseName: '', LicenseDescription: '', LicenseInstitution: '', LicenseStartDate: '', LicenseEndDate: '', File: null, FileName: '' }); }} className="btnCloseDialog">
                                                                                                            <CloseIcon />
                                                                                                        </IconButton>
                                                                                                    </DialogTitle>
                                                                                                    <DialogContent dividers={true}>
                                                                                                        <div className="profileContainer">
                                                                                                            <div className="formContainer">
                                                                                                                <div className="row">
                                                                                                                    <div className="col-12 col-sm-12">
                                                                                                                        <div className="form-group">
                                                                                                                            <label>Name</label>
                                                                                                                            <input id="licenseName" name="licenseName" key={'lic_' + LicenseName} type="text" className="form-control" placeholder="" defaultValue={LicenseName} onBlur={(e) => { this.setState({ LicenseName: e.target.value }); }} />
                                                                                                                            <Validator id="valLicenseName" type='required' cssClass="error" errorMessage="Provide name." controlId="licenseName" valueToValidate={LicenseName} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                            <Validator id="valLicenseNameLength" type='max_length' cssClass="error" errorMessage="Name cannot exceed 100 characters." maxLength={100} controlId="licenseName" valueToValidate={LicenseName} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                        </div>
                                                                                                                        <div className="form-group">
                                                                                                                            <label>Description</label>
                                                                                                                            <textarea id="licenseDesc" name="licenseDesc" key={'licD_' + LicenseName} rows={4} className="form-control" placeholder="" defaultValue={LicenseDescription} onBlur={(e) => { this.setState({ LicenseDescription: e.target.value }); }} />
                                                                                                                            <Validator id="vallicenseDesc" type='required' cssClass="error" errorMessage="Provide description." controlId="licenseDesc" valueToValidate={LicenseDescription} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                            <Validator id="vallicenseDescLength" type='max_length' cssClass="error" errorMessage="Description cannot exceed 500 characters." maxLength={500} controlId="licenseDesc" valueToValidate={LicenseDescription} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                        </div>
                                                                                                                        <div className="form-group">
                                                                                                                            <label>Institution</label>
                                                                                                                            <input id="licenseInstitution" name="licenseInstitution" key={'licI_' + LicenseName} type="text" className="form-control" placeholder="" defaultValue={LicenseInstitution} onBlur={(e) => { this.setState({ LicenseInstitution: e.target.value }); }} />
                                                                                                                            <Validator id="vallicenseInstitution" type='required' cssClass="error" errorMessage="Provide institution name." controlId="licenseInstitution" valueToValidate={LicenseInstitution} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                            <Validator id="vallicenseInstitutionLength" type='max_length' cssClass="error" errorMessage="Institution name cannot exceed 100 characters." maxLength={100} controlId="licenseInstitution" valueToValidate={LicenseInstitution} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                        </div>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-12 col-sm-6">
                                                                                                                                <div className="form-group">
                                                                                                                                    <label>Achieved On</label>
                                                                                                                                    <input id="licenseStartDate" name="licenseStartDate" key={'licSD_' + LicenseName} type="date" className="form-control" placeholder="" defaultValue={this.getFormattedDate(LicenseStartDate, Constants.IDateTimeFormat.yyyyMMdd, '-')} onChange={(e) => { this.setState({ LicenseStartDate: e.target.value }); }} />
                                                                                                                                    <Validator id="vallicenseStartDate" type='required' cssClass="error" errorMessage="Provide achieved on date." controlId="licenseStartDate" valueToValidate={LicenseStartDate} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                                    <Validator id="vallicenseStartDateCompEndDate" type='compare' compareMode="date" compareOperator="<=" valueToCompareWith={LicenseEndDate} cssClass="error" errorMessage="Achieved on date cannot be greater than Expires on date." controlId="licenseStartDate" valueToValidate={LicenseStartDate} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                                    <Validator id="vallicenseStartDateCompToday" type='compare' compareMode="date" compareOperator="<" valueToCompareWith={new Date()} cssClass="error" errorMessage="Achieved on date cannot be greater than today." controlId="licenseStartDate" valueToValidate={LicenseStartDate} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-12 col-sm-6">
                                                                                                                                <div className="form-group">
                                                                                                                                    <label>Expires On</label>
                                                                                                                                    <input id="licenseEndDate" name="licenseStartDate" key={'licED_' + LicenseName} type="date" className="form-control" placeholder="" defaultValue={this.getFormattedDate(LicenseEndDate, Constants.IDateTimeFormat.yyyyMMdd, '-')} onChange={(e) => { this.setState({ LicenseEndDate: e.target.value }); }} />
                                                                                                                                    <Validator id="vallicenseEndDate" type='required' cssClass="error" errorMessage="Provide expires on date." controlId="licenseEndDate" valueToValidate={LicenseEndDate} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                                    <Validator id="vallicenseEndDateCompToday" type='compare' compareMode="date" compareOperator=">=" valueToCompareWith={new Date()} cssClass="error" errorMessage="Expires on date cannot be lesser than today." controlId="licenseEndDate" valueToValidate={LicenseEndDate} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group">
                                                                                                                            <label>Upload License Document</label>
                                                                                                                            <div>
                                                                                                                                <input id="fLicense" name="fLicense" type="file" key={'licFU_' + LicenseName} disabled={FileName !== null && FileName !== ''} className="inputfile" multiple={false} onChange={(e) => { if (e.target.files && e.target.files.length > 0) { let file: any = e.target.files[0]; this.setState({ File: file, FileName: file.name }); e.target.value = ''; } }} />
                                                                                                                                <label htmlFor="fLicense">
                                                                                                                                    <span aria-disabled={FileName !== null && FileName !== ''} className="btn btn-primary cutmBtnLogin">Choose file</span>
                                                                                                                                </label>
                                                                                                                                <div>
                                                                                                                                    <Validator id="valfLicenseFileNameLength" type='max_length' maxLength={50} cssClass="error" errorMessage="File name (including extension) cannot exceed 50 characters." controlId="fLicense" valueToValidate={FileName} formValidators={this.licenseFormValidators}></Validator>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {FileName ? (
                                                                                                                            <div className="form-group mt-1">
                                                                                                                                <DeleteIcon onClick={(e) => { this.setState({ File: null, FileName: '' }); }} /> {FileName}
                                                                                                                            </div>) : (null)}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </DialogContent>
                                                                                                    <DialogActions>
                                                                                                        <div className="formButtonContainer ">
                                                                                                            <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.setState({ ShowLicenseDialog: false, LicenseId: 0, LicenseName: '', LicenseDescription: '', LicenseInstitution: '', LicenseStartDate: '', LicenseEndDate: '', File: null, FileName: '' }); }}>Cancel</button>
                                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handleLicenseSaveClick(); }}>Save</button>
                                                                                                        </div>
                                                                                                    </DialogActions>
                                                                                                </Dialog>)}
                                                                                        </div>
                                                                                    </div>)}
                                                                                </TabPanel>
                                                                                <TabPanel value={ActiveProfileTab} index={4} id={"tbp4"}>
                                                                                    {showPortfolioProgress ? (<ProgressBar />) : (<div className="mt-3">
                                                                                        <div className="form-group">
                                                                                            <div className="row">
                                                                                                <div className="col-9 col-sm-10">
                                                                                                    <Typography variant="h6" color="text.primary">
                                                                                                        Photos and Videos
                                                                                                    </Typography>
                                                                                                </div>
                                                                                                <div className="col-3 col-sm-2">
                                                                                                    <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showNewProtfolioMediaDialog: true }); }}><AddIcon /></button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>
                                                                                                Show off your business
                                                                                            </Typography>
                                                                                            <Typography variant="subtitle2" color="text.secondary">
                                                                                                Include photos/videos of your work (before and after), teams, workspace or equipment.
                                                                                            </Typography>
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 mt-3">
                                                                                                    <Box className="boxPortfolioMedia">
                                                                                                        <Grid container item spacing={0} columns={12}>
                                                                                                            {PortfolioMedia && PortfolioMedia.length > 0 ? (
                                                                                                                <React.Fragment>
                                                                                                                    {PortfolioMedia.map((item) => {
                                                                                                                        let thumbnailUrl = item.Url;
                                                                                                                        let isYouTube = false;
                                                                                                                        let videoUrl = item.Url;

                                                                                                                        if (thumbnailUrl.toLowerCase().indexOf('youtube.com') >= 0) {
                                                                                                                            let videoID = item.Url.substring(item.Url.indexOf('v=') + 2);

                                                                                                                            isYouTube = true;
                                                                                                                            thumbnailUrl = "https://img.youtube.com/vi/" + videoID + "/0.jpg";
                                                                                                                            videoUrl = "https://www.youtube.com/embed/" + videoID;
                                                                                                                        }
                                                                                                                        else if (thumbnailUrl.toLowerCase().indexOf('vimeo.com') >= 0) {
                                                                                                                            let videoID = videoUrl.substring(videoUrl.lastIndexOf('/') + 1);

                                                                                                                            isYouTube = true;
                                                                                                                            videoUrl = "https://player.vimeo.com/video/" + videoID + "?fullscreen=1";
                                                                                                                        }

                                                                                                                        // return (<Grid key={'grdItem_' + item.Id} item xs={4}>
                                                                                                                        //     <React.Fragment>
                                                                                                                        //         <Card key={'card_' + item.Id} variant="outlined" className="portfolioMediaCard">
                                                                                                                        //             {item.Type === 'V' ? (<CardMedia component={isYouTube ? "iframe" : "video"} height="194" playsInline={true} allowFullScreen={true} frameBorder={"0"} src={videoUrl} controls={true} title={item.Name} />) :
                                                                                                                        //                 (<CardMedia component="img" height="194" image={item.Url} alt={item.Name} title={item.Name} onClick={(e) => { window.open(item.Url); }} />)}
                                                                                                                        //         </Card>
                                                                                                                        //     </React.Fragment>
                                                                                                                        // </Grid>);

                                                                                                                        return (<Grid key={'grdItem_' + item.Id} item xs={4}>
                                                                                                                            <React.Fragment>
                                                                                                                                <Card key={'card_' + item.Id} variant="outlined" className="portfolioMediaCard">
                                                                                                                                    {item.Type === 'V' ? (
                                                                                                                                        <CardMedia component={isYouTube ? "iframe" : "video"} height="194" playsInline={true} allowFullScreen={true} frameBorder={"0"} src={videoUrl} controls={true} title={item.Name} />
                                                                                                                                        // <CardMedia component="video" height="194" image={item.Url} controls={true} title={item.Name} onClick={(e) => { window.open(item.Url); }} />
                                                                                                                                    ) : (
                                                                                                                                        <CardMedia component="img" height="194" image={item.Url} alt={item.Name} title={item.Name} onClick={(e) => { window.open(item.Url); }} />
                                                                                                                                    )}
                                                                                                                                    <CardActions disableSpacing className="portfolioMediaCardMedia" sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }}>
                                                                                                                                        <IconButton aria-label={"Delete " + item.Name} onClick={(e) => { this.handlePortfolioMediaDelete(item); }}>
                                                                                                                                            <DeleteIcon sx={{ color: '#fff' }} />
                                                                                                                                        </IconButton>
                                                                                                                                    </CardActions>
                                                                                                                                </Card>
                                                                                                                            </React.Fragment>
                                                                                                                        </Grid>);
                                                                                                                    })}
                                                                                                                </React.Fragment>
                                                                                                            ) : (null)}
                                                                                                        </Grid>
                                                                                                    </Box>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mt-3">
                                                                                            <div className="formButtonContainer text-right">
                                                                                                <button type="submit" className="btn btn-cancel" onClick={(e) => { this.props.onCancelClick(); }}>Back</button>
                                                                                            </div>
                                                                                        </div>
                                                                                        {!showNewProtfolioMediaDialog ? (null) : (
                                                                                            <Dialog key='dlgPortfolioMedia' keepMounted TransitionComponent={this.SlideDownTransition} open={showNewProtfolioMediaDialog}
                                                                                                onClose={(e) => { this.portfolioMediaPhotoFormValidators = new Array<Validator>(); this.portfolioMediaUrlFormValidators = new Array<Validator>(); this.setState({ showNewProtfolioMediaDialog: false, File: null, FileName: '', PortfolioVideoUrl: '', ProjectFiles: [] }); }} aria-labelledby={"dialogPortfolioMediaTitle"}
                                                                                                fullWidth={true} maxWidth={"sm"} >
                                                                                                <DialogTitle id={"dialogPortfolioMediaTitle"} className="modalTitle">{'Photos and Videos'}
                                                                                                    <IconButton aria-label="close" onClick={(e) => { this.portfolioMediaPhotoFormValidators = new Array<Validator>(); this.portfolioMediaUrlFormValidators = new Array<Validator>(); this.setState({ showNewProtfolioMediaDialog: false, File: null, FileName: '', PortfolioVideoUrl: '', ProjectFiles: [] }); }} className="btnCloseDialog">
                                                                                                        <CloseIcon />
                                                                                                    </IconButton>
                                                                                                </DialogTitle>
                                                                                                <DialogContent dividers={true}>
                                                                                                    <div className="profileContainer">
                                                                                                        <div className="formContainer">
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>
                                                                                                                            Upload Photos
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="subtitle2" color="text.secondary">
                                                                                                                            Add photos of your work (before and after), teams, workspace, or equipment.
                                                                                                                        </Typography>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-12">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Works best for images with resolution above 200 x 200 (10 photos max).</label>
                                                                                                                                <div>
                                                                                                                                    <input id="fPortfolioFile" name="fPortfolioFile" accept=".jpg, .jpeg,.png" type="file" disabled={ProjectFiles && ProjectFiles.length === 10} className="inputfile" multiple={false} onChange={(e) => { if (e.target.files && e.target.files.length > 0) { let file: any = e.target.files[0]; file.mode = ''; ProjectFiles.push(file); this.setState({ ProjectFiles: ProjectFiles }); e.target.value = ''; } }} />
                                                                                                                                    <label htmlFor="fPortfolioFile">
                                                                                                                                        <span aria-disabled={ProjectFiles && ProjectFiles.length === 10} className="btn btn-primary cutmBtnLogin">Choose photo</span>
                                                                                                                                    </label>
                                                                                                                                    {/* <Validator id="valfPortfolioFile" type='required' cssClass="error" errorMessage="Provide file." controlId="fPortfolioFile" valueToValidate={!ProjectFiles || ProjectFiles.length <= 0 ? '' : '1'} formValidators={this.portfolioMediaPhotoFormValidators}></Validator> */}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-group mt-1">
                                                                                                                                {ProjectFiles && ProjectFiles.map((pFile, index) => {
                                                                                                                                    if (pFile.mode !== 'D')
                                                                                                                                        return (<div><DeleteIcon onClick={(e) => { pFile.mode = 'D'; this.setState({ ProjectFiles: ProjectFiles }); }} />
                                                                                                                                            <React.Fragment>{pFile.name}
                                                                                                                                                <React.Fragment>
                                                                                                                                                    <Validator id={"valfPortfolioFileLength_" + index} key={'valLength_' + index} type='max_length' maxLength={50} cssClass="error" errorMessage="File name (including extension) cannot exceed 50 characters." controlId="fPortfolioFile" valueToValidate={pFile.name} formValidators={this.portfolioMediaPhotoFormValidators}></Validator>
                                                                                                                                                    <Validator id={"valfPortfolioFileExists_" + index} key={'valExists_' + index} type='required' cssClass="error" errorMessage="Another file with this name already exists." controlId="fPortfolioFile" valueToValidate={this.checkMediaFileExists(pFile.name, 'P', index)} formValidators={this.portfolioMediaPhotoFormValidators}></Validator>
                                                                                                                                                </React.Fragment>
                                                                                                                                            </React.Fragment>
                                                                                                                                        </div>);
                                                                                                                                    else
                                                                                                                                        return (null);
                                                                                                                                })}
                                                                                                                                {/* {FileName ? <React.Fragment><DeleteIcon onClick={(e) => { this.setState({ File: null, FileName: '' }); }} /> {FileName}</React.Fragment> : (null)} */}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {/* <div className="col-3 col-sm-2">
                                                                                                                            <div className="formButtonContainer mt-4">
                                                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handlePortfolioMediaFileUpload(); }}>Upload</button>
                                                                                                                            </div>
                                                                                                                        </div> */}
                                                                                                                    </div>
                                                                                                                    <div className="form-group mt-3">
                                                                                                                        <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>
                                                                                                                            Embed videos from YouTube or Vimeo
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="subtitle2" color="text.secondary">
                                                                                                                            Upload your video to one of those sites and paste the link here.
                                                                                                                        </Typography>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-12">
                                                                                                                            <div className="form-group">
                                                                                                                                <input id="portfolioVideo" name="portfolioVideo" type="text" key={'PFV_'} className="form-control" defaultValue={PortfolioVideoUrl} placeholder="Enter URL" onBlur={(e) => { this.setState({ PortfolioVideoUrl: e.target.value }); }} />
                                                                                                                                {/* <Validator id="valPortfolioVideoUrl" type='required' cssClass="error" errorMessage="Provide video url." controlId="portfolioVideo" valueToValidate={PortfolioVideoUrl} formValidators={this.portfolioMediaUrlFormValidators}></Validator> */}
                                                                                                                                <Validator id="valPortfolioVideoUrlLength" type='max_length' maxLength={500} cssClass="error" errorMessage="Url cannot exceed 500 characters." controlId="portfolioVideo" valueToValidate={PortfolioVideoUrl} formValidators={this.portfolioMediaUrlFormValidators}></Validator>
                                                                                                                                <Validator id="valPortfolioVideoUrlType" type='url' cssClass="error" errorMessage="Invalid url format." controlId="portfolioVideo" valueToValidate={PortfolioVideoUrl} formValidators={this.portfolioMediaUrlFormValidators}></Validator>
                                                                                                                                <Validator id="valPortfolioVideoUrlExists" type='required' cssClass="error" errorMessage="Another video with this url already exists." controlId="portfolioVideo" valueToValidate={this.checkMediaFileExists(PortfolioVideoUrl, 'V')} formValidators={this.portfolioMediaUrlFormValidators}></Validator>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {/* <div className="col-3 col-sm-2">
                                                                                                                            <div className="formButtonContainer mt-0">
                                                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handlePortfolioMediaVideoUpload(); }}>Upload</button>
                                                                                                                            </div>
                                                                                                                        </div> */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </DialogContent>
                                                                                                <DialogActions>
                                                                                                    <div className="formButtonContainer text-right">
                                                                                                        <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.portfolioMediaPhotoFormValidators = new Array<Validator>(); this.portfolioMediaUrlFormValidators = new Array<Validator>(); this.setState({ showNewProtfolioMediaDialog: false, File: null, FileName: '', PortfolioVideoUrl: '', ProjectFiles: [] }); }}>Cancel</button>
                                                                                                        {DialogMode !== 'V' ? (<button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handlePhotoVideoSave(); }} disabled={!PortfolioVideoUrl && (!ProjectFiles || ProjectFiles.length <= 0)}>Save</button>) : (null)}
                                                                                                    </div>
                                                                                                </DialogActions>
                                                                                            </Dialog>
                                                                                        )}
                                                                                    </div>)}
                                                                                </TabPanel>
                                                                                <TabPanel value={ActiveProfileTab} index={5} id={"tbp5"}>
                                                                                    {showPortfolioProgress ? (<ProgressBar />) : (<div className="mt-3">
                                                                                        <div className="form-group">
                                                                                            <div className="row">
                                                                                                <div className="col-9 col-sm-10">
                                                                                                    <Typography variant="h6" color="text.primary">
                                                                                                        Featured Projects
                                                                                                    </Typography>
                                                                                                </div>
                                                                                                <div className="col-3 col-sm-2">
                                                                                                    <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showNewProjectDialog: true, DialogMode: '' }); }}><AddIcon /></button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {!FeaturedProjects || FeaturedProjects.length <= 0 ? (<Typography variant="subtitle2" color="text.secondary">You have no featured projects available. Click '+' to add new featured projects to your portfolio.</Typography>) : (
                                                                                                <React.Fragment>
                                                                                                    <Typography variant="subtitle2" color="text.secondary">Click '+' to add new featured projects to your portfolio.</Typography>
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12 mt-3">
                                                                                                            <Box className="boxPortfolioMedia">
                                                                                                                <Grid container item spacing={0} columns={12}>
                                                                                                                    {FeaturedProjects && FeaturedProjects.length > 0 ? (
                                                                                                                        <React.Fragment>
                                                                                                                            {FeaturedProjects.map((item) => {
                                                                                                                                let thumbnail = item.Media && item.Media.length > 0 ? item.Media[0].LinkUrl : '';
                                                                                                                                if (!thumbnail) {
                                                                                                                                    thumbnail = item.Service ? item.Service.DefaultImage : '';
                                                                                                                                }

                                                                                                                                return (<Grid key={'grdPrjItem_' + item.Id} item xs={4}>
                                                                                                                                    <React.Fragment>
                                                                                                                                        <Card key={'cardPrj_' + item.Id} variant="outlined" className="portfolioProjectCard" >
                                                                                                                                            <CardMedia component="img" height="194" image={thumbnail} alt={item.Name} title={item.Name} />
                                                                                                                                            <CardContent>
                                                                                                                                                <Typography gutterBottom variant="subtitle1" component="a" onClick={(e) => { this.viewFeaturedProject(item); }}>
                                                                                                                                                    {item.Name}
                                                                                                                                                </Typography>
                                                                                                                                                <Typography variant="body2" color="text.secondary">
                                                                                                                                                    {item.Price ? " Approx. $" + item.Price : ''}
                                                                                                                                                </Typography>
                                                                                                                                            </CardContent>
                                                                                                                                            <CardActions disableSpacing className="portfolioMediaCardMedia" sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }}>
                                                                                                                                                <IconButton aria-label={"Edit " + item.Name} onClick={(e) => { this.editFeaturedProject(item); }}>
                                                                                                                                                    <EditIcon sx={{ color: '#fff' }} />
                                                                                                                                                </IconButton>
                                                                                                                                                <IconButton aria-label={"Delete " + item.Name} onClick={(e) => { this.handleFeaturedProjectDelete(item); }}>
                                                                                                                                                    <DeleteIcon sx={{ color: '#fff' }} />
                                                                                                                                                </IconButton>
                                                                                                                                            </CardActions>
                                                                                                                                        </Card>
                                                                                                                                    </React.Fragment>
                                                                                                                                </Grid>);
                                                                                                                            })}
                                                                                                                        </React.Fragment>
                                                                                                                    ) : (null)}
                                                                                                                </Grid>
                                                                                                            </Box>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="form-group mt-3">
                                                                                            <div className="formButtonContainer text-right">
                                                                                                <button type="submit" className="btn btn-cancel" onClick={(e) => { this.props.onCancelClick(); }}>Back</button>
                                                                                            </div>
                                                                                        </div>
                                                                                        {!showNewProjectDialog ? (null) : (
                                                                                            <Dialog key='dlgPortfolioProject' keepMounted TransitionComponent={this.SlideDownTransition} open={showNewProjectDialog}
                                                                                                onClose={(e) => { this.portfolioProjectFormValidators = new Array<Validator>(); this.setState({ showNewProjectDialog: false }); }} aria-labelledby={"dialogPortfolioProjectTitle"}
                                                                                                fullWidth={true} maxWidth={"sm"} >
                                                                                                <DialogTitle id={"dialogPortfolioProjectTitle"} className="modalTitle">{ProjectTitle ? ProjectTitle : 'New Featured Project'}
                                                                                                    <IconButton aria-label="close" onClick={(e) => { this.portfolioProjectFormValidators = new Array<Validator>(); this.setState({ showNewProjectDialog: false, ProjectFiles: [] }); }} className="btnCloseDialog">
                                                                                                        <CloseIcon />
                                                                                                    </IconButton>
                                                                                                </DialogTitle>
                                                                                                <DialogContent dividers={true}>
                                                                                                    <div className="profileContainer">
                                                                                                        <div className="formContainer">
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label>Project title</label>
                                                                                                                        {DialogMode === 'V' ? (<div><span>{ProjectTitle}</span></div>) : (
                                                                                                                            <React.Fragment>
                                                                                                                                <input id="projectTitle" name="projectTitle" type="text" key={'projectTitle_' + ProjectYear} defaultValue={ProjectTitle} className="form-control" placeholder="" onBlur={(e) => { this.setState({ ProjectTitle: e.target.value }); }} />
                                                                                                                                <Validator id="valProjectTitle" type='required' cssClass="error" errorMessage="Provide project title." controlId="projectTitle" valueToValidate={ProjectTitle} formValidators={this.portfolioProjectFormValidators}></Validator>
                                                                                                                                <Validator id="valProjectTitleLength" type='max_length' maxLength={100} cssClass="error" errorMessage="Project title cannot exceed 100 characters." controlId="projectTitle" valueToValidate={ProjectTitle} formValidators={this.portfolioProjectFormValidators}></Validator>
                                                                                                                            </React.Fragment>)}
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Service</label>
                                                                                                                                {DialogMode === 'V' ? (<div><span>{ProjectServiceName}</span></div>) : (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <select id="projectService" name="projectService" key={'projectTitle_' + ProjectService} defaultValue={ProjectService} className="form-control" placeholder="" onChange={(e) => { this.setState({ ProjectService: e.target.value, ProjectServiceName: e.target.selectedOptions[0].text }); }}>
                                                                                                                                            <option value=''>Select</option>
                                                                                                                                            {Services && Services.map((s) => {
                                                                                                                                                return (<option value={s.value}>{s.text}</option>);
                                                                                                                                            })}
                                                                                                                                        </select>
                                                                                                                                        <Validator id="valProjectService" type='required' cssClass="error" errorMessage="Select service." controlId="projectService" valueToValidate={ProjectService} formValidators={this.portfolioProjectFormValidators}></Validator>
                                                                                                                                    </React.Fragment>)}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Year</label>
                                                                                                                                {DialogMode === 'V' ? (<div><span>{ProjectYear}</span></div>) : (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <select id="projectYear" name="projectYear" key={'projectYear_' + ProjectYear} defaultValue={ProjectYear} className="form-control" placeholder="" onChange={(e) => { this.setState({ ProjectYear: e.target.value }); }}>
                                                                                                                                            <option value=''>Select</option>
                                                                                                                                            {Years && Years.map((y) => {
                                                                                                                                                return (<option value={y.value}>{y.text}</option>);
                                                                                                                                            })}
                                                                                                                                        </select>
                                                                                                                                        <Validator id="valProjectYear" type='required' cssClass="error" errorMessage="Select year." controlId="projectYear" valueToValidate={ProjectYear} formValidators={this.portfolioProjectFormValidators}></Validator>
                                                                                                                                    </React.Fragment>)}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="form-group">
                                                                                                                        <label>Approximate total price</label>
                                                                                                                        {DialogMode === 'V' ? (<div><span>{ProjectPrice}</span></div>) : (
                                                                                                                            <React.Fragment>
                                                                                                                                <input id="projectPrice" name="projectPrice" type="text" key={'projectPrice_' + ProjectYear} defaultValue={ProjectPrice} className="form-control dollarIconMap" placeholder="" onBlur={(e) => { this.setState({ ProjectPrice: e.target.value }); }} />
                                                                                                                                <Validator id="valProjectPriceType" type='number' cssClass="error" errorMessage="Only numeric values are allowed." controlId="projectPrice" valueToValidate={ProjectPrice} formValidators={this.portfolioProjectFormValidators}></Validator>
                                                                                                                            </React.Fragment>)}
                                                                                                                    </div>
                                                                                                                    <div className="form-group">
                                                                                                                        <label>Description (optional)</label>
                                                                                                                        {DialogMode === 'V' ? (<div><span>{ProjectDescription}</span></div>) : (
                                                                                                                            <React.Fragment>
                                                                                                                                <textarea id="projectDescription" name="projectDescription" rows={3} key={'projectDescription_' + ProjectYear} defaultValue={ProjectDescription} className="form-control" placeholder="" onBlur={(e) => { this.setState({ ProjectDescription: e.target.value }); }} />
                                                                                                                                <Validator id="valProjectProjectDescriptionLength" type='max_length' maxLength={500} cssClass="error" errorMessage="Description cannot exceed 500 characters." controlId="projectDescription" valueToValidate={ProjectDescription} formValidators={this.portfolioProjectFormValidators}></Validator>
                                                                                                                            </React.Fragment>)}
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-12">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Photos</label>
                                                                                                                                <Typography variant="subtitle2" color="text.secondary">
                                                                                                                                    Consider showing before and after photos, the work in progress, and you or your team at work (20 photos max).
                                                                                                                                </Typography>
                                                                                                                                {DialogMode !== 'V' ? (
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Works best for images with resolution above 200 x 200.</label>
                                                                                                                                        <div>
                                                                                                                                            <input id="projectPhotoFile" name="projectPhotoFile" disabled={ProjectFiles && ProjectFiles.length === 20} accept=".jpg, .jpeg,.png" type="file" className="inputfile" multiple={false} onChange={(e) => { if (e.target.files && e.target.files.length > 0) { let file: any = e.target.files[0]; file.mode = 'N'; ProjectFiles.push(file); this.setState({ ProjectFiles: ProjectFiles }); e.target.value = ''; } }} />
                                                                                                                                            <label htmlFor="projectPhotoFile">
                                                                                                                                                <span aria-disabled={ProjectFiles && ProjectFiles.length === 20} className="btn btn-primary cutmBtnLogin">Add Photo</span>
                                                                                                                                            </label>
                                                                                                                                            <div>
                                                                                                                                                <Validator id="valprojectPhotoFile" type='required' cssClass="error" errorMessage="Provide file." controlId="projectPhotoFile" valueToValidate={!ProjectFiles || ProjectFiles.length <= 0 ? '' : '1'} formValidators={this.portfolioProjectFormValidators}></Validator>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>) : (null)}
                                                                                                                                <div className="form-group">
                                                                                                                                    {ProjectFiles && ProjectFiles.map((pFile, index) => {
                                                                                                                                        if (DialogMode === 'V') {
                                                                                                                                            return (<div><LinkIcon /> <a href={pFile.url} target='blank'>{pFile.name}</a></div>);
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            if (pFile.mode !== 'D')
                                                                                                                                                return (<div>
                                                                                                                                                    <DeleteIcon sx={{ cursor: 'pointer' }} onClick={(e) => { pFile.mode = 'D'; this.setState({ ProjectFiles: ProjectFiles }); }} />
                                                                                                                                                    <React.Fragment>{pFile.name}
                                                                                                                                                        <React.Fragment>
                                                                                                                                                            <Validator id={"valprojectPhotoFileLength_" + index} key={'valLength_' + index} type='max_length' maxLength={50} cssClass="error" errorMessage="File name (including extension) cannot exceed 50 characters." controlId="projectPhotoFile" valueToValidate={pFile.name} formValidators={this.portfolioProjectFormValidators}></Validator>
                                                                                                                                                            <Validator id={"valprojectPhotoFileExists_" + index} key={'valExists_' + index} type='required' cssClass="error" errorMessage="Another file with this name already exists." controlId="projectPhotoFile" valueToValidate={this.checkProjectFileExists(pFile.name, index)} formValidators={this.portfolioProjectFormValidators}></Validator>
                                                                                                                                                        </React.Fragment>
                                                                                                                                                    </React.Fragment>
                                                                                                                                                </div>);
                                                                                                                                            else
                                                                                                                                                return (null);
                                                                                                                                        }
                                                                                                                                    })}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </DialogContent>
                                                                                                <DialogActions>
                                                                                                    <div className="formButtonContainer text-right">
                                                                                                        <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.portfolioProjectFormValidators = new Array<Validator>(); this.setState({ showNewProjectDialog: false, ProjectFiles: [] }); }}>Cancel</button>
                                                                                                        {DialogMode !== 'V' ? (<button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handleProjectSaveClick(); }}>Save</button>) : (null)}
                                                                                                    </div>
                                                                                                </DialogActions>
                                                                                            </Dialog>
                                                                                        )}
                                                                                    </div>)}
                                                                                </TabPanel>
                                                                                <TabPanel value={ActiveProfileTab} index={6} id={"tbp6"}>
                                                                                    {showReviewsProgress ? (<ProgressBar />) : (<div className="mt-3">
                                                                                        <div className="form-group reviewsContainer">
                                                                                            <div className="form-group">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    <div className="row askforReviewsContainer">
                                                                                                        <div className="col-4 col-sm-2">
                                                                                                            <img src='/images/reviewsSmiley.png' alt='' />
                                                                                                        </div>
                                                                                                        <div className="col-8 col-sm-7">
                                                                                                            <div className="commentAuthor">Get reviews from past customers even if they're not on ProsBE</div>
                                                                                                            <Typography variant="subtitle2" className="commentBody">Tell us which customers to ask for reviews.</Typography>
                                                                                                        </div>
                                                                                                        <div className="col-12 col-sm-3">
                                                                                                            <div className="formButtonContainer text-right">
                                                                                                                <button type="submit" className="btn btn-cancel" onClick={(e) => { this.props.onCancelClick(); }}>Cancel</button>
                                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin ml-2" onClick={(e) => { this.setState({ showAskForReviewDialog: true, Emails: '' }) }}>Ask for Reviews</button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="form-group mt-3">
                                                                                                <hr></hr>
                                                                                            </div>
                                                                                            <div className="row mt-3">
                                                                                                <div className="col-5 col-sm-5">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <span className="overallRating pl-1">{OverallRating > 0 ? OverallRating : ''}</span>
                                                                                                        </div>
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Rating name="profileRating" value={OverallRating} readOnly={true} className="ratedStar" />
                                                                                                        </div>
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant='caption' className="commentBody">{TotalReviewsCount} {TotalReviewsCount > 1 ? "reviews" : "review"}</Typography>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-7 col-sm-7">
                                                                                                    {[5, 4, 3, 2, 1].map((rating) => {
                                                                                                        let profileRating = ProfileRatings && ProfileRatings.find((pR) => { return (pR.Rating.toString() === rating.toString()); });

                                                                                                        return (
                                                                                                            <div className="row">
                                                                                                                <div className="col-2 col-sm-2 text-right p-0">
                                                                                                                    <span className='ratingValue commentBody'>{rating} </span><StarBorderIcon fontSize='small' className="disabledStar" />
                                                                                                                </div>
                                                                                                                <div className="col-7 col-sm-4 mt-2 p-0">
                                                                                                                    <LinearProgress variant="determinate" value={profileRating ? profileRating.TotalPercent : 0} className="reviewRatingBar" />
                                                                                                                </div>
                                                                                                                <div className="col-3 col-sm-6 p-0">
                                                                                                                    <span className='ratingValue commentBody'>{profileRating ? profileRating.TotalPercent.toString() + "%" : "0%"} </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                            {HighRatedParameters && HighRatedParameters.length > 0 ? (
                                                                                                <div className="form-group mt-3">
                                                                                                    <Typography variant="subtitle2" className="commentBody">Customers rated you highly for {this.getHighRatedFor()}</Typography>
                                                                                                </div>) : (null)}
                                                                                            <div className="form-group mt-3">
                                                                                                <hr></hr>
                                                                                            </div>
                                                                                            <div className="form-group mt-3">
                                                                                                {!ProfileRootComments || ProfileRootComments.length <= 0 ? (<Typography variant="subtitle2" className="commentBody">No review comments available.</Typography>) : (<React.Fragment>
                                                                                                    {ProfileRootComments.map((cmt) => {
                                                                                                        return (<React.Fragment>
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-5">
                                                                                                                    <span className="commentAuthor">{cmt.UserName}</span>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-sm-2">
                                                                                                                    <span className="commentDate">{this.getFormattedDate(new Date(this.getDateNumber(cmt.CreatedOn)), Constants.IDateTimeFormat.ddMMMyyyy, '/')}</span>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-sm-3">
                                                                                                                    <Rating value={cmt.Rating} className="commentRatingStar" readOnly={true} />
                                                                                                                </div>
                                                                                                                <div className="col-12 col-sm-2 text-right">
                                                                                                                    {showReplyId !== cmt.Id.toString() ? (<Typography variant="caption"><IconButton onClick={(e) => { this.setState({ showReplyId: cmt.Id.toString(), ParentCommentId: cmt.Id }); }} title="Reply"><ReplyIcon /></IconButton></Typography>) : (null)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row mt-1">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <span className="commentBody">{cmt.CommentText}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {showReplyId === cmt.Id.toString() ? (
                                                                                                                <div className="row mt-2">
                                                                                                                    <div className="col-12 col-sm-12">
                                                                                                                        <div className="commentReplyContainer">
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-10">
                                                                                                                                    <input key={"txtReply_" + cmt.Id.toString()} id={"txtReply_" + cmt.Id.toString()} name={"txtReply_" + cmt.Id.toString()} className="form-control" defaultValue={ReplyText} autoFocus={true} onBlur={(e) => { this.setState({ ReplyText: e.target.value }); }} />
                                                                                                                                    <Validator key={"valReplyText_" + cmt.Id.toString()} id={"valReplyText_" + cmt.Id.toString()} type="required" errorMessage="Provide reply text." controlId={"txtReply_" + cmt.Id.toString()} cssClass="error" valueToValidate={ReplyText} formValidators={this.commentReplyFormValidators}></Validator>
                                                                                                                                </div>
                                                                                                                                <div className="col-12 col-sm-2 text-right">
                                                                                                                                    <IconButton key={"iconPostBtn_" + cmt.Id.toString()} aria-label={"Post "} onClick={(e) => { this.postComment(cmt); }} title="Post">
                                                                                                                                        <SendOutlined />
                                                                                                                                    </IconButton>
                                                                                                                                    <IconButton key={"iconCancelBtn_" + cmt.Id.toString()} aria-label={"CancelReply "} onClick={(e) => { this.setState({ showReplyId: '0', ParentCommentId: '0', ReplyText: '' }); }} title="Cancel">
                                                                                                                                        <CancelOutlined />
                                                                                                                                    </IconButton>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>) : (null)}
                                                                                                            <React.Fragment>
                                                                                                                {this.getChildComments(cmt)}
                                                                                                            </React.Fragment>
                                                                                                            {/* <div className="form-group">
                                                                                                                <Typography variant="caption"><a href='#'>Read all reviews</a></Typography>
                                                                                                            </div> */}
                                                                                                        </React.Fragment>);
                                                                                                    })}
                                                                                                </React.Fragment>)}
                                                                                            </div>
                                                                                            {!showAskForReviewDialog ? (null) : (<React.Fragment>
                                                                                                <Dialog fullWidth={true} maxWidth={"sm"} open={showAskForReviewDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { this.formValidators = new Array<Validator>(); }} aria-describedby="askforReviewsDialog" >
                                                                                                    <DialogTitle id={"dialogAskForReviews"} className="modalTitle">{'Ask for Reviews'}
                                                                                                        <IconButton aria-label="close" onClick={(e) => { this.formValidators = new Array<Validator>(); this.setState({ showAskForReviewDialog: false, Recipients: [] }); }} className="btnCloseDialog">
                                                                                                            <CloseIcon />
                                                                                                        </IconButton>
                                                                                                    </DialogTitle>
                                                                                                    <DialogContent dividers={true}>
                                                                                                        <div className="profileContainer">
                                                                                                            <div className="formContainer">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <div className="form-group mt-3">
                                                                                                                        <Button startIcon={<img src='/images/googleIcon.png' alt='Google reviews' />} > Add reviews from Google</Button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <div className="form-group mt-3">
                                                                                                                        <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>Get more reviews</Typography>
                                                                                                                        <Typography variant="subtitle2" color="text.secondary">Invite your customers to leave reviews</Typography>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <div className="form-group mt-3">
                                                                                                                        <Autocomplete multiple={true} id="emails" options={ReviewRecipients && ReviewRecipients.length > 0 ? ReviewRecipients : []} getOptionLabel={(option) => { return (option && option.Profile.User.DisplayName ? option.Profile.User.DisplayName : ''); }}
                                                                                                                            onChange={(e, newVal) => { this.setState({ Recipients: newVal ? newVal : null }); }} defaultValue={Recipients} filterSelectedOptions={true} renderInput={(params) => (<TextField name="emails" {...params} label="" placeholder="" />)} />
                                                                                                                        <Validator id="valEmails" type="required" cssClass="error" controlId="emails" errorMessage="Provide email." valueToValidate={Recipients && Recipients.length > 0 ? '1' : ''} formValidators={this.formValidators} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </DialogContent>
                                                                                                    <DialogActions>
                                                                                                        <div className="formButtonContainer text-right">
                                                                                                            <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.formValidators = new Array<Validator>(); this.setState({ showAskForReviewDialog: false, Recipients: [] }); }}>Cancel</button>
                                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handleAskForReviewsClick(); }}>Invite</button>
                                                                                                        </div>
                                                                                                    </DialogActions>
                                                                                                </Dialog>
                                                                                            </React.Fragment>)}
                                                                                        </div>
                                                                                    </div>)}
                                                                                </TabPanel>
                                                                            </React.Fragment>) : (null)}
                                                                        </Box>)}
                                                                </React.Fragment>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)}
                                            </div>
                                            {!IsActive ? (null) : (<React.Fragment>
                                                <div className={"tab-pane fade " + (ActiveTab === 1 ? "show active" : "")} id="v-pills-profile">
                                                    {ActiveTab !== 1 ? (null) : (<div className="profileContainer">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="formContainer">
                                                                    <div className="headingTxt">Change Password</div>
                                                                    <div className="form-group">
                                                                        <label>Current Password</label>
                                                                        <input id="cpass" name="cpass" type="password" className="form-control" placeholder="" defaultValue={CurrentPassword} onBlur={(e) => { this.setState({ CurrentPassword: e.target.value }); }} />
                                                                        <Validator id="valCPass" type='required' cssClass="error" controlId="cpass" errorMessage="Provide current password." valueToValidate={CurrentPassword} formValidators={this.formValidators}></Validator>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>New Password</label>
                                                                        <input id="npass" name="npass" type="password" className="form-control" placeholder="" defaultValue={NewPassword} onBlur={(e) => { this.setState({ NewPassword: e.target.value }); }} />
                                                                        <Validator id="valNPass" type='required' cssClass="error" controlId="npass" errorMessage="Provide new password." valueToValidate={NewPassword} formValidators={this.formValidators}></Validator>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Confirm New Password</label>
                                                                        <input id="rpass" name="rpass" type="password" className="form-control" placeholder="" defaultValue={ConfirmPassword} onBlur={(e) => { this.setState({ ConfirmPassword: e.target.value }); }} />
                                                                        <Validator id="valRPass" type='required' cssClass="error" controlId="rpass" errorMessage="Confirm new password." valueToValidate={ConfirmPassword} formValidators={this.formValidators}></Validator>
                                                                        <Validator id="valRPassMatch" type='required' cssClass="error" controlId="rpass" errorMessage="New and Confirm passwords do not match." valueToValidate={ConfirmPassword && NewPassword && ConfirmPassword === NewPassword ? '1' : ''} formValidators={this.formValidators}></Validator>
                                                                    </div>
                                                                    <div className="formButtonContainer text-right">
                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSavePassword(); }}>Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)}
                                                </div>
                                                <div className={"tab-pane fade " + (ActiveTab === 2 ? "show active" : "")} id="v-pills-messages">
                                                    {ActiveTab !== 2 ? (null) : (<div className="notificationContainer">
                                                        <div className="headingTxt">Notifications</div>
                                                        <div className="notificationMessage"><i className="fa fa-info-circle custColor" aria-hidden="true"></i>
                                                            Choose what you'd like to be emailed about
                                                        </div>
                                                        <div className="switchControlContainter">
                                                            <div className="box">
                                                                <div className="custom-control custom-switch d-flex justify-content-between">
                                                                    <span>New Message</span>
                                                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={(e) => { this.handleNotificationChange(e, 'NM'); }} />
                                                                    <label className="custom-control-label" htmlFor="customSwitch1"></label>
                                                                </div>
                                                            </div>
                                                            <div className="box">
                                                                <div className="custom-control custom-switch d-flex justify-content-between">
                                                                    <span>New Quotes</span>
                                                                    <input type="checkbox" className="custom-control-input" id="customSwitch2" onChange={(e) => { this.handleNotificationChange(e, 'NM'); }} />
                                                                    <label className="custom-control-label" htmlFor="customSwitch2"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)}
                                                </div>
                                                <div className={"tab-pane fade " + (ActiveTab === 3 ? "show active" : "")} id="v-pills-deactivate">
                                                    {ActiveTab !== 3 ? (null) : (<DeactivateAccount pid={this.User.Id} email={Email} />)}
                                                </div>
                                            </React.Fragment>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <Footer /> */}
            </React.Fragment >);
        }
        // }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}