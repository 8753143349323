

import { Attachment } from "@mui/icons-material";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import { Loader, ProgressBar } from "src/common/Components";
import Constants from "src/common/Constants";
import Footer from "src/common/Footer";
import Header from "src/common/Header";

export default class Quote extends Base {

    public state: any;
    constructor(props) {
        super(props);

        this.state = {
            loading: true, showProgress: false, QuoteDetails: null, QuoteFAQs: [],
            showConfirmation: false, ConfirmationType: 'C', ConfirmationMessage: '', ShowMessage: false, MessageType: 'success', Message: ''
        };
    }

    public componentDidMount() {
        this.db.getQuoteDetails(this.props.item).then((result) => {
            if (result) {
                this.db.getQuoteFAQs(this.props.item).then((faqs) => {
                    this.setState({ loading: false, QuoteDetails: result, QuoteFAQs: faqs });
                });
            }
            else {
                this.setState({ loading: false });
            }
        });
    }

    private handleProHireClick() {

    }

    // private handleQuotesReceivedClick(project) {
    //     this.setState({ showProgress: true, TabIndex: 4 });
    //     this.db.getSubmittedQuotes(project.Id).then((submittedQuotes) => {
    //         this.setState({ showProgress: false, ItemId: project.Id, ReceivedQuotes: submittedQuotes, SelectedProject: project });
    //     });
    // }

    public render() {
        const { loading, showProgress, QuoteFAQs, QuoteDetails, showConfirmation, ConfirmationType, ConfirmationMessage, ShowMessage, MessageType, Message } = this.state;

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            if (!this.IsUserValid) {
                let query = new URLSearchParams(window.location.search);
                window.location.href = '/login?src=' + encodeURI('/quote' + (query ? '?' + query : ''));
            }
            else {
                template = (<React.Fragment>
                    <Header />
                    <section className="containerMinHeight accountSettingContainer borderBtm">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="profileContainer">
                                        <div className="col-12 col-sm-12">
                                            <div className="formContainer">
                                                {/* <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="breadCrumb mb-1">
                                                            <a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, ItemId: 0, ReceivedQuotes: [], QuoteDetails: null, QuoteFAQs: [], SelectedProject: null }); }}>My Projects</a><span className="ml-2">&gt;</span><a href="javascript:void(0);" className="ml-2" onClick={(e) => { this.handleQuotesReceivedClick(SelectedProject); }}>Received Quotes</a><span className="ml-2">&gt;</span><span className="ml-2">Quote Details</span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {this.AppRole !== Constants.UserRoles.Customer ? (<React.Fragment>
                                                    <div className="col-12 col-sm-12">
                                                        <div className="tabPanel">You must be a Customer to view these details.</div>
                                                    </div>
                                                </React.Fragment>) : (<React.Fragment>
                                                    <div className="row">
                                                        <div className={QuoteDetails.Project && QuoteDetails.Project.Status.toLowerCase() === 'closed' ? "col-12 col-sm-12" : "col-9 col-sm-10"}>
                                                            <h5>Quote Details{QuoteDetails.Project ? ' - ' + QuoteDetails.Project.Name : ''}</h5>
                                                        </div>
                                                        {QuoteDetails.Project && QuoteDetails.Project.Status.toLowerCase() !== "closed" ? (
                                                            <div className="col-3 col-sm-2 text-right">
                                                                <button type="submit" disabled={showProgress} className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleProHireClick(); }}>Hire Pro</button>
                                                            </div>) : (null)}
                                                    </div>
                                                    <div className="profileContainer">
                                                        <div className="formContainer">
                                                            <div className="tabPanel">
                                                                {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                    <div className="form-group">
                                                                        <label>Quote price</label>
                                                                        <Typography variant="subtitle2" color="text.secondary">{QuoteDetails.Price}</Typography>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Quote type</label>
                                                                        <Typography variant="subtitle2" color="text.secondary">{QuoteDetails.Type.toString() === "1" ? "Fixed Price" : "Hourly Rate"}</Typography>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Message</label>
                                                                        <Typography variant="subtitle2" color="text.secondary">{QuoteDetails.Message}</Typography>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        {QuoteDetails.File && QuoteDetails.File.Path ? (
                                                                            <Typography variant="caption" className="pt-2"><Attachment />&nbsp;<a href={QuoteDetails.File.Path}>{QuoteDetails.File.Name}</a></Typography>) : (null)}
                                                                    </div>
                                                                    <div className="mt-3">
                                                                        <h5>Frequently Asked Questions</h5>
                                                                        {QuoteFAQs ? (<React.Fragment>
                                                                            {QuoteFAQs.map((item, indx) => {
                                                                                return (<React.Fragment>
                                                                                    <div className="form-group">
                                                                                        <label>{item.Name}</label>
                                                                                        <Typography variant="subtitle2" color="text.secondary">{item.Description}</Typography>
                                                                                    </div>
                                                                                </React.Fragment>);
                                                                            })}
                                                                        </React.Fragment>) : (<Typography variant="subtitle2" color="text.secondary">There are no frequently asked questions available with this quote.</Typography>)}
                                                                    </div>
                                                                </React.Fragment>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </React.Fragment >);
            }
        }

        return (<React.Fragment>
            {template}
            <div id="map"></div>
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}