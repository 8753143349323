import {
    Alert, Avatar, Box, Button, Card, CardContent, CardHeader, CardMedia, Checkbox, Chip,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid,
    IconButton, Radio, RadioGroup, Snackbar, Typography
} from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import Reviews, { Loader, ProgressBar } from "src/common/Components";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import Validator from "src/common/Validator";
import Constants from "src/common/Constants";
import { red } from "@mui/material/colors";
import { Attachment } from "@mui/icons-material";

export default class Projects extends Base {
    private formValidators: Validator[];

    private validator: Validator;

    constructor(props) {
        super(props);

        this.state = {
            loading: true, Projects: [], showProgress: false, showConfirmation: false, ConfirmationMessage: '', Message: '', ConfirmationType: 'C', MessageType: 'success',
            ShowMessage: false, callBackMethod: null, showProjectsProgress: false, showDetailsDialog: false, ItemId: 0, DialogMode: '', ProjectName: '', ProjectDescription: '',
            ProjectFAQs: [], ProjectCategoryId: '', ProjectCategoryName: '', ProjectBudget: '', ProjectLocationId: '', ProjectLocationName: '', Cities: [], SelectedCities: null,
            Categories: [], FilteredProjects: [], ProjectCityName: '', ProjectPin: '', ProjectStateName: '', ProjectType: 'PUBLIC', showQuoteDialog: false, RelatedPros: [],
            showQuoteRequestProgress: false, showQuoteTemplate: false, showProfile: false, showProfileProgress: false, Profile: null, RelatedPro: null, showRelatedProProgress: false,
            ReceivedQuote: null, ReceivedQuotes: [], showQuotesReceivedDialog: false, showQuotesReceivedProgress: false, ProjectId: '', HasRequestedQuote: false, RequestedQuotes: [],
            showDialogConfirmation: false, DialogItemId: 0, DialogConfirmationMessage: '', DialogConfirmationType: 'C', ActiveTab: 0, showQuotesReceivedDialogProgress: false,
            QuoteDetails: null, QuoteFAQs: [], SelectedProject: null, TabIndex: 0, QuoteProject: null, ProjectLocation: null, ProjectStateCode: '', PageSize: 20, HasMoreRecords: false,
            PageNumber: 1
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        this.getCityMaster().then((cities) => {
            this.db.getCategories().then((categories) => {
                if (this.User && this.User.Profile && this.User.Profile.Id) {
                    this.getUserProfile().then((profile) => {
                        this.db.getProjects(this.props.id > 0 ? this.props.id : this.User.Profile.Id).then((projects) => {
                            let hasMore = false;
                            let _data: any = projects;

                            if (_data && _data.length > (this.state.PageSize * this.state.PageNumber)) {
                                _data = projects.slice(0, (this.state.PageSize * this.state.PageNumber));
                                hasMore = true;
                            }

                            if (this.props.mode === 'n') {
                                this.setState({
                                    loading: false, Projects: _data, Cities: cities, Categories: categories, Profile: profile,
                                    ProfileId: (this.props.id > 0 ? this.props.id : this.User.Profile.Id), FilterStatus: 'Open',
                                    TabIndex: 1, DialogMode: '', ItemId: 0, ActiveTab: 0, HasMoreRecords: hasMore
                                }, () => {
                                    this.addGoogleMapScriptTag();
                                    window.setTimeout(() => { this.initializePlacesAutoComplete(); }, 2000);
                                });
                            }
                            else {
                                this.setState({
                                    loading: false, Projects: projects, Cities: cities, Categories: categories, Profile: profile, ProfileId: (this.props.id > 0 ? this.props.id : this.User.Profile.Id),
                                    FilterStatus: 'Open'
                                }, () => {
                                    this.addGoogleMapScriptTag();
                                    this.filterProjects();
                                });
                            }
                        });
                    });
                }
                else
                    this.setState({ loading: false });
            });
        });
    }

    private addGoogleMapScriptTag() {
        let scriptElems = document.getElementsByTagName("script");
        let elemExists = false;
        for (var i = 0; i < scriptElems.length; i++) {
            if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
                elemExists = true;
        }

        if (!elemExists) {
            const script = document.createElement("script");
            script.src = Constants.Google.PlacesScriptPath;
            //script.id = "scriptGoogleMaps";
            script.setAttribute('async', '');
            script.setAttribute('defer', '');

            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }

    private getUserProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.props.id && this.props.id > 0) {
                this.db.getUserProfileById(this.props.id, Constants.UserRoles.Customer).then((profile) => {
                    resolve(profile);
                });
            }
            else if (this.LoginName) {
                this.db.getUserProfile(this.LoginName, this.AppRole).then((profile) => {
                    resolve(profile);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private initializePlacesAutoComplete() {

        const input = document.getElementById("zip") as HTMLInputElement;
        const autoCompleteOptions = {
            fields: ["formatted_address", "address_components", "geometry", "name", "place_id"],
            // strictBounds: false,
            types: ["(regions)"],
            componentRestrictions: { country: "us" }
        };

        const autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);

        autocomplete.addListener("place_changed", () => {
            let place: any = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "No details available for input: '" + place.name + "'", ConfirmationType: 'A' });
                return;
            }

            let hasPostalCode = false;
            let zipCode = '';
            let state = '';
            let sCode = '';
            let city = '';

            if (place.address_components) {
                for (var i = 0; i < place.address_components.length; i++) {
                    if (place.address_components[i].types) {
                        for (var j = 0; j < place.address_components[i].types.length; j++) {
                            if (place.address_components[i].types[j] === "postal_code") {
                                if (!hasPostalCode) {
                                    hasPostalCode = true;
                                    zipCode = place.address_components[i].long_name;
                                }
                            }

                            if (place.address_components[i].types[j] === "administrative_area_level_1") {
                                if (!state) {
                                    state = place.address_components[i].long_name;
                                    sCode = place.address_components[i].short_name;
                                }
                            }

                            if (place.address_components[i].types[j] === "locality") {
                                if (!city) {
                                    city = place.address_components[i].long_name;
                                }
                            }
                        }
                    }
                }
            }

            if (!hasPostalCode) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "Invalid Zip code. Please start your search with a Zip code.", ConfirmationType: 'A' });
                return;
            }
            else if (!city) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "Invalid City Name. Please start your search with a Zip code.", ConfirmationType: 'A' });
                return;
            }

            let formatted_address = place.formatted_address;
            let placeId = place.place_id;
            let lat = place.geometry?.location?.lat();
            let lon = place.geometry?.location?.lng();

            this.setState({
                ProjectLocation: { name: place.name, formatted_address: formatted_address, placeId: placeId, lat: lat, lng: lon, pinCode: zipCode },
                ProjectCityName: city, ProjectPin: zipCode, ProjectStateName: state, ProjectStateCode: sCode
            });
        });
    }

    private getProjects() {
        this.db.getProjects(this.state.ProfileId).then((projects) => {
            if (projects && projects.length > 0) {
                projects.forEach((p) => {
                    if (p.Location) {
                        p.Location = JSON.parse(p.Location);
                    }
                });
            }

            this.setState({ loading: false, showProgress: false, Projects: projects, TabIndex: 0 }, () => { this.filterProjects(); });
        });
    }

    private filterProjects() {
        let filteredProjects = this.state.Projects;
        this.setState({ showProgress: true });

        if (filteredProjects) {
            if (this.state.FilterType) {
                filteredProjects = filteredProjects.filter((p) => { return (p.Type.toLowerCase() === this.state.FilterType.toLowerCase()); });
            }

            if (this.state.FilterStatus) {
                filteredProjects = filteredProjects.filter((p) => { return (p.Status.toLowerCase() === this.state.FilterStatus.toLowerCase()); });
            }

            if (this.state.FilterCategoryId) {
                filteredProjects = filteredProjects.filter((p) => { return (p.Category && p.Category.Id.toString() === this.state.FilterCategoryId); });
            }
        }

        let hasMore = false;

        if (filteredProjects && filteredProjects.length > (this.state.PageSize * this.state.PageNumber)) {
            filteredProjects = filteredProjects.slice(0, (this.state.PageSize * this.state.PageNumber));
            hasMore = true;
        }

        this.setState({ showProgress: false, FilteredProjects: filteredProjects, HasMoreRecords: hasMore });
    }

    private sendEmail(itemId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.state.ItemId <= 0 && this.state.DialogMode !== 'E') {
                let emailTemplate = Constants.Email.NewProject;
                let username = this.User.FirstName + (this.User.LastName ? ' ' + this.User.LastName.toString().charAt(0) + '.' : '');

                let subject = emailTemplate.Subject.replace('[username]', username);
                subject = subject.replace('[servicename]', this.state.ProjectCategoryName);
                subject = subject.replace('[cityname]', this.state.ProjectCityName);
                subject = subject.replace('[statename]', this.state.ProjectStateName);

                let body = emailTemplate.Body;

                body = body.replace("[username]", username);
                body = body.replace('[servicename]', this.state.ProjectCategoryName);
                body = body.replace('[cityname]', this.state.ProjectCityName);
                body = body.replace('[statename]', this.state.ProjectStateName);
                body = body.replace('[zip]', this.state.ProjectPin);
                body = body.replace('[oid]', itemId);

                this.db.getRelatedPros(itemId).then((relatedPros) => {
                    if (relatedPros) {
                        let counter = 0;
                        relatedPros.forEach((pro) => {
                            this.checkIfUserIsSubscribedToNotificationType(pro.Profile.Id, (this.state.ProjectType === 'PRIVATE' ? Constants.NotificationTypes.NewDirectLeads : Constants.NotificationTypes.NewOpportunity)).then((exists) => {
                                if (exists) {
                                    if (pro.Profile.User.Email) {
                                        this.db.sendEmail(pro.Profile.User.Email, subject, body).then((sent) => {
                                            counter++;
                                            if (counter === relatedPros.length)
                                                resolve(true);
                                        });
                                    }
                                    else {
                                        counter++;
                                        if (counter === relatedPros.length)
                                            resolve(true);
                                    }
                                }
                                else {
                                    counter++;
                                    if (counter === relatedPros.length)
                                        resolve(true);
                                }
                            });
                        });
                    }
                    else {
                        resolve(true);
                    }
                });
            }
            else {
                resolve(true);
            }
        });
    }

    private handleProjectSaveClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showProgress: true });

            let { ItemId, ProfileId, ProjectCategoryId, ProjectName, ProjectDescription, ProjectBudget, ProjectCityName, ProjectStateName, ProjectFAQs, ProjectType, ProjectLocation, ProjectStateCode, ProjectPin } = this.state;

            let _pLocation = '';
            if (ProjectLocation)
                _pLocation = JSON.stringify(ProjectLocation);

            this.db.updateProfileProject(ItemId, ProfileId, ProjectCategoryId, ProjectName, ProjectDescription, ProjectBudget, ProjectCityName, ProjectType, _pLocation, ProjectStateName, ProjectStateCode, ProjectPin).then((itemId) => {
                if (itemId > 0) {
                    if (ProjectFAQs && ProjectFAQs.length > 0) {
                        let faqString = JSON.stringify(ProjectFAQs);
                        this.db.updateProfileProjectFAQs(itemId, ProfileId, faqString).then((faqUpdated) => {
                            if (faqUpdated) {
                                this.sendEmail(itemId).then((sent) => {
                                    if (sent) {
                                        this.successMessage('Project details updated successfully.');
                                    }
                                    else {
                                        this.errorMessage('Project details updated successfully but email could not be sent.');
                                    }

                                    this.setState({
                                        ItemId: 0, DialogMode: '', ProjectName: '', ProjectDescription: '', ProjectBudget: '',
                                        ProjectCategoryId: '', ProjectCategoryName: '', ProjectCityId: '', ProjectCityName: '', ProjectStateName: '', ProjectPin: '', ProjectFAQs: [],
                                        ProjectStatus: '', ProjectType: 'PUBLIC', SelectedCities: null, ActiveTab: 0, TabIndex: 0, ProjectLocation: null, ProjectStateCode: ''
                                    }, () => {
                                        if (this.props.mode === 'n')
                                            window.location.href = '/projects';
                                        else
                                            this.getProjects();
                                    });
                                });
                            }
                            else {
                                this.errorMessage('Project details could not be updated.');
                                this.setState({ showProgress: false });
                            }
                        });
                    }
                    else {
                        this.sendEmail(itemId).then((sent) => {
                            if (sent) {
                                this.successMessage('Project details updated successfully.');
                            }
                            else {
                                this.errorMessage('Project details updated successfully but email could not be sent.');
                            }

                            this.setState({
                                ItemId: 0, DialogMode: '', ProjectName: '', ProjectDescription: '', ProjectBudget: '', ProjectLocation: null, ProjectStateCode: '',
                                ProjectCategoryId: '', ProjectCategoryName: '', ProjectCityId: '', ProjectCityName: '', ProjectStateName: '', ProjectPin: '', ProjectFAQs: [],
                                ProjectStatus: '', ProjectType: 'PUBLIC', SelectedCities: null, ActiveTab: 0, TabIndex: 0
                            }, () => {
                                if (this.props.mode === 'n')
                                    window.location.href = '/projects';
                                else
                                    this.getProjects();
                            });
                        });
                    }
                }
                else {
                    this.errorMessage('Project details could not be updated.');
                    this.setState({ showProgress: false });
                }
            });
        }
    }

    private handleEditProject(project) {
        let selectedCity = this.state.Cities.find((c) => { return (c.Id.toString() === project.City.Id.toString()); });

        this.setState({
            TabIndex: 1, ItemId: project.Id, DialogMode: 'E', ProjectName: project.Name, ProjectDescription: project.Description, ProjectBudget: project.Budget, ProjectCategoryId: project.Category.Id.toString(), ProjectCategoryName: project.Category.Name,
            ProjectCityId: project.City.Id.toString(), ProjectCityName: project.City.Name, ProjectStateName: project.City.State.Code, ProjectPin: project.City.Pin,
            ProjectStatus: project.Status, ProjectType: project.Type, SelectedCities: selectedCity, ActiveTab: 0, ProjectLocation: project.Location
        }, () => {
            this.initializePlacesAutoComplete();

            this.db.getProjectFAQs(project.Id).then((faqs) => {
                this.setState({ ProjectFAQs: faqs });
            });
        });
    }

    private resetProjectDetails() {
        if (this.props.mode === 'n')
            window.location.href = '/projects';
        else
            this.setState({
                DialogMode: '', ItemId: 0, ProjectName: '', ProjectDescription: '', ProjectBudget: '', ProjectCategoryId: '',
                ProjectCategoryName: '', ProjectCityId: '', ProjectCityName: '', ProjectStateName: '', ProjectPin: '', ProjectStatus: '', ProjectType: '',
                SelectedCities: null, ProjectFAQs: [], ActiveTab: 0, TabIndex: 0, ProjectStateCode: '', ProjectLocation: null
            });
    }

    private handleViewProject(project) {
        let selectedCity = this.state.Cities.find((c) => { return (c.Id.toString() === project.City.Id.toString()); });

        this.setState({
            TabIndex: 1, ItemId: project.Id, DialogMode: 'V', ProjectName: project.Name, ProjectDescription: project.Description, ProjectBudget: project.Budget,
            ProjectCategoryId: project.Category.Id.toString(), ProjectCategoryName: project.Category.Name,
            ProjectCityId: project.City.Id.toString(), ProjectCityName: project.City.Name, ProjectStateName: project.City.State.Code, ProjectPin: project.City.Pin, ProjectFAQs: project.FAQs,
            ProjectStatus: project.Status, ProjectType: project.Type, SelectedCities: selectedCity, ActiveTab: 0, ProjectLocation: project.Location
        });
    }

    private handleDeleteProject(project) {
        this.setState({ showConfirmation: true, ConfirmationMessage: 'Are you sure you want to delete this project?', ItemId: project.Id, callBackMethod: this.deleteProject.bind(this) });
    }

    private handleCancelProject(project) {
        this.setState({ showConfirmation: true, ConfirmationMessage: 'Are you sure you want to cancel this project?', ItemId: project.Id, Project: project, callBackMethod: this.cancelProject.bind(this) });
    }

    private deleteProject() {
        this.setState({ showProgress: true });
        this.db.deleteProfileProject(this.state.ItemId).then((deleted) => {
            if (deleted) {
                this.successMessage('Project deleted successfully.');
                this.setState({ showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null }, () => {
                    this.getProjects();
                });
            }
            else {
                this.errorMessage('Project could not be deleted.');
                this.setState({ showProgress: false });
            }
        });
    }

    private cancelProject() {
        this.setState({ showProgress: true });
        this.db.cancelProfileProject(this.state.ItemId).then(async (deleted) => {
            if (deleted) {
                //await this.sendCancelProjectEmail(this.state.Project);

                this.successMessage('Project cancelled successfully.');
                this.setState({ showConfirmation: false, ConfirmationMessage: '', ItemId: 0, Project: null, callBackMethod: null }, () => {
                    this.getProjects();
                });
            }
            else {
                this.errorMessage('Project could not be cancelled.');
                this.setState({ showConfirmation: false, showProgress: false, Project: null, callBackMethod: null });
            }
        });
    }

    private handleOpenProject(project) {
        this.setState({ showConfirmation: true, ConfirmationMessage: 'Are you sure you want to open this project?', ItemId: project.Id, callBackMethod: this.openProject.bind(this) });
    }

    private openProject() {
        this.setState({ showProgress: true });
        this.db.openProfileProject(this.state.ItemId).then((opened) => {
            if (opened) {
                this.successMessage('Project opened successfully.');
                this.setState({ showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null }, () => {
                    this.getProjects();
                });
            }
            else {
                this.errorMessage('Project could not be opened.');
                this.setState({ showProgress: false });
            }
        });
    }

    // private sendCancelProjectEmail(project: any): Promise<boolean> {
    //     return new Promise(async (resolve, reject) => {
    //         let submittedQuotes = await this.db.getSubmittedQuotes(project.Id);
    //         if (submittedQuotes && submittedQuotes.length > 0) {
    //             let emailTemplate = Constants.Email.CancelProject;
    //             let username = this.User.FirstName + (this.User.LastName ? ' ' + this.User.LastName.toString().charAt(0) + '.' : '');

    //             let subject = emailTemplate.Subject.replace('[username]', username);
    //             subject = subject.replace('[servicename]', project.Category.Name);
    //             subject = subject.replace('[cityname]', project.City.Name);
    //             subject = subject.replace('[statename]', project.City.State.Code);

    //             let body = emailTemplate.Body;

    //             body = body.replace("[username]", username);
    //             body = body.replace('[servicename]', project.Category.Name);
    //             body = body.replace('[cityname]', project.City.Name);
    //             body = body.replace('[statename]', project.City.State.Code);
    //             body = body.replace('[zip]', project.City.Pin);

    //             let counter = 0;
    //             submittedQuotes.forEach((quote: any) => {
    //                 if (quote && quote.SenderProfile && quote.SenderProfile.User && quote.SenderProfile.User.Email) {
    //                     this.db.sendEmail(quote.SenderProfile.User.Email, subject, body).then((sent) => {
    //                         counter++;

    //                         if (counter === submittedQuotes.length)
    //                             resolve(sent);
    //                     });
    //                 }
    //                 else {
    //                     this.errorMessage('Email could not be sent due to invalid email id.');

    //                     counter++;
    //                     if (counter === submittedQuotes.length)
    //                         resolve(false);
    //                 }
    //             });
    //         }
    //     });
    // }

    private getCategoryFAQs(catId) {
        if (catId) {
            this.db.getCategoryFAQs(catId, 'P').then((faqs) => {
                if (faqs) {
                    faqs.forEach((f) => {
                        f.FAQId = f.Id;
                        f.Id = 0;
                        f.Description = '';
                    });
                }

                this.setState({ ProjectFAQs: faqs });
            });
        }
        else {
            this.setState({ ProjectFAQs: [] });
        }
    }

    private getAnswerType(item, indx, faqs) {
        let aType = item.ControlType;
        let isRequired = item.IsRequired;
        let maxLength = item.MaxLength;
        let defaultVal = item.Description;
        let activeTab = this.state.ActiveTab;

        if (aType === "TXTB") {
            return (<React.Fragment>
                <input type='text' name={"txtBAnswer_" + indx} id={"txtBAnswer_" + indx} className="form-control commentBody" defaultValue={defaultVal} onBlur={(e) => { item.Description = e.target.value; this.setState({ ProjectFAQs: faqs }); }} />
                {isRequired ? (
                    <Validator id={"valBAnswer_" + indx} type="required" cssClass="error" errorMessage="Provide value." controlId={"txtBAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                ) : (null)}
                {maxLength ? (
                    <Validator id={"valBAnswerMaxLength_" + indx} type="required" cssClass="error" errorMessage={"Value cannot exceed " + maxLength + " characters."} controlId={"txtBAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                ) : (null)}
            </React.Fragment >);
        }
        else if (aType === "TXTA") {
            return (<React.Fragment>
                <textarea name={"txtAAnswer_" + indx} id={"txtAAnswer_" + indx} className="form-control commentBody" rows={3} defaultValue={defaultVal} onBlur={(e) => { item.Description = e.target.value; this.setState({ ProjectFAQs: faqs }); }} />
                {isRequired ? (
                    <Validator id={"valAAnswer_" + indx} type="required" cssClass="error" errorMessage="Provide value." controlId={"txtAAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                ) : (null)}
                {maxLength ? (
                    <Validator id={"valAAnswerMaxLength_" + indx} type="required" cssClass="error" errorMessage={"Value cannot exceed " + maxLength + " characters."} controlId={"txtAAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                ) : (null)}
            </React.Fragment>);
        }
        else if (aType === "CHKB") {
            return (<React.Fragment>
                <div className="custom-control custom-switch d-flex justify-content-between">
                    <input type="checkbox" className="custom-control-input" id="chkBAnswer" checked={defaultVal && defaultVal.toLowerCase() === "true" ? true : false} onChange={(e) => { item.Description = e.target.checked.toString(); this.setState({ ProjectFAQs: faqs }); }} />
                    <label className="custom-control-label commentBody" htmlFor="chkBAnswer">{item.MasterValue}</label>
                </div>
            </React.Fragment>);
        }
        else if (aType === "CHKL") {
            if (item.MasterValue) {
                let _mVals = item.MasterValue.split(/\r?\n/);

                return (<React.Fragment>
                    <FormGroup id={"chkListAnswer_" + indx} key={"chkListAnswer_" + indx}>
                        {_mVals && _mVals.map((val, idx) => {
                            return (<FormControlLabel control={<Checkbox value={val} checked={defaultVal && defaultVal.indexOf(val + ';') >= 0} onChange={(e) => { if (e.target.checked) { if (item.Description) { item.Description += e.target.value + ';'; } else { item.Description = e.target.value + ';'; } } else { item.Description = item.Description.replace(e.target.value + ';', ''); } this.setState({ ProjectFAQs: faqs }); }} />} label={val} className="commentBody" />);
                        })}
                    </FormGroup>
                    {isRequired ? (
                        <Validator id={"valChkListAnswer_" + indx} type="required" cssClass="error" errorMessage="Select a value." controlId={"chkListAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                    ) : (null)}
                </React.Fragment>);
            }
            else
                return (null);
        }
        else if (aType === "RBTL") {
            if (item.MasterValue) {
                let _mVals = item.MasterValue.split(/\r?\n/);

                return (<React.Fragment>
                    <RadioGroup id={"rbtnListAnswer_" + indx} name={"rbtnListAnswer_" + indx} defaultValue={defaultVal} onChange={(e) => { item.Description = e.target.value; this.setState({ ProjectFAQs: faqs }); }} className="commentBody">
                        {_mVals && _mVals.map((val, idx) => {
                            return (<FormControlLabel control={<Radio value={val} />} label={val} />);
                        })}
                    </RadioGroup>
                    {isRequired ? (
                        <Validator id={"valRbtnListAnswer_" + indx} type="required" cssClass="error" errorMessage="Select a value." controlId={"rbtnListAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                    ) : (null)}
                </React.Fragment >);
            }
            else
                return (null);
        }
        else if (aType === "DDL") {
            if (item.MasterValue) {
                let _mVals = item.MasterValue.split(/\r?\n/);

                return (<React.Fragment>
                    <select id={"ddlAnswer_" + indx} name={"ddlAnswer" + indx} className="form-control commentBody" defaultValue={defaultVal} onChange={(e) => { item.Description = e.target.value; this.setState({ ProjectFAQs: faqs }); }} >
                        <option value="">Select</option>
                        {_mVals && _mVals.map((val, idx) => {
                            return (<option value={val}>{val}</option>);
                        })}
                    </select>
                    {isRequired ? (
                        <Validator id={"valDdlAnswer" + indx} type="required" cssClass="error" errorMessage="Select a value." controlId={"ddlAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                    ) : (null)}
                </React.Fragment>);
            }
            else
                return (null);
        }
        // else if (aType === "ATCF") {

        // }
        else {
            return null;
        }
    }

    private getPortfolio(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.getPortfolio(profileId).then((result) => {
                resolve(result);
            });
        });
    }

    private viewFeaturedProject(item) {
        let _pFiles = item.Media;
        let projectFiles: any[] = [];

        if (_pFiles && _pFiles.length > 0) {
            _pFiles.forEach((pFile) => {
                projectFiles.push({ id: pFile.Id, name: pFile.Name, url: pFile.LinkUrl });
            });
        }

        this.setState({
            ProjectId: item.Id, ProjectFiles: projectFiles, ProjectTitle: item.Name, ProjectDescription: item.Description, ProjectPrice: item.Price,
            ProjectService: (item.Service ? item.Service.Id.toString() : ''), ProjectServiceName: (item.Service ? item.Service.Name.toString() : ''),
            ProjectYear: item.Year.toString(), showProjectDialog: true, DialogMode: 'V'
        });
    }

    private resetProjectDialog() {
        this.setState({
            ProjectId: '', ProjectFiles: [], ProjectTitle: '', ProjectDescription: '', ProjectPrice: '', ProjectService: '', ProjectServiceName: '', ProjectYear: '',
            ProjectLocation: null, ProjectStateCode: '', showProjectDialog: false, DialogMode: ''
        });
    }

    private handleQuoteRequest(project) {
        this.setState({ TabIndex: 2, showProgress: true });
        this.db.getRelatedPros(project.Id).then((relatedPros) => {
            this.setState({
                showProgress: false, ItemId: project.Id, RelatedPros: relatedPros, CategoryId: project.Category.Id.toString(), SelectedProject: project,
                ProjectCategoryName: project.Category.Name, ProjectCityName: project.City.Name, ProjectStateName: project.City.State.Name, ProjectPin: project.City.Pin
            });
        });
    }

    private handleRequestQuote(relatedPro) {
        this.setState({ showProgress: true, TabIndex: 6 }, () => {
            this.db.getCategoryRelatedProjects(this.state.CategoryId, 0, (this.props.id > 0 ? this.props.id : this.User.Profile.Id)).then((result) => {
                this.setState({
                    showProgress: false, RelatedPro: relatedPro, RelatedProjects: result, ProjectId: '', ProjectName: '', HasRequestedQuote: false
                });
            });
        });
    }

    private handleViewProfile(relatedPro, tabIndex) {
        this.setState({ showProgress: true, TabIndex: tabIndex });
        this.getPortfolio(relatedPro.Profile.Id).then((portfolio) => {
            this.setState({
                showProgress: false, RelatedPro: relatedPro, ProjectId: '', ProjectName: '',
                Portfolio: portfolio, PortfolioMedia: portfolio ? portfolio.Media : [], FeaturedProjects: portfolio ? portfolio.FeaturedProjects : []
            });
        });
    }

    private handleViewHiredProProfile(profile) {
        this.setState({ showProgress: true, TabIndex: 7 });
        this.getPortfolio(profile.Profile.Id).then((portfolio) => {
            this.setState({
                showProgress: false, HiredPro: profile, ProjectId: '', ProjectName: '',
                Portfolio: portfolio, PortfolioMedia: portfolio ? portfolio.Media : [], FeaturedProjects: portfolio ? portfolio.FeaturedProjects : []
            });
        });
    }

    private handleQuotesReceivedClick(project) {
        this.setState({ showProgress: true, TabIndex: 4 });
        this.db.getSubmittedQuotes(project.Id).then((submittedQuotes) => {
            this.setState({ showProgress: false, ItemId: project.Id, ReceivedQuotes: submittedQuotes, SelectedProject: project });
        });
    }

    private handleViewQuoteDetails(item) {
        this.setState({ showProgress: true, TabIndex: 5 });
        this.db.getQuoteDetails(item.Id).then((result) => {
            if (result) {
                this.db.getQuoteFAQs(item.Id).then((faqs) => {
                    this.setState({ showProgress: false, QuoteDetails: result, QuoteFAQs: faqs, RelatedPro: { Profile: item.SenderProfile, Rating: item.SenderProfileRating && item.SenderProfileRating.length > 0 ? item.SenderProfileRating[0].Rating : 0 } });
                });
            }
            else {
                this.setState({ showProgress: false });
            }
        });
    }

    private sendProHireEmail(itemId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.HirePro;
            let subject = emailTemplate.Subject;
            let body = emailTemplate.Body;

            let username = this.state.QuoteDetails.SenderProfile.User.FirstName + (this.state.QuoteDetails.SenderProfile.User.LastName ? ' ' + this.state.QuoteDetails.SenderProfile.User.LastName.toString().charAt(0) + '.' : '');

            body = body.replace("[username]", username).replace("[username]", username);
            body = body.replace("[servicename]", this.state.ProjectCategoryName);
            body = body.replace('[cityname]', this.state.ProjectCityName);
            body = body.replace('[statename]', this.state.ProjectStateName);
            body = body.replace('[zip]', this.state.ProjectPin);
            body = body.replace('[lid]', itemId);

            if (this.state.QuoteDetails.SenderProfile.User.Email) {
                this.db.sendEmail(this.state.QuoteDetails.SenderProfile.User.Email, subject, body).then((sent) => {
                    if (sent) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(false);
            }
        });
    }

    private handleProHireClick() {
        this.setState({ showProgress: true });
        this.db.hirePro(this.state.QuoteDetails.SenderProfile.Id, this.state.QuoteDetails.SenderProfile.User.Email, this.state.ItemId, this.state.QuoteDetails.CustomerProfile.User.Email).then((hired) => {
            if (hired) {
                this.sendProHireEmail(this.state.ItemId).then((sent) => {
                    this.successMessage("You have hired " + this.state.QuoteDetails.SenderProfile.BusinessName + " for this project. " + this.state.QuoteDetails.SenderProfile.BusinessName + " has been informed at " + this.state.QuoteDetails.SenderProfile.User.Email + ".");
                    this.setState({ ItemId: 0, ReceivedQuotes: [], QuoteDetails: null, QuoteFAQs: [], SelectedProject: null }, () => { this.getProjects(); });
                });
            }
            else {
                this.errorMessage('The Pro could not be hired at this moment.');
                this.setState({ showProgress: false });
            }
        });
    }

    private handleProjectChange() {
        if (this.state.ProjectId) {
            let project = this.state.Projects.find((p) => { return (p.Id.toString() === this.state.ProjectId.toString()); });

            this.setState({ showQuoteRequestProgress: true, QuoteProject: project });
            if (!this.state.RequestedQuotes || this.state.RequestedQuotes.length <= 0) {
                this.db.getRequestedQuotes((this.props.id > 0 ? this.props.id : this.User.Profile.Id)).then((quotes) => {
                    let quoteExists = false;
                    if (quotes) {
                        let _quote = quotes.find((q) => { return (q.ProjectId.toString() === this.state.ProjectId.toString() && q.ProId.toString() === this.state.RelatedPro.Profile.Id.toString()); });
                        if (_quote)
                            quoteExists = true;
                    }

                    this.setState({
                        showQuoteRequestProgress: false, RequestedQuotes: quotes, HasRequestedQuote: quoteExists,
                        showDialogConfirmation: (quoteExists ? true : false), DialogConfirmationType: (quoteExists ? 'A' : ''),
                        DialogConfirmationMessage: (quoteExists ? 'You have already requested this Pro to submit a quote for this project.' : '')
                    });
                });
            }
            else {
                let quoteExists = false;
                if (this.state.RequestedQuotes) {
                    let _quote = this.state.RequestedQuotes.find((q) => { return (q.ProjectId.toString() === this.state.ProjectId.toString() && q.ProId.toString() === this.state.RelatedPro.Profile.Id.toString()); });
                    if (_quote)
                        quoteExists = true;
                }

                this.setState({
                    showQuoteRequestProgress: false, HasRequestedQuote: quoteExists, showDialogConfirmation: (quoteExists ? true : false), DialogConfirmationType: (quoteExists ? 'A' : ''),
                    DialogConfirmationMessage: (quoteExists ? 'You have already requested this Pro to submit a quote for this project.' : '')
                });
            }
        }
        else {
            this.setState({ HasRequestedQuote: false, QuoteProject: null });
        }
    }

    private sendQuoteRequestEmail(itemId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.RequestQuote;
            let subject = emailTemplate.Subject;
            let body = emailTemplate.Body;

            body = body.replace("[username]", this.User.FirstName + (this.User.LastName ? ' ' + this.User.LastName.toString().charAt(0) + '.' : ''));
            body = body.replace("[servicename]", this.state.QuoteProject.Category.Name);
            body = body.replace('[cityname]', this.state.QuoteProject.City.Name);
            body = body.replace('[statename]', this.state.QuoteProject.City.State.Name);
            body = body.replace('[zip]', this.state.QuoteProject.City.Pin);
            body = body.replace('[lid]', itemId);

            this.checkIfUserIsSubscribedToNotificationType(this.state.RelatedPro.Profile.Id, Constants.NotificationTypes.NewQuoteRequest).then((exists) => {
                if (exists) {
                    if (this.state.RelatedPro.Profile.User.Email) {
                        this.db.sendEmail(this.state.RelatedPro.Profile.User.Email, subject, body).then((sent) => {
                            if (sent) {
                                resolve(true);
                            }
                            else {
                                resolve(false);
                            }
                        });
                    }
                    else {
                        resolve(false);
                    }
                }
                else {
                    resolve(true);
                }
            });
        });
    }

    private handleQuoteSubmission() {
        this.setState({ showProgress: true });
        this.db.requestQuote(this.state.CategoryId, this.state.ProjectId, (this.props.id > 0 ? this.props.id : this.User.Profile.Id), this.User.Email, this.state.RelatedPro.Profile.Id, this.state.RelatedPro.Profile.User.Email).then((requestId) => {
            if (requestId > 0) {
                this.sendQuoteRequestEmail(requestId).then((sent) => {
                    if (sent)
                        this.successMessage('Request for quote submitted successfully.');
                    else
                        this.successMessage('Request for quote submitted successfully but email to Pro could not be sent.');

                    this.setState({ showProgress: false, HasRequestedQuote: true }, () => {
                        this.db.getRequestedQuotes((this.props.id > 0 ? this.props.id : this.User.Profile.Id)).then((quotes) => {
                            this.setState({
                                showProgress: false, RequestedQuotes: quotes, HasRequestedQuote: false, ProjectId: '',
                                ProjectCategoryName: '', ProjectCityName: '', ProjectStateName: '', ProjectPin: ''
                            });
                        });
                    });
                });
            }
            else {
                this.errorMessage('Request for quote could not be submitted.');
                this.setState({ showProgress: false });
            }
        });
    }

    private handleBackClick(activeTab) {
        this.setState({ ActiveTab: activeTab - 1 });
    }

    private handleContinueClick(activeTab) {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid)
            this.setState({ ActiveTab: activeTab + 1 });
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    private handleMessage() {
        this.setState({ showMessageDialog: true });
    }

    private resetMessage() {
        this.setState({ showMessageDialog: false, Body: '' }, () => {
            this.formValidators = new Array<Validator>();
        });
    }

    private handleSendMessage() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.formValidators = new Array<Validator>();
            this.setState({ showDialogProgress: true }, () => {
                let emailTemplate = Constants.Email.NewMessage;
                let subject = emailTemplate.Subject;
                let body = emailTemplate.Body;

                body = body.replace("[username]", this.User.DisplayName);
                body = body.replace("[message]", this.state.Body);

                this.db.addMessage(this.User.Profile.Id, this.state.RelatedPro.Profile.Id, subject, body, '0', '0', this.AppRole, Constants.UserRoles.Pro.toString()).then((added) => {
                    if (added > 0) {
                        if (this.state.RelatedPro.Profile.User.Email) {
                            this.db.sendEmail(this.state.RelatedPro.Profile.User.Email, subject, body).then((sent) => {
                                if (sent) {
                                    this.successMessage('Message sent successfully.');
                                    this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                                }
                                else {
                                    this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                                    this.errorMessage('Message email could not be sent.');
                                }
                            });
                        }
                        else {
                            this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                            this.errorMessage('Message could not be sent.');
                        }
                    }
                    else {
                        this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                        this.errorMessage("Message could not be added to sender's inbox.");
                    }
                });
            });
        }
    }

    private getPagedData(): any {
        const { Projects, FilterType, FilterStatus, FilterCategoryId } = this.state;
        let _data = Projects;

        if (FilterType) {
            _data = _data.filter((p) => { return (p.Type.toLowerCase() === FilterType.toLowerCase()); });
        }

        if (FilterStatus) {
            _data = _data.filter((p) => { return (p.Status.toLowerCase() === FilterStatus.toLowerCase()); });
        }

        if (FilterCategoryId) {
            _data = _data.filter((p) => { return (p.Category && p.Category.Id.toString() === FilterCategoryId); });
        }

        return _data;
    }

    private loadMore() {
        let pageNumber = (this.state.PageNumber + 1);
        let hasMore = false;

        let _data = this.getPagedData();
        if (_data && _data.length > (this.state.PageSize * pageNumber)) {
            _data = _data.slice(0, (this.state.PageSize * pageNumber));
            hasMore = true;
        }

        this.setState({ FilteredProjects: _data, HasMoreRecords: hasMore, PageNumber: pageNumber });
    }

    public render() {

        const { loading, showConfirmation, ConfirmationMessage, Message, MessageType, ShowMessage, ItemId, DialogMode, Profile,
            ProjectName, ProjectDescription, ProjectCategoryId, ProjectCategoryName, ProjectBudget, ConfirmationType, ProjectType,
            ProjectFAQs, Categories, FilterType, FilterStatus, FilterCategoryId, FilteredProjects, ProjectCityName, ProjectPin, ProjectStateName,
            RelatedPros, RelatedPro, ReceivedQuotes, QuoteDetails, QuoteFAQs, ActiveTab, PortfolioMedia, FeaturedProjects, showProjectDialog, ProjectTitle, ProjectYear,
            ProjectPrice, ProjectFiles, ProjectServiceName, RelatedProjects, ProjectId, HasRequestedQuote, HiredPro,
            TabIndex, showProgress, SelectedProject, ProjectLocation, showMessageDialog, showDialogProgress, Body, HasMoreRecords } = this.state;

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            if (!this.IsUserValid) {
                window.location.href = '/login' + (window.location.search ? '?src=' + window.location.search : '');
            }
            else {
                if (this.AppRole !== Constants.UserRoles.Customer && this.AppRole !== Constants.UserRoles.Admin) {

                }
                else {
                    template = (<React.Fragment>
                        <Header />
                        <section className="containerMinHeight accountSettingContainer borderBtm">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="profileContainer">
                                            <div className="col-12 col-sm-12">
                                                <div className="formContainer">
                                                    {/* My Projects */}
                                                    {TabIndex === 0 ? (<React.Fragment>
                                                        {this.props.id ? (<div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="breadCrumb mb-1">
                                                                    <a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2">&gt;</span><span className="ml-2">Customer's Projects</span>
                                                                </div>
                                                            </div>
                                                        </div>) : (null)}
                                                        <h5>{!this.props.id || this.User.Profile.Id === this.props.id ? "My Projects" : (Profile ? Profile.User.DisplayName + "'s Projects" : "Customer Projects")}</h5>
                                                        {!this.props.id || this.User.Profile.Id === this.props.id ? (<div className="row">
                                                            <div className="col-12 col-sm-10">
                                                                <Typography variant="subtitle2" color="text.secondary">Start a project for your requirements and let the Pros connect with you for services.</Typography>
                                                            </div>
                                                            <div className="col-12 col-sm-2">
                                                                <div className="formButtonContainer text-right mt-0 mb-0">
                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ TabIndex: 1, DialogMode: '', ItemId: 0, ActiveTab: 0 }, () => { this.initializePlacesAutoComplete(); }); }}><AddIcon /></button>
                                                                </div>
                                                            </div>
                                                        </div>) : (null)}
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-sm-4">
                                                                <div className="form-group">
                                                                    <label>Type</label>
                                                                    <select id="filterType" name="filterType" className="form-control commentBody" defaultValue={FilterType} onChange={(e) => { this.setState({ FilterType: e.target.value }, () => { this.filterProjects(); }); }}>
                                                                        <option value="">Select</option>
                                                                        <option value="Private">Private</option>
                                                                        <option value="Public">Public</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-4">
                                                                <div className="form-group">
                                                                    <label>Service</label>
                                                                    <select id="filterCategory" name="filterCategory" className="form-control commentBody" defaultValue={FilterCategoryId} onChange={(e) => { this.setState({ FilterCategoryId: e.target.value }, () => { this.filterProjects(); }); }}>
                                                                        <option value="">Select</option>
                                                                        {Categories && Categories.map((cat) => {
                                                                            return (<option value={cat.Id.toString()} >{cat.Name}</option>);
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-4">
                                                                <div className="form-group">
                                                                    <label>Status</label>
                                                                    <select id="filterStatus" name="filterStatus" className="form-control commentBody" defaultValue={FilterStatus} onChange={(e) => { this.setState({ FilterStatus: e.target.value }, () => { this.filterProjects(); }); }}>
                                                                        <option value="">Select</option>
                                                                        <option value="Open">Open</option>
                                                                        <option value="Closed">Closed</option>
                                                                        <option value="Cancelled">Cancelled</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {showProgress ? (<ProgressBar />) : (
                                                            <React.Fragment>
                                                                {!FilteredProjects || FilteredProjects.length <= 0 ? (<div className="tabPanel"><Typography variant="subtitle2" color="text.secondary" className="mt-3">There are no projects available.</Typography></div>) : (<React.Fragment>
                                                                    {FilteredProjects.map((item) => {
                                                                        return (<div className="tabPanel mt-2">
                                                                            <div className="form-group">
                                                                                <div className="row">
                                                                                    <div className="col-10 col-sm-10">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <a href="javascript:void(0);" onClick={(e) => { this.handleViewProject(item); }}><Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>{item.Name}</Typography></a>
                                                                                            </div>
                                                                                            {/* <div className="col-6 col-sm-3 text-right">
                                                                                                {item.Status.toLowerCase() !== "closed" ? (<React.Fragment>{item.Status.toLowerCase() === 'open' ? (<IconButton onClick={(e) => { this.handleQuoteRequest(item); }} title="Request a Quote"><ContactSupportOutlinedIcon /></IconButton>) : (null)}<IconButton onClick={(e) => { this.handleEditProject(item); }} title="Edit Project"><EditIcon /></IconButton> {item.Status === "Open" && item.QuotesSubmitted === 0 ? (<React.Fragment><IconButton onClick={(e) => { this.handleDeleteProject(item); }} className="pl-2" title="Delete Project"><DeleteIcon /></IconButton> <IconButton onClick={(e) => { this.handleCancelProject(item); }} className="pl-2" title="Cancel Project"><CancelIcon /></IconButton></React.Fragment>) : (null)}</React.Fragment>) :
                                                                                                    (<IconButton onClick={(e) => { this.handleViewProject(item); }} title="View details"><PreviewOutlinedIcon /></IconButton>)}
                                                                                            </div> */}
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <Chip label={item.Category.Name} variant="outlined" className="mt-1" /> <Chip icon={<LocationOnOutlinedIcon />} label={item.City.Name + "," + item.City.State.Code + " " + item.City.Pin} variant="outlined" className="mt-1" /> {item.Budget && item.Budget > 0 ? (<Chip icon={<MonetizationOnOutlinedIcon />} label={item.Budget} variant="outlined" className="mt-1" />) : (null)} <Chip icon={item.Type && item.Type.toUpperCase() === "PRIVATE" ? (<LockOutlinedIcon />) : (<PublicOutlinedIcon />)} label={item.Type && item.Type.toUpperCase()} className="mt-1" /> <Chip icon={<ContactSupportOutlinedIcon />} color={item.QuotesSubmitted > 0 && item.Status.toLowerCase() !== 'closed' ? "primary" : "default"} label={item.QuotesSubmitted + " quotes received"} disabled={item.QuotesSubmitted <= 0 || item.Status.toLowerCase() === 'closed'} onClick={(e) => { if (item.QuotesSubmitted > 0 && item.Status.toLowerCase() !== 'closed') { this.handleQuotesReceivedClick(item); } }} className="mt-1" /> {item.Status.toLowerCase() === 'closed' ? (<Chip color={"success"} sx={{ color: '#fff' }} label={item.HiredPro && item.HiredPro.Profile ? "Hired Pro: " + item.HiredPro.Profile.BusinessName : ''} onClick={(e) => { this.handleViewHiredProProfile(item.HiredPro); }} className="mt-1" />) : (null)}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <hr />
                                                                                                <Typography variant="subtitle2" color="text.secondary">{item.Description}</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-2 col-sm-2">
                                                                                        <div className="form-group mt-3">
                                                                                            {item.Status.toLowerCase() !== "closed" ? (<React.Fragment>
                                                                                                {item.Status.toLowerCase() === 'open' && (!this.props.id || this.User.Profile.Id === this.props.id) ? (<div>
                                                                                                    <Typography variant="caption" className="mt-3"><ContactSupportOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleQuoteRequest(item); }} title="Request a quote" className="ml-2">Request a Quote</a></Typography></div>) : (null)}
                                                                                                <div><Typography variant="caption" className="mt-3"><EditIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleEditProject(item); }} title="Edit project" className="ml-2">Edit Project</a></Typography></div>
                                                                                                {item.Status === "Open" && item.QuotesSubmitted === 0 ? (<React.Fragment>
                                                                                                    <div><Typography variant="caption" className="mt-3"><DeleteIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleDeleteProject(item); }} title="Delete project" className="ml-2">Delete Project</a></Typography></div>
                                                                                                    <div><Typography variant="caption" className="mt-3"><CancelIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleCancelProject(item); }} title="Cancel project" className="ml-2">Cancel Project</a></Typography></div>
                                                                                                </React.Fragment>) : (null)}
                                                                                                {/* {item.Status === "Open" ? (<React.Fragment>
                                                                                                    <div><Typography variant="caption" className="mt-3"><CancelIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleCancelProject(item); }} title="Cancel project" className="ml-2">Cancel Project</a></Typography></div>
                                                                                                </React.Fragment>) : (null)} */}
                                                                                                {item.Status === "Cancelled" ? (<React.Fragment>
                                                                                                    <div><Typography variant="caption" className="mt-3"><CancelIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleOpenProject(item); }} title="Open project" className="ml-2">Open Project</a></Typography></div>
                                                                                                </React.Fragment>) : (null)}
                                                                                            </React.Fragment>) : (<div><Typography variant="caption" className="mt-3"><PreviewOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleViewProject(item); }} title="View project details" className="ml-2">View Project</a></Typography></div>)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>);
                                                                    })}
                                                                </React.Fragment>)}
                                                                {HasMoreRecords ? (<div className="d-flex align-items-center justify-content-center pt-3">
                                                                    <button className='btn btn-dark' title="Load more results" onClick={(e) => { this.loadMore(); }}>Load more</button>
                                                                </div>) : (null)}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>) : (null)}
                                                    {/* New, Edit or View Project Details */}
                                                    {TabIndex === 1 ? (<React.Fragment>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="breadCrumb mb-1">
                                                                    {this.props.id ? (<><a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2 mr-2">&gt;</span></>) : (null)}<a href="javascript:void(0);" onClick={(e) => { this.resetProjectDetails(); }}>{this.props.id ? Profile.User.DisplayName + "'s Projects" : "My Projects"}</a><span className="ml-2">&gt;</span><span className="ml-2">{(DialogMode === 'E' ? 'Edit Project' : (ItemId <= 0 ? 'New Project' : 'Project details'))}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <h5>{(DialogMode === 'E' ? 'Edit Project' : (ItemId <= 0 ? 'New Project' : 'Project details'))}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="profileContainer">
                                                            <div className="formContainer">
                                                                <div className="tabPanel">
                                                                    {showProgress ? (<ProgressBar />) : (
                                                                        <React.Fragment>
                                                                            <div className="col-12 col-sm-12">
                                                                                {ActiveTab === 0 ? (<React.Fragment>
                                                                                    <div className="form-group">
                                                                                        <label>{DialogMode === 'V' ? "Project Name" : "Enter project name"}</label>
                                                                                        {DialogMode === 'V' ? (<Typography variant="subtitle2" color="text.secondary">{ProjectName}</Typography>) : (
                                                                                            <React.Fragment>
                                                                                                <input id="projectName" name="projectName" key="projectName" type="text" className="form-control" placeholder="" defaultValue={ProjectName} onBlur={(e) => { this.setState({ ProjectName: e.target.value }); }} />
                                                                                                <Validator id="valProjectName" type='required' cssClass="error" errorMessage="Provide name." controlId="projectName" valueToValidate={ActiveTab === 0 ? ProjectName : '1'} formValidators={this.formValidators}></Validator>
                                                                                                <Validator id="valProjectNameLength" type='max_length' cssClass="error" errorMessage="Name cannot exceed 100 characters." maxLength={100} controlId="projectName" valueToValidate={ActiveTab === 0 ? ProjectName : '1'} formValidators={this.formValidators}></Validator>
                                                                                            </React.Fragment>)}
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>{DialogMode === 'V' ? "Project Type" : "Is this a private project?"}</label>
                                                                                        {DialogMode === 'V' ? (<Typography variant="subtitle2" color="text.secondary">{ProjectType}</Typography>) : (<div className="custom-control custom-switch d-flex justify-content-between">
                                                                                            <input type="checkbox" className="custom-control-input" id="isPrivate" disabled={DialogMode === 'V'} checked={ProjectType.toUpperCase() === "PRIVATE"} onChange={(e) => { this.setState({ ProjectType: (e.target.checked ? "PRIVATE" : "PUBLIC") }); }} />
                                                                                            <label className="custom-control-label" htmlFor="isPrivate">{ProjectType.toLowerCase() === "public" ? "No" : "Yes"}</label>
                                                                                        </div>)}
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>{DialogMode === 'V' ? "Service" : "What service do you need?"}</label>
                                                                                        {DialogMode === 'V' || DialogMode === 'E' ? (<Typography variant="subtitle2" color="text.secondary">{ProjectCategoryName}</Typography>) : (
                                                                                            <React.Fragment>
                                                                                                <select id="category" name="category" className="form-control commentBody" defaultValue={ProjectCategoryId}
                                                                                                    onChange={(e) => { this.setState({ ProjectCategoryId: e.target.value, ProjectCategoryName: e.target.selectedOptions[0].text }, () => { this.getCategoryFAQs(e.target.value); }); }}>
                                                                                                    <option value="">Select</option>
                                                                                                    {Categories && Categories.map((cat) => {
                                                                                                        return (<option value={cat.Id.toString()} >{cat.Name}</option>);
                                                                                                    })}
                                                                                                </select>
                                                                                                <Validator id="valCategory" type='required' cssClass="error" errorMessage="Select a service." controlId="category" valueToValidate={ActiveTab === 0 ? ProjectCategoryId : '1'} formValidators={this.formValidators}></Validator>
                                                                                            </React.Fragment>)}
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>{DialogMode === 'V' ? "Location" : "Where do you need it (Zip code)?"}</label>
                                                                                        {DialogMode === 'V' || DialogMode === 'E' ? (<Typography variant="subtitle2" color="text.secondary">{ProjectLocation ? ProjectLocation.formatted_address : (ProjectCityName + "," + ProjectStateName + " " + ProjectPin)}</Typography>) : (
                                                                                            <React.Fragment>
                                                                                                <input id="zip" name="zip" type="text" className="form-control" defaultValue={ProjectPin} />
                                                                                                {/* <Autocomplete multiple={false} id="location" disabled={DialogMode === 'V'} className="autoComplete" options={Cities} getOptionLabel={(option) => { return (option && option.Name ? option.Name : ""); }}
                                                                                                    onChange={(e, newVal) => { this.setState({ SelectedCities: newVal, ProjectCityId: newVal ? newVal.Id.toString() : '', ProjectCityName: newVal ? newVal.Name : '', ProjectPin: newVal ? newVal.Pin.toString() : '', ProjectStateName: newVal ? newVal.State.Code : '' }); }}
                                                                                                    defaultValue={SelectedCities} filterSelectedOptions={true} renderInput={(params) => (<TextField name="location" {...params} label="" placeholder="" />)} /> */}
                                                                                                <Validator id="valLocation" type='required' cssClass="error" errorMessage="Provide location." controlId="location" valueToValidate={ActiveTab === 0 ? ProjectPin : '1'} formValidators={this.formValidators}></Validator>
                                                                                            </React.Fragment>)}
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>{DialogMode === 'V' ? "Estimated Budget" : "What is your estimated budget for this project? (Optional)"}</label>
                                                                                        {DialogMode === 'V' ? (<Typography variant="subtitle2" color="text.secondary">{ProjectBudget}</Typography>) : (
                                                                                            <React.Fragment>
                                                                                                <input id="budget" name="budget" key="budget" type="text" className="form-control dollarIconMap" placeholder="" defaultValue={ProjectBudget} onBlur={(e) => { this.setState({ ProjectBudget: e.target.value }); }} />
                                                                                                {/* <Validator id="valBudget" type='required' cssClass="error" errorMessage="Provide budget." controlId="budget" valueToValidate={ActiveTab === 0 ? ProjectBudget : '1'} formValidators={this.formValidators}></Validator> */}
                                                                                                <Validator id="valBudgetType" type='number' cssClass="error" errorMessage="Budget can be numeric only." controlId="budget" valueToValidate={ActiveTab === 0 ? ProjectBudget : '1'} formValidators={this.formValidators}></Validator>
                                                                                            </React.Fragment>)}
                                                                                    </div>
                                                                                </React.Fragment>) : (null)}
                                                                                {ProjectFAQs && ProjectFAQs.map((item, indx) => {
                                                                                    return (<React.Fragment>
                                                                                        {ActiveTab === (indx + 1) ? (<React.Fragment>
                                                                                            <div className="form-group">
                                                                                                <label>{item.Name}</label>
                                                                                                {DialogMode === 'V' ? (<Typography variant="subtitle2" color="text.secondary">{item.Description}</Typography>) : (
                                                                                                    <React.Fragment>
                                                                                                        {this.getAnswerType(item, indx, ProjectFAQs)}
                                                                                                    </React.Fragment>
                                                                                                )}
                                                                                            </div>
                                                                                        </React.Fragment>) : (null)}
                                                                                    </React.Fragment>);
                                                                                })}
                                                                                {ActiveTab === (ProjectFAQs && ProjectFAQs.length > 0 ? (ProjectFAQs.length + 1) : (1)) ? (
                                                                                    <div className="form-group">
                                                                                        <label>{DialogMode === 'V' ? "Comments" : "Any comments"}</label>
                                                                                        {DialogMode === 'V' ? (<Typography variant="subtitle2" color="text.secondary">{ProjectDescription}</Typography>) : (
                                                                                            <React.Fragment>
                                                                                                <textarea id="projDesc" name="projDesc" key="projDesc" rows={4} className="form-control" placeholder="" defaultValue={ProjectDescription} onBlur={(e) => { this.setState({ ProjectDescription: e.target.value }); }} />
                                                                                                <Validator id="valprojDesc" type='required' cssClass="error" errorMessage="Provide comments." controlId="projDesc" valueToValidate={ActiveTab === (ProjectFAQs && ProjectFAQs.length > 0 ? (ProjectFAQs.length + 1) : (1)) ? ProjectDescription : "1"} formValidators={this.formValidators}></Validator>
                                                                                                <Validator id="valprojDescLength" type='max_length' cssClass="error" errorMessage="Comments cannot exceed 500 characters." maxLength={500} controlId="projDesc" valueToValidate={ActiveTab === (ProjectFAQs && ProjectFAQs.length > 0 ? (ProjectFAQs.length + 1) : (1)) ? ProjectDescription : "1"} formValidators={this.formValidators}></Validator>
                                                                                            </React.Fragment>)}
                                                                                    </div>) : (null)}
                                                                            </div>
                                                                            <div className="col-12 col-sm-12">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-sm-12">
                                                                                        <div className="form-group text-right">
                                                                                            <div className="formButtonContainer mt-3">
                                                                                                <button type="submit" className={"btn btn-cancel btnCancel" + (ActiveTab > 0 ? " mr-5" : "")} disabled={showDialogProgress} onClick={(e) => { this.resetProjectDetails(); }}>Cancel</button>
                                                                                                {ActiveTab > 0 ? (<button type="submit" className="btn btn-cancel" disabled={showDialogProgress} onClick={(e) => { this.handleBackClick(ActiveTab); }}>Back</button>) : (null)}
                                                                                                {ActiveTab < (ProjectFAQs && ProjectFAQs.length > 0 ? (ProjectFAQs.length + 1) : (1)) ?
                                                                                                    (<button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={showDialogProgress} onClick={(e) => { this.handleContinueClick(ActiveTab); }}>Continue</button>) :
                                                                                                    (<React.Fragment>
                                                                                                        {DialogMode !== 'V' ? (<button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={showDialogProgress} onClick={(e) => { this.handleProjectSaveClick(); }}>Save</button>) : (null)}
                                                                                                    </React.Fragment>)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>) : (null)}
                                                    {/* Related Pros */}
                                                    {TabIndex === 2 ? (<React.Fragment>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="breadCrumb mb-1">
                                                                    {this.props.id ? (<><a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2 mr-2">&gt;</span></>) : (null)}<a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, ItemId: 0, RelatedPros: [], RelatedPro: null, CategoryId: '', ProjectCategoryName: '', ProjectCityName: '', ProjectStateName: '', ProjectPin: '', SelectedProject: null }); }}>{this.props.id ? Profile.User.DisplayName + "'s Projects" : "My Projects"}</a><span className="ml-2">&gt;</span><span className="ml-2">Related Pros</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <h5>Related Pros{SelectedProject ? ' - ' + SelectedProject.Name : ''}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="profileContainer">
                                                            <div className="formContainer">
                                                                {showProgress ? (<div className="tabPanel"><ProgressBar /></div>) : (
                                                                    <React.Fragment>
                                                                        {!RelatedPros || RelatedPros.length <= 0 ? (<Typography variant="subtitle2" color="text.secondary" className="mt-3">There are no related pros available.</Typography>) : (<React.Fragment>
                                                                            {RelatedPros.map((item) => {
                                                                                return (<div className="tabPanel mt-2">
                                                                                    <div className="form-group">
                                                                                        <div className="row">
                                                                                            <div className="col-2 col-sm-1">
                                                                                                <div className="form-group">
                                                                                                    <Avatar alt={item.Profile.BusinessName} src={item.Profile.ProfileImage ? item.Profile.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 60, height: 60 }}></Avatar>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-8 col-sm-9">
                                                                                                <div className="form-group">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{item.Profile.BusinessName}</Typography>
                                                                                                        </div>
                                                                                                        {/* <div className="col-4 col-sm-4 text-right">
                                                                                                            <Typography variant="subtitle2">
                                                                                                                <IconButton onClick={(e) => { this.handleRequestQuote(item); }} title="Request a Quote"><ContactSupportOutlinedIcon /></IconButton>
                                                                                                                <IconButton onClick={(e) => { this.handleViewProfile(item, 3) }} title="View Profile"><BadgeOutlinedIcon /></IconButton>
                                                                                                            </Typography>
                                                                                                        </div> */}
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Reviews id="reviews" profileId={item.Profile.Id} reviewerUser={this.User} allowAskForReviews={false} allowRating={true}
                                                                                                                defaultRating={item.Rating && item.Rating.length > 0 && item.Rating[0].Rating ? item.Rating[0].Rating : 0} defaultReviews={item.Rating && item.Rating.length > 0 && item.Rating[0].TotalCount ? item.Rating[0].TotalCount : '0'}
                                                                                                                ratingClassName='commentRatingStar' showRating={true} showReviewCount={true}></Reviews>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12 mt-2">
                                                                                                            <Typography variant="subtitle2" color="text.secondary">{item.Profile.Description}</Typography>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-2 col-sm-2">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div><Typography variant="caption" className="mt-3"><ContactSupportOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleRequestQuote(item); }} title="Request a quote" className="ml-2">Request a Quote</a></Typography></div>
                                                                                                    <div><Typography variant="caption" className="mt-3"><BadgeOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleViewProfile(item, 3); }} title="View profile" className="ml-2">View Pro's Profile</a></Typography></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>);
                                                                            })}
                                                                        </React.Fragment>)}
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>) : (null)}
                                                    {/* Profile */}
                                                    {TabIndex === 3 || TabIndex === 8 ? (<React.Fragment>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="breadCrumb mb-1">
                                                                    {this.props.id ? (<><a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2 mr-2">&gt;</span></>) : (null)}{TabIndex === 3 ? (<React.Fragment><a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, ItemId: 0, RelatedPros: [], CategoryId: '', ProjectCategoryName: '', ProjectCityName: '', ProjectStateName: '', ProjectPin: '', RelatedPro: null, ProjectId: '', ProjectName: '', Portfolio: null, PortfolioMedia: [], FeaturedProjects: [], SelectedProject: null }); }}>{this.props.id ? Profile.User.DisplayName + "'s Projects" : "My Projects"}</a><span className="ml-2">&gt;</span><a href="javascript:void(0);" className="ml-2" onClick={(e) => { this.handleQuoteRequest(SelectedProject); }}>Related Pros</a><span className="ml-2">&gt;</span><span className="ml-2">Pro Profile</span></React.Fragment>) :
                                                                        (<React.Fragment><a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, ItemId: 0, ReceivedQuotes: [], QuoteDetails: null, QuoteFAQs: [], SelectedProject: null }); }}>{this.props.id ? Profile.User.DisplayName + "'s Projects" : "My Projects"}</a><span className="ml-2">&gt;</span><a href="javascript:void(0);" className="ml-2" onClick={(e) => { this.handleQuotesReceivedClick(SelectedProject); }}>Received Quotes</a><span className="ml-2">&gt;</span><span className="ml-2">Pro Profile</span></React.Fragment>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <h5>Pro Profile</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12">
                                                            <div className="form-group">
                                                                {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                    {!RelatedPro ? (null) : (
                                                                        <div className="profileContainer">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-12 p-0">
                                                                                    <div className="formContainer">
                                                                                        <div className="headingTxt">Profile</div>
                                                                                        <div className="tabPanel">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-3 col-sm-2">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <Avatar alt={RelatedPro.Profile.BusinessName} src={RelatedPro.Profile.ProfileImage ? RelatedPro.Profile.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 80, height: 80 }}></Avatar>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-9 col-sm-10">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{RelatedPro.Profile.BusinessName}</Typography>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <Reviews id="reviews" profileId={RelatedPro.Profile.Id} reviewerUser={this.User} allowAskForReviews={false} allowRating={true}
                                                                                                                        defaultRating={RelatedPro.Rating && RelatedPro.Rating.length > 0 && RelatedPro.Rating[0].Rating ? RelatedPro.Rating[0].Rating : 0} defaultReviews={RelatedPro.Rating && RelatedPro.Rating.length > 0 && RelatedPro.Rating[0].TotalCount ? RelatedPro.Rating[0].TotalCount : '0'}
                                                                                                                        ratingClassName='commentRatingStar' showRating={true} showReviewCount={true}></Reviews>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {this.AppRole === Constants.UserRoles.Customer ? (<div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <div className="formButtonContainer">
                                                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleMessage(); }}>Message {RelatedPro.Profile.BusinessName}</button>
                                                                                                                        {TabIndex === 3 ? (<button type="submit" className="btn btn-primary cutmBtnLogin ml-3" onClick={(e) => { this.handleRequestQuote(RelatedPro); }}>Request Quote</button>) : (null)}
                                                                                                                        {/* <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic || lead.HasSubmittedQuote} onClick={(e) => { if (this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) { this.submitQuote(lead); } }}>Submit Quote</button> */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>) : (null)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mt-3">
                                                                                                    <hr></hr>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-sm-3">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <PhoneOutlinedIcon fontSize="small" ></PhoneOutlinedIcon> <label>Phone</label>
                                                                                                            <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.Phone}</Typography></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-3">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <EmailOutlinedIcon fontSize="small" ></EmailOutlinedIcon> <label>Email</label>
                                                                                                            <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.Email}</Typography></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-3">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <EmojiEventsOutlinedIcon fontSize="small" ></EmojiEventsOutlinedIcon> <label>Year founded</label>
                                                                                                            <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.YearFounded}</Typography></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-3">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <WebOutlinedIcon fontSize="small" ></WebOutlinedIcon> <label>Website</label>
                                                                                                            <div className="pl-4"><Typography variant="subtitle2"><a href={RelatedPro.Profile.WebSiteUrl} target='_blank'>{RelatedPro.Profile.WebSiteUrl}</a></Typography></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mt-3">
                                                                                                    <hr></hr>
                                                                                                </div>
                                                                                                <div className="form-group mt-3">
                                                                                                    <LocationOnOutlinedIcon fontSize="small" ></LocationOnOutlinedIcon> <label>Address</label>
                                                                                                    <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.Address}{RelatedPro.Profile.CityName ? ", " + RelatedPro.Profile.CityName : ''}{RelatedPro.Profile.StateName ? ", " + RelatedPro.Profile.StateName : ''}{RelatedPro.Profile.Pin ? " " + RelatedPro.Profile.Pin : ''}</Typography></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Social media</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            {RelatedPro.SocialMedia && RelatedPro.SocialMedia.length > 0 ? (<React.Fragment>
                                                                                                                {RelatedPro.SocialMedia.map((item) => {
                                                                                                                    return (<React.Fragment>
                                                                                                                        {item.LinkUrl ? (<div><img src={'/images/' + item.LinkType + 'Icon.png'} alt={item.LinkType} /> <a href={item.LinkUrl} target='_blank'><Typography variant='caption'>{item.LinkUrl}</Typography></a></div>) : (null)}
                                                                                                                    </React.Fragment>);
                                                                                                                })}
                                                                                                            </React.Fragment>) : (<Typography variant="subtitle2" color="text.secondary">There is no information available about Pro's social media presence.</Typography>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Payment methods accepted</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant="subtitle2" className="commentBody">{RelatedPro.Payments && RelatedPro.Payments.length > 0 ? (<React.Fragment>
                                                                                                                {RelatedPro.Payments.map((p, indx) => {
                                                                                                                    if (indx < RelatedPro.Payments.length - 1)
                                                                                                                        return (p.Mode) + ', ';
                                                                                                                    else
                                                                                                                        return (p.Mode);
                                                                                                                })}
                                                                                                            </React.Fragment>) : (<Typography variant="subtitle2" color="text.secondary">There is no information available about Pro's accepted payment methods.</Typography>)}</Typography>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Your introduction</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.Description}</Typography>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Accreditations</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            {RelatedPro.Accreditations && RelatedPro.Accreditations.length > 0 ? (<Box sx={{ flexGrow: 1 }}>
                                                                                                                <Grid container item spacing={3} columns={12}>
                                                                                                                    <React.Fragment>
                                                                                                                        {RelatedPro.Accreditations.map((item) => {
                                                                                                                            let subHeader = (<React.Fragment><div>{item.Institution}</div><div>{this.getFormattedDate(new Date(this.getDateNumber(item.StartDate)), Constants.IDateTimeFormat.MMddyyyy, '/') + " - " + this.getFormattedDate(new Date(this.getDateNumber(item.EndDate)), Constants.IDateTimeFormat.MMddyyyy, '/')}</div></React.Fragment>);
                                                                                                                            return (<Grid key={'grdItem_' + item.Id} item xs={12}>
                                                                                                                                <React.Fragment>
                                                                                                                                    <Card key={'card_' + item.Id} variant="outlined" className="licenseCard">
                                                                                                                                        <CardHeader avatar={<Avatar sx={{ bgcolor: red[500] }}>{item.Name ? item.Name.charAt(0).toUpperCase() : ''}</Avatar>}
                                                                                                                                            title={item.Name} subheader={subHeader} />
                                                                                                                                        <CardContent className="licenseCardContentMaxHeight">
                                                                                                                                            <Typography variant="subtitle2" color="text.secondary" className="commentBody">
                                                                                                                                                {item.Description}
                                                                                                                                            </Typography>
                                                                                                                                        </CardContent>
                                                                                                                                    </Card>
                                                                                                                                </React.Fragment>
                                                                                                                            </Grid>);
                                                                                                                        })}
                                                                                                                    </React.Fragment>
                                                                                                                </Grid>
                                                                                                            </Box>) : (<Typography variant="subtitle2" color="text.secondary">There is no information available for the Pro's accreditations.</Typography>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Photos & videos</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            {PortfolioMedia && PortfolioMedia.length > 0 ? (
                                                                                                                <Box className="boxPortfolioMedia">
                                                                                                                    <Grid container item spacing={0} columns={12}>
                                                                                                                        <React.Fragment>
                                                                                                                            {PortfolioMedia.map((item) => {
                                                                                                                                let thumbnailUrl = item.Url;
                                                                                                                                let isYouTube = false;
                                                                                                                                let videoUrl = item.Url;

                                                                                                                                if (thumbnailUrl.toLowerCase().indexOf('youtube.com') >= 0) {
                                                                                                                                    let videoID = item.Url.substring(item.Url.indexOf('v=') + 2);

                                                                                                                                    isYouTube = true;
                                                                                                                                    thumbnailUrl = "https://img.youtube.com/vi/" + videoID + "/0.jpg";
                                                                                                                                    videoUrl = "https://www.youtube.com/embed/" + videoID;
                                                                                                                                }
                                                                                                                                else if (thumbnailUrl.toLowerCase().indexOf('vimeo.com') >= 0) {
                                                                                                                                    let videoID = videoUrl.substring(videoUrl.lastIndexOf('/') + 1);

                                                                                                                                    isYouTube = true;
                                                                                                                                    videoUrl = "https://player.vimeo.com/video/" + videoID + "?fullscreen=1";
                                                                                                                                }

                                                                                                                                return (<Grid key={'grdItem_' + item.Id} item xs={4}>
                                                                                                                                    <React.Fragment>
                                                                                                                                        <Card key={'card_' + item.Id} variant="outlined" className="portfolioMediaCard">
                                                                                                                                            {item.Type === 'V' ? (<CardMedia component={isYouTube ? "iframe" : "video"} height="194" playsInline={true} allowFullScreen={true} frameBorder={"0"} src={videoUrl} controls={true} title={item.Name} />) :
                                                                                                                                                (<CardMedia component="img" height="194" image={item.Url} alt={item.Name} title={item.Name} onClick={(e) => { window.open(item.Url); }} />)}
                                                                                                                                        </Card>
                                                                                                                                    </React.Fragment>
                                                                                                                                </Grid>);
                                                                                                                            })}
                                                                                                                        </React.Fragment>
                                                                                                                    </Grid>
                                                                                                                </Box>) : (null)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Featured projects</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            {FeaturedProjects && FeaturedProjects.length > 0 ? (
                                                                                                                <Box className="boxPortfolioMedia">
                                                                                                                    <Grid container item spacing={0} columns={12}>
                                                                                                                        <React.Fragment>
                                                                                                                            {FeaturedProjects.map((item, indx) => {
                                                                                                                                let thumbnail = item.Media && item.Media.length > 0 ? item.Media[0].LinkUrl : '';
                                                                                                                                if (!thumbnail) {
                                                                                                                                    thumbnail = item.Service ? item.Service.DefaultImage : '';
                                                                                                                                }

                                                                                                                                return (<Grid key={'grdPrjItem_' + item.Id} item xs={4}>
                                                                                                                                    <React.Fragment>
                                                                                                                                        <Card key={'cardPrj_' + item.Id} variant="outlined" className={"portfolioProjectCard"} >
                                                                                                                                            <CardMedia component="img" height="194" image={thumbnail} alt={item.Name} title={item.Name} />
                                                                                                                                            <CardContent>
                                                                                                                                                <Typography gutterBottom variant="subtitle1" component="a" onClick={(e) => { this.viewFeaturedProject(item); }}>
                                                                                                                                                    {item.Name}
                                                                                                                                                </Typography>
                                                                                                                                                <Typography variant="body2" color="text.secondary">
                                                                                                                                                    {item.Price ? " Approx. $" + item.Price : ''}
                                                                                                                                                </Typography>
                                                                                                                                            </CardContent>
                                                                                                                                        </Card>
                                                                                                                                    </React.Fragment>
                                                                                                                                </Grid>);
                                                                                                                            })}
                                                                                                                        </React.Fragment>
                                                                                                                    </Grid>
                                                                                                                </Box>) : (null)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {!showProjectDialog ? (null) : (
                                                                                                    <Dialog key='dlgPortfolioProject' keepMounted TransitionComponent={this.SlideDownTransition} open={showProjectDialog}
                                                                                                        onClose={(e) => { this.resetProjectDialog(); }} aria-labelledby={"dialogPortfolioProjectTitle"}
                                                                                                        fullWidth={true} maxWidth={"sm"} >
                                                                                                        <DialogTitle id={"dialogPortfolioProjectTitle"} className="modalTitle">{ProjectTitle ? ProjectTitle : 'New Featured Project'}
                                                                                                            <IconButton aria-label="close" onClick={(e) => { this.resetProjectDialog(); }} className="btnCloseDialog">
                                                                                                                <CloseIcon />
                                                                                                            </IconButton>
                                                                                                        </DialogTitle>
                                                                                                        <DialogContent dividers={true}>
                                                                                                            <div className="profileContainer">
                                                                                                                <div className="formContainer">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-12">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Project title</label>
                                                                                                                                <Typography variant="subtitle2">{ProjectTitle}</Typography>
                                                                                                                            </div>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Service</label>
                                                                                                                                        <Typography variant="subtitle2">{ProjectServiceName}</Typography>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Year</label>
                                                                                                                                        <Typography variant="subtitle2">{ProjectYear}</Typography>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Approximate total price</label>
                                                                                                                                <Typography variant="subtitle2">{ProjectPrice}</Typography>
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Description </label>
                                                                                                                                <Typography variant="subtitle2">{ProjectDescription}</Typography>
                                                                                                                            </div>
                                                                                                                            {ProjectFiles && ProjectFiles.length > 0 ? (
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-12 col-sm-12">
                                                                                                                                        <div className="form-group">
                                                                                                                                            <hr />
                                                                                                                                            <Box className="boxPortfolioMedia">
                                                                                                                                                <Grid container item spacing={0} columns={12}>
                                                                                                                                                    {ProjectFiles.map((item, indx) => {
                                                                                                                                                        return (<Grid key={'grdPrjItem_' + item.id} item xs={3}>
                                                                                                                                                            <Card key={'cardPrj_' + item.id} variant="outlined" className={"portfolioProjectCard" + (indx > 0 ? ' ml-2' : '')} >
                                                                                                                                                                <CardMedia component="img" height="100" image={item.url} alt={item.name} title={item.name} onClick={(e) => { window.open(item.url); }} />
                                                                                                                                                            </Card>
                                                                                                                                                        </Grid>);
                                                                                                                                                    })}
                                                                                                                                                </Grid>
                                                                                                                                            </Box>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>) : (null)}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </DialogContent>
                                                                                                        <DialogActions>
                                                                                                            <div className="formButtonContainer text-right">
                                                                                                                <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.resetProjectDialog(); }}>Cancel</button>
                                                                                                            </div>
                                                                                                        </DialogActions>
                                                                                                    </Dialog>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        {!showMessageDialog ? (null) : (
                                                                                            <React.Fragment>
                                                                                                <Dialog fullWidth={true} open={showMessageDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} >
                                                                                                    <DialogTitle id={"detailsDialog"} className="modalTitle">Compose message
                                                                                                        <IconButton aria-label="close" onClick={(e) => { this.resetMessage(); }} className="btnCloseDialog" disabled={showDialogProgress} >
                                                                                                            <CloseIcon />
                                                                                                        </IconButton>
                                                                                                    </DialogTitle>
                                                                                                    <DialogContent dividers={true}>
                                                                                                        <div className="profileContainer">
                                                                                                            <div className="formContainer">
                                                                                                                <div className="col-12 col-sm-12 p-0">
                                                                                                                    <div className="minHeightDialogContainer">
                                                                                                                        {showDialogProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                                                            <div className="form-group mt-3">
                                                                                                                                <label>Message</label>
                                                                                                                                <textarea id="message" name="message" key="message" rows={6} className="form-control" placeholder="" defaultValue={Body} onBlur={(e) => { this.setState({ Body: e.target.value }); }} />
                                                                                                                                <Validator id="valMessage" type='required' cssClass="error" errorMessage="Provide message." controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                                                                <Validator id="valMessageLength" type='max_length' cssClass="error" errorMessage="Message cannot exceed 1000 characters." maxLength={1000} controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                                                            </div>
                                                                                                                        </React.Fragment>)}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </DialogContent>
                                                                                                    <DialogActions>
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group text-right">
                                                                                                                <div className="formButtonContainer mt-3">
                                                                                                                    <button type="submit" className="btn btn-cancel" disabled={showDialogProgress} onClick={(e) => { this.resetMessage(); }}>Cancel</button>
                                                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin ml-2" disabled={showDialogProgress} onClick={(e) => { this.handleSendMessage(); }}>Send</button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </DialogActions>
                                                                                                </Dialog>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>)}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>) : (null)}
                                                    {/* Received Quotes */}
                                                    {TabIndex === 4 ? (<React.Fragment>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="breadCrumb mb-1">
                                                                    {this.props.id ? (<><a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2 mr-2">&gt;</span></>) : (null)}<a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, ItemId: 0, ReceivedQuotes: [], SelectedProject: null }); }}>{this.props.id ? Profile.User.DisplayName + "'s Projects" : "My Projects"}</a><span className="ml-2">&gt;</span><span className="ml-2">Received Quotes</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <h5>Received Quotes{SelectedProject ? ' - ' + SelectedProject.Name : ''}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="profileContainer">
                                                            <div className="formContainer">
                                                                {showProgress ? (<ProgressBar />) : (
                                                                    <React.Fragment>
                                                                        {!ReceivedQuotes || ReceivedQuotes.length <= 0 ? (<div className="tabPanel"><Typography variant="subtitle2" color="text.secondary" className="mt-3">There are no quotes received for this project.</Typography></div>) : (<React.Fragment>
                                                                            {ReceivedQuotes.map((item, ind) => {
                                                                                return (<div className={"tabPanel " + (ind > 0 ? "mt-2" : "")}>
                                                                                    <div className="form-group">
                                                                                        <div className="row">
                                                                                            <div className="col-2 col-sm-1">
                                                                                                <div className="form-group mt-3">
                                                                                                    <Avatar alt={item.SenderProfile.BusinessName} src={item.SenderProfile.ProfileImage ? item.SenderProfile.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 60, height: 60 }}></Avatar>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-8 col-sm-9">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{item.SenderProfile.BusinessName}</Typography>
                                                                                                        </div>
                                                                                                        {/* <div className="col-4 col-sm-4 text-right">
                                                                                                            <Typography variant="subtitle2">
                                                                                                                <IconButton onClick={(e) => { this.handleViewQuoteDetails(item); }} title="View quote details"><PreviewOutlinedIcon /></IconButton><IconButton onClick={(e) => { this.handleViewProfile({ Profile: item.SenderProfile, Rating: item.SenderProfileRating[0] }, 8); }} title="View profile" className="ml-2"><BadgeOutlinedIcon /></IconButton>
                                                                                                            </Typography>
                                                                                                        </div> */}
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Reviews id="reviews" profileId={item.SenderProfile.Id} reviewerUser={this.User} allowAskForReviews={false} allowRating={true}
                                                                                                                defaultRating={item.SenderProfileRating && item.SenderProfileRating.length > 0 && item.SenderProfileRating[0].Rating ? item.SenderProfileRating[0].Rating : 0} defaultReviews={item.SenderProfileRating && item.SenderProfileRating.length > 0 && item.SenderProfileRating[0].TotalCount ? item.SenderProfileRating[0].TotalCount : '0'}
                                                                                                                ratingClassName='commentRatingStar' showRating={true} showReviewCount={true}></Reviews>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant="subtitle2" color="text.secondary">{item.SenderProfile.Description}</Typography>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-2 col-sm-2">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div><Typography variant="caption" className="mt-3"><PreviewOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleViewQuoteDetails(item); }} title="View quote details" className="ml-2">View Quote</a></Typography></div>
                                                                                                    <div><Typography variant="caption" className="mt-3"><BadgeOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleViewProfile({ Profile: item.SenderProfile, Rating: item.SenderProfileRating[0] }, 8); }} title="View profile" className="ml-2">View Pro's Profile</a></Typography></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>);
                                                                            })}
                                                                        </React.Fragment>)}
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>) : (null)}
                                                    {/* Quote Details */}
                                                    {TabIndex === 5 ? (<React.Fragment>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="breadCrumb mb-1">
                                                                    {this.props.id ? (<><a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2 mr-2">&gt;</span></>) : (null)}<a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, ItemId: 0, ReceivedQuotes: [], QuoteDetails: null, QuoteFAQs: [], SelectedProject: null }); }}>{this.props.id ? Profile.User.DisplayName + "'s Projects" : "My Projects"}</a><span className="ml-2">&gt;</span><a href="javascript:void(0);" className="ml-2" onClick={(e) => { this.handleQuotesReceivedClick(SelectedProject); }}>Received Quotes</a><span className="ml-2">&gt;</span><span className="ml-2">Quote Details</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className={SelectedProject && SelectedProject.Status.toLowerCase() === 'closed' ? "col-12 col-sm-12" : "col-8 col-sm-8"}>
                                                                <h5>Quote Details{SelectedProject ? ' - ' + SelectedProject.Name : ''}</h5>
                                                            </div>
                                                            {SelectedProject && SelectedProject.Status.toLowerCase() !== "closed" ? (
                                                                <div className="col-4 col-sm-4 text-right">
                                                                    {RelatedPro ? (<button type="submit" className="btn btn-primary cutmBtnLogin mr-2" onClick={(e) => { this.handleMessage(); }}>Message {RelatedPro.Profile.BusinessName}</button>) : (null)}
                                                                    {!this.props.id ? (<button type="submit" disabled={showProgress} className="btn btn-success cutmBtnLogin" onClick={(e) => { this.handleProHireClick(); }}>Hire Pro</button>) : (null)}
                                                                </div>) : (null)}
                                                        </div>
                                                        <div className="profileContainer">
                                                            <div className="formContainer">
                                                                <div className="tabPanel">
                                                                    {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                        <div className="form-group">
                                                                            <label>Quote price</label>
                                                                            <Typography variant="subtitle2" color="text.secondary">{QuoteDetails.Price}</Typography>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Quote type</label>
                                                                            <Typography variant="subtitle2" color="text.secondary">{QuoteDetails.Type.toString() === "1" ? "Fixed Price" : "Hourly Rate"}</Typography>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Message</label>
                                                                            <Typography variant="subtitle2" color="text.secondary">{QuoteDetails.Message}</Typography>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            {QuoteDetails.File && QuoteDetails.File.Path ? (
                                                                                <Typography variant="caption" className="pt-2"><Attachment />&nbsp;<a href={QuoteDetails.File.Path}>{QuoteDetails.File.Name}</a></Typography>) : (null)}
                                                                        </div>
                                                                        {/* <div className="mt-3"> */}
                                                                        {/* <h5>Frequently Asked Questions</h5> */}
                                                                        {QuoteFAQs && QuoteFAQs.length > 0 ? (<React.Fragment>
                                                                            {QuoteFAQs.map((item, indx) => {
                                                                                return (<React.Fragment>
                                                                                    <div className="form-group">
                                                                                        <label>{item.Name}</label>
                                                                                        <Typography variant="subtitle2" color="text.secondary">{item.Description}</Typography>
                                                                                    </div>
                                                                                </React.Fragment>);
                                                                            })}
                                                                        </React.Fragment>) : (null)}
                                                                        {/* (<Typography variant="subtitle2" color="text.secondary">There are no frequently asked questions available with this quote.</Typography>)} */}
                                                                        {/* </div> */}
                                                                    </React.Fragment>)}
                                                                </div>
                                                                {!showMessageDialog ? (null) : (
                                                                    <React.Fragment>
                                                                        <Dialog fullWidth={true} open={showMessageDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} >
                                                                            <DialogTitle id={"detailsDialog"} className="modalTitle">Compose message
                                                                                <IconButton aria-label="close" onClick={(e) => { this.resetMessage(); }} className="btnCloseDialog" disabled={showDialogProgress} >
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </DialogTitle>
                                                                            <DialogContent dividers={true}>
                                                                                <div className="profileContainer">
                                                                                    <div className="formContainer">
                                                                                        <div className="col-12 col-sm-12 p-0">
                                                                                            <div className="minHeightDialogContainer">
                                                                                                {showDialogProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                                    <div className="form-group mt-3">
                                                                                                        <label>Message</label>
                                                                                                        <textarea id="message" name="message" key="message" rows={6} className="form-control" placeholder="" defaultValue={Body} onBlur={(e) => { this.setState({ Body: e.target.value }); }} />
                                                                                                        <Validator id="valMessage" type='required' cssClass="error" errorMessage="Provide message." controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                                        <Validator id="valMessageLength" type='max_length' cssClass="error" errorMessage="Message cannot exceed 1000 characters." maxLength={1000} controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                                    </div>
                                                                                                </React.Fragment>)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="form-group text-right">
                                                                                        <div className="formButtonContainer mt-3">
                                                                                            <button type="submit" className="btn btn-cancel" disabled={showDialogProgress} onClick={(e) => { this.resetMessage(); }}>Cancel</button>
                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin ml-2" disabled={showDialogProgress} onClick={(e) => { this.handleSendMessage(); }}>Send</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </DialogActions>
                                                                        </Dialog>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>) : (null)}
                                                    {/* Request Quote */}
                                                    {TabIndex === 6 ? (<React.Fragment>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="breadCrumb mb-1">
                                                                    {this.props.id ? (<><a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2 mr-2">&gt;</span></>) : (null)}<a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, RelatedPros: [], RelatedProjects: [], ProjectId: '', CategoryId: '', ProjectCategoryName: '', ProjectCityName: '', ProjectStateName: '', ProjectPin: '', SelectedProject: null, RelatedPro: null, ProjectName: '', HasRequestedQuote: false }); }}>{this.props.id ? Profile.User.DisplayName + "'s Projects" : "My Projects"}</a><span className="ml-2">&gt;</span><a href="javascript:void(0);" className="ml-2" onClick={(e) => { this.handleQuoteRequest(SelectedProject); }}>Related Pros</a><span className="ml-2">&gt;</span><span className="ml-2">Request Quote</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <h5>Request Quote</h5>
                                                            </div>
                                                        </div>
                                                        <div className="tabPanel">
                                                            <div className="form-group">
                                                                {showProgress ? (<ProgressBar />) : (
                                                                    <React.Fragment>
                                                                        <div className="form-group">
                                                                            <label>Project name</label>
                                                                            {!RelatedProjects || RelatedProjects.length <= 0 ? (<Typography variant="body2" color="text.secondary" className="commentBody">There are no related projects for this category. <a href='/projects/new'>Click here</a> to create a new project.</Typography>) : (
                                                                                <select id="project" name="project" className="form-control" defaultValue={ProjectId} onChange={(e) => { this.setState({ ProjectId: e.target.value }, () => { this.handleProjectChange(); }); }}>
                                                                                    <option value="">Select</option>
                                                                                    {RelatedProjects && RelatedProjects.map((item, indx) => {
                                                                                        return (<option value={item.Id}>{item.Name}</option>);
                                                                                    })}
                                                                                </select>)}
                                                                        </div>
                                                                        <div className="form-group mt-3">
                                                                            <hr></hr>
                                                                        </div>
                                                                        <div className="form-group mt-3">
                                                                            <div className="formButtonContainer text-right">
                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin" disabled={!ProjectId || HasRequestedQuote ? true : false} onClick={(e) => { this.handleQuoteSubmission(); }}>Request for Quote</button>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>) : (null)}
                                                    {/* Hired Pro Profile */}
                                                    {TabIndex === 7 ? (<React.Fragment>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="breadCrumb mb-1">
                                                                    {this.props.id ? (<><a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2 mr-2">&gt;</span></>) : (null)}<a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, HiredPro: null, PortfolioMedia: [], FeaturedProjects: [], SelectedProject: null }); }}>{this.props.id ? Profile.User.DisplayName + "'s Projects" : "My Projects"}</a><span className="ml-2">&gt;</span><span className="ml-2">Hired Pro Profile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <h5>Hired Pro Profile{SelectedProject ? ' - ' + SelectedProject.Name : ''}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12">
                                                            <div className="form-group">
                                                                {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                    {!HiredPro ? (null) : (
                                                                        <div className="profileContainer">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-12 p-0">
                                                                                    <div className="formContainer">
                                                                                        <div className="headingTxt">Profile</div>
                                                                                        <div className="tabPanel">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-3 col-sm-2">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <Avatar alt={HiredPro.Profile.BusinessName} src={HiredPro.Profile.ProfileImage ? HiredPro.Profile.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 80, height: 80 }}></Avatar>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-9 col-sm-10">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{HiredPro.Profile.BusinessName}</Typography>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <Reviews id="reviews" profileId={HiredPro.Profile.Id} reviewerUser={this.User} allowAskForReviews={false} allowRating={true}
                                                                                                                        defaultRating={HiredPro.Rating && HiredPro.Rating.length > 0 && HiredPro.Rating[0].Rating ? HiredPro.Rating[0].Rating : 0} defaultReviews={HiredPro.Rating && HiredPro.Rating.length > 0 && HiredPro.Rating[0].TotalCount ? HiredPro.Rating[0].TotalCount : '0'}
                                                                                                                        ratingClassName='commentRatingStar' showRating={true} showReviewCount={true}></Reviews>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mt-3">
                                                                                                    <hr></hr>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-sm-3">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <PhoneOutlinedIcon fontSize="small" ></PhoneOutlinedIcon> <label>Phone</label>
                                                                                                            <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{HiredPro.Profile.Phone}</Typography></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-3">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <EmailOutlinedIcon fontSize="small" ></EmailOutlinedIcon> <label>Email</label>
                                                                                                            <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{HiredPro.Profile.User.Email}</Typography></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-3">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <EmojiEventsOutlinedIcon fontSize="small" ></EmojiEventsOutlinedIcon> <label>Year founded</label>
                                                                                                            <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{HiredPro.Profile.YearFounded}</Typography></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-3">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <WebOutlinedIcon fontSize="small" ></WebOutlinedIcon> <label>Website</label>
                                                                                                            <div className="pl-4"><Typography variant="subtitle2"><a href={HiredPro.Profile.WebSiteUrl} target='_blank'>{HiredPro.Profile.WebSiteUrl}</a></Typography></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mt-3">
                                                                                                    <hr></hr>
                                                                                                </div>
                                                                                                <div className="form-group mt-3">
                                                                                                    <LocationOnOutlinedIcon fontSize="small" ></LocationOnOutlinedIcon> <label>Address</label>
                                                                                                    <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{HiredPro.Profile.Address}{HiredPro.Profile.CityName ? ", " + HiredPro.Profile.CityName : ''}{HiredPro.Profile.StateName ? ", " + HiredPro.Profile.StateName : ''}{HiredPro.Profile.Pin ? " " + HiredPro.Profile.Pin : ''}</Typography></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Social media</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            {HiredPro.SocialMedia && HiredPro.SocialMedia.length > 0 ? (<React.Fragment>
                                                                                                                {HiredPro.SocialMedia.map((item) => {
                                                                                                                    return (<React.Fragment>
                                                                                                                        {item.LinkUrl ? (<div><img src={'/images/' + item.LinkType + 'Icon.png'} alt={item.LinkType} /> <a href={item.LinkUrl} target='_blank'><Typography variant='caption'>{item.LinkUrl}</Typography></a></div>) : (null)}
                                                                                                                    </React.Fragment>);
                                                                                                                })}
                                                                                                            </React.Fragment>) : (<Typography variant="subtitle2" color="text.secondary" className="mt-3">There is no information available about Pro's social media presence.</Typography>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Payment methods accepted</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant="subtitle2" className="commentBody">{HiredPro.Payments && HiredPro.Payments.length > 0 ? (<React.Fragment>
                                                                                                                {HiredPro.Payments.map((p, indx) => {
                                                                                                                    if (indx < HiredPro.Payments.length - 1)
                                                                                                                        return (p.Mode) + ', ';
                                                                                                                    else
                                                                                                                        return (p.Mode);
                                                                                                                })}
                                                                                                            </React.Fragment>) : (<Typography variant="subtitle2" color="text.secondary" className="mt-3">There is no information available about Pro's accepted payment methods.</Typography>)}</Typography>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Your introduction</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant="subtitle2" className="commentBody">{HiredPro.Profile.Description}</Typography>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Accreditations</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12 mt-2">
                                                                                                <div className="form-group">
                                                                                                    <Box sx={{ flexGrow: 1 }}>
                                                                                                        <Grid container item spacing={3} columns={12}>
                                                                                                            {HiredPro.Accreditations && HiredPro.Accreditations.length > 0 ? (
                                                                                                                <React.Fragment>
                                                                                                                    {HiredPro.Accreditations.map((item) => {
                                                                                                                        let subHeader = (<React.Fragment><div>{item.Institution}</div><div>{this.getFormattedDate(new Date(this.getDateNumber(item.StartDate)), Constants.IDateTimeFormat.MMddyyyy, '/') + " - " + this.getFormattedDate(new Date(this.getDateNumber(item.EndDate)), Constants.IDateTimeFormat.MMddyyyy, '/')}</div></React.Fragment>);
                                                                                                                        return (<Grid key={'grdItem_' + item.Id} item xs={12}>
                                                                                                                            <React.Fragment>
                                                                                                                                <Card key={'card_' + item.Id} variant="outlined" className="licenseCard">
                                                                                                                                    <CardHeader avatar={<Avatar sx={{ bgcolor: red[500] }}>{item.Name ? item.Name.charAt(0).toUpperCase() : ''}</Avatar>}
                                                                                                                                        title={item.Name} subheader={subHeader} />
                                                                                                                                    <CardContent className="licenseCardContentMaxHeight">
                                                                                                                                        <Typography variant="subtitle2" color="text.secondary" className="commentBody">
                                                                                                                                            {item.Description}
                                                                                                                                        </Typography>
                                                                                                                                    </CardContent>
                                                                                                                                </Card>
                                                                                                                            </React.Fragment>
                                                                                                                        </Grid>);
                                                                                                                    })}
                                                                                                                </React.Fragment>
                                                                                                            ) : (<Typography variant="subtitle2" color="text.secondary" className="mt-3">There is no information available for the Pro's accreditations.</Typography>)}
                                                                                                        </Grid>
                                                                                                    </Box>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Photos & videos</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12 mt-2">
                                                                                                <div className="form-group">
                                                                                                    <Box className="boxPortfolioMedia">
                                                                                                        <Grid container item spacing={0} columns={12}>
                                                                                                            {PortfolioMedia && PortfolioMedia.length > 0 ? (
                                                                                                                <React.Fragment>
                                                                                                                    {PortfolioMedia.map((item) => {
                                                                                                                        let thumbnailUrl = item.Url;
                                                                                                                        let isYouTube = false;
                                                                                                                        let videoUrl = item.Url;

                                                                                                                        if (thumbnailUrl.toLowerCase().indexOf('youtube.com') >= 0) {
                                                                                                                            let videoID = item.Url.substring(item.Url.indexOf('v=') + 2);

                                                                                                                            isYouTube = true;
                                                                                                                            thumbnailUrl = "https://img.youtube.com/vi/" + videoID + "/0.jpg";
                                                                                                                            videoUrl = "https://www.youtube.com/embed/" + videoID;
                                                                                                                        }
                                                                                                                        else if (thumbnailUrl.toLowerCase().indexOf('vimeo.com') >= 0) {
                                                                                                                            let videoID = videoUrl.substring(videoUrl.lastIndexOf('/') + 1);

                                                                                                                            isYouTube = true;
                                                                                                                            videoUrl = "https://player.vimeo.com/video/" + videoID + "?fullscreen=1";
                                                                                                                        }

                                                                                                                        return (<Grid key={'grdItem_' + item.Id} item xs={4}>
                                                                                                                            <React.Fragment>
                                                                                                                                <Card key={'card_' + item.Id} variant="outlined" className="portfolioMediaCard">
                                                                                                                                    {item.Type === 'V' ? (<CardMedia component={isYouTube ? "iframe" : "video"} height="194" playsInline={true} allowFullScreen={true} frameBorder={"0"} src={videoUrl} controls={true} title={item.Name} />) :
                                                                                                                                        (<CardMedia component="img" height="194" image={item.Url} alt={item.Name} title={item.Name} onClick={(e) => { window.open(item.Url); }} />)}
                                                                                                                                </Card>
                                                                                                                            </React.Fragment>
                                                                                                                        </Grid>);
                                                                                                                        // return (<Grid key={'grdItem_' + item.Id} item xs={4}>
                                                                                                                        //     <React.Fragment>
                                                                                                                        //         <Card key={'card_' + item.Id} variant="outlined" className="portfolioMediaCard">
                                                                                                                        //             {item.Type === 'V' ? (<CardMedia component="video" height="194" image={item.Url} controls={true} title={item.Name} onClick={(e) => { window.open(item.Url); }} />) :
                                                                                                                        //                 (<CardMedia component="img" height="194" image={item.Url} alt={item.Name} title={item.Name} onClick={(e) => { window.open(item.Url); }} />)}
                                                                                                                        //         </Card>
                                                                                                                        //     </React.Fragment>
                                                                                                                        // </Grid>);
                                                                                                                    })}
                                                                                                                </React.Fragment>
                                                                                                            ) : (null)}
                                                                                                        </Grid>
                                                                                                    </Box>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tabPanel mt-1">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 align-self-center">
                                                                                                    <div className="headingTxt">Featured projects</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12 mt-2">
                                                                                                <div className="form-group">
                                                                                                    <Box className="boxPortfolioMedia">
                                                                                                        <Grid container item spacing={0} columns={12}>
                                                                                                            {FeaturedProjects && FeaturedProjects.length > 0 ? (
                                                                                                                <React.Fragment>
                                                                                                                    {FeaturedProjects.map((item, indx) => {
                                                                                                                        let thumbnail = item.Media && item.Media.length > 0 ? item.Media[0].LinkUrl : '';
                                                                                                                        if (!thumbnail) {
                                                                                                                            thumbnail = item.Service ? item.Service.DefaultImage : '';
                                                                                                                        }

                                                                                                                        return (<Grid key={'grdPrjItem_' + item.Id} item xs={4}>
                                                                                                                            <React.Fragment>
                                                                                                                                <Card key={'cardPrj_' + item.Id} variant="outlined" className={"portfolioProjectCard"} >
                                                                                                                                    <CardMedia component="img" height="194" image={thumbnail} alt={item.Name} title={item.Name} />
                                                                                                                                    <CardContent>
                                                                                                                                        <Typography gutterBottom variant="subtitle1" component="a" onClick={(e) => { this.viewFeaturedProject(item); }}>
                                                                                                                                            {item.Name}
                                                                                                                                        </Typography>
                                                                                                                                        <Typography variant="body2" color="text.secondary">
                                                                                                                                            {item.Price ? " Approx. $" + item.Price : ''}
                                                                                                                                        </Typography>
                                                                                                                                    </CardContent>
                                                                                                                                </Card>
                                                                                                                            </React.Fragment>
                                                                                                                        </Grid>);
                                                                                                                    })}
                                                                                                                </React.Fragment>
                                                                                                            ) : (null)}
                                                                                                        </Grid>
                                                                                                    </Box>
                                                                                                </div>
                                                                                                {!showProjectDialog ? (null) : (
                                                                                                    <Dialog key='dlgPortfolioProject' keepMounted TransitionComponent={this.SlideDownTransition} open={showProjectDialog}
                                                                                                        onClose={(e) => { this.resetProjectDialog(); }} aria-labelledby={"dialogPortfolioProjectTitle"}
                                                                                                        fullWidth={true} maxWidth={"sm"} >
                                                                                                        <DialogTitle id={"dialogPortfolioProjectTitle"} className="modalTitle">{ProjectTitle ? ProjectTitle : 'New Featured Project'}
                                                                                                            <IconButton aria-label="close" onClick={(e) => { this.resetProjectDialog(); }} className="btnCloseDialog">
                                                                                                                <CloseIcon />
                                                                                                            </IconButton>
                                                                                                        </DialogTitle>
                                                                                                        <DialogContent dividers={true}>
                                                                                                            <div className="profileContainer">
                                                                                                                <div className="formContainer">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-12">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Project title</label>
                                                                                                                                <Typography variant="subtitle2">{ProjectTitle}</Typography>
                                                                                                                            </div>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Service</label>
                                                                                                                                        <Typography variant="subtitle2">{ProjectServiceName}</Typography>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Year</label>
                                                                                                                                        <Typography variant="subtitle2">{ProjectYear}</Typography>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Approximate total price</label>
                                                                                                                                <Typography variant="subtitle2">{ProjectPrice}</Typography>
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Description </label>
                                                                                                                                <Typography variant="subtitle2">{ProjectDescription}</Typography>
                                                                                                                            </div>
                                                                                                                            {ProjectFiles && ProjectFiles.length > 0 ? (
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-12 col-sm-12">
                                                                                                                                        <div className="form-group">
                                                                                                                                            <hr />
                                                                                                                                            <Box className="boxPortfolioMedia">
                                                                                                                                                <Grid container item spacing={0} columns={12}>
                                                                                                                                                    {ProjectFiles.map((item, indx) => {
                                                                                                                                                        return (<Grid key={'grdPrjItem_' + item.id} item xs={3}>
                                                                                                                                                            <Card key={'cardPrj_' + item.id} variant="outlined" className={"portfolioProjectCard" + (indx > 0 ? ' ml-2' : '')} >
                                                                                                                                                                <CardMedia component="img" height="100" image={item.url} alt={item.name} title={item.name} onClick={(e) => { window.open(item.url); }} />
                                                                                                                                                            </Card>
                                                                                                                                                        </Grid>);
                                                                                                                                                    })}
                                                                                                                                                </Grid>
                                                                                                                                            </Box>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>) : (null)}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </DialogContent>
                                                                                                        <DialogActions>
                                                                                                            <div className="formButtonContainer text-right">
                                                                                                                <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.resetProjectDialog(); }}>Cancel</button>
                                                                                                            </div>
                                                                                                        </DialogActions>
                                                                                                    </Dialog>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>)}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>) : (null)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </React.Fragment >);
                }
            }
        }

        return (<React.Fragment>
            {template}
            <div id="map"></div>
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}