
namespace Constants {
    export enum UserRoles {
        Customer = 'Customer',
        Pro = 'Pro',
        Admin = 'Admin'
    }

    export enum IDateTimeFormat {
        ddMMyyyy = 'dd/MM/yyyy',
        MMddyyyy = 'MM/dd/yyyy',
        yyyyMMdd = 'yyyy/MM/dd',
        ddMMMyyyy = 'DD-MMM-YYYY',
        MMMddyyyy = 'MMM/dd/yyyy',
        ddMMyy = 'dd/MM/yy',
        MMddyy = 'MM/dd/yy',
        yyMMdd = 'yy/MM/dd',
        ddMM = 'dd/MM',
        hhmm = 'hh:mm',
        MMyyyy = 'MMyyyy',
        MMMyyyy = 'MMMyyyy',
        ddMMM = 'ddMMM'
    }

    export enum SocialMediaLinkType {
        Twitter = 'Twitter',
        Facebook = 'Facebook',
        Instagram = 'Instagram',
        LinkedIn = 'LinkedIn',
        YouTube = 'YouTube'
    }

    export enum PaymentType {
        MasterCard = 'MasterCard',
        Visa = 'Visa',
        PayPal = 'PayPal'
    }

    export enum Subscriptions {
        Basic = 'B',
        Premium = 'P'
    }

    export const Email = {
        Registration: { Subject: "ProsBE | Welcome to ProsBE", Body: '<table width="100%"><tr><td>Welcome [username]!<br /><br />We’re excited to have you at ProsBE! Please use below link to visit ProsBE.<br /><br /><a href="https://prosbe.com">ProsBE</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        DeactivateAccount: { Subject: "ProsBE | Accoount deactivation", Body: '<table width="100%"><tr><td>Your ProsBE account has been deactivated.<br /><br />If you’re having second thoughts, simply click on below link to reactivate your account.<br /><br /><a href="https://prosbe.com/reactivate?id=[em]">Reactivate account</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        ReactivateAccount: { Subject: "ProsBE | Accoount reactivation", Body: '<table width="100%"><tr><td>We have received you account reactivation request.<br /><br />Simply click on below link to reactivate your account.<br /><br /><a href="https://prosbe.com/reactivate?id=[em]">Reactivate account</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        ChangePassword: { Subject: "ProsBE | Account password changed", Body: '<table width="100%"><tr><td>Hi [username]!<br /><br />Your password has been changed successfully. Please use below link to login to ProsBE.<br /><br /><a href="https://prosbe.com">ProsBE</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        ResetPassword: { Subject: "ProsBE | ProsBE account password reset", Body: '<table width="100%"><tr><td>Hi [username]!<br /><br />Your password has been reset successfully.<br /><br />Below is the temporary password which must be changed after login.<br /><br />[password]<br /><br />Please use below link to login to ProsBE.<br /><br /><a href="https://prosbe.com">ProsBE</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        AskForReviews: { Subject: "ProsBE | Do you have a moment to review {0}?", Body: "<table width='100%'><tr><td>Hi [username],<br /><br />Thanks for choosing <b>[businessname]</b>. I’d like to personally invite you to share your experience.<br /><br /><a href='https://prosbe.com/review?rid=[rid]&em=[em]'>Leave Review</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>" },
        ContactUser: { Subject: "ProsBE | [proname] wants to connect", Body: '<table width="100%"><tr><td>Dear [username],<br /><br />[proname] wants to connect with you regarding your project [projectname].<br /><br />Click <a href="https://prosbe.com/inbox">here</a> to visit your inbox on ProsBE and start a conversation.<br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        NewMessage: { Subject: "ProsBE | You have a new message", Body: '<table width="100%"><tr><td>[username] sent you a message:<br /><br />[message]<br /><br />Click <a href="https://prosbe.com/inbox">here</a> to view your message on ProsBE.<br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        RequestQuote: { Subject: "ProsBE | You have a quote request", Body: '<table width="100%"><tr><td>[username] requested a quote for <b>[servicename]</b> service.<br /><br />Location: [cityname], [statename] [zip]<br /><br /><a href="https://prosbe.com/lead?id=[lid]">View Lead details</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        SubmitQuote: { Subject: "ProsBE | You have a quote for [projectname]", Body: '<table width="100%"><tr><td>[businessname] submitted a quote for <b>[projectname]</b>.<br /><br /><a href="https://prosbe.com/quote?id=[qid]">View quote details</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        NewProject: { Subject: "ProsBE | [username] needs [servicename] in [cityname], [statename]", Body: '<table width="100%"><tr><td>[username] is looking for <b>[servicename]</b> service.<br /><br />Location: [cityname], [statename] [zip]<br /><br /><a href="https://prosbe.com/opportunity?id=[oid]">View Opportunity details</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        HirePro: { Subject: "ProsBE | You are hired!", Body: '<table width="100%"><tr><td>Congratulations!<br /><br />[username] wants to hire you for [servicename] service.<br /><br />Location: [cityname], [statename] [zip]<br /><br /><a href="https://prosbe.com/lead?id=[lid]">View Lead details</a> or <a href="https://prosbe.com/inbox">Contact [username]</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        UpgradePlan: { Subject: "ProsBE | You subscription is upgraded to Premium", Body: '<table width="100%"><tr><td>Congratulations!<br /><br />You have successfully upgraded to Premium subscription for ProsBE.<br /><br /><u><b>Payment Details:</b></u><br /><br />Card Number: [cardnumber]<br /><br />Amount: [amount]<br /><br />Billing mode: [mode]<br /><br />Next Payment scheduled on: [nextpayment]<br /><br /><a href="https://prosbe.com">Visit ProsBE</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        DowngradePlan: { Subject: "ProsBE | Your subscription is downgraded to Basic", Body: '<table width="100%"><tr><td>Your subscription to ProsBE has been downgraded to Basic.<br /><br /><a href="https://prosbe.com">Visit ProsBE</a><br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' },
        CancelProject: { Subject: "ProsBE | [username] has cancelled the requirement of [servicename] in [cityname], [statename]", Body: '<table width="100%"><tr><td>[username] has <b>cancelled</b> their project with requirements for <b>[servicename]</b> service at [cityname], [statename] [zip] location. Your quotes submitted for this project stand cancelled.<br /><br /><br /><br /><i>Please do not reply to this email. Emails sent to this address will not be answered.</i></td></tr></table>' }
    }

    export enum NotificationTypes {
        NewMessage = 'NM',
        NewDirectLeads = 'NDL',
        NewOpportunity = 'NO',
        NewQuoteRequest = 'NQR',
        NewReviews = 'NR'
    }

    export enum Google {
        ClientID = '979392577131-pgr82qas58435c6gmbr8ff0ahbvshg1s.apps.googleusercontent.com',
        MapAPIKey = 'AIzaSyAC5tEmcpaknWg_A3nx7BbEeQeBQAP5Xvg',
        PlacesScriptPath = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAC5tEmcpaknWg_A3nx7BbEeQeBQAP5Xvg&mapDiv=map&libraries=places"
    }

    export enum ZipCodeAPI {
        AppID = 'AvpMfv3plO3t2mIBYXsixnpubpD22cMlxVBItDA6n4Ts78KCIB7CtcJEoBB84nNV',
        JSKey = 'js-EzS9MX7lisBeQdbt83yuUJMet8TLIhHlQMdlnGL0RyBk6BGcvkIsuRH7czfqfu5q'
    }

    export enum Stripe {
        Account = "acct_1IHbvgLs1Olq9OVl",
        AccountName = "Csdigitalgroup",

        /* Test Environment */

        // PublishKey = 'pk_test_51IHbvgLs1Olq9OVl6ewHgPziHO55ExgOJcCTNSk5glAZk8CR14SAH42HeyKSovnVk0DRq4ZLqPO5kXWc4FPyweg6002hwx34tv',
        // SecretKey = 'sk_test_51IHbvgLs1Olq9OVlvXnAv3wAbbczDHlQLLBKeWOokGEpss89p7C1GaTa4vyASjoUPWSiYCzqKz9LVe45Emo7ZIjW005qfmJ2lO',
        // ProductID = 'prod_Mb8VaEPNDoKh4q',
        // PriceID = 'price_1LsSMALs1Olq9OVldYAl9FFq', // to be enabled for $30 price later TEST
        // //PriceID = 'price_1OYsJNLs1Olq9OVlMPpMm36J', // $0 price implemented at present TEST
        // PaymentLink = "https://buy.stripe.com/test_eVabIL3gdcjFgj67ss"

        /* Test Environment Ends */

        /* PROD Environment */

        PublishKey = 'pk_live_51IHbvgLs1Olq9OVlgj0V7lYKdFpZ7cGil24OHguBA90hU3YQgB8iJWZgZS8GEjao8vxQZeEP7GOSADk3Qhqx8Jpd00tL1jlJGq',
        SecretKey = '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',
        ProductID = 'prod_PV2JVyeDtdOmd8',
        //PriceID = 'price_1Og2F1Ls1Olq9OVl7kPEvo7U', // $0 price
        //PriceID = 'price_1Og2FgLs1Olq9OVlKBgWieHz', // $30 price        
        PriceID = 'price_1PoeSwLs1Olq9OVluqHlo7HZ', // $14.99 price
        PaymentLink = 'https://buy.stripe.com/dR6aHo0KscoW7cI000'

        /* PROD Environment Ends */
    }

    export const refreshGoogleToken = (googleUser) => {
        let refreshTime = (googleUser.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
        const refreshToken = async () => {
            const newAuthRes = await googleUser.reloadAuthResponse();
            refreshTime = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;

            setTimeout(refreshToken, refreshTime);
        };

        setTimeout(refreshToken, refreshTime);
    };
}

export default Constants;