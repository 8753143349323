import React from "react";
import { Alert, Snackbar } from "@mui/material";
import Base from "src/common/Base";
import Validator from "src/common/Validator";
import { ProgressBar } from "src/common/Components";
import Constants from "src/common/Constants";
import { gapi } from 'gapi-script';

const initClient = () => { gapi.client.init({ clientId: Constants.Google.ClientID, scope: 'profile email' }); }

export default class DeactivateAccount extends Base {

    private formValidators: Validator[];
    private validator: Validator;

    constructor(props) {
        super(props);

        this.state = { loading: true, Email: this.props.email, IsChecked: false, ShowMessage: false, Message: '', MessageType: 'success' };
        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();

        gapi.load('client:auth2', initClient);
    }

    public componentDidMount() {
        this.setState({ loading: false });
    }

    private sendEmail(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.DeactivateAccount;
            let subject = emailTemplate.Subject;
            let body = emailTemplate.Body;

            body = body.replace('[em]', this.User.Id);

            if (this.User.Email) {
                this.db.sendEmail(this.User.Email, subject, body).then((sent) => {
                    if (sent) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(false);
            }
        });
    }

    private handleDeactivate() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ loading: true });
            this.db.deactivateUser(this.props.pid).then((deactivated) => {
                if (deactivated) {
                    this.sendEmail().then((sent) => {
                        if (sent) {
                            this.successMessage('Your account has been deactivated.');
                        }
                        else {
                            this.errorMessage('Your account has been deactivated but email could not be sent.');
                        }

                        this.setState({ loading: false });
                        this.handleSignOut();
                    });
                }
                else {
                    this.errorMessage('Your account could not be deactivated. Please try again later.');
                    this.setState({ loading: false });
                }
            });
        }
    }

    private handleSignOut() {
        if (this.User.Source === "GOOGLE") {
            var authInstance = gapi.auth2.getAuthInstance();
            if (authInstance) {
                authInstance.signOut().then(() => {
                    this.logOut();
                });
            }
        }
        else {
            this.logOut();
        }
    }

    private logOut() {
        let token = localStorage.getItem(this.encodeString("ProsBEUser_Token"));
        if (!token) {
            token = sessionStorage.getItem(this.encodeString("ProsBEUser_Token"));
        }

        if (token) {
            this.db.signOutUser(token).then((result) => {
                if (result) {
                    localStorage.removeItem(this.encodeString("ProsBE_User"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_Token"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_ExpiresOn"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_Role"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_AppRole"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_LoginName"));

                    localStorage.removeItem(this.encodeString("ProsBE_GoogleUser"));

                    sessionStorage.removeItem(this.encodeString("ProsBE_User"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_Token"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_ExpiresOn"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_Role"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_AppRole"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_LoginName"));

                    sessionStorage.removeItem(this.encodeString("ProsBE_GoogleUser"));

                    this.User = null;
                    this.UserRole = '';
                    this.AppRole = '';
                    this.IsUserValid = false;
                }
                else {
                    this.errorMessage('Some error occurred while signing you out. Please close the browser to sign out of the application.');
                }

                window.location.href = '/';
            });
        }
        else {
            this.errorMessage('Some error occurred while signing you out. Please close the browser to sign out of the application.');
        }
    }

    private handleCancel() {
        window.location.href = '/profile';
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {

        const { loading, IsChecked, ShowMessage, Message, MessageType } = this.state;
        let template: any = <ProgressBar />;

        if (!loading) {
            template = (<React.Fragment>
                {/* <Header /> */}
                <section className="containerMinHeight">
                    <div className="profileContainer">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <div className="formContainer">
                                    <div className="headingTxt">Deactivate Account</div>
                                    <div className="tabPanel">
                                        <div className="form-group">
                                            <label htmlFor="email">Are you sure you want to deactivate your account?</label>
                                        </div>
                                        <div className="mt-1 mb-4">
                                            <div className="custom-control custom-switch d-flex justify-content-between">
                                                <input type="checkbox" className="custom-control-input" id="deactivateAccount" checked={IsChecked} onChange={(e) => { this.setState({ IsChecked: e.target.checked }); }} />
                                                <label className="custom-control-label mt-4" htmlFor="deactivateAccount">I confirm I want to deactivate my account</label>
                                            </div>
                                            <Validator id="valCheck" type="required" errorMessage="Provide confirmation to deactivate." cssClass="error" controlId="deactivateAccount" valueToValidate={IsChecked ? '1' : ''} formValidators={this.formValidators} />
                                        </div>
                                        <div className="formButtonContainer text-right">
                                            <button type="submit" className="btn btn-primary cutmBtnLogin mr-2" onClick={(e) => { this.handleDeactivate(); }}>Deactivate Account</button><button type="submit" className="btn btn-cancel" onClick={(e) => { this.handleCancel(); }}>Cancel</button>
                                        </div>
                                    </div>
                                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                                        <Alert severity={MessageType} sx={{ width: '100%' }}>
                                            {Message}
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <Footer /> */}
            </React.Fragment>);
        }

        return template;
    }
}