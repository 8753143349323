import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";

export default class About extends Base {
    constructor(props) {
        super(props);

    }

    public componentDidMount() {

    }

    public render() {

        let template = (<React.Fragment>
            <Header />
            <section className="containerMinHeight borderBtm">
                <div className="becomeLayer2 about_us">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                            </div>
                            <div className="col-12 col-sm-6 p-5">
                                <div className="cardCotent p-5">
                                    <div className="layerOne">
                                        Grow your business with ProsBe
                                    </div>
                                    <div className="layerTwo mt-2">
                                        Welcome to <a href="https://prosbe.com">prosbe.com</a>, your one-stop platform for finding trusted service providers for all your needs. Whether you're looking for a skilled professional to handle home improvement, event planning, pet care, tutoring, or any other service, we've got you covered. Our mission is to make it easy for you to connect with reliable experts who can deliver top-notch services tailored to your requirements.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="becomeLayer2 text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12 pr-5 pl-5 pt-5">
                                <div className="layerOne">
                                    Connecting Service Seekers and Service Providers - Building Stronger Communities
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 pl-5 pr-5 pb-5 mt-3">
                                <div className="layerTwo">
                                    At <a href="https://prosbe.com">prosbe.com</a>, our vision is to create a seamless and efficient marketplace where customers can find and hire the perfect service providers for their unique needs. We aim to empower both customers and service providers, fostering a sense of trust and transparency in every interaction. We believe in simplifying the process of hiring services and ensuring that every customer finds the right match effortlessly.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="becomeLayer3 text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12 pl-5 pr-5 pt-5">
                                <div className="layerOne">
                                    Join <a href="https://prosbe.com">prosbe.com</a> Today
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 pr-5 pl-5 pb-5 mt-3">
                                <div className="layerTwo">
                                    <span>Whether you're a customer seeking services or a skilled professional looking to connect with clients, prosbe.com is the platform for you. Join our community today and experience the convenience, reliability, and excellence that our platform has to offer. Let's embark on this journey together and make your service needs a seamless reality.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>);

        return template;
    }
}