import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";

export default class ContactUs extends Base {
    constructor(props) {
        super(props);

    }

    public componentDidMount() {

    }

    public render() {

        let template = (<React.Fragment>
            <Header />
            <section className="containerMinHeight borderBtm staticContent">
                <div className="becomeLayer5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-6 p-5">
                                <h3>Visit help center for tips & advice</h3>
                                <div className="mt-3 text-center">
                                    <button type="submit" className="signUp" onClick={(e) => { window.open('http://help.prosbe.com'); }}>Help</button>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 p-5">
                                <h3>For general questions</h3>
                                <div className="layerTwo">
                                    <span>If you have a question about finding a pro, leaving a review, your membership, or anything else:</span>
                                </div>
                                <div className="layerTwo">
                                    <span>Email:</span> <a href="mailto:info@prosbe.com">info@prosbe.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>);

        return template;
    }
}