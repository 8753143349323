import {
    Alert, Avatar, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, IconButton, Radio,
    RadioGroup, Snackbar, Typography
} from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import { Loader, ProgressBar } from "src/common/Components";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import Constants from "src/common/Constants";
import Validator from "src/common/Validator";
import { Attachment, FilterAltOff, MonetizationOnOutlined } from "@mui/icons-material";

export default class Leads extends Base {

    public state: any;
    private validator: Validator;
    private formValidators: Validator[];

    constructor(props) {
        super(props);

        this.state = {
            loading: true, showProgress: false, Data: [], FilteredData: [], DialogMode: '', ItemId: (this.props.item && Number(this.props.item) > 0 ? Number(this.props.item) : 0),
            ProfileId: 0, showConfirmation: false, ConfirmationMessage: '', callBackMethod: null, ShowMessage: false, Message: '', MessageType: 'success',
            ActiveTab: (this.props.tab ? this.props.tab : 0), showDetailsDialog: false,
            QuotePrice: '', QuoteType: '', QuoteTypes: [], QuoteMessage: '', FileName: '', QuoteFAQs: [], File: null, QuoteTypeName: '', ActiveTabInner: 0,
            showDialogProgress: false, showFAQProgress: false, CategoryId: '', ProjectId: '', Quotes: [], showQuotesProgress: false, ProjectName: '', CustomerEmail: '',
            showFilter: false
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        this.getUserProfile().then((profile) => {
            if (profile) {
                this.setState({ ProfileId: profile.Id }, () => {
                    this.handleTabChange(this.state.ActiveTab);
                });
            }
            else {
                this.setState({ loading: false });
            }
        });
    }

    private getLeads() {
        this.setState({ showProgress: true });
        this.db.getLeads(this.state.ProfileId).then((leads) => {
            let filteredData: any = [];
            if (leads) {
                leads.forEach((lead) => {
                    lead.showDetailsDialog = false;
                });

                filteredData = leads;
                if (this.props.tab === 0 && this.state.ItemId > 0) {
                    filteredData = leads.filter((d) => { return (d.Id === this.state.ItemId); });
                }
            }

            this.setState({
                loading: false, showProgress: false, Data: leads, FilteredData: filteredData,
                showFilter: (this.props.tab === 0 && this.state.ItemId > 0)
            });
        });
    }

    private getUserProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.LoginName && this.state.ProfileId === 0) {
                this.db.getUserProfile(this.LoginName, this.AppRole).then((profile) => {
                    resolve(profile);
                });
            }
            else {
                if (this.state.ProfileId > 0)
                    resolve({ Id: this.state.ProfileId });
                else
                    resolve(null);
            }
        });
    }

    private getOpportunities() {
        this.setState({ showProgress: true });
        this.db.getOpportunities(this.state.ProfileId).then((opportunities) => {
            let filteredData: any = [];
            if (opportunities) {
                opportunities.forEach((opp) => {
                    opp.showDetailsDialog = false;
                });

                filteredData = opportunities;
                if (this.props.tab === 1 && this.state.ItemId > 0) {
                    filteredData = opportunities.filter((d) => { return (d.Id === this.state.ItemId); });
                }
            }

            this.setState({
                loading: false, showProgress: false, Data: opportunities, FilteredData: filteredData,
                showFilter: (this.props.tab === 1 && this.state.ItemId > 0)
            });
        });
    }

    private handleTabChange(tabId) {
        this.setState({ ActiveTab: tabId }, () => {
            if (tabId === 0) { // Leads
                this.getLeads();
            }
            else if (tabId === 1) { // Opportunities
                this.getOpportunities();
            }
            else if (tabId === 2) { // Quotes
                this.getMyQuotes();
            }
        });
    }

    private getDays(createdOn) {
        let currentDate = new Date();
        let createdDate = new Date(this.getDateNumber(createdOn));

        const diffTime = currentDate.getTime() - createdDate.getTime();
        let diffDays: any = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 0) {
            if (diffDays >= 365) {
                diffDays = Math.floor(diffDays / 365);
                diffDays = diffDays.toString() + (diffDays > 1 ? " year(s) ago" : " year ago");
            }
            else if (diffDays >= 30) {
                diffDays = Math.floor(diffDays / 30);
                diffDays = diffDays.toString() + (diffDays > 1 ? " month(s) ago" : " month ago");
            }
            else if (diffDays >= 7) {
                diffDays = Math.floor(diffDays / 7);
                diffDays = diffDays.toString() + (diffDays > 1 ? " week(s) ago" : " week ago");
            }
            else {
                diffDays = diffDays.toString() + (diffDays > 1 ? " day(s) ago" : " day ago");
            }
        }
        else {
            diffDays = "today";
        }

        return diffDays;
    }

    private contactUser(item) {
        let emailTemplate = Constants.Email.ContactUser;
        let subject = emailTemplate.Subject.replace('[proname]', this.User.Profile.BusinessName);
        let body = emailTemplate.Body;

        body = body.replace("[username]", item.CustomerName);
        body = body.replace("[proname]", this.User.Profile.BusinessName);
        body = body.replace("[projectname]", item.ProjectName);

        this.db.addMessage(this.User.Profile.Id, item.CustomerId, subject, body, '0', '0', this.AppRole, Constants.UserRoles.Customer).then((added) => {
            if (added > 0) {
                if (item.CustomerEmail) {
                    this.db.sendEmail(item.CustomerEmail, subject, body).then((sent) => {
                        if (sent) {
                            this.successMessage('Your message has been sent to the customer.');
                        }
                        else {
                            this.errorMessage('Customer could not be contacted. Please try again later.');
                        }
                    });
                }
                else {
                    this.errorMessage('Customer email is not available. Please try again later.');
                }
            }
            else {
                this.errorMessage('Message could not be sent.');
            }
        });
    }

    private passProject(item, type) {
        if (type === 'L')
            this.setState({ showLeadsProgress: true });
        else
            this.setState({ showOpportunitiesProgress: true });

        this.db.passProject(item.ProjectId, this.state.ProfileId).then((passed) => {
            if (passed) {
                this.successMessage('This project will not show in your list now.');
                item.showDetailsDialog = false;

                if (type === 'L') {
                    this.setState({ showLeadsProgress: false, }, () => {
                        this.getLeads();
                    });
                }
                else {
                    this.setState({ showOpportunitiesProgress: false }, () => {
                        this.getOpportunities();
                    });
                }
            }
            else {
                this.errorMessage('Some error occurred. Please try again later.');
                if (type === 'L')
                    this.setState({ showLeadsProgress: false });
                else
                    this.setState({ showOpportunitiesProgress: true });
            }
        });
    }

    private getCategoryFAQs(catId) {
        this.setState({ showDialogProgress: true });
        this.db.getCategoryFAQs(catId, 'Q').then((faqs) => {
            if (faqs) {
                faqs.forEach((f) => {
                    f.FAQId = f.Id;
                    f.Id = 0;
                    f.Description = '';
                });
            }

            this.setState({ showDialogProgress: false, QuoteFAQs: faqs });
        });
    }

    private getMyQuotes() {
        this.setState({ showQuotesProgress: true });
        this.db.getMyQuotes(this.state.ProfileId).then((data) => {
            if (data) {
                data.forEach((item) => {
                    item.showDetailsDialog = false;
                });
            }

            this.setState({ showQuotesProgress: false, Quotes: data });
        });
    }

    private submitQuote(item) {
        this.setState({ showDetailsDialog: true, ActiveTabInner: 0, CategoryId: item.Category.Id, ProjectId: item.ProjectId, ProjectName: item.ProjectName, CustomerEmail: item.CustomerEmail });
        this.getCategoryFAQs(item.Category.Id);
    }

    private resetQuoteDetails() {
        this.formValidators = new Array<Validator>();
        this.setState({
            showDetailsDialog: false, showDialogProgress: false, DialogMode: '', QuotePrice: '', QuoteType: '', QuoteMessage: '', FileName: '', QuoteFAQs: [],
            ActiveTabInner: 0, File: null, QuoteTypeName: '', CategoryId: '', ProjectId: '', ProjectName: '', CustomerEmail: ''
        });
    }

    private sendEmail(quoteId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.SubmitQuote;
            let subject = emailTemplate.Subject.replace('[projectname]', this.state.ProjectName);
            let body = emailTemplate.Body;

            body = body.replace("[qid]", quoteId);
            body = body.replace("[businessname]", this.User.Profile.BusinessName);
            body = body.replace("[projectname]", this.state.ProjectName);

            if (this.state.CustomerEmail) {
                this.db.sendEmail(this.state.CustomerEmail, subject, body).then((sent) => {
                    if (sent) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(false);
            }
        });
    }

    private handleQuoteSaveClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            let { QuotePrice, QuoteType, QuoteMessage, QuoteFAQs, FileName, File, ProjectId, ActiveTab } = this.state;
            this.db.submitQuote(ProjectId, this.User.Profile.Id, QuotePrice, QuoteType, QuoteMessage).then((itemId) => {
                if (itemId > 0) {
                    this.sendEmail(itemId).then((sent) => {
                        if (sent) {
                            if (File) {
                                this.db.uploadQuoteFile(itemId, FileName, '/files/' + this.User.Profile.Id + '/quotes/' + ProjectId + '/' + FileName, File).then((uploaded) => {
                                    if (uploaded) {
                                        if (QuoteFAQs && QuoteFAQs.length > 0) {
                                            let faqs = JSON.stringify(QuoteFAQs);
                                            this.db.updateQuoteFAQs(ProjectId, this.User.Profile.Id, faqs).then((updated) => {
                                                if (updated) {
                                                    this.successMessage('Quote submitted successfully.');
                                                    this.resetQuoteDetails();

                                                    if (ActiveTab === 0)
                                                        this.getLeads();
                                                    else
                                                        this.getOpportunities();
                                                }
                                                else {
                                                    this.errorMessage('Quote FAQs could not be updated.');
                                                    this.setState({ showDialogProgress: false });
                                                }
                                            });
                                        }
                                        else {
                                            this.successMessage('Quote submitted successfully.');
                                            this.resetQuoteDetails();

                                            if (ActiveTab === 0)
                                                this.getLeads();
                                            else
                                                this.getOpportunities();
                                        }
                                    }
                                    else {
                                        this.errorMessage('Quote attachement could not be updated.');
                                        this.setState({ showDialogProgress: false });
                                    }
                                });
                            }
                            else {
                                if (QuoteFAQs && QuoteFAQs.length > 0) {
                                    let faqs = JSON.stringify(QuoteFAQs);
                                    this.db.updateQuoteFAQs(ProjectId, this.User.Profile.Id, faqs).then((updated) => {
                                        if (updated) {
                                            this.successMessage('Quote submitted successfully.');
                                            this.resetQuoteDetails();

                                            if (ActiveTab === 0)
                                                this.getLeads();
                                            else
                                                this.getOpportunities();
                                        }
                                        else {
                                            this.errorMessage('Quote FAQs could not be updated.');
                                            this.setState({ showDialogProgress: false });
                                        }
                                    });
                                }
                                else {
                                    this.successMessage('Quote submitted successfully.');
                                    this.resetQuoteDetails();

                                    if (ActiveTab === 0)
                                        this.getLeads();
                                    else
                                        this.getOpportunities();
                                }
                            }
                        }
                        else {
                            this.errorMessage('Quote email could not be sent.');
                            this.setState({ showDialogProgress: false });
                        }
                    });
                }
                else {
                    this.errorMessage('Quote could not be submitted.');
                    this.setState({ showDialogProgress: false });
                }
            });
        }
    }

    private getAnswerType(item, indx, faqs) {
        let aType = item.ControlType;
        let isRequired = item.IsRequired;
        let maxLength = item.MaxLength;
        let defaultVal = item.Description;
        let activeTab = this.state.ActiveTab;

        if (aType === "TXTB") {
            return (<React.Fragment>
                <input type='text' name={"txtBAnswer_" + indx} id={"txtBAnswer_" + indx} className="form-control commentBody" defaultValue={defaultVal} onBlur={(e) => { item.Description = e.target.value; this.setState({ ProjectFAQs: faqs }); }} />
                {isRequired ? (
                    <Validator id={"valBAnswer_" + indx} type="required" cssClass="error" errorMessage="Provide value." controlId={"txtBAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                ) : (null)}
                {maxLength ? (
                    <Validator id={"valBAnswerMaxLength_" + indx} type="required" cssClass="error" errorMessage={"Value cannot exceed " + maxLength + " characters."} controlId={"txtBAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                ) : (null)}
            </React.Fragment >);
        }
        else if (aType === "TXTA") {
            return (<React.Fragment>
                <textarea name={"txtAAnswer_" + indx} id={"txtAAnswer_" + indx} className="form-control commentBody" rows={3} defaultValue={defaultVal} onBlur={(e) => { item.Description = e.target.value; this.setState({ ProjectFAQs: faqs }); }} />
                {isRequired ? (
                    <Validator id={"valAAnswer_" + indx} type="required" cssClass="error" errorMessage="Provide value." controlId={"txtAAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                ) : (null)}
                {maxLength ? (
                    <Validator id={"valAAnswerMaxLength_" + indx} type="required" cssClass="error" errorMessage={"Value cannot exceed " + maxLength + " characters."} controlId={"txtAAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                ) : (null)}
            </React.Fragment>);
        }
        else if (aType === "CHKB") {
            return (<React.Fragment>
                <div className="custom-control custom-switch d-flex justify-content-between">
                    <input type="checkbox" className="custom-control-input" id="chkBAnswer" checked={defaultVal && defaultVal.toLowerCase() === "true" ? true : false} onChange={(e) => { item.Description = e.target.checked.toString(); this.setState({ ProjectFAQs: faqs }); }} />
                    <label className="custom-control-label commentBody" htmlFor="chkBAnswer">{item.MasterValue}</label>
                </div>
            </React.Fragment>);
        }
        else if (aType === "CHKL") {
            if (item.MasterValue) {
                let _mVals = item.MasterValue.split(/\r?\n/);

                return (<React.Fragment>
                    <FormGroup id={"chkListAnswer_" + indx} key={"chkListAnswer_" + indx}>
                        {_mVals && _mVals.map((val, idx) => {
                            return (<FormControlLabel control={<Checkbox value={val} checked={defaultVal && defaultVal.indexOf(val + ';') >= 0} onChange={(e) => { if (e.target.checked) { if (item.Description) { item.Description += e.target.value + ';'; } else { item.Description = e.target.value + ';'; } } else { item.Description = item.Description.replace(e.target.value + ';', ''); } this.setState({ ProjectFAQs: faqs }); }} />} label={val} className="commentBody" />);
                        })}
                    </FormGroup>
                    {isRequired ? (
                        <Validator id={"valChkListAnswer_" + indx} type="required" cssClass="error" errorMessage="Select a value." controlId={"chkListAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                    ) : (null)}
                </React.Fragment>);
            }
            else
                return (null);
        }
        else if (aType === "RBTL") {
            if (item.MasterValue) {
                let _mVals = item.MasterValue.split(/\r?\n/);

                return (<React.Fragment>
                    <RadioGroup id={"rbtnListAnswer_" + indx} name={"rbtnListAnswer_" + indx} defaultValue={defaultVal} onChange={(e) => { item.Description = e.target.value; this.setState({ ProjectFAQs: faqs }); }} className="commentBody">
                        {_mVals && _mVals.map((val, idx) => {
                            return (<FormControlLabel control={<Radio value={val} />} label={val} />);
                        })}
                    </RadioGroup>
                    {isRequired ? (
                        <Validator id={"valRbtnListAnswer_" + indx} type="required" cssClass="error" errorMessage="Select a value." controlId={"rbtnListAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                    ) : (null)}
                </React.Fragment >);
            }
            else
                return (null);
        }
        else if (aType === "DDL") {
            if (item.MasterValue) {
                let _mVals = item.MasterValue.split(/\r?\n/);

                return (<React.Fragment>
                    <select id={"ddlAnswer_" + indx} name={"ddlAnswer" + indx} className="form-control commentBody" defaultValue={defaultVal} onChange={(e) => { item.Description = e.target.value; this.setState({ ProjectFAQs: faqs }); }} >
                        <option value="">Select</option>
                        {_mVals && _mVals.map((val, idx) => {
                            return (<option value={val}>{val}</option>);
                        })}
                    </select>
                    {isRequired ? (
                        <Validator id={"valDdlAnswer" + indx} type="required" cssClass="error" errorMessage="Select a value." controlId={"ddlAnswer_" + indx} valueToValidate={activeTab === (indx + 1) ? defaultVal : '1'} formValidators={this.formValidators}></Validator>
                    ) : (null)}
                </React.Fragment>);
            }
            else
                return (null);
        }
        // else if (aType === "ATCF") {

        // }
        else {
            return null;
        }
    }

    private handleBackClick(activeTab) {
        this.setState({ ActiveTabInner: activeTab - 1 });
    }

    private handleContinueClick(activeTab) {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid)
            this.setState({ ActiveTabInner: activeTab + 1 });
    }

    private removeFilter() {
        this.setState({ FilteredData: this.state.Data, showFilter: false, ItemId: 0 });
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {

        const { loading, showProgress, FilteredData, showQuotesProgress, showConfirmation, ConfirmationMessage, Message, ShowMessage, MessageType, ActiveTab,
            showDetailsDialog, showDialogProgress, DialogMode, QuotePrice, QuoteType, QuoteMessage, FileName, QuoteFAQs, showFilter,
            ActiveTabInner, Quotes } = this.state;

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            if (!this.IsUserValid) {
                let query = new URLSearchParams(window.location.search);
                window.location.href = '/login?src=' + encodeURI('/' + (this.props.item ? (this.props.tab === 1 ? 'opportunity' : 'lead') : 'leads') + '?' + (query ? query : ''));
            }
            else {
                template = (<React.Fragment>
                    <Header />
                    <section className="containerMinHeight accountSettingContainer borderBtm">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="row">
                                        {this.AppRole !== Constants.UserRoles.Pro ? (<React.Fragment>
                                            <div className="col-12 col-sm-12">
                                                <div className="tabPanel">You must be a Pro to view these details.</div>
                                            </div>
                                        </React.Fragment>) : (<React.Fragment>
                                            <div className="col-12 col-sm-3">
                                                <div className="accountNavigation">
                                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                        <a className={"nav-link " + (ActiveTab === 0 ? "active" : "")} id="v-pills-leads-tab" onClick={(e) => { this.handleTabChange(0); }}>Leads</a>
                                                        <a className={"nav-link " + (ActiveTab === 1 ? "active" : "")} id="v-pills-opportunity-tab" onClick={(e) => { this.handleTabChange(1); }}>Opportunities</a>
                                                        <a className={"nav-link " + (ActiveTab === 2 ? "active" : "")} id="v-pills-opportunity-tab" onClick={(e) => { this.handleTabChange(2); }}>Submitted Quotes</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-9">
                                                <div className="tab-content" id="v-pills-tabContent">
                                                    <div className={"tab-pane fade " + (ActiveTab === 0 ? "show active" : "")} id="v-pills-leads">
                                                        {ActiveTab !== 0 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <div className="row">
                                                                            <div className="col-6 col-sm-9">
                                                                                <div className="headingTxt">Leads</div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-3 text-right">
                                                                                {showFilter ? (<IconButton onClick={(e) => { this.removeFilter(); }}><FilterAltOff /></IconButton>) : (null)}
                                                                            </div>
                                                                        </div>
                                                                        <React.Fragment>
                                                                            {showProgress ? (<div className="tabPanel"><ProgressBar /></div>) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<div className="tabPanel"><Typography variant="body2">There are no leads available for you.</Typography></div>) : (
                                                                                    <React.Fragment>
                                                                                        {FilteredData.map((lead, indx) => {
                                                                                            let days = this.getDays(lead.CreatedOn);
                                                                                            return (
                                                                                                <div className={"tabPanel" + (indx > 0 ? " mt-2" : "")}>
                                                                                                    <div className="form-group">
                                                                                                        <div className="row">
                                                                                                            <div className="col-12 col-sm-9">
                                                                                                                <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>{lead.ProjectName}</Typography>
                                                                                                                <Typography variant="subtitle2" color="text.secondary" className="pt-2"><LocationOnIcon /> {lead.CityName}, {lead.StateName} {lead.Pin}</Typography>
                                                                                                                <div className="mt-2">
                                                                                                                    <Chip icon={<MonetizationOnOutlinedIcon />} label={lead.Budget} variant="outlined" className="mt-1" /> <Chip variant="outlined" label={"Quote requested " + days} /> <Chip className="ml-1" variant="outlined" label={lead.QuotesRequested > 0 ? (lead.QuotesSubmitted + " out of " + lead.QuotesRequested + " quotes submitted") : (lead.QuotesSubmitted + " quotes submitted")} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-12 col-sm-3 text-right">
                                                                                                                <Button onClick={(e) => { lead.showDetailsDialog = true; this.setState({ FilteredData: FilteredData }); }}>View Details</Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {!lead.showDetailsDialog ? (null) : (<React.Fragment>
                                                                                                        <Dialog fullScreen={true} open={lead.showDetailsDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                                                                            <DialogTitle id={"detailsDialog"} className="modalTitle">{'Lead Details'}
                                                                                                                <IconButton aria-label="close" onClick={(e) => { lead.showDetailsDialog = false; this.setState({ FilteredData: FilteredData }); }} className="btnCloseDialog">
                                                                                                                    <CloseIcon />
                                                                                                                </IconButton>
                                                                                                            </DialogTitle>
                                                                                                            <DialogContent dividers={true}>
                                                                                                                <div className="profileContainer">
                                                                                                                    <div className="formContainer">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-12 col-sm-5">
                                                                                                                                <div className="tabPanel">
                                                                                                                                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{lead.ProjectName}</Typography>
                                                                                                                                    <Typography variant="subtitle2" color="text.secondary">{lead.Category.Name}</Typography>
                                                                                                                                    <div className="mt-2">
                                                                                                                                        <Chip icon={<MonetizationOnOutlinedIcon />} label={lead.Budget} variant="outlined" className="mt-1" /> <Chip variant="outlined" label={"Quote requested " + days} /> <Chip className="ml-1" variant="outlined" label={lead.QuotesRequested > 0 ? (lead.QuotesSubmitted + " out of " + lead.QuotesRequested + " quotes submitted") : (lead.QuotesSubmitted + " quotes submitted")} />
                                                                                                                                    </div>
                                                                                                                                    <Typography variant="subtitle2" color="text.secondary" className="pt-2"><LocationOnIcon /> {lead.CityName}, {lead.StateName} {lead.Pin}</Typography>
                                                                                                                                    <Typography variant="subtitle2" color="text.secondary" className="pt-2">{lead.ProjectDescription}</Typography>
                                                                                                                                    <div className="mt-2">
                                                                                                                                        <hr></hr>
                                                                                                                                    </div>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-1 col-sm-1">
                                                                                                                                            <div className="form-group">
                                                                                                                                                <Avatar alt={lead.CustomerName} src={lead.ProfileImage ? lead.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 50, height: 50 }}></Avatar>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-11 col-sm-11">
                                                                                                                                            <div className="form-group">
                                                                                                                                                <Typography variant="caption" sx={{ fontWeight: 600 }}>{lead.CustomerName}</Typography>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="mt-1"><Typography variant="subtitle2" className="commentBody"><PhoneIcon /> {this.OpenToAll !== '1' && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic ? (lead.Phone.substring(0, 3) + '*******') : (lead.Phone)}</Typography></div>
                                                                                                                                    <div className="mt-1"><Typography variant="subtitle2" className="commentBody"><EmailIcon /> {this.OpenToAll !== '1' && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic ? (lead.CustomerEmail.substring(0, 1) + '*******' + lead.CustomerEmail.substring(lead.CustomerEmail.indexOf('@') - 1, 3) + "***.com") : (lead.CustomerEmail)}</Typography></div>
                                                                                                                                </div>
                                                                                                                                <div className="formButtonContainer text-right mt-3">
                                                                                                                                    {this.OpenToAll === '1' || this.User.Profile.ActiveSubscription !== Constants.Subscriptions.Basic ? (<button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.contactUser(lead); }} disabled={this.OpenToAll !== '1' && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic}>Contact {lead.CustomerName.split(' ')[0]}</button>) : (null)}
                                                                                                                                    <button type="submit" className="btn btn-cancel ml-3" onClick={(e) => { this.passProject(lead, 'L'); }}>Not Interested</button>
                                                                                                                                    {this.OpenToAll === '1' || this.User.Profile.ActiveSubscription !== Constants.Subscriptions.Basic ? (<button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={(this.OpenToAll !== '1' && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic) || lead.HasSubmittedQuote} onClick={(e) => { if (this.OpenToAll === "1" || this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) { this.submitQuote(lead); } }}>Submit Quote</button>) : (null)}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-12 col-sm-7">
                                                                                                                                <div className="accountSettingContainer p-3">
                                                                                                                                    <h5>Project Details</h5>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-12 col-sm-12">
                                                                                                                                            <div className="form-group">
                                                                                                                                                {lead.FAQs && lead.FAQs.map((item, indx) => {
                                                                                                                                                    return (<div className={indx > 0 ? "mt-3" : ""}>
                                                                                                                                                        <label>{item.Name}</label>
                                                                                                                                                        <Typography variant="subtitle2" color="text.secondary">{item.Description}</Typography>
                                                                                                                                                    </div>);
                                                                                                                                                })}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </DialogContent>
                                                                                                        </Dialog>
                                                                                                    </React.Fragment>)}
                                                                                                </div>);
                                                                                        })}
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </React.Fragment>)}
                                                                        </React.Fragment>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 1 ? "show active" : "")} id="v-pills-opportunities">
                                                        {ActiveTab !== 1 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <div className="row">
                                                                            <div className="col-6 col-sm-9">
                                                                                <div className="headingTxt">Opportunities</div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-3 text-right">
                                                                                {showFilter ? (<IconButton onClick={(e) => { this.removeFilter(); }}><FilterAltOff /></IconButton>) : (null)}
                                                                            </div>
                                                                        </div>
                                                                        {showProgress ? (<div className="tabPanel"><ProgressBar /></div>) : (<React.Fragment>
                                                                            {!FilteredData || FilteredData.length <= 0 ? (<div className="tabPanel"><Typography variant="body2">There are no opportunities available.</Typography></div>) : (
                                                                                <React.Fragment>
                                                                                    {FilteredData.map((opp, indx) => {
                                                                                        let days = this.getDays(opp.ProjectCreatedOn);
                                                                                        return (
                                                                                            <div className={"tabPanel" + (indx > 0 ? " mt-2" : "")}>
                                                                                                <div className="form-group">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-9">
                                                                                                            <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>{opp.ProjectName}</Typography>
                                                                                                            <Typography variant="subtitle2" color="text.secondary" className="pt-2"><LocationOnIcon /> {opp.CityName}, {opp.StateName} {opp.Pin}</Typography>
                                                                                                            <div className="mt-2">
                                                                                                                <Chip icon={<MonetizationOnOutlinedIcon />} label={opp.Budget} variant="outlined" className="mt-1" /> <Chip variant="outlined" label={days} /> <Chip className="ml-1" variant="outlined" label={opp.QuotesRequested > 0 ? (opp.QuotesSubmitted + " out of " + opp.QuotesRequested + " quotes submitted") : (opp.QuotesSubmitted + " quotes submitted")} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-12 col-sm-3 text-right">
                                                                                                            <Button onClick={(e) => { opp.showDetailsDialog = true; this.setState({ FilteredData: FilteredData }); }}>View Details</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {!opp.showDetailsDialog ? (null) : (<React.Fragment>
                                                                                                    <Dialog fullScreen={true} open={opp.showDetailsDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                                                                        <DialogTitle id={"detailsDialog"} className="modalTitle">{'Opportunity Details'}
                                                                                                            <IconButton aria-label="close" onClick={(e) => { opp.showDetailsDialog = false; this.setState({ FilteredData: FilteredData }); }} className="btnCloseDialog">
                                                                                                                <CloseIcon />
                                                                                                            </IconButton>
                                                                                                        </DialogTitle>
                                                                                                        <DialogContent dividers={true}>
                                                                                                            <div className="profileContainer">
                                                                                                                <div className="formContainer">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-5">
                                                                                                                            <div className="tabPanel">
                                                                                                                                <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>{opp.ProjectName}</Typography>
                                                                                                                                <Typography variant="subtitle2" color="text.secondary">{opp.Category.Name}</Typography>
                                                                                                                                <div className="mt-2">
                                                                                                                                    <Chip icon={<MonetizationOnOutlinedIcon />} label={opp.Budget} variant="outlined" className="mt-1" /> <Chip variant="outlined" label={days} /> <Chip className="ml-1" variant="outlined" label={opp.QuotesRequested > 0 ? (opp.QuotesSubmitted + " out of " + opp.QuotesRequested + " quotes submitted") : (opp.QuotesSubmitted + " quotes submitted")} />
                                                                                                                                </div>
                                                                                                                                <Typography variant="subtitle2" color="text.secondary" className="pt-2"><LocationOnIcon /> {opp.CityName}, {opp.StateName} {opp.Pin}</Typography>
                                                                                                                                <Typography variant="subtitle2" color="text.secondary" className="pt-2">{opp.ProjectDescription}</Typography>
                                                                                                                                <div className="mt-2">
                                                                                                                                    <hr></hr>
                                                                                                                                </div>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-1 col-sm-1">
                                                                                                                                        <div className="form-group">
                                                                                                                                            <Avatar alt={opp.CustomerName} src={opp.ProfileImage ? opp.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 50, height: 50 }}></Avatar>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-11 col-sm-11">
                                                                                                                                        <div className="form-group">
                                                                                                                                            <Typography variant="caption" sx={{ fontWeight: 600 }}>{opp.CustomerName}</Typography>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="mt-1"><Typography variant="subtitle2" className="commentBody"><PhoneIcon /> {this.OpenToAll !== '1' && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic ? (opp.Phone.substring(0, 3) + '*******') : (opp.Phone)}</Typography></div>
                                                                                                                                <div className="mt-1"><Typography variant="subtitle2" className="commentBody"><EmailIcon /> {this.OpenToAll !== '1' && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic ? (opp.CustomerEmail.substring(0, 1) + '*******' + opp.CustomerEmail.substring(opp.CustomerEmail.indexOf('@') - 1, 3) + "***.com") : (opp.CustomerEmail)}</Typography></div>
                                                                                                                            </div>
                                                                                                                            <div className="formButtonContainer text-right mt-3">
                                                                                                                                {this.OpenToAll === '1' || this.User.Profile.ActiveSubscription !== Constants.Subscriptions.Basic ? (<button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { if (this.OpenToAll === "1" || this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) { this.contactUser(opp); } }}>Contact {opp.CustomerName.split(' ')[0]}</button>) : (null)}
                                                                                                                                <button type="submit" className="btn btn-cancel ml-3" onClick={(e) => { this.passProject(opp, 'O'); }}>Not Interested</button>
                                                                                                                                {this.OpenToAll === '1' || this.User.Profile.ActiveSubscription !== Constants.Subscriptions.Basic ? (<button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={(this.OpenToAll === "0" && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic) || opp.HasSubmittedQuote} onClick={(e) => { if (this.OpenToAll === "1" || this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) { this.submitQuote(opp); } }}>Submit Quote</button>) : (null)}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-12 col-sm-7">
                                                                                                                            <div className="accountSettingContainer p-3">
                                                                                                                                <h5>Project Details</h5>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-12 col-sm-12">
                                                                                                                                        <div className="form-group">
                                                                                                                                            {opp.FAQs && opp.FAQs.map((item, indx) => {
                                                                                                                                                return (<div className={indx > 0 ? "mt-3" : ""}>
                                                                                                                                                    <label>{item.Name}</label>
                                                                                                                                                    <Typography variant="subtitle2" color="text.secondary">{item.Description}</Typography>
                                                                                                                                                </div>);
                                                                                                                                            })}
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </DialogContent>
                                                                                                    </Dialog>
                                                                                                </React.Fragment>)}
                                                                                            </div>);
                                                                                    })}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </React.Fragment>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 2 ? "show active" : "")} id="v-pills-submittedquotes">
                                                        {ActiveTab !== 2 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <div className="headingTxt">Quotes</div>
                                                                        {showQuotesProgress ? (<div className="tabPanel"><ProgressBar /></div>) : (<React.Fragment>
                                                                            {!Quotes || Quotes.length <= 0 ? (<div className="tabPanel"><Typography variant="body2">There are no quotes submitted by you.</Typography></div>) : (
                                                                                <React.Fragment>
                                                                                    {Quotes.map((item, indx) => {
                                                                                        let days = this.getDays(item.SubmittedOn);
                                                                                        return (
                                                                                            <div className={"tabPanel" + (indx > 0 ? " mt-2" : "")}>
                                                                                                <div className="form-group">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>{item.ProjectName}</Typography>
                                                                                                            <Typography variant="subtitle2" color="text.secondary" className="pt-2"><LocationOnIcon /> {item.City.Name}, {item.City.State.Code} {item.City.Pin}</Typography>
                                                                                                            <div className="mt-2">
                                                                                                                <Chip variant="outlined" label={"Quote submitted " + days} /> <Chip className="ml-1" variant="outlined" label={item.QuotesRequested > 0 ? (item.QuotesSubmitted + " out of " + item.QuotesRequested + " quotes submitted") : (item.QuotesSubmitted + " quotes submitted")} />
                                                                                                            </div>
                                                                                                            <div className="mt-2">
                                                                                                                <Chip icon={<MonetizationOnOutlined />} label={item.Price} /><Chip className="ml-1" label={item.Type === 1 ? 'Fixed Price' : 'Hourly Rate'} />
                                                                                                            </div>
                                                                                                            <Typography variant="subtitle2" color="text.secondary" className="pt-2">{item.Message}</Typography>
                                                                                                            {item.File && item.File.Name ? (
                                                                                                                <Typography variant="caption" className="pt-2"><Attachment /><a href={item.File.Path}>{item.File.Name}</a></Typography>) : (null)}
                                                                                                        </div>
                                                                                                        {/* <div className="col-12 col-sm-3 text-right">
                                                                                                            <Button onClick={(e) => { item.showDetailsDialog = true; this.setState({ Quotes: Quotes }); }}>View Details</Button>
                                                                                                        </div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* {!item.showDetailsDialog ? (null) : (<React.Fragment>
                                                                                                    <Dialog fullScreen={true} open={item.showDetailsDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                                                                        <DialogTitle id={"detailsDialog"} className="modalTitle">{'Quote Details'}
                                                                                                            <IconButton aria-label="close" onClick={(e) => { item.showDetailsDialog = false; this.setState({ Quotes: Quotes }); }} className="btnCloseDialog">
                                                                                                                <CloseIcon />
                                                                                                            </IconButton>
                                                                                                        </DialogTitle>
                                                                                                        <DialogContent dividers={true}>
                                                                                                            <div className="profileContainer">
                                                                                                                <div className="formContainer">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-5">
                                                                                                                            <div className="tabPanel">
                                                                                                                                <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>{item.ProjectName}</Typography>
                                                                                                                                <div className="mt-2">
                                                                                                                                    <Chip variant="outlined" label={"Submitted" + days + " day(s) ago"} /> <Chip className="ml-1" variant="outlined" label={item.QuotesRequested > 0 ? (item.QuotesSubmitted + " out of " + item.QuotesRequested + " quotes submitted") : (item.QuotesSubmitted + " quotes submitted")} />
                                                                                                                                </div>
                                                                                                                                <Typography variant="subtitle2" color="text.secondary" className="pt-2"><LocationOnIcon /> {item.CityName}, {item.StateName} {item.Pin}</Typography>
                                                                                                                                <Typography variant="subtitle2" color="text.secondary" className="pt-2">{item.ProjectDescription}</Typography>
                                                                                                                                <div className="mt-2">
                                                                                                                                    <hr></hr>
                                                                                                                                </div>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-1 col-sm-1">
                                                                                                                                        <div className="form-group">
                                                                                                                                            <Avatar alt={item.CustomerName} src={item.ProfileImage ? item.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 50, height: 50 }}></Avatar>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-11 col-sm-11">
                                                                                                                                        <div className="form-group">
                                                                                                                                            <Typography variant="caption" sx={{ fontWeight: 600 }}>{item.CustomerName}</Typography>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="mt-1"><Typography variant="subtitle2" className="commentBody"><PhoneIcon /> {this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic ? (item.Phone.substring(0, 3) + '*******') : (item.Phone)}</Typography></div>
                                                                                                                                <div className="mt-1"><Typography variant="subtitle2" className="commentBody"><EmailIcon /> {this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic ? (item.CustomerEmail.substring(0, 1) + '*******' + item.CustomerEmail.substring(item.CustomerEmail.indexOf('@') - 1, 3) + "***.com") : (item.CustomerEmail)}</Typography></div>
                                                                                                                            </div>
                                                                                                                            <div className="formButtonContainer text-right mt-3">
                                                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin" disabled={this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic} onClick={(e) => { if (this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) { this.contactUser(item.CustomerEmail); } }}>Contact {item.CustomerName.split(' ')[0]}</button>
                                                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic || item.HasSubmittedQuote} onClick={(e) => { if (this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) { this.submitQuote(item); } }}>Submit Quote</button>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-12 col-sm-7">
                                                                                                                            <div className="accountSettingContainer p-3">
                                                                                                                                <h5>Project Details</h5>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-12 col-sm-12">
                                                                                                                                        <div className="form-group">
                                                                                                                                            {item.FAQs && item.FAQs.map((item, indx) => {
                                                                                                                                                return (<div className={indx > 0 ? "mt-3" : ""}>
                                                                                                                                                    <label>{item.Name}</label>
                                                                                                                                                    <Typography variant="subtitle2" color="text.secondary">{item.Description}</Typography>
                                                                                                                                                </div>);
                                                                                                                                            })}
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </DialogContent>
                                                                                                    </Dialog>
                                                                                                </React.Fragment>)} */}
                                                                                            </div>);
                                                                                    })}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </React.Fragment>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    {!showDetailsDialog ? (null) : (<React.Fragment>
                                                        <Dialog fullWidth={true} open={showDetailsDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                            <DialogTitle id={"detailsDialog"} className="modalTitle">Quote Details
                                                                <IconButton aria-label="close" onClick={(e) => { this.resetQuoteDetails(); }} className="btnCloseDialog" disabled={showDialogProgress} >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </DialogTitle>
                                                            <DialogContent dividers={true}>
                                                                <div className="profileContainer">
                                                                    <div className="formContainer">
                                                                        <div className="col-12 col-sm-12 p-0">
                                                                            <div className="minHeightDialogContainer">
                                                                                {showDialogProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                    {ActiveTabInner === 0 ? (<React.Fragment>
                                                                                        <div className="form-group">
                                                                                            <label>What is your quote price?</label>
                                                                                            {DialogMode === 'V' ? (<Typography variant="subtitle2" color="text.secondary">{QuotePrice}</Typography>) : (
                                                                                                <React.Fragment>
                                                                                                    <input id="budget" name="budget" key="budget" type="text" className="form-control dollarIconMap" placeholder="" defaultValue={QuotePrice} onBlur={(e) => { this.setState({ QuotePrice: e.target.value }); }} />
                                                                                                    <Validator id="valBudget" type='required' cssClass="error" errorMessage="Provide quote price." controlId="budget" valueToValidate={ActiveTabInner === 0 ? QuotePrice : '1'} formValidators={this.formValidators}></Validator>
                                                                                                    <Validator id="valBudgetType" type='number' cssClass="error" errorMessage="Quote price can be numeric only." controlId="budget" valueToValidate={ActiveTabInner === 0 ? QuotePrice : '1'} formValidators={this.formValidators}></Validator>
                                                                                                </React.Fragment>)}
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label>What type of quote is this?</label>
                                                                                            <select id="type" name="type" className="form-control commentBody" defaultValue={QuoteType}
                                                                                                onChange={(e) => { this.setState({ QuoteType: e.target.value, QuoteTypeName: e.target.selectedOptions[0].text }); }}>
                                                                                                <option value="">Select</option>
                                                                                                <option value="1">Fixed Price</option>
                                                                                                <option value="2">Hourly Rate</option>
                                                                                            </select>
                                                                                            <Validator id="valQuoteType" type='required' cssClass="error" errorMessage="Select a quote type." controlId="category" valueToValidate={ActiveTabInner === 0 ? QuoteType : '1'} formValidators={this.formValidators}></Validator>
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label>Message</label>
                                                                                            {DialogMode === 'V' ? (<Typography variant="subtitle2" color="text.secondary">{QuoteMessage}</Typography>) : (
                                                                                                <React.Fragment>
                                                                                                    <textarea id="projDesc" name="projDesc" key="projDesc" rows={4} className="form-control" placeholder="" defaultValue={QuoteMessage} onBlur={(e) => { this.setState({ QuoteMessage: e.target.value }); }} />
                                                                                                    <Validator id="valprojDesc" type='required' cssClass="error" errorMessage="Provide message." controlId="projDesc" valueToValidate={ActiveTabInner === 0 ? QuoteMessage : "1"} formValidators={this.formValidators}></Validator>
                                                                                                    <Validator id="valprojDescLength" type='max_length' cssClass="error" errorMessage="Message cannot exceed 500 characters." maxLength={500} controlId="projDesc" valueToValidate={ActiveTabInner === 0 ? QuoteMessage : "1"} formValidators={this.formValidators}></Validator>
                                                                                                </React.Fragment>)}
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label>&nbsp;</label>
                                                                                            <div>
                                                                                                <input id="fLicense" name="fLicense" type="file" key={'licFU'} disabled={FileName !== null && FileName !== ''} className="inputfile" multiple={false} onChange={(e) => { if (e.target.files && e.target.files.length > 0) { let file: any = e.target.files[0]; this.setState({ File: file, FileName: file.name }); e.target.value = ''; } }} />
                                                                                                <label htmlFor="fLicense">
                                                                                                    <span aria-disabled={FileName !== null && FileName !== ''} className="btn btn-primary cutmBtnLogin">Attach file</span>
                                                                                                </label>
                                                                                                <div>
                                                                                                    <Validator id="valfLicenseFileNameLength" type='max_length' maxLength={50} cssClass="error" errorMessage="File name (including extension) cannot exceed 50 characters." controlId="fLicense" valueToValidate={FileName} formValidators={this.formValidators}></Validator>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {FileName ? (
                                                                                            <div className="form-group mt-1">
                                                                                                <DeleteIcon onClick={(e) => { this.setState({ File: null, FileName: '' }); }} /> {FileName}
                                                                                            </div>) : (null)}
                                                                                    </React.Fragment>) : (null)}
                                                                                    {QuoteFAQs && QuoteFAQs.map((item, indx) => {
                                                                                        return (<React.Fragment>
                                                                                            {ActiveTabInner === (indx + 1) ? (<React.Fragment>
                                                                                                <div className="form-group">
                                                                                                    <label>{item.Name}</label>
                                                                                                    {DialogMode === 'V' ? (<Typography variant="subtitle2" color="text.secondary">{item.Description}</Typography>) : (
                                                                                                        <React.Fragment>
                                                                                                            {this.getAnswerType(item, indx, QuoteFAQs)}
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                </div>
                                                                                            </React.Fragment>) : (null)}
                                                                                        </React.Fragment>);
                                                                                    })}
                                                                                </React.Fragment>)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-6 col-sm-6">
                                                                            <div className="form-group">
                                                                                <div className="formButtonContainer mt-3">
                                                                                    <button type="submit" className="btn btn-cancel" disabled={showDialogProgress} onClick={(e) => { this.resetQuoteDetails(); }}>Cancel</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 col-sm-6">
                                                                            <div className="form-group text-right">
                                                                                <div className="formButtonContainer mt-3">
                                                                                    {ActiveTabInner > 0 ? (<button type="submit" className="btn btn-cancel mr-3" disabled={showDialogProgress} onClick={(e) => { this.handleBackClick(ActiveTabInner); }}>Back</button>) : (null)}
                                                                                    {ActiveTabInner < (QuoteFAQs && QuoteFAQs.length > 0 ? (QuoteFAQs.length) : (0)) ?
                                                                                        (<button type="submit" className="btn btn-primary cutmBtnLogin" disabled={showDialogProgress} onClick={(e) => { this.handleContinueClick(ActiveTabInner); }}>Continue</button>) :
                                                                                        (<button type="submit" className="btn btn-primary cutmBtnLogin" disabled={showDialogProgress} onClick={(e) => { this.handleQuoteSaveClick(); }}>Submit Quote</button>)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </React.Fragment>)}
                                                </div>
                                            </div>
                                        </React.Fragment>)}
                                    </div >
                                </div >
                            </div >
                        </div >
                    </section >
                    <Footer />
                </React.Fragment >);
            }
        }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>ProsBE Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null }) }}>No</Button>
                    <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}