import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import Constants from "src/common/Constants";
import { Loader } from "src/common/Components";
import Stripes from "src/api/stripe";

//const stripe = require('stripe')(Constants.Stripe.SecretKey);
const stripePromise = loadStripe(Constants.Stripe.PublishKey);

export default class Subscribe extends Base {

    private _stripe: Stripes;

    constructor(props) {
        super(props);

        this.state = {
            loading: true, Subscription: null, InvalidParameters: false, InvalidCustomer: false, PaymentIntent: null, paymentIntentCS: '', ProfileId: '', FirstName: '', LastName: '', Email: ''
        };

        this._stripe = new Stripes();
    }

    public async componentDidMount() {
        if (this.props.clientSecret)
            this.loadComponent();
        else {
            this.setState({ loading: false, InvalidParameters: true });
        }
    }

    private loadComponent() {
        this.getUserProfile().then((profile) => {
            if (profile) {
                this.setState({
                    AccountProfile: profile,
                    ProfileId: profile.Id, FirstName: profile.User ? profile.User.FirstName : '', LastName: profile.User ? profile.User.LastName : '', Email: profile.User ? profile.User.Email : ''
                }, () => {
                    this._stripe.getPaymentIntent(this.props.clientSecret).then((paymentIntent) => {
                        if (paymentIntent) {
                            this.setState({
                                loading: false, AccountProfile: profile, PaymentIntent: paymentIntent, paymentIntentCS: paymentIntent.client_secret,
                                ProfileId: profile.Id, FirstName: profile.User ? profile.User.FirstName : '', LastName: profile.User ? profile.User.LastName : '', Email: profile.User ? profile.User.Email : '',
                            });
                        }
                        else {
                            this.setState({
                                loading: false, AccountProfile: profile,
                                ProfileId: profile.Id, FirstName: profile.User ? profile.User.FirstName : '', LastName: profile.User ? profile.User.LastName : '', Email: profile.User ? profile.User.Email : '', InvalidCustomer: true
                            });
                        }
                    });
                });
            }
            else {
                this.setState({ loading: false });
            }
        });
    }

    private getUserProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.props.id && this.props.id > 0) {
                this.db.getUserProfileById(this.props.id, Constants.UserRoles.Pro).then((profile) => {
                    resolve(profile);
                });
            }
            else if (this.LoginName) {
                this.db.getUserProfile(this.LoginName, this.AppRole).then((profile) => {
                    resolve(profile);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private async handleStripePay(elements) {
        const { PaymentIntent, AccountProfile } = this.state;

        if (PaymentIntent) {
            let cardElement = elements.getElement(CardElement);
            if (cardElement) {
                try {
                    let _strip = await stripePromise;

                    let paymentMethod = await _strip?.createPaymentMethod({ type: 'card', card: cardElement, });
                    if (paymentMethod) {
                        console.log(paymentMethod);
                        let pIConfirmation = await _strip?.confirmCardPayment(this.state.paymentIntentCS, { setup_future_usage: "off_session", receipt_email: this.User.Email, save_payment_method: true, payment_method: paymentMethod?.paymentMethod?.id, return_url: "https://prosbe.com/account" });
                        console.log(pIConfirmation);
                        if (pIConfirmation && !pIConfirmation.error && pIConfirmation.paymentIntent.status === "succeeded") {
                            let subscription = await this._stripe.createSubscription(PaymentIntent?.customer, Constants.Stripe.PriceID, 1);
                            if (subscription) {
                                console.log(subscription);
                                window.location.href = '/account?id=' + AccountProfile.Id;
                            }
                            else {

                            }
                        }
                        else {
                        }
                    }
                }
                catch (er) {
                    console.log(er);
                }
            }
            else {
                console.log("Card element is not available");
            }
        }
        else {
            console.log("Invalid payment attempt.");
        }
    }

    private resetPaymentForm() {

    }

    public render() {
        const { loading, InvalidParameters, InvalidCustomer, paymentIntentCS } = this.state;
        const options = { clientSecret: paymentIntentCS };

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            if (InvalidParameters) {

            }
            else if (InvalidCustomer) {

            }
            else {
                template = (<React.Fragment>
                    <Header />
                    <section className="containerMinHeight accountSettingContainer borderBtm">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    {this.props.id ? (<div className="row">
                                        <div className="col-12 col-sm-12">
                                            <div className="breadCrumb mb-1">
                                                <a href="javascript:void(0);" onClick={(e) => { window.location.href = '/account'; }}>Account</a><span className="ml-2">&gt;</span><span className="ml-2">Subscription</span>
                                            </div>
                                        </div>
                                    </div>) : (null)}
                                    <h5>ProsBE Premium Subscription</h5>
                                    <div className="row">
                                        <div className="col-12 col-sm-12">
                                            <Elements stripe={stripePromise} options={options} >
                                                <ElementsConsumer>
                                                    {({ stripe, elements }) => (<>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <CardElement />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="formButtonContainer text-right">
                                                                    <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.resetPaymentForm(); }}>Cancel</button>
                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handleStripePay(elements); }}>Pay</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>)}
                                                </ElementsConsumer>
                                            </Elements>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </React.Fragment >);
            }
        }

        return template;
    }
}