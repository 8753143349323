import { Alert, Snackbar } from "@mui/material";
import React from "react";
import DBHelper from "src/api/service";
import Base from "src/common/Base";
import { Loader } from "src/common/Components";
import Constants from "src/common/Constants";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import Validator from "src/common/Validator";

export default class ResetPassword extends Base {

    private formValidators: Validator[];
    private validator: Validator;

    constructor(props) {
        super(props);

        this.state = { loading: true, Email: '', ShowMessage: false, Message: '', MessageType: 'success' };
        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        this.setState({ loading: false });
    }

    private generatePassword() {
        //var buf = new Uint16Array(6);
        return this.encodeString(window.crypto.randomUUID());//.getRandomValues(buf);
        //return String.fromCharCode.apply(null, buf);
    }

    private sendEmail(nPass, user): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.ResetPassword;
            let subject = emailTemplate.Subject;
            let body = emailTemplate.Body;

            body = body.replace("[username]", user.DisplayName);
            body = body.replace("[password]", nPass);

            if (this.state.Email) {
                this.db.sendEmail(user.Email, subject, body).then((sent) => {
                    if (sent) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(false);
            }
        });
    }

    private handleReset() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ loading: true });

            let db = new DBHelper();
            db.getUser(this.state.Email, '').then((user) => {
                if (!user) {
                    this.errorMessage('This username is not registered with a valid user account.');
                }
                else {
                    let nPass = this.generatePassword();
                    db.resetPassword(this.state.Email, this.encodeString(nPass)).then((result: any) => {
                        if (!result) {
                            this.setState({ loading: false });
                            this.errorMessage('Password could not be reset. Please try again.');
                        }
                        else {
                            this.sendEmail(nPass, user).then((sent) => {
                                if (sent) {
                                    this.successMessage('An email with new password has been sent at ' + user.Email + '. Please retry login using the new password.');
                                    this.setState({ loading: false, Email: '' });
                                }
                                else {
                                    this.errorMessage('Your password has been reset but email could not be sent. Please try again later.');
                                    this.setState({ loading: false, Email: '' });
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    private handleKeyDown(e, src) {
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();

            if (src === 'E')
                this.setState({ Email: e.target.value }, () => { this.handleReset() });
        }
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {

        const { loading, Email, ShowMessage, Message, MessageType } = this.state;
        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            template = (<React.Fragment>
                <Header />
                <section className="containerMinHeight loginContainer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="formContainer">
                                    <h3>Reset Password</h3>
                                    <div className="form-group">
                                        <label htmlFor="email">User name</label>
                                        <input id="email" name="email" type="email" className="form-control" placeholder="" defaultValue={Email} onBlur={(e) => { this.setState({ Email: e.target.value }); }} onKeyDown={(e) => { this.handleKeyDown(e, 'E'); }} />
                                        <Validator id="valEmail" type='required' cssClass="error" errorMessage="Provide username." controlId="email" valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                        <Validator id="valEmailFormat" type='email' cssClass="error" controlId="email" errorMessage="Invalid username." valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                    </div>
                                    <div className="formButtonContainer">
                                        <button type="submit" className="btn btn-primary cutmBtnLogin w-100" onClick={(e) => { this.handleReset(); }}>Reset</button>
                                    </div>
                                    <div className="signUpLink pb-3">Already have an account? <a href="/login">Log in</a>.</div>
                                    <div className="signUpLink">Don’t have an account? <a href="/users/new?m=cust">Sign up</a>.</div>
                                </div>
                                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                                    <Alert severity={MessageType} sx={{ width: '100%' }}>
                                        {Message}
                                    </Alert>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>);
        }

        return template;
    }
}