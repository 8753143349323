import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";

export default class Pricing extends Base {
    constructor(props) {
        super(props);

    }

    public componentDidMount() {

    }

    public render() {

        let template = (<React.Fragment>
            <Header />
            <section className="containerMinHeight borderBtm staticContent">
                <div className="becomeLayer5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12 p-5">
                                <h2><b>PRICING</b></h2>
                                <div className="mt-3">
                                    <span>We offer flexible pricing plans designed to suit the needs and goals of service providers like you. Whether you're an experienced professional or just starting your business, our pricing options are designed to help you grow your clientele and showcase your expertise. Here are our available subscription plans:</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 p-5">
                                <h4>1. Free Basic Membership - Get Started</h4>
                                <div>
                                    <ul>
                                        <li className="mt-3">
                                            <b>Sign Up:</b> Our basic membership for service providers is completely free. Sign up and create your profile to get started on our platform.
                                        </li>
                                        <li className="mt-3">
                                            <b>Profile Creation:</b> As a free member, you can create a comprehensive profile, highlighting your skills, experience, and areas of expertise.
                                        </li>
                                        <li className="mt-3">
                                            <b>Unlimited Leads:</b> With the basic membership, you can receive unlimited number of lead opportunities and quote requests from potential customers per month.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 p-5">
                                <h4>2. Premium Membership - Unlock More Opportunities</h4>
                                <div className="mt-3">
                                    <span>For service providers looking to expand their reach and increase their chances of securing new clients, we offer a premium membership. Upgrade to our premium plan to enjoy the following benefits::</span>
                                </div>
                                <div>
                                    <ul>
                                        <li className="mt-3">
                                            <b>Unlimited Messaging:</b> Send and receive unlimited messages to communicate and discuss with your potential customers.
                                        </li>
                                        <li className="mt-3">
                                            <b>Response to Quote Requests:</b> As a premium member, you will respond to all quote requests from potential customers and maximize your business opportunities with no limits.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 text-center p-5">
                                <h3>"Unlock Your Business Potential - Expand, Connect, Succeed."</h3>
                                <div className="mt-3">
                                    <button type="submit" className="signUp" onClick={(e) => { window.location.href = 'https://prosbe.com/users/new?m=pro'; }}>Sign Up as a Pro</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>);

        return template;
    }
}