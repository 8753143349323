import { Dialog, DialogTitle, IconButton, DialogContent } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import Constants from "src/common/Constants";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import CloseIcon from '@mui/icons-material/Close';

export default class BecomeAPro extends Base {

    constructor(props) {
        super(props);
        this.state = { showPopUp: true };
    }

    public componentDidMount() {

    }

    private handleSwitchRoleClick() {
        this.db.getUser(this.LoginName, Constants.UserRoles.Pro).then((user) => {
            if (user)
                sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(JSON.stringify(user)));

            sessionStorage.setItem(this.encodeString("ProsBEUser_AppRole"), this.encodeString(Constants.UserRoles.Pro));
            window.location.href = '/';
        });
    }

    private resetPopUp() {
        this.setState({ showPopUp: false });
    }

    public render() {
        const { showPopUp } = this.state;

        let template = (<React.Fragment>
            <Header />
            <section className="containerMinHeight borderBtm">
                <div className="becomeLayer11 text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6 col-sm-7">
                                <div className="layerOne">
                                    Grow your business with ProsBe
                                </div>
                                <div className="layerTwo mt-2">
                                    Get <u>unlimited leads</u> with ProsBe.<br />
                                    Do not pay per lead.
                                </div>
                                <div className="mt-3">
                                    {this.IsUserValid && this.User ? (<React.Fragment>
                                        {this.User.DefaultRole !== Constants.UserRoles.Pro && this.User.Role2 != Constants.UserRoles.Pro ?
                                            (<button type="submit" className="signUp" onClick={(e) => { window.location.href = '/users/new?m=pro'; }}>Sign Up as a Pro</button>) :
                                            (<button type="submit" className="signUp" onClick={(e) => { this.handleSwitchRoleClick(); }}>Switch to Pro role</button>)
                                        }
                                    </React.Fragment>) : (<button type="submit" className="signUp" onClick={(e) => { window.location.href = '/users/new?m=pro'; }}>Sign Up as a Pro</button>)}
                                </div>
                            </div>
                            <div className="col-6 col-sm-5">
                                <div className="become_growth">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="becomeLayer2 text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <div className="layerOne">
                                    HOW IT WORKS
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 mt-4">
                                <div className="layerTwo">
                                    <div className="row">
                                        <div className="col-12 col-sm-4">
                                            <span className="counter">1. </span><span>Create your business profile</span>
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <span className="counter">2. </span><span>Get quality leads and submit quotes</span>
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <span className="counter">3. </span><span>Get jobs and deliver great works</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="becomeLayer3 text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <div className="layerOne">
                                    PRICING
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 mt-4">
                                <div className="layerTwo">
                                    <span>SIGN UP is Free. Get unlimited leads and do not pay per lead.</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12">
                                <div className="layerTwo">
                                    <span>Submit quotes and communicate with customers for less than 50 cents a day.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="becomeLayer4 text-center">
                    <div className="becomeLayer4Container">
                        <div className="row">
                            <div className="col-12 col-sm-6 nb-3">
                                <div className="layerOne">
                                    <span>Basic</span>
                                </div>
                                <div className="layerTwo">
                                    <span>FREE</span>
                                </div>
                                <div className="layerThree">
                                    <span>*Your Business Profile</span>
                                </div>
                                <div className="layerThree">
                                    <span>*Unlimited Leads</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="layerOne">
                                    <span>Premium</span>
                                </div>
                                <div className="layerTwo">
                                    <span>$14.99/mo</span>
                                </div>
                                <div className="layerThree">
                                    <span>*All basic features</span>
                                </div>
                                <div className="layerThree">
                                    <span>*You submit your quote</span>
                                </div>
                                <div className="layerThree">
                                    <span>*You receive and reply messages from customers</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="becomeLayer5 text-center">
                    <div className="row">
                        <div className="col-6 col-sm-6">
                            <div className="layerOne">
                                Customers are looking for Pros like you.
                            </div>
                            <div className="mt-3">
                                {this.IsUserValid && this.User ? (<React.Fragment>
                                    {this.User.DefaultRole !== Constants.UserRoles.Pro && this.User.Role2 != Constants.UserRoles.Pro ?
                                        (<button type="submit" className="signUp" onClick={(e) => { window.location.href = '/users/new?m=pro'; }}>Sign Up as a Pro</button>) :
                                        (<button type="submit" className="signUp" onClick={(e) => { this.handleSwitchRoleClick(); }}>Switch to Pro role</button>)
                                    }
                                </React.Fragment>) : (<button type="submit" className="signUp" onClick={(e) => { window.location.href = '/users/new?m=pro'; }}>Sign Up as a Pro</button>)}
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 p-5 text-left">
                            <div className="become_layer4">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {!showPopUp ? (null) : (
                <React.Fragment>
                    <Dialog fullWidth={true} open={showPopUp} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} >
                        <DialogTitle id={"detailsDialog"} className="modalTitle">
                            <IconButton aria-label="close" onClick={(e) => { this.resetPopUp(); }} className="btnCloseDialog p-0 m-0" >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <div className="profileContainer">
                                <div className="formContainer">
                                    <div className="col-12 col-sm-12 p-0">
                                        <div className="minHeightDialogContainer">
                                            <div className="becomeLayer2 text-center">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <div className="layerOne">
                                                                Limited Time Offer: Unlock Premium Membership Benefits for FREE!
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 mt-4">
                                                            <div className="layerTwo">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12">
                                                                        <span>Big news! For a limited time, Basic members can access all the perks of Premium membership - no upgrade required! Experience the full power of Premium Membership without spending a dime.</span>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 mt-4">
                                                                        <span><a href="/users/new?m=pro">Join now</a> and seize the opportunity!</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </React.Fragment>
            )}
            <Footer />
        </React.Fragment>);

        return template;
    }
}