import Constants from 'src/common/Constants';
import Axios, { AxiosRequestConfig } from 'axios';

export enum ZipCodeAPIUnits {
    Kilometers = 'km',
    Miles = 'mile'
}

export default class ZipCodeAPI {
    private restConfig: AxiosRequestConfig = {};

    private servicePath = "https://www.zipcodeapi.com/rest/" + Constants.ZipCodeAPI.JSKey;

    public getZipCodesInRadius(centralZipCode, radius, distanceUnit): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/radius.json/" + centralZipCode + "/" + radius + "/" + distanceUnit, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getMultipleZipCodeLocations(zipCodes): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/multi-info.json/" + zipCodes + "/degrees", this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }
}