import { Alert, Avatar, Badge, Divider, IconButton, ListItemIcon, Menu, MenuItem, Snackbar } from "@mui/material";
import React from "react";
import Base from "./Base";
import Constants from "./Constants";
import { Logout, Settings } from "@mui/icons-material";
import { gapi } from 'gapi-script';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const initClient = () => { gapi.client.init({ clientId: Constants.Google.ClientID, scope: 'profile email' }); }

export default class Header extends Base {

    constructor(props) {
        super(props);

        this.state = { loading: true, Message: '', ShowMessage: false, MessageType: 'error', User: null, NewNotifications: 0 };
        this.handleSignOut = this.handleSignOut.bind(this);
        this.onGoogleLogoutFailure = this.onGoogleLogoutFailure.bind(this);

        gapi.load('client:auth2', initClient);
    }

    public componentDidMount() {
        if (this.IsUserValid) {
            this.db.getUser(this.LoginName, this.AppRole).then((user) => {
                if (user && user.Profile) {
                    this.db.getNewMessageCount(user.Profile.Id).then((counter) => {
                        this.setState({ loading: false, User: user, NewNotifications: counter });
                    });
                }
                else {
                    this.setState({ loading: false });
                }
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    private handleSignOut() {
        if (this.User.Source === "GOOGLE") {
            var authInstance = gapi.auth2.getAuthInstance();
            if (authInstance) {
                authInstance.signOut().then(() => {
                    this.logOut();
                });
            }
        }
        else {
            this.logOut();
        }
    }

    private logOut() {
        let token = localStorage.getItem(this.encodeString("ProsBEUser_Token"));
        if (!token) {
            token = sessionStorage.getItem(this.encodeString("ProsBEUser_Token"));
        }

        if (token) {
            this.db.signOutUser(token).then((result) => {
                if (result) {
                    localStorage.removeItem(this.encodeString("ProsBE_User"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_Token"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_ExpiresOn"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_Role"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_AppRole"));
                    localStorage.removeItem(this.encodeString("ProsBEUser_LoginName"));

                    localStorage.removeItem(this.encodeString("ProsBE_GoogleUser"));

                    sessionStorage.removeItem(this.encodeString("ProsBE_User"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_Token"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_ExpiresOn"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_Role"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_AppRole"));
                    sessionStorage.removeItem(this.encodeString("ProsBEUser_LoginName"));

                    sessionStorage.removeItem(this.encodeString("ProsBE_GoogleUser"));

                    this.User = null;
                    this.UserRole = '';
                    this.AppRole = '';
                    this.IsUserValid = false;
                }
                else {
                    this.errorMessage('Some error occurred while signing you out. Please close the browser to sign out of the application.');
                }

                window.location.href = '/';
            });
        }
        else {
            this.errorMessage('Some error occurred while signing you out. Please close the browser to sign out of the application.');
        }
    }

    private onGoogleLogoutFailure() {
        this.errorMessage('Some error occurred while signing you out. Please close the browser to sign out of the application.');
    }

    private handleSwitchRoleClick() {
        this.db.getUser(this.LoginName, (this.AppRole === Constants.UserRoles.Pro ? Constants.UserRoles.Customer : Constants.UserRoles.Pro)).then((user) => {
            if (user)
                sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(JSON.stringify(user)));

            sessionStorage.setItem(this.encodeString("ProsBEUser_AppRole"), this.encodeString(this.AppRole === Constants.UserRoles.Pro ? Constants.UserRoles.Customer : Constants.UserRoles.Pro));
            window.location.href = '/';
        });
    }

    private isActive(url) {
        if (window.location.pathname.toLowerCase().endsWith(url.toLowerCase()))
            return true;
        else
            return false;
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    // private sendEmail() {
    //     let iParams = [{ Key: "emails", Value: "hariom.ssharmah@gmail.com" }, { Key: "pId", Value: "1" }];

    //     this.db.sendEmail('hariom.ssharmah@gmail.com', 'Email from app', 'This email has been sent from the app<br />test');
    //     this.db.sendEmailType('AskForReview', JSON.stringify(iParams));
    //     //this.db.askForReviews('hariom.ssharmah@gmail.com', 1);
    // }

    // private successMessage(message: string) {
    //     this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    // }

    public render() {
        const { loading, Message, ShowMessage, MessageType, showMenu, anchorElement, User, NewNotifications } = this.state;
        let template: any = null;
        let showSwitch = false;

        if (this.IsUserValid && this.User) {
            if ((this.User.DefaultRole === Constants.UserRoles.Pro && this.User.Role2 === Constants.UserRoles.Customer) || (this.User.DefaultRole === Constants.UserRoles.Customer && this.User.Role2 === Constants.UserRoles.Pro)) {
                showSwitch = true;
            }
        }

        template = (<section className="masterHead">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3 col-sm-3 col-md-3 pt-2">
                        <div className="logoContainer">
                            <a href='/' title='ProsBE'><img src="/images/logo.png" alt="" className="img-fluid" /></a>
                        </div>
                    </div>
                    <div className="col-9 col-sm-9 col-md-9">
                        <div className="navigationContainer">
                            {!loading ? (
                                <ul>
                                    {/* <li><a href="#" onClick={(e) => { this.sendEmail(); }} className="login">Email</a></li> */}
                                    {!showSwitch ? (<li><a href={this.AppRole === Constants.UserRoles.Pro ? "/users/new?m=cust" : (this.isActive('/becomepro') ? "/users/new?m=pro" : "/becomepro")} className="signUp">{this.isActive('/becomepro') ? "Sign Up as a " : "Become a "}{this.AppRole === Constants.UserRoles.Pro ? "Customer" : "Pro"}</a></li>) : (null)}
                                    {!this.IsUserValid ? (<React.Fragment><li><a href="/login" className="login">Login</a></li>{this.isActive('/becomepro') ? (null) : (<li><a href="/users/new?m=cust" className="login">Sign up</a></li>)}</React.Fragment>) : (<React.Fragment>
                                        {this.AppRole === Constants.UserRoles.Pro ? (<React.Fragment>
                                            {this.isActive('/leads') || this.isActive('/lead') || this.isActive('/opportunities') || this.isActive('/opportunity') ? (<li className="liHeaderActive"><span className="login">Leads</span></li>) : (<li><a href="/leads" className="login">Leads</a></li>)}
                                            {this.isActive('/services') ? (<li className="liHeaderActive"><span className="login">My Services</span></li>) : (<li><a href="/services" className="login">My Services</a></li>)}
                                            {this.isActive('/profile') ? (<li className="liHeaderActive"><span className="login">Profile</span></li>) : (<li><a href="/profile" className="login">Profile</a></li>)}
                                            {this.isActive('/inbox') ? (<li className="liHeaderActive"><span className="login">{NewNotifications > 0 ? (<Badge badgeContent={NewNotifications} color="error">Inbox</Badge>) : ("Inbox")}</span></li>) : (<li><a href="/inbox" className="login">{NewNotifications > 0 ? (<Badge badgeContent={NewNotifications} color="error">Inbox</Badge>) : ("Inbox")}</a></li>)}
                                            <IconButton onClick={(e) => { this.setState({ showMenu: !showMenu, anchorElement: e.currentTarget }); }} size="small" sx={{ ml: 2 }} aria-controls={showMenu ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={showMenu ? 'true' : undefined}>
                                                <Avatar sx={{ width: 50, height: 50 }} src={User && User.Profile && User.Profile.ProfileImage ? User.Profile.ProfileImage : '/images/profile.png'} alt={User ? User.Profile.BusinessName : ''} />
                                            </IconButton>
                                            <Menu anchorEl={anchorElement} id="account-menu" open={showMenu} onClose={(e) => { this.setState({ showMenu: false, anchorElement: null }); }} onClick={(e) => { this.setState({ showMenu: false, anchorElement: null }); }} PaperProps={{
                                                elevation: 0, sx: {
                                                    overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 40, height: 40, ml: -0.5, mr: 1, },
                                                    '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0, },
                                                },
                                            }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                                                {showSwitch ? (<MenuItem onClick={(e) => { this.handleSwitchRoleClick(); }}>
                                                    <ListItemIcon><SwapVertIcon fontSize="small" /></ListItemIcon> Switch to Customer
                                                </MenuItem>) : (null)}
                                                <Divider />
                                                <MenuItem onClick={(e) => { window.location.href = '/account'; }}>
                                                    <ListItemIcon><Settings fontSize="small" /></ListItemIcon> Settings
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem onClick={(e) => { this.handleSignOut(); }}>
                                                    <ListItemIcon><Logout fontSize="small" /></ListItemIcon> Logout
                                                </MenuItem>
                                            </Menu>
                                        </React.Fragment>) : (<React.Fragment>
                                            {this.AppRole === Constants.UserRoles.Customer ? (<React.Fragment>
                                                {this.isActive('/projects') ? (<li className="liHeaderActive"><span className="login">My Projects</span></li>) : (<li><a href="/projects" className="login">My Projects</a></li>)}
                                                {this.isActive('/inbox') ? (<li className="liHeaderActive"><span className="login">{NewNotifications > 0 ? (<Badge badgeContent={NewNotifications} color="error">Inbox</Badge>) : ("Inbox")}</span></li>) : (<li><a href="/inbox" className="login">{NewNotifications > 0 ? (<Badge badgeContent={NewNotifications} color="error">Inbox</Badge>) : ("Inbox")}</a></li>)}
                                                <IconButton onClick={(e) => { this.setState({ showMenu: !showMenu, anchorElement: e.currentTarget }); }} size="small" sx={{ ml: 2 }} aria-controls={showMenu ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={showMenu ? 'true' : undefined}>
                                                    <Avatar sx={{ width: 50, height: 50 }} src={User && User.Profile && User.Profile.ProfileImage ? User.Profile.ProfileImage : '/images/profile.png'} alt={User ? User.DisplayName : ''} />
                                                </IconButton>
                                                <Menu anchorEl={anchorElement} id="account-menu" open={showMenu} onClose={(e) => { this.setState({ showMenu: false, anchorElement: null }); }} onClick={(e) => { this.setState({ showMenu: false, anchorElement: null }); }} PaperProps={{
                                                    elevation: 0, sx: {
                                                        overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 40, height: 40, ml: -0.5, mr: 1, },
                                                        '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0, },
                                                    },
                                                }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                                                    {showSwitch ? (<MenuItem onClick={(e) => { this.handleSwitchRoleClick(); }}>
                                                        <ListItemIcon><SwapVertIcon fontSize="small" /></ListItemIcon> Switch to Pro
                                                    </MenuItem>) : (null)}
                                                    <Divider />
                                                    <MenuItem onClick={(e) => { window.location.href = '/account'; }}>
                                                        <ListItemIcon><Settings fontSize="small" /></ListItemIcon> Settings
                                                    </MenuItem>
                                                    <Divider />
                                                    <MenuItem onClick={(e) => { this.handleSignOut(); }}>
                                                        <ListItemIcon><Logout fontSize="small" /></ListItemIcon> Logout
                                                    </MenuItem>
                                                </Menu>
                                            </React.Fragment>) : (<React.Fragment>
                                                {this.AppRole === Constants.UserRoles.Admin ? (<React.Fragment>
                                                    <IconButton onClick={(e) => { this.setState({ showMenu: !showMenu, anchorElement: e.currentTarget }); }} size="small" sx={{ ml: 2 }} aria-controls={showMenu ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={showMenu ? 'true' : undefined}>
                                                        <Avatar sx={{ width: 40, height: 40 }} src={User && User.Profile && User.Profile.ProfileImage ? User.Profile.ProfileImage : '/images/profile.png'} alt={User ? User.DisplayName : ''} />
                                                    </IconButton>
                                                    <Menu anchorEl={anchorElement} id="account-menu" open={showMenu} onClose={(e) => { this.setState({ showMenu: false, anchorElement: null }); }} onClick={(e) => { this.setState({ showMenu: false, anchorElement: null }); }} PaperProps={{
                                                        elevation: 0, sx: {
                                                            overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 40, height: 40, ml: -0.5, mr: 1, },
                                                            '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0, },
                                                        },
                                                    }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                                                        <MenuItem onClick={(e) => { window.location.href = '/account'; }}>
                                                            <ListItemIcon><Settings fontSize="small" /></ListItemIcon> Settings
                                                        </MenuItem>
                                                        <Divider />
                                                        <MenuItem onClick={(e) => { window.location.href = '/admin'; }}>
                                                            <ListItemIcon><Settings fontSize="small" /></ListItemIcon> Administration
                                                        </MenuItem>
                                                        <Divider />
                                                        <MenuItem onClick={(e) => { this.handleSignOut(); }}>
                                                            <ListItemIcon><Logout fontSize="small" /></ListItemIcon> Logout
                                                        </MenuItem>
                                                    </Menu>
                                                </React.Fragment>) : (null)}
                                            </React.Fragment>)}
                                        </React.Fragment>)}
                                    </React.Fragment>)}
                                </ul>) : (null)}
                        </div>
                    </div>
                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                        <Alert severity={MessageType} sx={{ width: '100%' }}>
                            {Message}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        </section >);

        return template;
    }
}