import { Alert, Avatar, Box, Button, Card, CardContent, CardHeader, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import Reviews, { IAutoCompleteOption, Loader, ProgressBar } from "src/common/Components";
import Constants from "src/common/Constants";
import { red } from "@mui/material/colors";
import CloseIcon from '@mui/icons-material/Close';
import Account from "./Account";
import Validator from "src/common/Validator";
import EditIcon from '@mui/icons-material/Edit';
import Insights from "./Insights";
import AskForReview from "./AskForReview";

export default class UserProfile extends Base {

    private validator: Validator;
    private formValidators: Validator[];

    constructor(props) {
        super(props);

        this.state = {
            loading: true, IsActive: true, ActiveTab: 0, ProfileId: 0, TabIndex: 0,
            showPasswordProgress: false, showProfileProgress: true, showNotificationProgress: false, showSubscriptionProgress: false, showBillingProgress: false, showPaymentDialog: false,
            FirstName: '', LastName: '', DisplayName: '', HighRatedParameters: [], BusinessName: '', Email: '', Phone: '', Address: '', ProfileImage: '', CityName: '', StateName: '', StateCode: '',
            Pin: '', WebSiteUrl: '', Year: '', Description: '', Role: '', OVerallRating: 0, TotalReviewsCount: 0, Accreditations: [], Payments: [], SocialMediaLinks: [],
            TwitterUrl: '', FacebookUrl: '', InstagramUrl: '', YouTubeUrl: '', LinkedInUrl: '', showDialog: false, Message: '', MessageType: '', ShowMessage: false, showConfirmation: false,
            CurrentPassword: '', NewPassword: '', ConfirmPassword: '', ActiveSubscription: 'B', Notifications: [], Billing: [], ItemId: 0, DialogMode: '', callBackMethod: null, ConfirmationType: '',
            CardNumber: '', CardExpiryMonth: '', CardExpiryMonthTitle: '', Months: [], Years: [], Countries: [], CardType: '',
            CardExpiryYear: '', CardFirstName: '', CardLastName: '', CardZipCode: '', CardBillingAddress: '', CardCountryName: '', CardCountry: '', CardCompanyName: '', IsDefaultCard: false,
            showAskForReviewDialog: false, Emails: '', Portfolio: [], PortfolioMedia: [], FeaturedProjects: [],
            ProjectId: '', ProjectFiles: [], ProjectTitle: '', ProjectDescription: '', ProjectPrice: '', ProjectService: '', ProjectServiceName: '', ProjectYear: '', showProjectDialog: false,
            Recipients: [], ReviewRecipients: [], showRequestQuoteDialog: false, HasRequestedQuote: false, RelatedProjects: [], RequestedQuotes: [], QuoteProject: null, Projects: [], showDialogConfirmation: false,
            DialogConfirmationType: '', DialogConfirmationMessage: '', CategoryId: ''
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        this.addGoogleMapScriptTag();

        this.getCountries().then((countries) => {
            let _countries: IAutoCompleteOption[] = [];
            if (countries && countries.length > 0) {
                countries.forEach((cnt) => {
                    _countries.push({ key: cnt.Name, text: cnt.Name, value: cnt.Id });
                });
            }

            let _years: IAutoCompleteOption[] = [];
            let _currentYear = new Date().getFullYear();
            for (var i = 0; i <= 5; i++) {
                let year = (_currentYear + i).toString();
                _years.push({ key: year, text: year, value: year });
            }

            this.setState({ Months: this.getMonths(), Years: _years, Countries: _countries }, () => {
                this.loadComponent();
            });
        });
    }

    private addGoogleMapScriptTag() {
        let scriptElems = document.getElementsByTagName("script");
        let elemExists = false;
        for (var i = 0; i < scriptElems.length; i++) {
            if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
                elemExists = true;
        }

        if (!elemExists) {
            const script = document.createElement("script");
            script.src = Constants.Google.PlacesScriptPath;
            //script.id = "scriptGoogleMaps";
            script.setAttribute('async', '');
            script.setAttribute('defer', '');

            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }

    private loadComponent() {
        this.setState({ loading: true });
        this.getUserProfile().then((profile) => {
            if (profile) {
                this.getSocialMediaLinks(profile.Id).then((socialMediaLinks) => {
                    this.getPayments(profile.Id).then((payments) => {
                        this.getAccreditations(profile.Id).then((accreditations) => {
                            this.getPortfolio(profile.Id).then((portfolio) => {
                                this.db.getRecipients(profile.Id, 'P').then((recipients) => {
                                    let _twitter = '';
                                    let _facebook = '';
                                    let _insta = '';
                                    let _youtube = '';
                                    let _linkedin = '';

                                    if (socialMediaLinks) {
                                        socialMediaLinks.forEach((link) => {
                                            if (link.LinkType === Constants.SocialMediaLinkType.Twitter)
                                                _twitter = link.LinkUrl;
                                            else if (link.LinkType === Constants.SocialMediaLinkType.Facebook)
                                                _facebook = link.LinkUrl;
                                            else if (link.LinkType === Constants.SocialMediaLinkType.Instagram)
                                                _insta = link.LinkUrl;
                                            else if (link.LinkType === Constants.SocialMediaLinkType.LinkedIn)
                                                _linkedin = link.LinkUrl;
                                            else if (link.LinkType === Constants.SocialMediaLinkType.YouTube)
                                                _youtube = link.LinkUrl;
                                        });
                                    }

                                    this.setState({
                                        loading: false, showProfileProgress: false,
                                        ProfileId: profile.Id, FirstName: profile.User ? profile.User.FirstName : '', LastName: profile.User ? profile.User.LastName : '',
                                        HighRatedParameters: profile.HighRatedParameters, BusinessName: profile.BusinessName, Email: profile && profile.User ? profile.User.Email : '',
                                        Phone: profile.Phone, Address: profile.Address, ProfileImage: profile.ProfileImage, CityName: profile.City ? profile.City.Name : '', Pin: profile.Pin,
                                        StateName: profile.State ? profile.State.Name : '', StateCode: profile.State ? profile.State.Code : '', IsActive: profile.IsActive, WebSiteUrl: profile.WebSiteUrl, Year: profile.YearFounded,
                                        Description: profile.Description, DisplayName: profile.User ? profile.User.DisplayName : '', ActiveSubscription: profile.ActiveSubscription,
                                        Role: (this.props.id > 0 ? (this.props.role.toLowerCase() === Constants.UserRoles.Pro.toLowerCase() ? Constants.UserRoles.Pro : this.props.role.toLowerCase() === Constants.UserRoles.Customer.toLowerCase() ? Constants.UserRoles.Customer : '') : (this.AppRole.toLowerCase() === Constants.UserRoles.Pro.toLowerCase() ? Constants.UserRoles.Pro : (this.AppRole.toLowerCase() === Constants.UserRoles.Customer.toLowerCase() ? Constants.UserRoles.Customer : ''))),
                                        OverallRating: profile.Rating, TotalReviewsCount: profile.Comments,
                                        SocialMediaLinks: socialMediaLinks, TwitterUrl: _twitter, FacebookUrl: _facebook, InstagramUrl: _insta, YouTubeUrl: _youtube, LinkedInUrl: _linkedin,
                                        Accreditations: accreditations, Payments: payments,
                                        Portfolio: portfolio, PortfolioMedia: portfolio ? portfolio.Media : [], FeaturedProjects: portfolio ? portfolio.FeaturedProjects : [],
                                        ReviewRecipients: recipients
                                    });
                                });
                            });
                        });
                    });
                });
            }
            else {
                this.setState({ loading: false, showProfileProgress: false, });
            }
        });
    }

    private getUserProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.props.id && this.props.id > 0) {
                this.db.getUserProfileById(this.props.id, this.props.role).then((profile) => {
                    resolve(profile);
                });
            }
            else if (this.LoginName) {
                this.db.getUserProfile(this.LoginName, this.AppRole).then((profile) => {
                    resolve(profile);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private getSocialMediaLinks(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            if (profileId > 0) {
                this.db.getSocialMediaLinks(profileId).then((result) => {
                    resolve(result);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private getAccreditations(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.getLicenses(profileId).then((result) => {
                if (result) {
                    result.forEach((_l) => {
                        _l.StartDate = this.getFormattedDate(new Date(this.getDateNumber(_l.StartDate)), Constants.IDateTimeFormat.yyyyMMdd, '-');
                        _l.EndDate = this.getFormattedDate(new Date(this.getDateNumber(_l.EndDate)), Constants.IDateTimeFormat.yyyyMMdd, '-');
                    });
                }

                resolve(result);
            });
        });
    }

    private getPayments(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.getPaymentModes(profileId).then((result) => {
                resolve(result);
            });
        });
    }

    private getPortfolio(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.getPortfolio(profileId).then((result) => {
                resolve(result);
            });
        });
    }

    public handleSavePassword() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showPasswordProgress: true });
            this.db.changePassword(this.User.Id, this.encodeString(this.state.NewPassword)).then((changed) => {
                if (changed) {
                    this.successMessage('Password changed sucessfully.');
                }
                else {
                    this.errorMessage('Password cannot be changed.');
                }

                this.setState({ showPasswordProgress: false });
            });
        }
    }

    // private handleAskForReviewsClick() {
    //     let isValid = this.validator.validateForm(this.formValidators);
    //     if (isValid && this.state.Recipients && this.state.Recipients.length > 0) {
    //         // let _emails: string = '';

    //         let emailTemplate = Constants.Email.AskForReviews;
    //         let subject = emailTemplate.Subject.replace('{0}', this.state.BusinessName);
    //         let body = emailTemplate.Body;

    //         let counter = 0;

    //         this.state.Recipients.forEach((cust, indx) => {
    //             body = body.replace("[username]", cust.Profile.User.FirstName);
    //             body = body.replace("[businessname]", this.state.BusinessName);
    //             body = body.replace("[rid]", this.state.ProfileId.toString());
    //             body = body.replace("[em]", this.state.Email);

    //             if (cust.Profile.User.Email) {
    //                 this.db.sendEmail(cust.Profile.User.Email, subject, body).then((emailSent) => {
    //                     counter++;
    //                     if (counter === this.state.Recipients.length) {
    //                         this.successMessage('Review request sent successfully.');
    //                         this.formValidators = new Array<Validator>();
    //                         this.setState({ showAskForReviewDialog: false, Recipients: [] });
    //                     }
    //                 });
    //             }
    //         });

    //         // this.state.Recipients.forEach((cust, indx) => {
    //         //     if (indx < this.state.Recipients.length - 1)
    //         //         _emails += cust.Profile.User.Email + ",";
    //         //     else
    //         //         _emails += cust.Profile.User.Email;
    //         // });

    //         // this.db.askForReviews(_emails, this.state.ProfileId).then((asked) => {
    //         //     if (asked) {
    //         //         this.successMessage('Review request sent successfully.');
    //         //         this.formValidators = new Array<Validator>();
    //         //         this.setState({ showAskForReviewDialog: false, Recipients: [], ReviewRecipients: [] });
    //         //     }
    //         //     else {
    //         //         this.errorMessage('Review request could not be sent.');
    //         //     }
    //         // });
    //     }
    // }

    private viewFeaturedProject(item) {
        let _pFiles = item.Media;
        let projectFiles: any[] = [];

        if (_pFiles && _pFiles.length > 0) {
            _pFiles.forEach((pFile) => {
                projectFiles.push({ id: pFile.Id, name: pFile.Name, url: pFile.LinkUrl });
            });
        }

        this.setState({
            ProjectId: item.Id, ProjectFiles: projectFiles, ProjectTitle: item.Name, ProjectDescription: item.Description, ProjectPrice: item.Price,
            ProjectService: (item.Service ? item.Service.Id.toString() : ''), ProjectServiceName: (item.Service ? item.Service.Name.toString() : ''),
            ProjectYear: item.Year.toString(), showProjectDialog: true, DialogMode: 'V'
        });
    }

    private resetProjectDialog() {
        this.setState({
            ProjectId: '', ProjectFiles: [], ProjectTitle: '', ProjectDescription: '', ProjectPrice: '', ProjectService: '', ProjectServiceName: '', ProjectYear: '', showProjectDialog: false, DialogMode: ''
        });
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    private handleMessage() {
        this.setState({ showMessageDialog: true });
    }

    private resetMessage() {
        this.setState({ showMessageDialog: false, Body: '' }, () => {
            this.formValidators = new Array<Validator>();
        });
    }

    private handleSendMessage() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.formValidators = new Array<Validator>();
            this.setState({ showDialogProgress: true }, () => {
                let emailTemplate = Constants.Email.NewMessage;
                let subject = emailTemplate.Subject;
                let body = emailTemplate.Body;
                body = body.replace("[username]", this.User.DisplayName);
                body = body.replace("[message]", this.state.Body);

                this.db.addMessage(this.User.Profile.Id, this.state.ProfileId, subject, body, '0', '0', this.AppRole, this.state.Role).then((added) => {
                    if (added > 0) {
                        if (this.state.Email) {
                            this.db.sendEmail(this.state.Email, subject, body).then((sent) => {
                                if (sent) {
                                    this.successMessage('Message sent successfully.');
                                    this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                                }
                                else {
                                    this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                                    this.errorMessage('Message email could not be sent.');
                                }
                            });
                        }
                        else {
                            this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                            this.errorMessage('Message could not be sent.');
                        }
                    }
                    else {
                        this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                        this.errorMessage("Message could not be added to sender's inbox.");
                    }
                });
            });
        }
    }

    private handleQuoteRequest() {
        this.setState({ showRequestQuoteDialog: true, showDialogProgress: true }, () => {
            this.getProjects().then((projects) => {
                this.setState({ showDialogProgress: false, Projects: projects });
            });
        });
    }

    private resetQuoteDialog() {
        this.setState({ showRequestQuoteDialog: false, Projects: [], ProjectId: '', HasRequestedQuote: false });
    }

    private getProjects(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.getProjects(this.User.Profile.Id).then((projects) => {
                if (projects && projects.length > 0) {
                    this.db.getProfileServices(this.state.ProfileId).then((proServices) => {
                        if (proServices && proServices.length > 0) {
                            let retVal: any = [];
                            projects.forEach((p) => {
                                let hasProjectCategory = proServices.find((pS) => { return (p.Category && pS.Category && pS.Category.Id === p.Category.Id); });
                                if (hasProjectCategory) {
                                    if (p.Location) {
                                        p.Location = JSON.parse(p.Location);
                                    }

                                    retVal.push((p));
                                }
                            });

                            resolve(retVal);
                        }
                        else
                            resolve(null);
                    });
                }
                else
                    resolve(null);
            });
        });
    }

    private handleProjectChange() {
        if (this.state.ProjectId) {
            let project = this.state.Projects.find((p) => { return (p.Id.toString() === this.state.ProjectId.toString()); });

            this.setState({ showDialogProgress: true, QuoteProject: project });
            if (!this.state.RequestedQuotes || this.state.RequestedQuotes.length <= 0) {
                this.db.getRequestedQuotes(this.User.Profile.Id).then((quotes) => {
                    let quoteExists = false;
                    if (quotes) {
                        let _quote = quotes.find((q) => { return (q.ProjectId.toString() === this.state.ProjectId.toString() && q.ProId.toString() === this.state.ProfileId.toString()); });
                        if (_quote)
                            quoteExists = true;
                    }

                    this.setState({
                        showDialogProgress: false, RequestedQuotes: quotes, HasRequestedQuote: quoteExists, CategoryId: project.Category.Id,
                        showDialogConfirmation: (quoteExists ? true : false), DialogConfirmationType: (quoteExists ? 'A' : ''),
                        DialogConfirmationMessage: (quoteExists ? 'You have already requested this Pro to submit a quote for this project.' : '')
                    });
                });
            }
            else {
                let quoteExists = false;
                if (this.state.RequestedQuotes) {
                    let _quote = this.state.RequestedQuotes.find((q) => { return (q.ProjectId.toString() === this.state.ProjectId.toString() && q.ProId.toString() === this.state.ProfileId.toString()); });
                    if (_quote)
                        quoteExists = true;
                }

                this.setState({
                    showQuoteRequestProgress: false, HasRequestedQuote: quoteExists, showDialogConfirmation: (quoteExists ? true : false), DialogConfirmationType: (quoteExists ? 'A' : ''),
                    DialogConfirmationMessage: (quoteExists ? 'You have already requested this Pro to submit a quote for this project.' : ''), CategoryId: project.Category.Id
                });
            }
        }
        else {
            this.setState({ HasRequestedQuote: false, QuoteProject: null });
        }
    }

    private submitQuoteRequest() {
        this.setState({ showDialogProgress: true }, () => {
            this.db.requestQuote(this.state.QuoteProject.Category.Id, this.state.ProjectId, this.User.Profile.Id, this.User.Email, this.state.ProfileId, this.state.Email).then((requestId) => {
                if (requestId > 0) {
                    this.sendQuoteRequestEmail(requestId).then((sent) => {
                        if (sent)
                            this.successMessage('Request for quote submitted successfully.');
                        else
                            this.successMessage('Request for quote submitted successfully but email to Pro could not be sent.');

                        this.setState({ showDialogProgress: false, HasRequestedQuote: true }, () => {
                            this.db.getRequestedQuotes(this.User.Profile.Id).then((quotes) => {
                                this.setState({
                                    showDialogProgress: false, RequestedQuotes: quotes, HasRequestedQuote: false, ProjectId: '',
                                    ProjectCategoryName: '', ProjectCityName: '', ProjectStateName: '', ProjectPin: '', CategoryId: ''
                                });
                            });
                        });
                    });
                }
                else {
                    this.errorMessage('Request for quote could not be submitted.');
                    this.setState({ showDialogProgress: false });
                }
            });
        });
    }

    private sendQuoteRequestEmail(itemId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.RequestQuote;
            let subject = emailTemplate.Subject;
            let body = emailTemplate.Body;

            body = body.replace("[username]", this.User.FirstName + (this.User.LastName ? ' ' + this.User.LastName.toString().charAt(0) + '.' : ''));
            body = body.replace("[servicename]", this.state.QuoteProject.Service.Category.Name);
            body = body.replace('[cityname]', this.state.QuoteProject.City.Name);
            body = body.replace('[statename]', this.state.QuoteProject.City.State.Name);
            body = body.replace('[zip]', this.state.QuoteProject.City.Pin);
            body = body.replace('[lid]', itemId);

            this.checkIfUserIsSubscribedToNotificationType(this.state.ProfileId, Constants.NotificationTypes.NewQuoteRequest).then((exists) => {
                if (exists) {
                    if (this.state.Email) {
                        this.db.sendEmail(this.state.Email, subject, body).then((sent) => {
                            if (sent) {
                                resolve(true);
                            }
                            else {
                                resolve(false);
                            }
                        });
                    }
                    else {
                        resolve(false);
                    }
                }
                else {
                    resolve(true);
                }
            });
        });
    }

    public render() {

        const { loading, showProfileProgress, showConfirmation, ConfirmationMessage, Message, ShowMessage, MessageType, IsActive, ProfileImage,
            BusinessName, DisplayName, OverallRating, TotalReviewsCount, Phone, Address, CityName, Pin, StateCode, WebSiteUrl, Year, Email, ConfirmationType,
            Role, SocialMediaLinks, TwitterUrl, FacebookUrl, InstagramUrl, YouTubeUrl, LinkedInUrl, Description, Payments, Accreditations, showDialog, TabIndex,
            StateName, showAskForReviewDialog, PortfolioMedia, FeaturedProjects, showProjectDialog, ProjectTitle, ProjectYear, ProjectPrice, ProjectDescription,
            ProjectFiles, ProjectServiceName, showMessageDialog, showDialogProgress, Body, showRequestQuoteDialog, Projects, ProjectId, HasRequestedQuote, showDialogConfirmation,
            DialogConfirmationMessage, DialogConfirmationType } = this.state;

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            if (!this.IsUserValid) {
                window.location.href = '/login';
            }
            else {
                template = (<React.Fragment>
                    <Header />
                    <section className="containerMinHeight accountSettingContainer borderBtm">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="row">
                                        <div className={"col-12 " + (this.props.id ? "col-sm-12" : "col-sm-9")}>
                                            <div className="profileContainer">
                                                {this.props.id ? (<div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="breadCrumb mb-1">
                                                            <a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2">&gt;</span><span className="ml-2">Profile</span>
                                                        </div>
                                                    </div>
                                                </div>) : (null)}
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="formContainer">
                                                            {!showAskForReviewDialog && !showDialog ? <div className="headingTxt">{(Role === Constants.UserRoles.Pro ? BusinessName : DisplayName) + " Profile"}</div> : (<React.Fragment>
                                                                {showDialog ? (<div className="headingTxt">
                                                                    {TabIndex === 0 ? "Profile" : (TabIndex === 1 ? "Social media" : (TabIndex === 2 ? "Payment methods accepted" : (TabIndex === 3 ? "Accreditations" : (TabIndex === 4 ? "Photos & videos" : (TabIndex === 5 ? "Featured projects" : "Ask for Reviews")))))}
                                                                </div>) : (null)}
                                                            </React.Fragment>)}
                                                            {!IsActive ? (<div className="switchControlContainter mt-1">
                                                                <div className="box">
                                                                    <div className="custom-control custom-switch">
                                                                        <span>Your account has been deactivated. </span><a href='/reactivate'>Click here</a> to submit a request to re-activate your account.
                                                                    </div>
                                                                </div>
                                                            </div>) : (<React.Fragment>
                                                                {showProfileProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                    {showDialog || showAskForReviewDialog ? (<React.Fragment>
                                                                        {showDialog ? (<div className="tabPanel">
                                                                            <Account index={TabIndex} mode='P' onCancelClick={(e) => { window.location.href = window.location.href; }} onSubmitClick={(e) => { window.location.href = window.location.href; }} />
                                                                        </div>) : (null)}
                                                                    </React.Fragment>) : (<React.Fragment>
                                                                        <div className="tabPanel">
                                                                            <div className="col-12 col-sm-12">
                                                                                <div className="row">
                                                                                    <div className="col-3 col-sm-2">
                                                                                        <div className="form-group mt-3">
                                                                                            <Avatar alt={Role === Constants.UserRoles.Pro ? BusinessName : DisplayName} src={ProfileImage ? ProfileImage : '/images/profile.png'} variant="square" sx={{ width: 100, height: 100 }}></Avatar>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-9 col-sm-10">
                                                                                        <div className="form-group mt-3">
                                                                                            <div className="row">
                                                                                                <div className="col-10 col-sm-10">
                                                                                                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{Role === Constants.UserRoles.Pro ? BusinessName : DisplayName}</Typography>
                                                                                                </div>
                                                                                                <div className="col-2 col-sm-2 text-right">
                                                                                                    {this.props.mode !== 'V' && !this.props.id ? (<IconButton onClick={(e) => { this.setState({ TabIndex: 0, showDialog: true }); }}><EditIcon fontSize="small" /></IconButton>) : (null)}
                                                                                                </div>
                                                                                            </div>
                                                                                            {Role === Constants.UserRoles.Pro ? (
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <Reviews id="reviews" profileId={this.props.id ? this.props.id : this.User.Profile.Id} reviewerUser={this.User} allowAskForReviews={false} allowRating={false} defaultRating={OverallRating} defaultReviews={TotalReviewsCount} ratingClassName='commentRatingStar' showRating={true} showReviewCount={true}></Reviews>
                                                                                                        {this.props.mode !== 'V' && !this.props.id ? (<Typography variant="caption" className="pl-2"><a href="javascript:void(0);" onClick={(e) => { this.setState({ showAskForReviewDialog: true, Emails: '' }); }}>Ask for Reviews</a></Typography>) : (null)}
                                                                                                    </div>
                                                                                                </div>) : (null)}
                                                                                            {Role === Constants.UserRoles.Customer && !this.props.id ? (<div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    <div className="formButtonContainer">
                                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleMessage(); }}>Message {BusinessName}</button> {/*disabled={this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic} */}
                                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" onClick={(e) => { this.handleQuoteRequest(); }}>Request Quote</button>
                                                                                                        {/* <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic || lead.HasSubmittedQuote} onClick={(e) => { if (this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) { this.submitQuote(lead); } }}>Submit Quote</button> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>) : (this.props.id > 0 ? (<div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    <div className="formButtonContainer">
                                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleMessage(); }}>Message {Role === Constants.UserRoles.Pro ? BusinessName : DisplayName}</button> {/*disabled={this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic} */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>) : (null))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="form-group mt-3">
                                                                                            <PhoneOutlinedIcon fontSize="small" ></PhoneOutlinedIcon> <label>Phone</label>
                                                                                            <div className="pl-4"><Typography variant="caption" className="commentBody">{Phone}</Typography></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="form-group mt-3">
                                                                                            <EmailOutlinedIcon fontSize="small" ></EmailOutlinedIcon> <label>Email</label>
                                                                                            <div className="pl-4"><Typography variant="caption" className="commentBody">{Email}</Typography></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {Role === Constants.UserRoles.Pro ? (<React.Fragment>
                                                                                    <div className="form-group mt-3">
                                                                                        <hr></hr>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className="form-group mt-3">
                                                                                                <WebOutlinedIcon fontSize="small" ></WebOutlinedIcon> <label>Website</label>
                                                                                                <div className="pl-4"><Typography variant="caption"><a href={WebSiteUrl} target='_blank'>{WebSiteUrl}</a></Typography></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className="form-group mt-3">
                                                                                                <EmojiEventsOutlinedIcon fontSize="small" ></EmojiEventsOutlinedIcon> <label>Year founded</label>
                                                                                                <div className="pl-4"><Typography variant="caption" className="commentBody">{Year}</Typography></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </React.Fragment>) : (null)}
                                                                                <div className="form-group mt-3">
                                                                                    <hr></hr>
                                                                                </div>
                                                                                <div className="form-group mt-3">
                                                                                    <LocationOnOutlinedIcon fontSize="small" ></LocationOnOutlinedIcon> <label>Address</label>
                                                                                    <div className="pl-4"><Typography variant="caption" className="commentBody">{Address}{CityName ? ", " + CityName : ''}{StateCode ? ", " + StateCode : StateName}{Pin ? " " + Pin : ''}</Typography></div>
                                                                                </div>
                                                                                {!showMessageDialog ? (null) : (
                                                                                    <React.Fragment>
                                                                                        <Dialog fullWidth={true} open={showMessageDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} >
                                                                                            <DialogTitle id={"detailsDialog"} className="modalTitle">Compose message
                                                                                                <IconButton aria-label="close" onClick={(e) => { this.resetMessage(); }} className="btnCloseDialog" disabled={showDialogProgress} >
                                                                                                    <CloseIcon />
                                                                                                </IconButton>
                                                                                            </DialogTitle>
                                                                                            <DialogContent dividers={true}>
                                                                                                <div className="profileContainer">
                                                                                                    <div className="formContainer">
                                                                                                        <div className="col-12 col-sm-12 p-0">
                                                                                                            <div className="minHeightDialogContainer">
                                                                                                                {showDialogProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                                                    <div className="form-group mt-3">
                                                                                                                        <label>Message</label>
                                                                                                                        <textarea id="message" name="message" key="message" rows={6} className="form-control" placeholder="" defaultValue={Body} onBlur={(e) => { this.setState({ Body: e.target.value }); }} />
                                                                                                                        <Validator id="valMessage" type='required' cssClass="error" errorMessage="Provide message." controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                                                        <Validator id="valMessageLength" type='max_length' cssClass="error" errorMessage="Message cannot exceed 1000 characters." maxLength={1000} controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                                                    </div>
                                                                                                                </React.Fragment>)}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </DialogContent>
                                                                                            <DialogActions>
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    <div className="form-group text-right">
                                                                                                        <div className="formButtonContainer mt-3">
                                                                                                            <button type="submit" className="btn btn-cancel" disabled={showDialogProgress} onClick={(e) => { this.resetMessage(); }}>Cancel</button>
                                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin ml-2" disabled={showDialogProgress} onClick={(e) => { this.handleSendMessage(); }}>Send</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </DialogActions>
                                                                                        </Dialog>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {!showRequestQuoteDialog ? (null) : (<React.Fragment>
                                                                                    <Dialog fullWidth={true} open={showMessageDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} >
                                                                                        <DialogTitle id={"detailsDialog"} className="modalTitle">Compose message
                                                                                            <IconButton aria-label="close" onClick={(e) => { this.resetMessage(); }} className="btnCloseDialog" disabled={showDialogProgress} >
                                                                                                <CloseIcon />
                                                                                            </IconButton>
                                                                                        </DialogTitle>
                                                                                        <DialogContent dividers={true}>
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    <h5>Request Quote</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="tabPanel">
                                                                                                <div className="form-group">
                                                                                                    {showDialogProgress ? (<ProgressBar />) : (
                                                                                                        <div className="form-group">
                                                                                                            <label>Project name</label>
                                                                                                            {!Projects || Projects.length <= 0 ? (<Typography variant="body2" color="text.secondary" className="commentBody">There are no projects related to this Pro's services. <a href='/projects/new'>Click here</a> to create a new project.</Typography>) : (
                                                                                                                <select id="project" name="project" className="form-control" defaultValue={ProjectId} onChange={(e) => { this.setState({ ProjectId: e.target.value }, () => { this.handleProjectChange(); }); }}>
                                                                                                                    <option value="">Select</option>
                                                                                                                    {Projects && Projects.map((item, indx) => {
                                                                                                                        return (<option value={item.Id}>{item.Name}</option>);
                                                                                                                    })}
                                                                                                                </select>)}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <Dialog open={showDialogConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                                                                                                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                                                                                                <DialogContent>
                                                                                                    <DialogContentText id="confirmationDialog">
                                                                                                        {DialogConfirmationMessage}
                                                                                                    </DialogContentText>
                                                                                                </DialogContent>
                                                                                                <DialogActions>
                                                                                                    {DialogConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showDialogConfirmation: false, ItemId: 0, DialogConfirmationMessage: '', callBackMethod: null, DialogConfirmationType: '' }) }}>OK</Button>) : (
                                                                                                        <React.Fragment>
                                                                                                            <Button onClick={(e) => { this.setState({ showDialogConfirmation: false, ItemId: 0, DialogConfirmationMessage: '', callBackMethod: null, DialogConfirmationType: '' }) }}>No</Button>
                                                                                                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                                                                                                        </React.Fragment>)}
                                                                                                </DialogActions>
                                                                                            </Dialog>
                                                                                        </DialogContent>
                                                                                        <DialogActions>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group text-right">
                                                                                                    <div className="formButtonContainer mt-3">
                                                                                                        <button type="submit" className="btn btn-cancel" disabled={showDialogProgress} onClick={(e) => { this.resetQuoteDialog(); }}>Cancel</button>
                                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={!ProjectId || HasRequestedQuote || showDialogProgress ? true : false} onClick={(e) => { this.submitQuoteRequest(); }}>Request for Quote</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </DialogActions>
                                                                                    </Dialog>
                                                                                </React.Fragment>)}
                                                                            </div>
                                                                        </div>
                                                                        {Role === Constants.UserRoles.Pro ? (<React.Fragment>
                                                                            <div className="tabPanel mt-1">
                                                                                <div className="row">
                                                                                    <div className="col-8 col-sm-10 align-self-center">
                                                                                        <div className="headingTxt">Social media</div>
                                                                                    </div>
                                                                                    <div className="col-4 col-sm-2 align-self-center text-right">
                                                                                        {this.props.mode !== 'V' && !this.props.id ? (<IconButton onClick={(e) => { this.setState({ TabIndex: 1, showDialog: true }); }}><EditIcon fontSize="small" /></IconButton>) : (null)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="form-group mt-3">
                                                                                        <div className="row">
                                                                                            {SocialMediaLinks && SocialMediaLinks.length > 0 ? (<React.Fragment>
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    {TwitterUrl ? (<div><img src='/images/twitterIcon.png' alt='Twitter' /> <a href={TwitterUrl} target='_blank'><Typography variant='caption'>{TwitterUrl}</Typography></a></div>) : (null)}
                                                                                                    {FacebookUrl ? (<div><img src='/images/facebookIcon.png' alt='Facebook' /> <a href={FacebookUrl} target='_blank'><Typography variant='caption'>{FacebookUrl}</Typography></a></div>) : (null)}
                                                                                                    {InstagramUrl ? (<div><img src='/images/instagramIcon.png' alt='Instagram' /> <a href={InstagramUrl} target='_blank'><Typography variant='caption'>{InstagramUrl}</Typography></a></div>) : (null)}
                                                                                                    {YouTubeUrl ? (<div><img src='/images/youtubeIcon.png' alt='YouTube' /> <a href={YouTubeUrl} target='_blank'><Typography variant='caption'>{YouTubeUrl}</Typography></a></div>) : (null)}
                                                                                                    {LinkedInUrl ? (<div><img src='/images/linkedInIcon.png' alt='LinkedIn' /> <a href={LinkedInUrl} target='_blank'><Typography variant='caption'>{LinkedInUrl}</Typography></a></div>) : (null)}
                                                                                                </div>
                                                                                            </React.Fragment>) : (<React.Fragment>{!this.props.id ? (<div className="col-12 col-sm-12">
                                                                                                <Button variant="outlined" startIcon={<FacebookIcon />} className="mr-3" onClick={(e) => { this.setState({ TabIndex: 1, showDialog: true }); }}>Add Facebook</Button>
                                                                                                <Button variant="outlined" startIcon={<InstagramIcon />} className="mr-3" onClick={(e) => { this.setState({ TabIndex: 1, showDialog: true }); }}>Add Instagram</Button>
                                                                                                <Button variant="outlined" startIcon={<TwitterIcon />} className="mr-3" onClick={(e) => { this.setState({ TabIndex: 1, showDialog: true }); }}>Add Twitter</Button>
                                                                                            </div>) : (null)}</React.Fragment>)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tabPanel mt-1">
                                                                                <div className="row">
                                                                                    <div className="col-8 col-sm-10 align-self-center">
                                                                                        <div className="headingTxt">Payment methods accepted</div>
                                                                                    </div>
                                                                                    <div className="col-4 col-sm-2 align-self-center text-right">
                                                                                        {this.props.mode !== 'V' && !this.props.id ? (<IconButton onClick={(e) => { this.setState({ TabIndex: 2, showDialog: true }); }}><EditIcon fontSize="small" /></IconButton>) : (null)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="form-group mt-3">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <Typography variant="subtitle2" className="commentBody">{Payments && Payments.length > 0 ? (<React.Fragment>
                                                                                                    {Payments.map((p, indx) => {
                                                                                                        if (indx < Payments.length - 1)
                                                                                                            return (p.Mode) + ', ';
                                                                                                        else
                                                                                                            return (p.Mode);
                                                                                                    })}
                                                                                                </React.Fragment>) : (null)}</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tabPanel mt-1">
                                                                                <div className="row">
                                                                                    <div className="col-8 col-sm-10 align-self-center">
                                                                                        <div className="headingTxt">Your introduction</div>
                                                                                    </div>
                                                                                    <div className="col-4 col-sm-2 align-self-center text-right">
                                                                                        {this.props.mode !== 'V' && !this.props.id ? (<IconButton onClick={(e) => { this.setState({ TabIndex: 0, showDialog: true }); }}><EditIcon fontSize="small" /></IconButton>) : (null)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="form-group mt-3">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <Typography variant="subtitle2" className="commentBody">{Description}</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tabPanel mt-1">
                                                                                <div className="row">
                                                                                    <div className="col-8 col-sm-8 align-self-center">
                                                                                        <div className="headingTxt">Accreditations</div>
                                                                                    </div>
                                                                                    <div className="col-4 col-sm-4 align-self-center text-right">
                                                                                        {!this.props.id ? (<div className="formButtonContainer text-right mt-0">
                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ TabIndex: 3, showDialog: true }); }}> + Add accreditations</button>
                                                                                        </div>) : (null)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="form-group">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <Typography variant="subtitle2" className="commentBody">Increase your chances of getting hired and boost customer confidence by adding accreditations.</Typography>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12 mt-3">
                                                                                                    <Box sx={{ flexGrow: 1 }}>
                                                                                                        <Grid container item spacing={3} columns={12}>
                                                                                                            {Accreditations && Accreditations.length > 0 ? (
                                                                                                                <React.Fragment>
                                                                                                                    {Accreditations.map((item) => {
                                                                                                                        let subHeader = (<React.Fragment><div>{item.Institution}</div><div>{this.getFormattedDate(item.StartDate, Constants.IDateTimeFormat.MMddyyyy, '/') + " - " + this.getFormattedDate(item.EndDate, Constants.IDateTimeFormat.MMddyyyy, '/')}</div></React.Fragment>);
                                                                                                                        return (<Grid key={'grdItem_' + item.Id} item xs={12}>
                                                                                                                            <React.Fragment>
                                                                                                                                <Card key={'card_' + item.Id} variant="outlined" className="licenseCard">
                                                                                                                                    <CardHeader avatar={<Avatar sx={{ bgcolor: red[500] }}>{item.Name ? item.Name.charAt(0).toUpperCase() : ''}</Avatar>}
                                                                                                                                        title={item.Name} subheader={subHeader} />
                                                                                                                                    <CardContent className="licenseCardContentMaxHeight">
                                                                                                                                        <Typography variant="body2" color="text.secondary">
                                                                                                                                            {item.Description}
                                                                                                                                        </Typography>
                                                                                                                                    </CardContent>
                                                                                                                                </Card>
                                                                                                                            </React.Fragment>
                                                                                                                        </Grid>);
                                                                                                                    })}
                                                                                                                </React.Fragment>
                                                                                                            ) : (null)}
                                                                                                        </Grid>
                                                                                                    </Box>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tabPanel mt-1">
                                                                                <div className="row">
                                                                                    <div className="col-8 col-sm-10 align-self-center">
                                                                                        <div className="headingTxt">Photos & videos</div>
                                                                                    </div>
                                                                                    <div className="col-4 col-sm-2 align-self-center text-right">
                                                                                        {this.props.mode !== 'V' && !this.props.id ? (<IconButton onClick={(e) => { this.setState({ TabIndex: 4, showDialog: true }); }}><EditIcon fontSize="small" /></IconButton>) : (null)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="form-group">
                                                                                        {this.props.mode !== 'V' ? (<div className="row">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <Typography variant="subtitle2" color="text.secondary">
                                                                                                    Include photos/videos of your work (before and after), teams, workspace or equipment.
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </div>) : (null)}
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12 mt-3">
                                                                                                <Box className="boxPortfolioMedia">
                                                                                                    <Grid container item spacing={0} columns={12}>
                                                                                                        {PortfolioMedia && PortfolioMedia.length > 0 ? (
                                                                                                            <React.Fragment>
                                                                                                                {PortfolioMedia.map((item) => {
                                                                                                                    let thumbnailUrl = item.Url;
                                                                                                                    let isYouTube = false;
                                                                                                                    let videoUrl = item.Url;

                                                                                                                    if (thumbnailUrl.toLowerCase().indexOf('youtube.com') >= 0) {
                                                                                                                        let videoID = item.Url.substring(item.Url.indexOf('v=') + 2);

                                                                                                                        isYouTube = true;
                                                                                                                        thumbnailUrl = "https://img.youtube.com/vi/" + videoID + "/0.jpg";
                                                                                                                        videoUrl = "https://www.youtube.com/embed/" + videoID;
                                                                                                                    }
                                                                                                                    else if (thumbnailUrl.toLowerCase().indexOf('vimeo.com') >= 0) {
                                                                                                                        let videoID = videoUrl.substring(videoUrl.lastIndexOf('/') + 1);

                                                                                                                        isYouTube = true;
                                                                                                                        videoUrl = "https://player.vimeo.com/video/" + videoID + "?fullscreen=1";
                                                                                                                    }

                                                                                                                    return (<Grid key={'grdItem_' + item.Id} item xs={4}>
                                                                                                                        <React.Fragment>
                                                                                                                            <Card key={'card_' + item.Id} variant="outlined" className="portfolioMediaCard">
                                                                                                                                {item.Type === 'V' ? (<CardMedia component={isYouTube ? "iframe" : "video"} height="194" playsInline={true} allowFullScreen={true} frameBorder={"0"} src={videoUrl} controls={true} title={item.Name} />) :
                                                                                                                                    (<CardMedia component="img" height="194" image={item.Url} alt={item.Name} title={item.Name} onClick={(e) => { window.open(item.Url); }} />)}
                                                                                                                            </Card>
                                                                                                                        </React.Fragment>
                                                                                                                    </Grid>);
                                                                                                                    // return (<Grid key={'grdItem_' + item.Id} item xs={4}>
                                                                                                                    //     <React.Fragment>
                                                                                                                    //         <Card key={'card_' + item.Id} variant="outlined" className="portfolioMediaCard">
                                                                                                                    //             {item.Type === 'V' ? (<CardMedia component="video" height="194" image={item.Url} controls={true} title={item.Name} onClick={(e) => { window.open(item.Url); }} />) :
                                                                                                                    //                 (<CardMedia component="img" height="194" image={item.Url} alt={item.Name} title={item.Name} onClick={(e) => { window.open(item.Url); }} />)}
                                                                                                                    //         </Card>
                                                                                                                    //     </React.Fragment>
                                                                                                                    // </Grid>);
                                                                                                                })}
                                                                                                            </React.Fragment>
                                                                                                        ) : (null)}
                                                                                                    </Grid>
                                                                                                </Box>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tabPanel mt-1">
                                                                                <div className="row">
                                                                                    <div className="col-8 col-sm-10 align-self-center">
                                                                                        <div className="headingTxt">Featured projects</div>
                                                                                    </div>
                                                                                    <div className="col-4 col-sm-2 align-self-center text-right">
                                                                                        {this.props.mode !== 'V' && !this.props.id ? (<IconButton onClick={(e) => { this.setState({ TabIndex: 5, showDialog: true }); }}><EditIcon fontSize="small" /></IconButton>) : (null)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="form-group">
                                                                                        {this.props.mode !== 'V' ? (<div className="row">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <Typography variant="subtitle2" color="text.secondary">
                                                                                                    Let your work speak for you. Add more featured projects to showcase what you have done for your customers.
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </div>) : (null)}
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12 mt-3">
                                                                                                <Box className="boxPortfolioMedia">
                                                                                                    <Grid container item spacing={0} columns={12}>
                                                                                                        {FeaturedProjects && FeaturedProjects.length > 0 ? (
                                                                                                            <React.Fragment>
                                                                                                                {FeaturedProjects.map((item, indx) => {
                                                                                                                    let thumbnail = item.Media && item.Media.length > 0 ? item.Media[0].LinkUrl : '';
                                                                                                                    if (!thumbnail) {
                                                                                                                        thumbnail = item.Service ? item.Service.DefaultImage : '';
                                                                                                                    }

                                                                                                                    return (<Grid key={'grdPrjItem_' + item.Id} item xs={4}>
                                                                                                                        <React.Fragment>
                                                                                                                            <Card key={'cardPrj_' + item.Id} variant="outlined" className={"portfolioProjectCard"} >
                                                                                                                                <CardMedia component="img" height="194" image={thumbnail} alt={item.Name} title={item.Name} />
                                                                                                                                <CardContent>
                                                                                                                                    <Typography gutterBottom variant="subtitle1" component="a" onClick={(e) => { this.viewFeaturedProject(item); }}>
                                                                                                                                        {item.Name}
                                                                                                                                    </Typography>
                                                                                                                                    <Typography variant="body2" color="text.secondary">
                                                                                                                                        {item.Price ? " Approx. $" + item.Price : ''}
                                                                                                                                    </Typography>
                                                                                                                                </CardContent>
                                                                                                                            </Card>
                                                                                                                        </React.Fragment>
                                                                                                                    </Grid>);
                                                                                                                })}
                                                                                                            </React.Fragment>
                                                                                                        ) : (null)}
                                                                                                    </Grid>
                                                                                                </Box>
                                                                                            </div>
                                                                                        </div>
                                                                                        {!showProjectDialog ? (null) : (
                                                                                            <Dialog key='dlgPortfolioProject' keepMounted TransitionComponent={this.SlideDownTransition} open={showProjectDialog}
                                                                                                onClose={(e) => { this.resetProjectDialog(); }} aria-labelledby={"dialogPortfolioProjectTitle"}
                                                                                                fullWidth={true} maxWidth={"sm"} >
                                                                                                <DialogTitle id={"dialogPortfolioProjectTitle"} className="modalTitle">{ProjectTitle ? ProjectTitle : 'New Featured Project'}
                                                                                                    <IconButton aria-label="close" onClick={(e) => { this.resetProjectDialog(); }} className="btnCloseDialog">
                                                                                                        <CloseIcon />
                                                                                                    </IconButton>
                                                                                                </DialogTitle>
                                                                                                <DialogContent dividers={true}>
                                                                                                    <div className="profileContainer">
                                                                                                        <div className="formContainer">
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label>Project title</label>
                                                                                                                        <Typography variant="subtitle2">{ProjectTitle}</Typography>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Service</label>
                                                                                                                                <Typography variant="subtitle2">{ProjectServiceName}</Typography>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Year</label>
                                                                                                                                <Typography variant="subtitle2">{ProjectYear}</Typography>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="form-group">
                                                                                                                        <label>Approximate total price</label>
                                                                                                                        <Typography variant="subtitle2">{ProjectPrice}</Typography>
                                                                                                                    </div>
                                                                                                                    <div className="form-group">
                                                                                                                        <label>Description </label>
                                                                                                                        <Typography variant="subtitle2">{ProjectDescription}</Typography>
                                                                                                                    </div>
                                                                                                                    {ProjectFiles && ProjectFiles.length > 0 ? (
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-12 col-sm-12">
                                                                                                                                <div className="form-group">
                                                                                                                                    <hr />
                                                                                                                                    <Box className="boxPortfolioMedia">
                                                                                                                                        <Grid container item spacing={0} columns={12}>
                                                                                                                                            {ProjectFiles.map((item, indx) => {
                                                                                                                                                return (<Grid key={'grdPrjItem_' + item.id} item xs={3}>
                                                                                                                                                    <Card key={'cardPrj_' + item.id} variant="outlined" className={"portfolioProjectCard" + (indx > 0 ? ' ml-2' : '')} >
                                                                                                                                                        <CardMedia component="img" height="100" image={item.url} alt={item.name} title={item.name} onClick={(e) => { window.open(item.url); }} />
                                                                                                                                                    </Card>
                                                                                                                                                </Grid>);
                                                                                                                                            })}
                                                                                                                                        </Grid>
                                                                                                                                    </Box>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>) : (null)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </DialogContent>
                                                                                                <DialogActions>
                                                                                                    <div className="formButtonContainer text-right">
                                                                                                        <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.resetProjectDialog(); }}>Cancel</button>
                                                                                                    </div>
                                                                                                </DialogActions>
                                                                                            </Dialog>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>) : (null)}
                                                                    </React.Fragment>)}
                                                                </React.Fragment>)}
                                                                {!showAskForReviewDialog ? (null) : (<React.Fragment>
                                                                    <AskForReview onCancel={(e) => { this.setState({ showAskForReviewDialog: false, Recipients: [] }); }} />
                                                                </React.Fragment>)}
                                                            </React.Fragment>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.id ? (null) : (<div className="col-12 col-sm-3">
                                            <div className="profileContainer">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="formContainer">
                                                            <div className="headingTxt">Insights</div>
                                                            <Insights />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </React.Fragment >);
            }
        }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}