import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";

export default class Terms extends Base {
    constructor(props) {
        super(props);

    }

    public componentDidMount() {

    }

    public render() {

        let template = (<React.Fragment>
            <Header />
            <section className="containerMinHeight borderBtm staticContent">
                <div className="becomeLayer5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12 p-5">
                                <h2>Terms &amp; Conditions</h2>
                                <div className="mt-3">Effective Date: August 1, 2023</div>
                                <div className="mt-3">
                                    <h4>1. Introduction</h4>
                                    <div className="mt-3">Welcome to ProsBe.com! These Terms and Conditions govern your use of our platform and services. By accessing or using our website, you agree to comply with these terms. If you do not agree with any part of these terms, please refrain from using our services.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>2. Definitions</h4>
                                    <div className="mt-3">a. "ProsBe.com" refers to the online platform provided by CS Digital Group LLC, which connects users ("Customers") with service providers ("Service Providers") offering various services.</div>
                                    <div className="mt-2">b. "User" refers to any individual accessing or using the website, including Customers and Service Providers.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>3. User Conduct</h4>
                                    <div className="mt-3">a. Eligibility: You must be at least 18 years old to use our services. By using the website, you affirm that you are of legal age and have the legal capacity to enter into this agreement.</div>
                                    <div className="mt-2">b. Accurate Information: You agree to provide accurate, complete, and up-to-date information when creating an account and using our services.</div>
                                    <div className="mt-2">c. Prohibited Activities: You shall not engage in any unlawful, harmful, or fraudulent activities on our platform. This includes, but is not limited to, impersonating others, transmitting viruses, spamming, or attempting to gain unauthorized access to our systems.</div>
                                    <div className="mt-2">d. User Reviews and Feedback: Users may post reviews and feedback on Service Providers. You agree that any content you submit will be honest, accurate, and respectful. We reserve the right to moderate or remove content that violates our policies.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>4. Service Providers</h4>
                                    <div className="mt-3">a. Listing Creation: If you offer services, you can create a profile and list your services on our platform. You are responsible for the accuracy of your listing and the content provided.</div>
                                    <div className="mt-2">b. Service Agreements: When a Customer books your services, you agree to enter into a service agreement with them. It is your responsibility to define the scope of services, pricing, and other relevant terms.</div>
                                    <div className="mt-2">c. Professionalism: As a Service Provider, you agree to deliver your services professionally and in a timely manner. Failure to meet expectations may result in negative reviews or account suspension.</div>
                                    <div className="mt-2">d. Verification: We may conduct identity and background checks on Service Providers to enhance user safety. By using our services, you consent to such verification processes.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>5. Customers</h4>
                                    <div className="mt-3">a. Booking Services: Customers may use our platform to request and book services from Service Providers. You are responsible for selecting suitable Service Providers for your needs.</div>
                                    <div className="mt-2">b. Payment: Customers agree to pay the agreed-upon fees for services booked through our platform. Payment details will be processed securely through our payment gateway.</div>
                                    <div className="mt-2">c. Communication: Customers must communicate with Service Providers through our platform for the duration of the service agreement.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>6. Fees and Payments</h4>
                                    <div className="mt-3">a. Service Fees: Pros may be charged service fees for using our platform when they upgrade their membership to Premium, as outlined in our Pricing page.</div>
                                    <div className="mt-2">b. Payment Processing: Payments will be processed securely through our designated payment gateway. We do not store or retain any credit card information.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>7. Intellectual Property</h4>
                                    <div className="mt-3">a. Ownership: All content and intellectual property on our website, including logos, trademarks, text, images, and software, are owned by CS Digital Group LLC or its licensors.</div>
                                    <div className="mt-2">b. Limited License: You are granted a limited, non-exclusive, non-transferable license to access and use our website for personal or business purposes. You must not reproduce, modify, distribute, or create derivative works without explicit permission.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>8. Termination</h4>
                                    <div className="mt-3">We reserve the right to suspend or terminate your access to our services at any time for violating these terms or for any other reason we deem necessary.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>9. Disclaimer of Warranties</h4>
                                    <div className="mt-3">Our platform and services are provided on an "as-is" and "as available" basis. We do not make any warranties regarding the accuracy, reliability, or suitability of the content and services provided.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>10. Limitation of Liability</h4>
                                    <div className="mt-3">To the extent permitted by law, CS Digital Group LLC shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of our platform or services.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>11. Governing Law and Jurisdiction</h4>
                                    <div className="mt-3">These Terms and Conditions shall be governed by and construed in accordance with the laws of Virginia and the jurisdiction is in the Circuit Court of Fairfax County, Fairfax, Virginia. Any disputes arising out of or relating to these terms shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].</div>
                                </div>
                                <div className="mt-3">
                                    <h4>12. Changes to the Terms and Conditions</h4>
                                    <div className="mt-3">We may update these Terms and Conditions from time to time. The latest version will be posted on this page, along with the effective date.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>13. Contact Us</h4>
                                    <div className="mt-3">If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:info@prosbe.com">info@prosbe.com</a>.</div>
                                </div>
                                <div className="mt-5">
                                    <h4>End Notes</h4>
                                    <div className="mt-3">The above Terms and Conditions serve as a general template and should be adjusted to match the specific requirements and characteristics of the website similar to Bark.com. To ensure legal compliance and address regional variations, it's essential to seek professional legal advice when finalizing the terms and conditions.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>);

        return template;
    }
}