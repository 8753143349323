import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from 'src/pages/Admin';
import BecomeAPro from 'src/pages/BecomeAPro';
// import Account from 'src/pages/Account';
import CreateUser from 'src/pages/CreateUser';
import Dashboard from 'src/pages/Dashboard';
import DeactivateAccount from 'src/pages/DeactivateAccount';
import Inbox from 'src/pages/Inbox';
import Leads from 'src/pages/Leads';
import Login from 'src/pages/Login';
import UserAccount from 'src/pages/UserAccount';
import Projects from 'src/pages/Projects';
import ReactivateRequest from 'src/pages/ReactivateRequest';
import ResetPassword from 'src/pages/ResetPassword';
import Review from 'src/pages/Review';
import Search from 'src/pages/Search';
import Services from 'src/pages/Services';
import UserProfile from 'src/pages/UserProfile';
import Quote from 'src/pages/Quote';
import ContactUs from 'src/pages/ContactUs';
import Terms from 'src/pages/Terms';
import Privacy from 'src/pages/Privacy';
import FindPros from 'src/pages/FindPros';
import Pricing from 'src/pages/Pricing';
import About from 'src/pages/About';
import HowToUse from 'src/pages/HowToUse';
import HowItWorks from 'src/pages/HowItWorks';
import Subscribe from 'src/pages/Subscribe';

require('src/styles/css/bootstrap.min.css');
require('src/styles/css/portal.css');
require('jquery');
require('bootstrap');

const query = new URLSearchParams(window.location.search);
export default class AppRouter extends React.Component<any, any> {

    public render() {

        return (<Router>
            <Routes>
                <Route path='/' element={<Dashboard />} />
                <Route path='/login' element={<Login />} />
                <Route path='/becomepro' element={<BecomeAPro />} />
                <Route path='/admin' element={<Admin tabIndex={0} />} />
                <Route path='/passreset' element={<ResetPassword />} />
                <Route path='/reactivate' element={<ReactivateRequest pid={query.get('id')} />} />
                <Route path='/deactivate' element={<DeactivateAccount pid={query.get('pid')} email={query.get('em')} />} />
                <Route path='/projects' element={<Projects id={query.get('id')} />} />
                <Route path='/projects/new' element={<Projects mode='n' />} />
                <Route path='/search' element={<Search category={query.get('c')} pin={query.get('p')} add={query.get('add')} />} />
                <Route path='/inbox' element={<Inbox />} />
                <Route path='/profile' element={<UserProfile id={query.get('id')} role={query.get('role')} />} />
                <Route path='/account' element={<UserAccount id={query.get('id')} />} />
                <Route path='/services' element={<Services id={query.get('id')} />} />
                <Route path='/leads' element={<Leads tab={0} />} />
                <Route path='/lead' element={<Leads tab={0} item={query.get('id')} />} />
                <Route path='/quote' element={<Quote item={query.get('id')} />} />
                <Route path='/opportunities' element={<Leads tab={1} />} />
                <Route path='/opportunity' element={<Leads tab={1} item={query.get('id')} />} />
                <Route path='/users/new' element={<CreateUser mode='n' userType={query.get('m')} />} />
                <Route path='/users/edit' element={<CreateUser mode='e' />} />
                <Route path='/review' element={<Review receiverProfileId={query.get('rid')} email={query.get('em')} />} />
                <Route path='/subscribe' element={<Subscribe clientSecret= {query.get('isc')}/>} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/findpros' element={<FindPros />} />
                <Route path='/pricing' element={<Pricing />} />
                <Route path='/use' element={<HowToUse />} />
                <Route path='/works' element={<HowItWorks />} />
                <Route path='/about' element={<About />} />
            </Routes>
        </Router>);
    }
}