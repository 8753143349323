import React from "react";
import Base from "src/common/Base";
import Constants from "src/common/Constants";
import Footer from "src/common/Footer";
import Header from "src/common/Header";

export default class HowItWorks extends Base {
    constructor(props) {
        super(props);

    }

    public componentDidMount() {

    }

    private handleSwitchRoleClick() {
        this.db.getUser(this.LoginName, Constants.UserRoles.Pro).then((user) => {
            if (user)
                sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(JSON.stringify(user)));

            sessionStorage.setItem(this.encodeString("ProsBEUser_AppRole"), this.encodeString(Constants.UserRoles.Pro));
            window.location.href = '/';
        });
    }

    public render() {

        let template = (<React.Fragment>
            <Header />
            <section className="containerMinHeight borderBtm">
                <div className="becomeLayer2 howItWorks">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-5">
                                <div className="cardCotent p-5">
                                    <div className="layerTwo">Welcome to ProsBe.com! We're thrilled to have you join our platform as a service provider. Here's a step-by-step guide on how you can make the most of your experience and connect with customers seeking your expertise:</div>
                                    <div className="layerOne mt-3">1. Create Your Profile</div>
                                    <div className="layerTwo mt-2"><b>Sign Up:</b> Begin by creating your account on ProsBe.com. It's quick and easy. Provide the necessary details, and you're on your way to becoming a part of our trusted network.</div>
                                    <div className="layerTwo mt-1"><b>Build Your Profile:</b> Complete your profile with relevant information about your services, experience, qualifications, and any specializations you offer. This is your opportunity to showcase your skills and expertise to potential customers.</div>
                                    <div className="layerOne mt-3">2. List Your Services</div>
                                    <div className="layerTwo mt-2"><b>Choose Your Categories:</b> Select the service categories that best represent the services you offer. Whether you're a plumber, a photographer, a tutor, or anything else, make sure to categorize your services accurately.</div>
                                    <div className="layerTwo mt-1"><b>Define Your Services:</b> Describe your services in detail, including what you offer, your unique selling points, and any additional benefits customers can expect when choosing you.</div>
                                    <div className="layerOne mt-3">3. Receive Requests and Quotes</div>
                                    <div className="layerTwo mt-2"><b>Customer Inquiries:</b> Customers looking for services in your categories will find your profile and reach out to you through our platform. Stay attentive to inquiries and respond promptly to show your professionalism.</div>
                                    <div className="layerTwo mt-1"><b>Quote Requests:</b> When customers express interest in your services, they may send you quote requests. Provide detailed and competitive quotes to win their business.</div>
                                    <div className="layerOne mt-3">4. Deliver Quality Services</div>
                                    <div className="layerTwo mt-2"><b>Professionalism:</b> Strive for excellence in every interaction with customers. Be punctual, attentive, and deliver the services you promise to maintain a positive reputation.</div>
                                    <div className="layerTwo mt-1"><b>Customer Communication:</b> Maintain clear and consistent communication with customers before, during, and after providing your services. This fosters trust and helps build long-term relationships.</div>
                                    <div className="layerOne mt-3">5. Customer Reviews and Ratings</div>
                                    <div className="layerTwo mt-2"><b>Customer Feedback:</b> After completing a service, encourage customers to leave honest reviews and ratings on your profile. Positive reviews can enhance your credibility and attract more customers.</div>
                                    <div className="layerTwo mt-1"><b>Respond to Reviews:</b> Engage with customer reviews, whether positive or constructive, to show your commitment to customer satisfaction and continuous improvement.</div>
                                    <div className="layerOne mt-3">6. Grow Your Business</div>
                                    <div className="layerTwo mt-2"><b>Stay Active:</b> Regularly update your profile, add new services, and keep your information current. An active and engaging profile increases your chances of being noticed by potential customers.</div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-7"></div>
                        </div>
                    </div>
                </div>
                <div className="becomeLayer5 text-center mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-6 p-5">
                                <h4><b>Join ProsBe.com Today</b></h4>
                                <div className="layerTwo mt-2">
                                    ProsBe.com is your gateway to new opportunities and a thriving community of customers seeking your services. Sign up today, complete your profile, and start connecting with customers who can benefit from your skills and expertise. Let's work together to make your business flourish!
                                </div>
                                <div className="mt-3">
                                    {this.IsUserValid && this.User ? (<React.Fragment>
                                        {this.User.DefaultRole !== Constants.UserRoles.Pro && this.User.Role2 != Constants.UserRoles.Pro ?
                                            (<button type="submit" className="signUp" onClick={(e) => { window.location.href = '/users/new?m=pro'; }}>Sign Up as a Pro</button>) :
                                            (<button type="submit" className="signUp" onClick={(e) => { this.handleSwitchRoleClick(); }}>Switch to Pro role</button>)
                                        }
                                    </React.Fragment>) : (<button type="submit" className="signUp" onClick={(e) => { window.location.href = '/users/new?m=pro'; }}>Sign Up as a Pro</button>)}
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 p-5">
                                <div className="become_layer4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>);

        return template;
    }
}