import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Rating, Snackbar, Typography } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import { Loader } from "src/common/Components";
import Constants from "src/common/Constants";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import Validator from "src/common/Validator";

export default class Review extends Base {
    public state: any;
    private validator: Validator;
    private formValidators: Validator[];

    constructor(props) {
        super(props);

        this.state = {
            loading: true, NewRating: '', Comment: '', ReceiverProfileId: this.props.receiverProfileId, UserName: '', UserEmail: this.props.email, ReviewParameters: [],
            showConfirmation: false, ConfirmationType: '', ConfirmationMessage: '', ShowMessage: false, MessageType: 'success', User: null, ReceiverProfile: null,
            Message: '', ItemId: 0, callBackMethod: null,
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount(): void {
        if (this.props.email && this.props.receiverProfileId) {
            this.db.getUserProfileById(this.props.receiverProfileId, Constants.UserRoles.Pro).then((receiverProfile) => {
                if (receiverProfile) {
                    this.db.getUser(this.props.email, Constants.UserRoles.Customer).then((user) => {
                        if (user) {
                            this.db.getReviewParameters().then((pars) => {
                                this.setState({ loading: false, ReviewParameters: pars, User: user, UserName: user.DisplayName, ReceiverProfile: receiverProfile });
                            });
                        }
                        else {
                            window.location.href = '/login?src=' + window.location.href;
                        }
                    });
                }
                else {
                    window.location.href = '/login?src=' + window.location.href;
                }
            });
        }
    }

    private submitReview() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            let reviewParameters = '';
            if (this.state.ReviewParameters && this.state.ReviewParameters.length > 0) {
                reviewParameters = JSON.stringify(this.state.ReviewParameters);
            }

            this.db.addProfileRating(this.state.ReceiverProfileId, this.state.NewRating, this.state.User.DisplayName, this.state.User.Email, reviewParameters).then((rated) => {
                if (rated) {
                    if (this.state.Comment) {
                        this.db.addProfileComment(this.state.ReceiverProfileId, this.state.Comment, '0', this.state.User.DisplayName, this.state.User.Email, this.state.NewRating).then((commented) => {
                            if (commented) {
                                this.successMessage('Review submitted successfully.');
                                window.location.href = '/';
                            }
                            else {
                                this.errorMessage('Review comment could not be submitted.');
                            }
                        });
                    }
                    else {
                        this.successMessage('Review submitted successfully.');
                        window.location.href = '/';
                    }
                }
                else {
                    this.errorMessage('Review rating could not be submitted.');
                }
            });
        }
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {
        const { loading, NewRating, Comment, ShowMessage, MessageType, Message, showConfirmation, ConfirmationType, ConfirmationMessage, ReviewParameters, UserName, ReceiverProfile } = this.state;
        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            template = (<React.Fragment>
                <Header />
                <section className="containerMinHeight accountSettingContainer borderBtm">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <h5>{"Submit your review" + (ReceiverProfile ? " for " + ReceiverProfile.BusinessName : "")}</h5>
                                <div className="profileContainer">
                                    <div className="formContainer">
                                        <div className="tabPanel p-4">
                                            {!this.props.email || !this.props.receiverProfileId ? (<Typography variant="subtitle2" color="text.secondary" className="commentBody mt-3">
                                                This does not seem a valid review request.
                                            </Typography>) : (<React.Fragment>
                                                <div className="form-group mt-3">
                                                    <label>Your name</label>
                                                    <input type="text" id="userName" name="userName" defaultValue={UserName} className="form-control" onBlur={(e) => { this.setState({ UserName: e.target.value }); }} />
                                                    <Validator id="valUName" type="required" cssClass="error" valueToValidate={UserName} errorMessage="Provide your name." controlId="userName" formValidators={this.formValidators} />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <label>Overall rating</label>
                                                    <div>
                                                        <Rating key="newRating" name="newRating" value={NewRating} className="ratedStar" onChange={(e, newVal) => { this.setState({ NewRating: newVal }); }} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Service oriented rating</label>
                                                    {!ReviewParameters || ReviewParameters.length <= 0 ? (null) : (<React.Fragment>
                                                        {ReviewParameters.map((item, indx) => {
                                                            return (
                                                                <div className="row">
                                                                    <div className="col-4 col-sm-2">
                                                                        <Rating key={"sRating_" + indx.toString()} name={"sRating_" + indx.toString()} value={item.Rating} className="ratedStar" onChange={(e, newVal) => { item.Rating = newVal; this.setState({ ReviewParameters: ReviewParameters }); }} />
                                                                    </div>
                                                                    <div className="col-8 col-sm-10">
                                                                        <Typography variant="caption" color="text.secondary" className="commentBody">{item.Name}</Typography>
                                                                    </div>
                                                                </div>);
                                                        })}
                                                    </React.Fragment>)}
                                                </div>
                                                <div className="form-group mt-3">
                                                    <label>Comments</label>
                                                    <textarea id="comment" name="comment" defaultValue={Comment} className="form-control" rows={4} onBlur={(e) => { this.setState({ Comment: e.target.value }); }} />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="formButtonContainer text-right">
                                                        <button type="submit" className="btn btn-cancel" onClick={(e) => { window.location.href = '/'; }}>Cancel</button>
                                                        <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={!UserName || !NewRating} onClick={(e) => { this.submitReview(); }}>Save</button>
                                                    </div>
                                                </div>
                                            </React.Fragment>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment >);
        }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}