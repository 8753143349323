import {
    Alert, Autocomplete, Avatar, Box, Button, Card, CardContent, CardHeader, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton,
    Snackbar, TextField, Typography
} from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import Reviews, { IAutoCompleteOption, Loader, ProgressBar } from "src/common/Components";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import Validator from "src/common/Validator";
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Constants from "src/common/Constants";
import { red } from "@mui/material/colors";
import CloseIcon from '@mui/icons-material/Close';

export default class Search extends Base {
    public validator: Validator;
    public formValidators: Validator[];

    constructor(props) {
        super(props);

        this.state = {
            loading: true, Result: null, Category: this.props.category, Pin: this.props.pin, Categories: [], Pins: [], CategoryId: '', PinCode: '', PinAddress: '',
            showQuoteTemplate: false, showQuoteProgress: false, showProfile: false, RelatedPro: null, RelatedPros: [], showRelatedProProgress: false,
            RelatedProjects: [], ProjectId: '', ProjectName: '', RequestedQuotes: [], HasRequestedQuote: false,
            showConfirmation: false, ConfirmationMessage: '', ShowMessage: false, MessageType: 'success', Message: '', ConfirmationType: '', callBackMethod: null,
            Portfolio: [], PortfolioMedia: [], FeaturedProjects: [], TabIndex: 0, Distance: '', RefinedResults: [], QuoteProject: null,
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        // get saved categories and pins
        this.db.getCategories().then((categories) => {
            this.getPinsMaster().then((pins) => {
                let _categories: IAutoCompleteOption[] = [];
                let _category: any = null;

                if (categories && categories.length > 0) {
                    categories.forEach((cat) => {
                        _categories.push({ key: cat.Name, text: cat.Name, value: cat.Id });

                        if (cat.Id.toString() === this.props.category.toString())
                            _category = { key: cat.Name, text: cat.Name, value: cat.Id };
                    });
                }

                let _pins: IAutoCompleteOption[] = [];
                let _pin: any = null;

                if (pins && pins.length > 0) {
                    pins.forEach((pin) => {
                        _pins.push({ key: pin.Name, text: pin.Name, value: pin.Pin });

                        if (pin.Pin.toString() === this.props.pin.toString())
                            _pin = { key: pin.Name, text: pin.Name, value: pin.Pin };
                    });
                }

                this.addGoogleMapScriptTag();

                if (this.props.category || this.props.pin) {
                    this.db.searchUserProfiles(this.props.category, this.props.pin).then((result) => {
                        this.setState({
                            loading: false, RelatedPros: result, RefinedResults: result, Categories: _categories, Pins: _pins, Category: _category, CategoryId: this.props.category,
                            Pin: _pin, PinCode: this.props.pin, PinAddress: this.props.add
                        }, () => {
                            this.initializePlacesAutoComplete();
                        });
                    });
                }
                else {
                    this.setState({ loading: false, Categories: _categories, Pins: _pins }, () => {
                        this.initializePlacesAutoComplete();
                    });
                }
            });
        });
    }

    private handleSearch() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showProgress: true, TabIndex: 0 });
            this.db.searchUserProfiles(this.state.CategoryId, this.state.PinCode).then((result) => {
                this.setState({ showProgress: false, RelatedPros: result, RefinedResults: result, Distance: '' });
            });
        }
    }

    // private refineSearch() {
    //     if (this.state.Distance) {

    //         let refinedResult = this.state.RelatedPros.filter((pro) => { return (pro.ServiceLocations && pro.ServiceLocations.find((sL) => { return (pro.Profile.Id === sL.ProfileId && Number(sL.Distance) <= Number(this.state.Distance)); })); });
    //         this.setState({ RefinedResults: refinedResult });
    //     }
    //     else {
    //         this.setState({ RefinedResults: this.state.RelatedPros });
    //     }
    // }

    private handleRequestQuote(relatedPro) {
        this.setState({ showProgress: true, TabIndex: 2 }, () => {
            this.db.getCategoryRelatedProjects(this.state.CategoryId, this.state.PinCode, this.User.Profile.Id).then((result) => {
                this.setState({ RelatedPro: relatedPro, RelatedProjects: result, showProgress: false, ProjectId: '', ProjectName: '', HasRequestedQuote: false });
            });
        });
    }

    private handleProjectChange() {
        if (this.state.ProjectId) {
            let project = this.state.RelatedProjects.find((p) => { return (p.Id.toString() === this.state.ProjectId.toString()); });

            this.setState({ showProgress: true, QuoteProject: project });
            if (!this.state.RequestedQuotes || this.state.RequestedQuotes.length <= 0) {
                this.db.getRequestedQuotes(this.User.Profile.Id).then((quotes) => {
                    let quoteExists = false;
                    if (quotes) {
                        let _quote = quotes.find((q) => { return (q.ProjectId.toString() === this.state.ProjectId.toString() && q.ProId.toString() === this.state.RelatedPro.Profile.Id.toString()); });
                        if (_quote)
                            quoteExists = true;
                    }

                    this.setState({
                        showProgress: false, RequestedQuotes: quotes, HasRequestedQuote: quoteExists,
                        showConfirmation: (quoteExists ? true : false), ConfirmationType: (quoteExists ? 'A' : ''),
                        ConfirmationMessage: (quoteExists ? 'You have already requested this Pro to submit a quote for this project.' : '')
                    });
                });
            }
            else {
                let quoteExists = false;
                if (this.state.RequestedQuotes) {
                    let _quote = this.state.RequestedQuotes.find((q) => { return (q.ProjectId.toString() === this.state.ProjectId.toString() && q.ProId.toString() === this.state.RelatedPro.Profile.Id.toString()); });
                    if (_quote)
                        quoteExists = true;
                }

                this.setState({
                    showProgress: false, HasRequestedQuote: quoteExists, showConfirmation: (quoteExists ? true : false), ConfirmationType: (quoteExists ? 'A' : ''),
                    ConfirmationMessage: (quoteExists ? 'You have already requested this Pro to submit a quote for this project.' : '')
                });
            }
        }
        else {
            this.setState({ HasRequestedQuote: false });
        }
    }

    private handleViewProfile(relatedPro) {
        this.setState({ showProgress: true, TabIndex: 1 });
        this.getPortfolio(relatedPro.Profile.Id).then((portfolio) => {
            this.setState({
                RelatedPro: relatedPro, showProgress: false, HasRequestedQuote: false, ProjectId: '', ProjectName: '',
                Portfolio: portfolio, PortfolioMedia: portfolio ? portfolio.Media : [], FeaturedProjects: portfolio ? portfolio.FeaturedProjects : []
            });
        });
    }

    private getPortfolio(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.getPortfolio(profileId).then((result) => {
                resolve(result);
            });
        });
    }

    private sendQuoteRequestEmail(itemId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.RequestQuote;
            let subject = emailTemplate.Subject;
            let body = emailTemplate.Body;

            body = body.replace("[username]", this.User.FirstName + (this.User.LastName ? ' ' + this.User.LastName.toString().charAt(0) + '.' : ''));
            body = body.replace("[servicename]", this.state.QuoteProject.Service.Category.Name);
            body = body.replace('[cityname]', this.state.QuoteProject.City.Name);
            body = body.replace('[statename]', this.state.QuoteProject.City.State.Name);
            body = body.replace('[zip]', this.state.QuoteProject.City.Pin);
            body = body.replace('[lid]', itemId);

            this.checkIfUserIsSubscribedToNotificationType(this.state.RelatedPro.Profile.Id, Constants.NotificationTypes.NewQuoteRequest).then((exists) => {
                if (exists) {
                    if (this.state.RelatedPro.Profile.User.Email) {
                        this.db.sendEmail(this.state.RelatedPro.Profile.User.Email, subject, body).then((sent) => {
                            if (sent) {
                                resolve(true);
                            }
                            else {
                                resolve(false);
                            }
                        });
                    }
                    else {
                        resolve(false);
                    }
                }
                else {
                    resolve(true);
                }
            });
        });
    }

    private handleQuoteSubmission() {
        this.setState({ showProgress: true });
        this.db.requestQuote(this.state.CategoryId, this.state.ProjectId, this.User.Profile.Id, this.User.Email, this.state.RelatedPro.Profile.Id, this.state.RelatedPro.Profile.User.Email).then((requested) => {
            if (requested > 0) {
                this.sendQuoteRequestEmail(requested).then((sent) => {
                    if (sent)
                        this.successMessage('Request for quote submitted successfully.');
                    else
                        this.successMessage('Request for quote submitted successfully but email to Pro could not be sent.');

                    this.setState({ showProgress: false, HasRequestedQuote: true }, () => {
                        this.db.getRequestedQuotes(this.User.Profile.Id).then((quotes) => {
                            this.setState({ showProgress: false, RequestedQuotes: quotes, QuoteProject: null, HasRequestedQuote: false, ProjectId: '', ProjectName: '' });
                        });
                    });
                });
            }
            else {
                this.errorMessage('Request for quote could not be submitted.');
                this.setState({ showProgress: false });
            }
        });
    }

    private viewFeaturedProject(item) {
        let _pFiles = item.Media;
        let projectFiles: any[] = [];

        if (_pFiles && _pFiles.length > 0) {
            _pFiles.forEach((pFile) => {
                projectFiles.push({ id: pFile.Id, name: pFile.Name, url: pFile.LinkUrl });
            });
        }

        this.setState({
            ProjectId: item.Id, ProjectFiles: projectFiles, ProjectTitle: item.Name, ProjectDescription: item.Description, ProjectPrice: item.Price,
            ProjectService: (item.Service ? item.Service.Id.toString() : ''), ProjectServiceName: (item.Service ? item.Service.Name.toString() : ''),
            ProjectYear: item.Year.toString(), showProjectDialog: true, DialogMode: 'V'
        });
    }

    private resetProjectDialog() {
        this.setState({
            ProjectId: '', ProjectFiles: [], ProjectTitle: '', ProjectDescription: '', ProjectPrice: '', ProjectService: '', ProjectServiceName: '', ProjectYear: '', showProjectDialog: false, DialogMode: ''
        });
    }

    private addGoogleMapScriptTag() {
        let scriptElems = document.getElementsByTagName("script");
        let elemExists = false;
        for (var i = 0; i < scriptElems.length; i++) {
            if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
                elemExists = true;
        }

        if (!elemExists) {
            const script = document.createElement("script");
            script.src = Constants.Google.PlacesScriptPath;
            //script.id = "scriptGoogleMaps";
            script.setAttribute('async', '');
            script.setAttribute('defer', '');

            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }

    private initializePlacesAutoComplete() {

        const input = document.getElementById("zip") as HTMLInputElement;
        const autoCompleteOptions = {
            fields: ["formatted_address", "address_components", "geometry", "name", "place_id"],
            // strictBounds: false,
            types: ["(regions)"],
            componentRestrictions: { country: "us" }
        };

        const autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);

        autocomplete.addListener("place_changed", () => {
            let place: any = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "No details available for input: '" + place.name + "'", ConfirmationType: 'A' });
                return;
            }

            let hasPostalCode = false;
            let zipCode = '';
            // let state = '';
            // let sCode = '';
            // let city = '';

            if (place.address_components) {
                for (var i = 0; i < place.address_components.length; i++) {
                    if (place.address_components[i].types) {
                        for (var j = 0; j < place.address_components[i].types.length; j++) {
                            if (place.address_components[i].types[j] === "postal_code") {
                                if (!hasPostalCode) {
                                    hasPostalCode = true;
                                    zipCode = place.address_components[i].long_name;
                                }
                            }

                            // if (place.address_components[i].types[j] === "administrative_area_level_1") {
                            //     if (!state) {
                            //         state = place.address_components[i].long_name;
                            //         sCode = place.address_components[i].short_name;
                            //     }
                            // }

                            // if (place.address_components[i].types[j] === "locality") {
                            //     if (!city) {
                            //         city = place.address_components[i].long_name;
                            //     }
                            // }
                        }
                    }
                }
            }

            if (!hasPostalCode) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "Invalid Zip code. Please start your search with a Zip code.", ConfirmationType: 'A' });
                return;
            }

            let formatted_address = place.formatted_address;
            // let placeId = place.place_id;
            // let lat = place.geometry?.location?.lat();
            // let lon = place.geometry?.location?.lng();

            this.setState({ PinCode: zipCode, PinAddress: formatted_address });
        });
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    private handleMessage() {
        this.setState({ showMessageDialog: true });
    }

    private resetMessage() {
        this.setState({ showMessageDialog: false, Body: '' }, () => {
            this.formValidators = new Array<Validator>();
        });
    }

    private handleSendMessage() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.formValidators = new Array<Validator>();
            this.setState({ showDialogProgress: true }, () => {
                let emailTemplate = Constants.Email.NewMessage;
                let subject = emailTemplate.Subject;
                let body = emailTemplate.Body;

                body = body.replace("[username]", this.User.DisplayName);
                body = body.replace("[message]", this.state.Body);

                if (this.state.RelatedPro.Profile.User.Email) {
                    this.db.sendEmail(this.state.RelatedPro.Profile.User.Email, subject, body).then((sent) => {
                        if (sent) {
                            this.successMessage('Message sent successfully.');
                            this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                        }
                        else {
                            this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                            this.errorMessage('Message email could not be sent.');
                        }
                    });
                }
                else {
                    this.setState({ showDialogProgress: false, showMessageDialog: false, Body: '' });
                    this.errorMessage('Message could not be sent.');
                }
            });
        }
    }

    public render() {

        const { loading, Categories, Category, PinAddress, RelatedPro,
            RelatedProjects, ProjectId, HasRequestedQuote, showConfirmation, ConfirmationType, ConfirmationMessage, ShowMessage, MessageType, Message,
            PortfolioMedia, FeaturedProjects, showProjectDialog, ProjectTitle, ProjectYear, ProjectPrice, ProjectDescription,
            ProjectFiles, ProjectServiceName, TabIndex, showProgress, RefinedResults, showMessageDialog, showDialogProgress, Body, } = this.state; //Distance

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            template = (<React.Fragment>
                <Header />
                <section className="containerMinHeight accountSettingContainer borderBtm">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="profileContainer">
                                    <div className="col-12 col-sm-12">
                                        <div className="formContainer">
                                            {TabIndex === 0 ? (<React.Fragment>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="searchResultContainerForm">
                                                            <div className="searchSection">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 pr-0">
                                                                        <div className="height50">
                                                                            <Autocomplete multiple={false} id="category" className="autoComplete" options={Categories && Categories.length > 0 ? Categories : []} getOptionLabel={(option) => { return (option && option.text ? option.text : ''); }}
                                                                                onChange={(e, newVal) => { this.setState({ Category: newVal ? newVal : null, CategoryId: newVal ? newVal.value.toString() : '' }); }} defaultValue={Category} filterSelectedOptions={true} renderInput={(params) => (<TextField name="category" {...params} label="" placeholder="" />)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 p-0">
                                                                        <div className="height50">
                                                                            <input id="zip" name="zip" type="text" className="zipCodeGoogle iconMap" defaultValue={PinAddress} />
                                                                            <div id="map"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-2 pl-0">
                                                                        <div className="height50">
                                                                            <button className="btn-search" type="button" onClick={(e) => { this.handleSearch(); }}>Search</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Validator id="valSearch" type="required" cssClass="error" errorMessage="Provide a service or pin to search the professionals." valueToValidate={(!Category && !PinAddress ? '' : '1')} formValidators={this.formValidators} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="form-group mt-2">
                                                    <label>Refiners</label>
                                                </div>
                                                <div className="form-group mt-1">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-4">
                                                            <select id="distance" key={"distance_" + Distance} name="distance" className="form-control" placeholder="Distance" defaultValue={Distance} onChange={(e) => { this.setState({ Distance: e.target.value }, () => { this.refineSearch(); }); }}>
                                                                <option value="">Distance</option>
                                                                <option value="5">5 miles</option>
                                                                <option value="10">10 miles</option>
                                                                <option value="15">15 miles</option>
                                                                <option value="20">20 miles</option>
                                                                <option value="25">25 miles</option>
                                                                <option value="30">30 miles</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {showProgress ? (<div className="tabPanel"><ProgressBar /></div>) : (
                                                    <React.Fragment>
                                                        {!RefinedResults || RefinedResults.length <= 0 ? (<div className="tabPanel mt-1"><Typography variant="body2" color="text.secondary">There are no related pros available.</Typography></div>) : (<React.Fragment>
                                                            {RefinedResults.map((item, indx) => {
                                                                return (<div className="tabPanel mt-2">
                                                                    <div className="form-group">
                                                                        <div className="row">
                                                                            <div className="col-2 col-sm-1">
                                                                                <div className="form-group mt-3">
                                                                                    <Avatar alt={item.Profile.BusinessName} src={item.Profile.ProfileImage ? item.Profile.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 60, height: 60 }}></Avatar>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 col-sm-9">
                                                                                <div className="form-group mt-3">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12">
                                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{item.Profile.BusinessName}</Typography>
                                                                                        </div>
                                                                                        {/* <div className="col-5 col-sm-4 text-right">
                                                                                            <Typography variant="caption">
                                                                                                {this.IsUserValid && this.AppRole === Constants.UserRoles.Customer ? (
                                                                                                    <IconButton onClick={(e) => { this.handleRequestQuote(item); }} title="Request a quote"><ContactSupportOutlinedIcon /> Request a Quote</IconButton>) : (null)}
                                                                                                <IconButton onClick={(e) => { this.handleViewProfile(item) }} title="View profile"><BadgeOutlinedIcon /> View Pro's Profile</IconButton>
                                                                                            </Typography>
                                                                                        </div> */}
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12">
                                                                                            <Reviews id="reviews" profileId={item.Profile.Id} reviewerUser={this.User} allowAskForReviews={false} allowRating={true}
                                                                                                defaultRating={item.Rating && item.Rating.length > 0 && item.Rating[0].Rating ? item.Rating[0].Rating : 0} defaultReviews={item.Rating && item.Rating.length > 0 && item.Rating[0].TotalCount ? item.Rating[0].TotalCount : '0'}
                                                                                                ratingClassName='commentRatingStar' showRating={true} showReviewCount={true}></Reviews>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12">
                                                                                            <Typography variant="body2" color="text.secondary">{item.Profile.Description}</Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-sm-2">
                                                                                <div className="form-group mt-3">
                                                                                    {this.IsUserValid && this.AppRole === Constants.UserRoles.Customer ? (<div><Typography variant="caption" className="mt-3">
                                                                                        <ContactSupportOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleRequestQuote(item); }} title="Request a quote" className="ml-2">Request a Quote</a></Typography></div>) : (null)}
                                                                                    <div><Typography variant="caption" className="mt-3"><BadgeOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleViewProfile(item) }} title="View profile" className="ml-2">View Pro's Profile</a></Typography></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>);
                                                            })}
                                                        </React.Fragment>)}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                            ) : (null)}
                                            {TabIndex === 1 ? (<React.Fragment>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="breadCrumb mb-1">
                                                            <a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, ItemId: 0, RelatedPro: null, HasRequestedQuote: false, ProjectId: '', ProjectName: '', Portfolio: null, PortfolioMedia: [], FeaturedProjects: [], SelectedProject: null }, () => { this.initializePlacesAutoComplete(); }); }}>Search</a><span className="ml-2">&gt;</span><span className="ml-2">Pro Profile</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <h5>Pro Profile</h5>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <div className="form-group">
                                                        {showProgress ? (<div className="tabPanel"><ProgressBar /></div>) : (<React.Fragment>
                                                            {!RelatedPro ? (null) : (
                                                                <div className="profileContainer">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-12 p-0">
                                                                            <div className="formContainer">
                                                                                <div className="headingTxt">Profile</div>
                                                                                <div className="tabPanel">
                                                                                    <div className="col-12 col-sm-12">
                                                                                        <div className="row">
                                                                                            <div className="col-3 col-sm-2">
                                                                                                <div className="form-group mt-3">
                                                                                                    <Avatar alt={RelatedPro.Profile.BusinessName} src={RelatedPro.Profile.ProfileImage ? RelatedPro.Profile.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 80, height: 80 }}></Avatar>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-9 col-sm-10">
                                                                                                <div className="form-group mt-3">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{RelatedPro.Profile.BusinessName}</Typography>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <Reviews id="reviews" profileId={RelatedPro.Profile.Id} reviewerUser={this.User} allowAskForReviews={false} allowRating={true}
                                                                                                                defaultRating={RelatedPro.Rating && RelatedPro.Rating.length > 0 && RelatedPro.Rating[0].Rating ? RelatedPro.Rating[0].Rating : 0} defaultReviews={RelatedPro.Rating && RelatedPro.Rating.length > 0 && RelatedPro.Rating[0].TotalCount ? RelatedPro.Rating[0].TotalCount : '0'}
                                                                                                                ratingClassName='commentRatingStar' showRating={true} showReviewCount={true}></Reviews>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {this.AppRole === Constants.UserRoles.Customer ? (<div className="row">
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="formButtonContainer">
                                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin" disabled={RelatedPro.Profile.ActiveSubscription === Constants.Subscriptions.Basic} onClick={(e) => { this.handleMessage(); }}>Message {RelatedPro.Profile.BusinessName}</button> {/*disabled={this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic} */}
                                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" onClick={(e) => { this.handleRequestQuote(RelatedPro); }}>Request Quote</button>
                                                                                                                {/* <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={this.User.Profile.ActiveSubscription === Constants.Subscriptions.Basic || lead.HasSubmittedQuote} onClick={(e) => { if (this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) { this.submitQuote(lead); } }}>Submit Quote</button> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>) : (null)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mt-3">
                                                                                            <hr></hr>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-3">
                                                                                                <div className="form-group mt-3">
                                                                                                    <PhoneOutlinedIcon fontSize="small" ></PhoneOutlinedIcon> <label>Phone</label>
                                                                                                    <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.Phone}</Typography></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-3">
                                                                                                <div className="form-group mt-3">
                                                                                                    <EmailOutlinedIcon fontSize="small" ></EmailOutlinedIcon> <label>Email</label>
                                                                                                    <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.Email}</Typography></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-3">
                                                                                                <div className="form-group mt-3">
                                                                                                    <EmojiEventsOutlinedIcon fontSize="small" ></EmojiEventsOutlinedIcon> <label>Year founded</label>
                                                                                                    <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.YearFounded}</Typography></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-3">
                                                                                                <div className="form-group mt-3">
                                                                                                    <WebOutlinedIcon fontSize="small" ></WebOutlinedIcon> <label>Website</label>
                                                                                                    <div className="pl-4"><Typography variant="subtitle2"><a href={RelatedPro.Profile.WebSiteUrl} target='_blank'>{RelatedPro.Profile.WebSiteUrl}</a></Typography></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mt-3">
                                                                                            <hr></hr>
                                                                                        </div>
                                                                                        <div className="form-group mt-3">
                                                                                            <LocationOnOutlinedIcon fontSize="small" ></LocationOnOutlinedIcon> <label>Address</label>
                                                                                            <div className="pl-4"><Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.Address}{RelatedPro.Profile.CityName ? ", " + RelatedPro.Profile.CityName : ''}{RelatedPro.Profile.StateName ? ", " + RelatedPro.Profile.StateName : ''}{RelatedPro.Profile.Pin ? " " + RelatedPro.Profile.Pin : ''}</Typography></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tabPanel mt-1">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12 align-self-center">
                                                                                            <div className="headingTxt">Social media</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-12">
                                                                                        <div className="form-group mt-3">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    {RelatedPro.SocialMedia && RelatedPro.SocialMedia.length > 0 ? (<React.Fragment>
                                                                                                        {RelatedPro.SocialMedia.map((item) => {
                                                                                                            return (<React.Fragment>
                                                                                                                {item.LinkUrl ? (<div><img src={'/images/' + item.LinkType + 'Icon.png'} alt={item.LinkType} /> <a href={item.LinkUrl} target='_blank'><Typography variant='caption'>{item.LinkUrl}</Typography></a></div>) : (null)}
                                                                                                            </React.Fragment>);
                                                                                                        })}
                                                                                                    </React.Fragment>) : (<Typography variant="subtitle2" color="text.secondary">There is no information available about Pro's social media presence.</Typography>)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tabPanel mt-1">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12 align-self-center">
                                                                                            <div className="headingTxt">Payment methods accepted</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-12">
                                                                                        <div className="form-group mt-3">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    <Typography variant="subtitle2" className="commentBody">{RelatedPro.Payments && RelatedPro.Payments.length > 0 ? (<React.Fragment>
                                                                                                        {RelatedPro.Payments.map((p, indx) => {
                                                                                                            if (indx < RelatedPro.Payments.length - 1)
                                                                                                                return (p.Mode) + ', ';
                                                                                                            else
                                                                                                                return (p.Mode);
                                                                                                        })}
                                                                                                    </React.Fragment>) : (<Typography variant="subtitle2" color="text.secondary">There is no information available about Pro's accepted payment methods.</Typography>)}</Typography>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tabPanel mt-1">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12 align-self-center">
                                                                                            <div className="headingTxt">Your introduction</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-12">
                                                                                        <div className="form-group mt-3">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    <Typography variant="subtitle2" className="commentBody">{RelatedPro.Profile.Description}</Typography>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tabPanel mt-1">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12 align-self-center">
                                                                                            <div className="headingTxt">Accreditations</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-12">
                                                                                        <div className="form-group mt-3">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    {RelatedPro.Accreditations && RelatedPro.Accreditations.length > 0 ? (
                                                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                                                            <Grid container item spacing={3} columns={12}>
                                                                                                                <React.Fragment>
                                                                                                                    {RelatedPro.Accreditations.map((item) => {
                                                                                                                        let subHeader = (<React.Fragment><div>{item.Institution}</div><div>{this.getFormattedDate(new Date(this.getDateNumber(item.StartDate)), Constants.IDateTimeFormat.MMddyyyy, '/') + " - " + this.getFormattedDate(new Date(this.getDateNumber(item.EndDate)), Constants.IDateTimeFormat.MMddyyyy, '/')}</div></React.Fragment>);
                                                                                                                        return (<Grid key={'grdItem_' + item.Id} item xs={12}>
                                                                                                                            <React.Fragment>
                                                                                                                                <Card key={'card_' + item.Id} variant="outlined" className="licenseCard">
                                                                                                                                    <CardHeader avatar={<Avatar sx={{ bgcolor: red[500] }}>{item.Name ? item.Name.charAt(0).toUpperCase() : ''}</Avatar>}
                                                                                                                                        title={item.Name} subheader={subHeader} />
                                                                                                                                    <CardContent className="licenseCardContentMaxHeight">
                                                                                                                                        <Typography variant="subtitle2" color="text.secondary" className="commentBody">
                                                                                                                                            {item.Description}
                                                                                                                                        </Typography>
                                                                                                                                    </CardContent>
                                                                                                                                </Card>
                                                                                                                            </React.Fragment>
                                                                                                                        </Grid>);
                                                                                                                    })}
                                                                                                                </React.Fragment>
                                                                                                            </Grid>
                                                                                                        </Box>) : (<Typography variant="subtitle2" color="text.secondary">There is no information available for the Pro's accreditations.</Typography>)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tabPanel mt-1">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12 align-self-center">
                                                                                            <div className="headingTxt">Photos & videos</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-12">
                                                                                        <div className="form-group mt-3">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    {PortfolioMedia && PortfolioMedia.length > 0 ? (
                                                                                                        <Box className="boxPortfolioMedia">
                                                                                                            <Grid container item spacing={0} columns={12}>
                                                                                                                <React.Fragment>
                                                                                                                    {PortfolioMedia.map((item) => {
                                                                                                                        let thumbnailUrl = item.Url;
                                                                                                                        let isYouTube = false;
                                                                                                                        let videoUrl = item.Url;

                                                                                                                        if (thumbnailUrl.toLowerCase().indexOf('youtube.com') >= 0) {
                                                                                                                            let videoID = item.Url.substring(item.Url.indexOf('v=') + 2);

                                                                                                                            isYouTube = true;
                                                                                                                            thumbnailUrl = "https://img.youtube.com/vi/" + videoID + "/0.jpg";
                                                                                                                            videoUrl = "https://www.youtube.com/embed/" + videoID;
                                                                                                                        }
                                                                                                                        else if (thumbnailUrl.toLowerCase().indexOf('vimeo.com') >= 0) {
                                                                                                                            let videoID = videoUrl.substring(videoUrl.lastIndexOf('/') + 1);

                                                                                                                            isYouTube = true;
                                                                                                                            videoUrl = "https://player.vimeo.com/video/" + videoID + "?fullscreen=1";
                                                                                                                        }

                                                                                                                        return (<Grid key={'grdItem_' + item.Id} item xs={4}>
                                                                                                                            <React.Fragment>
                                                                                                                                <Card key={'card_' + item.Id} variant="outlined" className="portfolioMediaCard">
                                                                                                                                    {item.Type === 'V' ? (<CardMedia component={isYouTube ? "iframe" : "video"} height="194" playsInline={true} allowFullScreen={true} frameBorder={"0"} src={videoUrl} controls={true} title={item.Name} />) :
                                                                                                                                        (<CardMedia component="img" height="194" image={item.Url} alt={item.Name} title={item.Name} onClick={(e) => { window.open(item.Url); }} />)}
                                                                                                                                </Card>
                                                                                                                            </React.Fragment>
                                                                                                                        </Grid>);
                                                                                                                        // return (<Grid key={'grdItem_' + item.Id} item xs={4}>
                                                                                                                        //     <React.Fragment>
                                                                                                                        //         <Card key={'card_' + item.Id} variant="outlined" className="portfolioMediaCard">
                                                                                                                        //             {item.Type === 'V' ? (<CardMedia component="video" height="194" image={item.Url} controls={true} title={item.Name} onClick={(e) => { window.open(item.Url); }} />) :
                                                                                                                        //                 (<CardMedia component="img" height="194" image={item.Url} alt={item.Name} title={item.Name} onClick={(e) => { window.open(item.Url); }} />)}
                                                                                                                        //         </Card>
                                                                                                                        //     </React.Fragment>
                                                                                                                        // </Grid>);
                                                                                                                    })}
                                                                                                                </React.Fragment>
                                                                                                            </Grid>
                                                                                                        </Box>) : (null)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tabPanel mt-1">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12 align-self-center">
                                                                                            <div className="headingTxt">Featured projects</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-12">
                                                                                        <div className="form-group mt-3">
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-12">
                                                                                                    {FeaturedProjects && FeaturedProjects.length > 0 ? (<Box className="boxPortfolioMedia">
                                                                                                        <Grid container item spacing={0} columns={12}>
                                                                                                            <React.Fragment>
                                                                                                                {FeaturedProjects.map((item, indx) => {
                                                                                                                    let thumbnail = item.Media && item.Media.length > 0 ? item.Media[0].LinkUrl : '';
                                                                                                                    if (!thumbnail) {
                                                                                                                        thumbnail = item.Service ? item.Service.DefaultImage : '';
                                                                                                                    }

                                                                                                                    return (<Grid key={'grdPrjItem_' + item.Id} item xs={4}>
                                                                                                                        <React.Fragment>
                                                                                                                            <Card key={'cardPrj_' + item.Id} variant="outlined" className={"portfolioProjectCard"} >
                                                                                                                                <CardMedia component="img" height="194" image={thumbnail} alt={item.Name} title={item.Name} />
                                                                                                                                <CardContent>
                                                                                                                                    <Typography gutterBottom variant="subtitle1" component="a" onClick={(e) => { this.viewFeaturedProject(item); }}>
                                                                                                                                        {item.Name}
                                                                                                                                    </Typography>
                                                                                                                                    <Typography variant="body2" color="text.secondary">
                                                                                                                                        {item.Price ? " Approx. $" + item.Price : ''}
                                                                                                                                    </Typography>
                                                                                                                                </CardContent>
                                                                                                                            </Card>
                                                                                                                        </React.Fragment>
                                                                                                                    </Grid>);
                                                                                                                })}
                                                                                                            </React.Fragment>
                                                                                                        </Grid>
                                                                                                    </Box>) : (null)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {!showProjectDialog ? (null) : (
                                                                                            <Dialog key='dlgPortfolioProject' keepMounted TransitionComponent={this.SlideDownTransition} open={showProjectDialog}
                                                                                                onClose={(e) => { this.resetProjectDialog(); }} aria-labelledby={"dialogPortfolioProjectTitle"}
                                                                                                fullWidth={true} maxWidth={"sm"} >
                                                                                                <DialogTitle id={"dialogPortfolioProjectTitle"} className="modalTitle">{ProjectTitle ? ProjectTitle : 'New Featured Project'}
                                                                                                    <IconButton aria-label="close" onClick={(e) => { this.resetProjectDialog(); }} className="btnCloseDialog">
                                                                                                        <CloseIcon />
                                                                                                    </IconButton>
                                                                                                </DialogTitle>
                                                                                                <DialogContent dividers={true}>
                                                                                                    <div className="profileContainer">
                                                                                                        <div className="formContainer">
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label>Project title</label>
                                                                                                                        <Typography variant="subtitle2">{ProjectTitle}</Typography>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Service</label>
                                                                                                                                <Typography variant="subtitle2">{ProjectServiceName}</Typography>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Year</label>
                                                                                                                                <Typography variant="subtitle2">{ProjectYear}</Typography>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="form-group">
                                                                                                                        <label>Approximate total price</label>
                                                                                                                        <Typography variant="subtitle2">{ProjectPrice}</Typography>
                                                                                                                    </div>
                                                                                                                    <div className="form-group">
                                                                                                                        <label>Description </label>
                                                                                                                        <Typography variant="subtitle2">{ProjectDescription}</Typography>
                                                                                                                    </div>
                                                                                                                    {ProjectFiles && ProjectFiles.length > 0 ? (
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-12 col-sm-12">
                                                                                                                                <div className="form-group">
                                                                                                                                    <hr />
                                                                                                                                    <Box className="boxPortfolioMedia">
                                                                                                                                        <Grid container item spacing={0} columns={12}>
                                                                                                                                            {ProjectFiles.map((item, indx) => {
                                                                                                                                                return (<Grid key={'grdPrjItem_' + item.id} item xs={3}>
                                                                                                                                                    <Card key={'cardPrj_' + item.id} variant="outlined" className={"portfolioProjectCard" + (indx > 0 ? ' ml-2' : '')} >
                                                                                                                                                        <CardMedia component="img" height="100" image={item.url} alt={item.name} title={item.name} onClick={(e) => { window.open(item.url); }} />
                                                                                                                                                    </Card>
                                                                                                                                                </Grid>);
                                                                                                                                            })}
                                                                                                                                        </Grid>
                                                                                                                                    </Box>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>) : (null)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </DialogContent>
                                                                                                <DialogActions>
                                                                                                    <div className="formButtonContainer text-right">
                                                                                                        <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.resetProjectDialog(); }}>Cancel</button>
                                                                                                    </div>
                                                                                                </DialogActions>
                                                                                            </Dialog>
                                                                                        )}
                                                                                        {!showMessageDialog ? (null) : (
                                                                                            <React.Fragment>
                                                                                                <Dialog fullWidth={true} open={showMessageDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} >
                                                                                                    <DialogTitle id={"detailsDialog"} className="modalTitle">Compose message
                                                                                                        <IconButton aria-label="close" onClick={(e) => { this.resetMessage(); }} className="btnCloseDialog" disabled={showDialogProgress} >
                                                                                                            <CloseIcon />
                                                                                                        </IconButton>
                                                                                                    </DialogTitle>
                                                                                                    <DialogContent dividers={true}>
                                                                                                        <div className="profileContainer">
                                                                                                            <div className="formContainer">
                                                                                                                <div className="col-12 col-sm-12 p-0">
                                                                                                                    <div className="minHeightDialogContainer">
                                                                                                                        {showDialogProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                                                            <div className="form-group mt-3">
                                                                                                                                <label>Message</label>
                                                                                                                                <textarea id="message" name="message" key="message" rows={6} className="form-control" placeholder="" defaultValue={Body} onBlur={(e) => { this.setState({ Body: e.target.value }); }} />
                                                                                                                                <Validator id="valMessage" type='required' cssClass="error" errorMessage="Provide message." controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                                                                <Validator id="valMessageLength" type='max_length' cssClass="error" errorMessage="Message cannot exceed 1000 characters." maxLength={1000} controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                                                            </div>
                                                                                                                        </React.Fragment>)}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </DialogContent>
                                                                                                    <DialogActions>
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group text-right">
                                                                                                                <div className="formButtonContainer mt-3">
                                                                                                                    <button type="submit" className="btn btn-cancel" disabled={showDialogProgress} onClick={(e) => { this.resetMessage(); }}>Cancel</button>
                                                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin ml-2" disabled={showDialogProgress} onClick={(e) => { this.handleSendMessage(); }}>Send</button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </DialogActions>
                                                                                                </Dialog>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </React.Fragment>)}
                                                    </div>
                                                </div>
                                            </React.Fragment>) : (null)}
                                            {TabIndex === 2 ? (<React.Fragment>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="breadCrumb mb-1">
                                                            <a href="javascript:void(0);" onClick={(e) => { this.setState({ TabIndex: 0, ItemId: 0, RelatedPro: null, RelatedProjects: [], RequestedQuotes: [], HasRequestedQuote: false, ProjectId: '', ProjectName: '', SelectedProject: null }, () => { this.initializePlacesAutoComplete(); }); }}>Search</a><span className="ml-2">&gt;</span><span className="ml-2">Request Quote</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <h5>Request Quote</h5>
                                                    </div>
                                                </div>
                                                <div className="tabPanel">
                                                    <div className="form-group">
                                                        {showProgress ? (<ProgressBar />) : (
                                                            <React.Fragment>
                                                                <div className="form-group">
                                                                    <label>Project name</label>
                                                                    {!RelatedProjects || RelatedProjects.length <= 0 ? (<Typography variant="body2" color="text.secondary" className="commentBody">There are no related projects for this category. <a href='/projects/new'>Click here</a> to create a new project.</Typography>) : (
                                                                        <select id="project" name="project" className="form-control" defaultValue={ProjectId} onChange={(e) => { this.setState({ ProjectId: e.target.value }, () => { this.handleProjectChange(); }); }}>
                                                                            <option value="">Select</option>
                                                                            {RelatedProjects && RelatedProjects.map((item, indx) => {
                                                                                return (<option value={item.Id}>{item.Name}</option>);
                                                                            })}
                                                                        </select>)}
                                                                </div>
                                                                <div className="form-group mt-3">
                                                                    <hr></hr>
                                                                </div>
                                                                <div className="form-group mt-3">
                                                                    <div className="formButtonContainer text-right">
                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" disabled={!ProjectId || HasRequestedQuote ? true : false} onClick={(e) => { this.handleQuoteSubmission(); }}>Request for Quote</button>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </React.Fragment>) : (null)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment >);
        }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}