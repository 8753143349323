import {
    Alert, Autocomplete, Avatar, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton,
    Snackbar, TextField, Typography
} from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { IAutoCompleteOption, Loader, ProgressBar } from "src/common/Components";
import Constants from "src/common/Constants";
import CloseIcon from '@mui/icons-material/Close';
import Account from "./Account";
import DeactivateAccount from "./DeactivateAccount";
import Validator from "src/common/Validator";
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Stripes from "src/api/stripe";
import { StripeElements, loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(Constants.Stripe.PublishKey);
//const stripe = require('stripe')(Constants.Stripe.SecretKey);

export default class UserAccount extends Base {

    private validator: Validator;
    private formValidators: Validator[];
    private billingFormValidators: Validator[];
    private _stripe: Stripes;

    constructor(props) {
        super(props);

        this.state = {
            loading: true, IsActive: true, ActiveTab: (this.props.id && this.props.id > 0 ? 5 : 0), ProfileId: 0, TabIndex: 0, showBillingChangeProgress: false,
            showPasswordProgress: false, showProfileProgress: true, showNotificationProgress: false, showSubscriptionProgress: false, showBillingProgress: false, showPaymentDialog: false,
            FirstName: '', LastName: '', DisplayName: '', HighRatedParameters: [], BusinessName: '', Email: '', Phone: '', Address: '', ProfileImage: '', CityName: '', StateName: '', StateCode: '',
            Pin: '', WebSiteUrl: '', Year: '', Description: '', Role: '', OVerallRating: 0, TotalReviewsCount: 0, Accreditations: [], Payments: [], SocialMediaLinks: [],
            TwitterUrl: '', FacebookUrl: '', InstagramUrl: '', YouTubeUrl: '', LinkedInUrl: '', showDialog: false, Message: '', MessageType: '', ShowMessage: false, showConfirmation: false,
            CurrentPassword: '', NewPassword: '', ConfirmPassword: '', ActiveSubscription: 'B', Notifications: [], Billing: [], ItemId: 0, DialogMode: '', callBackMethod: null, ConfirmationType: '',
            CardNumber: '', CardExpiryMonth: '', CardExpiryMonthTitle: '', Months: [], Years: [], Countries: [], CardType: '',
            CardExpiryYear: '', CardFirstName: '', CardLastName: '', CardZipCode: '', CardBillingAddress: '', CardCountryName: '', CardCountry: '', CardCompanyName: '', IsDefaultCard: false,
            showAskForReviewDialog: false, Emails: '', CVC: '', StripePayments: [], Subscription: null, Recipients: [], ReviewRecipients: [], paymentOptions: {},
            showNewCard: false, PaymentMethodID: ''
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
        this.billingFormValidators = new Array<Validator>();
        this._stripe = new Stripes();
        this.handleStripePay = this.handleStripePay.bind(this);
    }

    public componentDidMount() {
        this.addGoogleMapScriptTag();

        this.getCountries().then((countries) => {
            let _countries: IAutoCompleteOption[] = [];
            if (countries && countries.length > 0) {
                countries.forEach((cnt) => {
                    _countries.push({ key: cnt.Name, text: cnt.Name, value: cnt.Id });
                });
            }

            _countries = _countries.sort((a, b) => { return (a.key > b.key ? 1 : -1); });

            let _years: IAutoCompleteOption[] = [];
            let _currentYear = new Date().getFullYear();
            for (var i = 0; i <= 5; i++) {
                let year = (_currentYear + i).toString();
                _years.push({ key: year, text: year, value: year });
            }

            let stripeCustomer = this.User && this.User.Profile && this.User.Profile.StripeCustomer ? JSON.parse(this.User.Profile.StripeCustomer) : null;
            if (stripeCustomer && stripeCustomer.subscription) {
                this._stripe.getSubscriptionById(stripeCustomer.subscription.id).then((subscription) => {
                    this.setState({ Months: this.getMonths(), Years: _years, Countries: _countries, Subscription: subscription }, () => {
                        this.loadComponent();
                    });
                });
            }
            else {
                this.setState({ Months: this.getMonths(), Years: _years, Countries: _countries, Subscription: null }, () => {
                    this.loadComponent();
                });
            }
        });
    }

    private addGoogleMapScriptTag() {
        let scriptElems = document.getElementsByTagName("script");
        let elemExists = false;
        for (var i = 0; i < scriptElems.length; i++) {
            if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
                elemExists = true;
        }

        if (!elemExists) {
            const script = document.createElement("script");
            script.src = Constants.Google.PlacesScriptPath;
            //script.id = "scriptGoogleMaps";
            script.setAttribute('async', '');
            script.setAttribute('defer', '');

            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }

    private loadComponent() {
        this.setState({ loading: true });
        this.getUserProfile().then((profile) => {
            if (profile) {
                this.getSocialMediaLinks(profile.Id).then((socialMediaLinks) => {
                    this.getPayments(profile.Id).then((payments) => {
                        this.getAccreditations(profile.Id).then((accreditations) => {
                            this.db.getRecipients(profile.Id, 'P').then((recipients) => {
                                let _twitter = '';
                                let _facebook = '';
                                let _insta = '';
                                let _youtube = '';
                                let _linkedin = '';

                                if (socialMediaLinks) {
                                    socialMediaLinks.forEach((link) => {
                                        if (link.LinkType === Constants.SocialMediaLinkType.Twitter)
                                            _twitter = link.LinkUrl;
                                        else if (link.LinkType === Constants.SocialMediaLinkType.Facebook)
                                            _facebook = link.LinkUrl;
                                        else if (link.LinkType === Constants.SocialMediaLinkType.Instagram)
                                            _insta = link.LinkUrl;
                                        else if (link.LinkType === Constants.SocialMediaLinkType.LinkedIn)
                                            _linkedin = link.LinkUrl;
                                        else if (link.LinkType === Constants.SocialMediaLinkType.YouTube)
                                            _youtube = link.LinkUrl;
                                    });
                                }

                                this.setState({
                                    loading: false, showProfileProgress: false, AccountProfile: profile,
                                    ProfileId: profile.Id, FirstName: profile.User ? profile.User.FirstName : '', LastName: profile.User ? profile.User.LastName : '',
                                    HighRatedParameters: profile.HighRatedParameters, BusinessName: profile.BusinessName,
                                    Email: profile.User ? profile.User.Email : '',
                                    Phone: profile.Phone, Address: profile.User ? profile.User.Address : '',
                                    ProfileImage: profile.ProfileImage, CityName: profile.User && profile.User.City ? profile.User.City.Name : '', Pin: profile.User.Pin,
                                    StateName: profile.User.State ? profile.User.State.Name : '', StateCode: profile.User.State ? profile.User.State.Code : '', IsActive: profile.IsActive, WebSiteUrl: profile.WebSiteUrl, Year: profile.YearFounded,
                                    Description: profile.Description, DisplayName: profile.User ? profile.User.DisplayName : '', ActiveSubscription: profile.ActiveSubscription,
                                    Role: (this.AppRole.toLowerCase() === Constants.UserRoles.Pro.toLowerCase() ? Constants.UserRoles.Pro : (this.AppRole.toLowerCase() === Constants.UserRoles.Customer.toLowerCase() ? Constants.UserRoles.Customer : '')),
                                    OverallRating: profile.Rating, TotalReviewsCount: profile.Comments,
                                    SocialMediaLinks: socialMediaLinks, TwitterUrl: _twitter, FacebookUrl: _facebook, InstagramUrl: _insta, YouTubeUrl: _youtube, LinkedInUrl: _linkedin,
                                    Accreditations: accreditations, Payments: payments, ReviewRecipients: recipients
                                }, () => {
                                    if (this.props.id && this.state.ActiveTab === 5) {
                                        this.getBillingInfo();
                                    }
                                });
                            });
                        });
                    });
                });
            }
            else {
                this.setState({ loading: false, showProfileProgress: false, });
            }
        });
    }

    private getUserProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.props.id && this.props.id > 0) {
                this.db.getUserProfileById(this.props.id, Constants.UserRoles.Pro).then((profile) => {
                    resolve(profile);
                });
            }
            else if (this.LoginName) {
                this.db.getUserProfile(this.LoginName, this.AppRole).then((profile) => {
                    resolve(profile);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private getSocialMediaLinks(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            if (profileId > 0) {
                this.db.getSocialMediaLinks(profileId).then((result) => {
                    resolve(result);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private getAccreditations(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.getLicenses(profileId).then((result) => {
                if (result) {
                    result.forEach((_l) => {
                        _l.StartDate = this.getFormattedDate(new Date(this.getDateNumber(_l.StartDate)), Constants.IDateTimeFormat.yyyyMMdd, '-');
                        _l.EndDate = this.getFormattedDate(new Date(this.getDateNumber(_l.EndDate)), Constants.IDateTimeFormat.yyyyMMdd, '-');
                    });
                }

                resolve(result);
            });
        });
    }

    private getPayments(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.getPaymentModes(profileId).then((result) => {
                resolve(result);
            });
        });
    }

    private getNotifications() {
        this.setState({ showNotificationProgress: true });
        this.db.getProfileNotifications(this.state.ProfileId).then((result) => {
            let _temp: any[] = [];
            if (result) {
                result.forEach((n) => {
                    _temp.push({ type: n.Name, status: n.IsActive });
                });
            }

            this.setState({ showNotificationProgress: false, Notifications: _temp });
        });
    }

    private handleSaveNotifications() {
        let notifications = this.state.Notifications;
        this.setState({ showNotificationProgress: true });

        if (notifications && notifications.length > 0) {
            let counter = 0;

            notifications.forEach((n) => {
                this.db.addProfileNotification(this.state.ProfileId, n.type, n.status).then((result) => {
                    counter++;

                    if (counter === notifications.length) {
                        this.successMessage('Notifications updated.');
                        this.getNotifications();
                    }
                });
            });
        }
        else {
            this.setState({ showNotificationProgress: false });
        }
    }

    private getBillingInfo() {
        this.setState({ showBillingProgress: true });
        this.db.getProfileBillingInfo(this.state.AccountProfile.Id).then((result) => {
            let defaultBilling: any = null;
            let otherBillings: any = [];

            if (result) {
                defaultBilling = result.find((n) => { return (n.IsDefault); });
                // if (defaultBilling) {
                //     let cType = defaultBilling.CardType;
                //     if (cType.indexOf('~') > 0) {
                //         defaultBilling.CardType = cType.split('~')[0];
                //         defaultBilling.StripePaymentMethodID = cType.split('~')[1];
                //     }
                // }

                otherBillings = result.filter((n) => { return (!n.IsDefault); });
                // if (otherBillings && otherBillings.length > 0) {
                //     otherBillings.forEach((billing: any) => {
                //         let dcType = billing.CardType;
                //         if (dcType.indexOf('~') > 0) {
                //             billing.CardType = dcType.split('~')[0];
                //             billing.StripePaymentMethodID = dcType.split('~')[1];
                //         }
                //     });
                // }
            }

            if (this.state.ActiveTab === 5) {
                this.db.getUserPaymentHistory(this.state.AccountProfile.Id).then((userPayments: any) => {
                    this.setState({
                        showBillingProgress: false, showBillingEdit: false, Billing: result, PaymentMethods: [], showNewPaymentMethod: false, ItemId: 0,
                        DefaultBilling: defaultBilling, OtherBilling: otherBillings, StripePayments: userPayments, PaymentMethodID: defaultBilling?.StripePaymentMethodID,
                        showNewCard: (!defaultBilling && (!otherBillings || otherBillings.length <= 0))
                    }, () => {
                        this.updateUserPaymentHistory();
                    });
                });

                // let stripeCustomer = this.state.AccountProfile && this.state.AccountProfile.StripeCustomer ? JSON.parse(this.state.AccountProfile.StripeCustomer) : null;
                // if (stripeCustomer && stripeCustomer.customer) {
                //     this._stripe.getCustomerPayments(stripeCustomer.customer.id).then((payments) => {
                //         this.setState({
                //             showBillingProgress: false, Billing: result, PaymentMethods: [], showNewPaymentMethod: false, ItemId: 0,
                //             DefaultBilling: defaultBilling, OtherBilling: otherBillings, StripePayments: payments
                //         });
                //     });
                // }
                // else {
                //     this.setState({
                //         showBillingProgress: false, Billing: result, PaymentMethods: [], showNewPaymentMethod: false, ItemId: 0,
                //         DefaultBilling: defaultBilling, OtherBilling: otherBillings
                //     });
                // }
            }
            else {
                this.setState({
                    showBillingProgress: false, showBillingEdit: false, Billing: result, PaymentMethods: [], showNewPaymentMethod: false, ItemId: 0,
                    DefaultBilling: defaultBilling, OtherBilling: otherBillings, PaymentMethodID: defaultBilling?.StripePaymentMethodID,
                    showNewCard: (!defaultBilling && (!otherBillings || otherBillings.length <= 0))
                });
            }
        });
    }

    private updateUserPaymentHistory(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let stripeCustomer = this.state.AccountProfile && this.state.AccountProfile.StripeCustomer ? JSON.parse(this.state.AccountProfile.StripeCustomer) : null;
            if (stripeCustomer && stripeCustomer.customer) {
                this._stripe.getCustomerPayments(stripeCustomer.customer.id).then((payments) => {
                    if (payments) {
                        let tempPayments: any[] = [];
                        payments.data.forEach((pm: any) => {
                            let existingPayment = this.state.StripePayments && this.state.StripePayments.find((sPm: any) => { return (sPm.Customer === pm.customer && sPm.PaymentIntent === pm.id && (!pm.invoice || sPm.Invoice === pm.invoice)); });
                            if (!existingPayment && pm.status.toLowerCase() !== 'requires_confirmation') {
                                tempPayments.push(pm);
                            }
                        });

                        if (tempPayments && tempPayments.length > 0) {
                            let counter = 0;
                            tempPayments.forEach((tPM: any) => {
                                this.db.updateUserPaymentHistory(this.state.AccountProfile.Id, tPM.customer, tPM.currency, tPM.charges && tPM.charges.data && tPM.charges.data.length > 0 ? tPM.charges.data[0].id : '', tPM.id, tPM.payment_method ? tPM.payment_method : '', tPM.invoice ? tPM.invoice : "", tPM.amount ? tPM.amount : 0, tPM.amount_received ? tPM.amount_received : 0, tPM.created, tPM.status, tPM.description, tPM.charges && tPM.charges.data && tPM.charges.data.length > 0 ? tPM.charges.data[0].receipt_url : '').then((isHistoryUpdated: boolean) => {
                                    counter++;
                                    if (counter === tempPayments.length)
                                        resolve(true);
                                });
                            });
                        }
                        else
                            resolve(true);
                    }
                    else {
                        resolve(true);
                    }
                });
            }
            else {
                resolve(true);
            }
        });
    }

    private handleTabChange(tabId) {
        this.formValidators = new Array<Validator>();
        this.setState({ ActiveTab: tabId, showDialog: false, TabIndex: 0 }, () => {
            if (tabId === 2) { // Notifications
                this.getNotifications();
            }
            else if (tabId === 4) { // Subscription
                this.db.getUserPaymentHistory(this.state.AccountProfile.Id).then((userPayments: any) => {
                    this.setState({
                        StripePayments: userPayments
                    }, () => {
                        this.updateUserPaymentHistory();
                    });
                });
            }
            else if (tabId === 5) { // Billing
                this.getBillingInfo();
            }
        });
    }

    private sendEmail(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.ChangePassword;
            let subject = emailTemplate.Subject;
            let body = emailTemplate.Body;

            body = body.replace("[username]", this.User.FirstName + (this.User.LastName ? ' ' + this.User.LastName.toString().charAt(0) + '.' : ''));

            if (this.User.Email) {
                this.db.sendEmail(this.User.Email, subject, body).then((sent) => {
                    if (sent) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(false);
            }
        });
    }

    public handleSavePassword() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showPasswordProgress: true });
            this.db.changePassword(this.User.Id, this.encodeString(this.state.NewPassword)).then((changed) => {
                if (changed) {
                    this.sendEmail().then((sent) => {
                        if (sent) {
                            this.successMessage('Password changed sucessfully.');
                        }
                        else {
                            this.errorMessage('Password changed successfully but email could not be sent.');
                        }
                    });
                }
                else {
                    this.errorMessage('Password cannot be changed.');
                }

                this.setState({ showPasswordProgress: false });
            });
        }
    }

    private handleNotificationChange(e, mode) {
        let notifications = this.state.Notifications;
        let existing = notifications.find((n) => { return (n.type === mode); });

        if (!existing) {
            notifications.push({ type: mode, status: e.target.checked });
        }
        else {
            existing.status = e.target.checked;
        }

        this.setState({ Notifications: notifications });
    }

    private getNotificationStatus(mode) {
        let notifications = this.state.Notifications;
        let existing = notifications.find((n) => { return (n.type === mode); });

        if (!existing) {
            return false;
        }
        else {
            return existing.status;
        }
    }

    private handleSubscriptionUpgrade(mode) {
        this.db.getProfileBillingInfo(this.state.ProfileId).then((result) => {
            let defaultBilling: any = null;
            let otherBillings: any = [];

            if (result) {
                defaultBilling = result.find((n) => { return (n.IsDefault); });
                // if (defaultBilling) {
                //     let cType = defaultBilling.CardType;
                //     if (cType.indexOf('~') > 0) {
                //         defaultBilling.CardType = cType.split('~')[0];
                //         defaultBilling.StripePaymentMethodID = cType.split('~')[1];
                //     }
                // }

                otherBillings = result.filter((n) => { return (!n.IsDefault); });
                // if (otherBillings && otherBillings.length > 0) {
                //     otherBillings.forEach((billing: any) => {
                //         let dcType = billing.CardType;
                //         if (dcType.indexOf('~') > 0) {
                //             billing.CardType = dcType.split('~')[0];
                //             billing.StripePaymentMethodID = dcType.split('~')[1];
                //         }
                //     });
                // }
            }

            if (mode === 'B') {
                this.setState({ showConfirmation: true, DefaultBilling: defaultBilling, ConfirmationMessage: 'Are you sure to change your plan to Basic?', ConfirmationType: 'C', callBackMethod: this.downgradePlan.bind(this) });
            }
            else if (mode === 'P') {
                this.setState({
                    showPaymentDialog: true, DialogMode: '', Billing: result, DefaultBilling: defaultBilling, OtherBilling: otherBillings, ItemId: defaultBilling ? defaultBilling.Id : 0, //CardNumber: defaultBilling.CardNumber,
                    CardExpiryMonth: defaultBilling?.ExpiryMonth.toString(),
                    CardExpiryMonthTitle: defaultBilling?.ExpiryMonth.toString(), CardExpiryYear: defaultBilling?.ExpiryYear.toString(), CardFirstName: defaultBilling?.FirstName,
                    CardLastName: defaultBilling?.LastName, CardZipCode: defaultBilling?.PinCode.toString(),
                    CardBillingAddress: defaultBilling?.Address, CardCountryName: defaultBilling?.Country?.Name,
                    CardCountry: defaultBilling?.Country?.Id.toString(),
                    CardCompanyName: defaultBilling?.CompanyName, IsDefaultCard: defaultBilling?.IsDefault, CardType: defaultBilling?.CardType,
                    showNewCard: (!defaultBilling && (!this.state.OtherBilling || this.state.OtherBilling.length <= 0))
                });
            }
        });
    }

    private downgradePlan() {
        this.setState({ showSubscriptionProgress: true, showConfirmation: false, ConfirmationMessage: '', ConfirmationType: '', callBackMethod: null });

        let stripeCustomer = this.User && this.User.Profile && this.User.Profile.StripeCustomer ? JSON.parse(this.User.Profile.StripeCustomer) : null;
        if (stripeCustomer && stripeCustomer.subscription && stripeCustomer.paymentMethod && stripeCustomer.customer) {
            this._stripe.getSubscriptionById(stripeCustomer.subscription.id).then((subscription) => {
                if (subscription) {
                    if (subscription.status === 'active') {
                        this._stripe.cancelSubscription(subscription.id).then((cSubscription) => {
                            if (cSubscription && cSubscription.status === 'canceled') {
                                stripeCustomer.subscription = cSubscription;

                                this.getPaymentMethod(stripeCustomer.customer, null, false).then((pMethod) => {
                                    if (pMethod) {
                                        this._stripe.detachPaymentMethod(pMethod.id).then(async (paymentMethod) => {
                                            if (paymentMethod) {
                                                this.getCustomer(false).then((customer) => {
                                                    if (customer) {
                                                        stripeCustomer.customer = customer;
                                                        stripeCustomer.paymentMethod = paymentMethod;

                                                        // this._stripe.deleteCustomer(customer.id).then((deleted) => {
                                                        //     if (deleted) {
                                                        this.db.updateProfileSubscription(this.state.ProfileId, 'B', JSON.stringify(stripeCustomer)).then((result) => {
                                                            if (result) {
                                                                this.db.deletePaymentMethod(this.state.DefaultBilling.Id).then((deleted) => {
                                                                    if (deleted) {
                                                                        this.getUserProfile().then((profile) => {
                                                                            this.setState({ AccountProfile: profile, showSubscriptionProgress: false, ActiveSubscription: profile.ActiveSubscription }, () => {
                                                                                this.updateUserCache();
                                                                                this.updateUserPaymentHistory().then((historyUpdated: boolean) => {
                                                                                    if (!historyUpdated)
                                                                                        this.errorMessage("User Payment history could not be updated.");

                                                                                    this.successMessage('Subscription downgraded to Basic.');
                                                                                    this.sendSubscriptionEmail('D').then((sent) => {
                                                                                        if (!sent) {
                                                                                            this.errorMessage('The notification could not be sent.');
                                                                                            this.setState({ showSubscriptionProgress: false });
                                                                                        }
                                                                                    });
                                                                                });
                                                                            });
                                                                        });
                                                                    }
                                                                    else {
                                                                        this.errorMessage('The payment option could not be deleted.');
                                                                        this.setState({ showSubscriptionProgress: false });
                                                                    }
                                                                });
                                                            }
                                                            else {
                                                                this.errorMessage('Subscription could not be removed.');
                                                                this.setState({ showSubscriptionProgress: false });
                                                            }
                                                        });
                                                        //}
                                                        //     else {
                                                        //         this.errorMessage('Stripe customer could not be deleted.');
                                                        //         this.setState({ showSubscriptionProgress: false });
                                                        //     }
                                                        // });
                                                    }
                                                    else {
                                                        this.db.updateProfileSubscription(this.state.ProfileId, 'B', JSON.stringify(stripeCustomer)).then((result) => {
                                                            if (result) {
                                                                this.db.deletePaymentMethod(this.state.DefaultBilling.Id).then((deleted) => {
                                                                    if (deleted) {
                                                                        this.getUserProfile().then((profile) => {
                                                                            this.setState({ AccountProfile: profile, showSubscriptionProgress: false, ActiveSubscription: profile.ActiveSubscription }, () => {
                                                                                this.updateUserCache();
                                                                                this.updateUserPaymentHistory().then((historyUpdated: boolean) => {
                                                                                    if (!historyUpdated)
                                                                                        this.errorMessage("User Payment history could not be updated.");

                                                                                    this.successMessage('Subscription downgraded to Basic.');
                                                                                    this.sendSubscriptionEmail('D').then((sent) => {
                                                                                        if (!sent) {
                                                                                            this.errorMessage('The notification could not be sent.');
                                                                                            this.setState({ showSubscriptionProgress: false });
                                                                                        }
                                                                                    });
                                                                                });
                                                                            });
                                                                        });
                                                                    }
                                                                    else {
                                                                        this.errorMessage('The payment option could not be deleted.');
                                                                        this.setState({ showSubscriptionProgress: false });
                                                                    }
                                                                });
                                                            }
                                                            else {
                                                                this.errorMessage('Subscription could not be removed.');
                                                                this.setState({ showSubscriptionProgress: false });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                            else {
                                                this.errorMessage('Stripe payment method could not be detached.');
                                                this.setState({ showSubscriptionProgress: false });
                                            }
                                        });
                                    }
                                    else {
                                        this.db.updateProfileSubscription(this.state.ProfileId, 'B', JSON.stringify(stripeCustomer)).then((result) => {
                                            if (result) {
                                                this.db.deletePaymentMethod(this.state.DefaultBilling.Id).then((deleted) => {
                                                    if (deleted) {
                                                        this.getUserProfile().then((profile) => {
                                                            this.setState({ AccountProfile: profile, showSubscriptionProgress: false, ActiveSubscription: profile.ActiveSubscription }, () => {
                                                                this.updateUserCache();
                                                                this.updateUserPaymentHistory().then((historyUpdated: boolean) => {
                                                                    if (!historyUpdated)
                                                                        this.errorMessage("User Payment history could not be updated.");

                                                                    this.successMessage('Subscription downgraded to Basic.');
                                                                    this.sendSubscriptionEmail('D').then((sent) => {
                                                                        if (!sent) {
                                                                            this.errorMessage('The notification could not be sent.');
                                                                            this.setState({ showSubscriptionProgress: false });
                                                                        }
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    }
                                                    else {
                                                        this.errorMessage('The payment option could not be deleted.');
                                                        this.setState({ showSubscriptionProgress: false });
                                                    }
                                                });
                                            }
                                            else {
                                                this.errorMessage('Subscription could not be removed.');
                                                this.setState({ showSubscriptionProgress: false });
                                            }
                                        });
                                    }
                                });
                            }
                            else {
                                this.errorMessage('Stripe subscription could not be cancelled.');
                                this.setState({ showSubscriptionProgress: false });
                            }
                        });
                    }
                    else {
                        this.db.updateProfileSubscription(this.state.ProfileId, 'B', JSON.stringify(stripeCustomer)).then((result) => {
                            if (result) {
                                this.db.deletePaymentMethod(this.state.DefaultBilling.Id).then((deleted) => {
                                    if (deleted) {
                                        this.getUserProfile().then((profile) => {
                                            this.setState({ AccountProfile: profile, showSubscriptionProgress: false, ActiveSubscription: profile.ActiveSubscription }, () => {
                                                this.updateUserCache();
                                                this.updateUserPaymentHistory().then((historyUpdated: boolean) => {
                                                    if (!historyUpdated)
                                                        this.errorMessage("User Payment history could not be updated.");

                                                    this.successMessage('Subscription downgraded to Basic.');
                                                    this.sendSubscriptionEmail('D').then((sent) => {
                                                        if (!sent) {
                                                            this.errorMessage('The notification could not be sent.');
                                                            this.setState({ showSubscriptionProgress: false });
                                                        }
                                                    });
                                                });
                                            });
                                        });
                                    }
                                    else {
                                        this.errorMessage('The payment option could not be deleted.');
                                        this.setState({ showSubscriptionProgress: false });
                                    }
                                });
                            }
                            else {
                                this.errorMessage('Subscription could not be removed.');
                                this.setState({ showSubscriptionProgress: false });
                            }
                        });
                    }
                }
                else {
                    this.db.updateProfileSubscription(this.state.ProfileId, 'B', JSON.stringify(stripeCustomer)).then((result) => {
                        if (result) {
                            this.db.deletePaymentMethod(this.state.DefaultBilling.Id).then((deleted) => {
                                if (deleted) {
                                    this.getUserProfile().then((profile) => {
                                        this.setState({ AccountProfile: profile, showSubscriptionProgress: false, ActiveSubscription: profile.ActiveSubscription }, () => {
                                            this.updateUserCache();
                                            this.updateUserPaymentHistory().then((historyUpdated: boolean) => {
                                                if (!historyUpdated)
                                                    this.errorMessage("User Payment history could not be updated.");

                                                this.successMessage('Subscription downgraded to Basic.');
                                                this.sendSubscriptionEmail('D').then((sent) => {
                                                    if (!sent) {
                                                        this.errorMessage('The notification could not be sent.');
                                                        this.setState({ showSubscriptionProgress: false });
                                                    }
                                                });
                                            });
                                        });
                                    });
                                }
                                else {
                                    this.errorMessage('The payment option could not be deleted.');
                                    this.setState({ showSubscriptionProgress: false });
                                }
                            });
                        }
                        else {
                            this.errorMessage('Subscription could not be removed.');
                            this.setState({ showSubscriptionProgress: false });
                        }
                    });
                }
            });
        }
        else {
            this.errorMessage('Stripe customer details are not available.');
            this.setState({ showSubscriptionProgress: false });
        }
    }

    private upgradePlan(stripe) {
        this.db.updateProfileSubscription(this.state.ProfileId, 'P', JSON.stringify(stripe)).then((result) => {
            if (result) {
                let { ItemId, ProfileId, CardFirstName, CardLastName, CardBillingAddress, CardZipCode, CardCountry, IsDefaultCard, CardCompanyName } = this.state;
                this.db.updatePaymentMethod(ItemId ? ItemId : 0, ProfileId, stripe?.paymentMethod?.card?.last4, stripe?.paymentMethod?.card?.brand, stripe?.paymentMethod?.card?.exp_month, stripe?.paymentMethod?.card?.exp_year, CardFirstName, CardLastName, CardBillingAddress, CardZipCode, CardCountry, IsDefaultCard, CardCompanyName, stripe?.paymentMethod?.id).then((updated) => {
                    if (updated) {
                        //this.getUserProfile().then((profile) => {
                        // let stripeCustomer = stripe; //profile && profile.StripeCustomer ? JSON.parse(profile.StripeCustomer) : null;
                        this.setState({
                            CardType: stripe?.paymentMethod?.card?.brand, //AccountProfile: profile,ActiveSubscription: profile.ActiveSubscription, 
                            CardNumber: stripe?.paymentMethod?.card?.last4, Subscription: stripe?.subscription
                        }, () => {
                            this.updateUserCache();
                            this.updateUserPaymentHistory().then((historyUpdated: boolean) => {
                                if (!historyUpdated)
                                    this.errorMessage("User Payment history could not be updated.");

                                this.successMessage('Subscription upgraded to Premium.');
                                this.sendSubscriptionEmail('U', stripe?.subscription).then((sent) => {
                                    if (!sent) {
                                        this.errorMessage('The notification could not be sent.');
                                    }

                                    this.getBillingInfo();
                                    this.resetPaymentForm();

                                    this.getUserProfile().then((profile) => {
                                        let stripeCustomer = profile && profile.StripeCustomer ? JSON.parse(profile.StripeCustomer) : null;
                                        this.setState({ AccountProfile: profile, showSubscriptionProgress: false, ActiveSubscription: profile.ActiveSubscription, Subscription: (stripeCustomer ? stripeCustomer.subscription : null) }, () => { this.updateUserCache(); });
                                    });
                                });
                            });
                        });
                        //});
                    }
                    else {
                        this.errorMessage('The payment option could not be updated.');
                        this.setState({ showSubscriptionProgress: false });
                    }
                });
            }
            else {
                this.errorMessage('Subscription could not be changed.');
                this.setState({ showSubscriptionProgress: false });
            }
        });
    }

    private async handleStripePay(elements?: StripeElements) {
        let isValid = this.validator.validateForm(this.billingFormValidators);
        let cardElement: any = elements?.getElement(CardElement);

        if (isValid) {
            this.setState({ showConfirmation: false, ConfirmationMessage: '', ConfirmationType: '', callBackMethod: null });
            let { showNewCard, PaymentMethodID, IsDefaultCard } = this.state;

            let _strip = await stripePromise;

            let stripePrice = await this._stripe.getPrice(Constants.Stripe.PriceID);
            if (stripePrice && stripePrice.active) {
                let isCustomerNew = false;
                let customer: any = await this.getCustomer(true, isCustomerNew);
                if (customer) {
                    let paymentMethod: any = null;
                    let isPMAttached = false;

                    if (!showNewCard && PaymentMethodID)
                        paymentMethod = await this._stripe.getPaymentMethodById(PaymentMethodID);
                    else if (cardElement) {
                        paymentMethod = await this.getPaymentMethod(customer, cardElement, true);

                        let currMonth = (new Date()).getMonth() + 1;
                        let currYear = (new Date()).getFullYear();

                        if (paymentMethod && (paymentMethod.customer === customer.id || paymentMethod?.card?.exp_month < currMonth || paymentMethod?.card?.exp_year < currYear)) {
                            let pmDetached = await this._stripe.detachPaymentMethod(paymentMethod?.id);
                            if (pmDetached) {
                                paymentMethod = await this.getPaymentMethod(customer, cardElement, true);
                                isPMAttached = await this._stripe.attachPaymentMethod(customer.id, paymentMethod?.id);
                            }
                        }
                    }
                    else {
                        this.errorMessage("There is no payment method configured for you. Please add a default payment method using Billing & Payment section and try again.");
                        return;
                    }

                    if (paymentMethod) {
                        this.setState({ showSubscriptionProgress: true });
                        if (stripePrice.unit_amount > 0) {
                            if (paymentMethod.customer !== customer.id) {
                                isPMAttached = await this._stripe.attachPaymentMethod(customer.id, paymentMethod?.id);
                            }
                            else {
                                isPMAttached = true;
                            }

                            if (isPMAttached) {
                                if (IsDefaultCard) {
                                    let setDefaultPaymentMethod = await this._stripe.updateCustomerDefaultPaymentMethod(customer.id, paymentMethod?.id);
                                    if (!setDefaultPaymentMethod) {
                                        this.errorMessage('Stripe Default Payment Method could not be updated.');
                                    }
                                }

                                let subscription = await this._stripe.createSubscription(customer.id, Constants.Stripe.PriceID, 1);
                                if (subscription) {
                                    let paymentIntent = subscription.pending_setup_intent ? subscription.pending_setup_intent : (subscription.latest_invoice ? subscription.latest_invoice.payment_intent : null);

                                    if (paymentIntent) {
                                        let pIConfirmation = await _strip?.confirmCardPayment(paymentIntent.client_secret, { setup_future_usage: "off_session", receipt_email: this.User.Email, save_payment_method: true, payment_method: paymentMethod?.id, return_url: "https://prosbe.com/subscription" });

                                        if (pIConfirmation && !pIConfirmation.error && pIConfirmation.paymentIntent.status === "succeeded") {
                                            subscription = await this._stripe.getSubscriptionById(subscription.id);
                                            this.upgradePlan({ customer: customer, paymentMethod: paymentMethod, subscription: subscription });
                                        }
                                        else {
                                            this.errorMessage('Stripe Payment Intent could not be confirmed. ' + (pIConfirmation?.error?.message));
                                            this.setState({ showSubscriptionProgress: false });
                                        }
                                    }
                                    else {
                                        this.errorMessage('Stripe Payment Method could not be attached.');
                                        this.setState({ showSubscriptionProgress: false });
                                    }
                                }
                                else {
                                    this.errorMessage('Stripe Subscription could not be created.');
                                    this.setState({ showSubscriptionProgress: false });
                                }
                            }
                            else {
                                this.errorMessage('Stripe Payment Intent could not be created.');
                                this.setState({ showSubscriptionProgress: false });
                            }
                        }
                        else { // Temporary code to enable $0 price based subscription. This entire block will be removed when $0 based subscriptions are disabled.
                            this.errorMessage('Stripe Payment Price could not be found.');
                            this.setState({ showSubscriptionProgress: false });

                            // let isPMAttached = false;
                            // if (paymentMethod.customer !== customer.id) {
                            //     isPMAttached = await this._stripe.attachPaymentMethod(customer.id, paymentMethod?.id);
                            // }
                            // else {
                            //     isPMAttached = true;
                            // }

                            // if (isPMAttached) {
                            //     if (IsDefaultCard && customer.invoice_settings && customer.invoice_settings.default_payment_method && customer.invoice_settings.default_payment_method !== paymentMethod?.id) {
                            //         let setDefaultPaymentMethod = await this._stripe.updateCustomerDefaultPaymentMethod(customer.id, paymentMethod?.id);
                            //         if (!setDefaultPaymentMethod) {
                            //             this.errorMessage('Stripe Default Payment Method could not be updated.');
                            //         }
                            //     }

                            //     let subscription = await this._stripe.createSubscription(customer.id, Constants.Stripe.PriceID, 1);
                            //     if (subscription) {
                            //         this.upgradePlan({ customer: customer, paymentMethod: paymentMethod, subscription: subscription });
                            //     }
                            //     else {
                            //         await this._stripe.deleteCustomer(customer.id);
                            //         this.errorMessage('Stripe Subscription could not be created.');
                            //         this.setState({ showSubscriptionProgress: false });
                            //     }
                            // }
                            // else {
                            //     this.errorMessage('Stripe Payment Method could not be attached.');
                            //     this.setState({ showSubscriptionProgress: false });
                            // }
                        }
                    }
                    else {
                        await this._stripe.deleteCustomer(customer.id);
                        this.errorMessage('Stripe Payment Method could not be created.');
                        this.setState({ showSubscriptionProgress: false });
                    }
                }
                else {
                    this.errorMessage('Stripe Customer could not be created.');
                    this.setState({ showSubscriptionProgress: false });
                }
            }
            else {
                this.errorMessage('Stripe Product not available.');
                this.setState({ showSubscriptionProgress: false });
            }
        }
    }

    // private getSubscription(customer, createIfNotExists?): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this._stripe.getCustomerSubscriptions(customer.id).then((subscriptions) => {
    //             if (createIfNotExists && (!subscriptions || !subscriptions.data || subscriptions.data.length <= 0)) {
    //                 this._stripe.createSubscription(customer.id, Constants.Stripe.PriceID, 1).then((subscription) => {
    //                     resolve(subscription);
    //                 });
    //             }
    //             else if (subscriptions && subscriptions.data && subscriptions.data.length > 0) {
    //                 this._stripe.getSubscriptionById(subscriptions.data[0].id).then((subscription) => {
    //                     resolve(subscription);
    //                 });
    //             }
    //             else {
    //                 resolve(null);
    //             }
    //         });
    //     });
    // }

    private getPaymentMethod(customer, cardElement, createIfNotExists?): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let { CardFirstName, CardLastName, CardBillingAddress } = this.state;
            if (customer) {
                this._stripe.getCustomerPaymentMethods(customer.id, 'card').then(async (paymentMethods) => {
                    if (createIfNotExists && (!paymentMethods || !paymentMethods.data || paymentMethods.data.length <= 0)) {
                        let _strip = await stripePromise;
                        let paymentMethod = await _strip?.createPaymentMethod({ type: 'card', card: cardElement, billing_details: { address: CardBillingAddress, email: this.User.Email, name: CardFirstName + (CardLastName ? " " + CardLastName : "") } });
                        resolve(paymentMethod?.paymentMethod);
                    }
                    else if (paymentMethods && paymentMethods.data && paymentMethods.data.length > 0) {
                        resolve(paymentMethods.data[0]);
                    }
                    else {
                        resolve(null);
                    }
                });
            }
            else {
                if (createIfNotExists) {
                    let _strip = await stripePromise;
                    let paymentMethod = await _strip?.createPaymentMethod({ type: 'card', card: cardElement, billing_details: { address: CardBillingAddress, email: this.User.Email, name: CardFirstName + (CardLastName ? " " + CardLastName : "") } });
                    resolve(paymentMethod?.paymentMethod);
                }
                else {
                    resolve(null);
                }
            }
        });
    }

    private getCustomer(createIfNotExists?, isNew?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            isNew = false;
            let stripeCustomer = this.User && this.User.Profile && this.User.Profile.StripeCustomer ? JSON.parse(this.User.Profile.StripeCustomer) : null;
            if (stripeCustomer && stripeCustomer.customer) {
                this._stripe.getCustomerById(stripeCustomer.customer.id).then((customer) => {
                    if (customer && customer.deleted) {
                        this._stripe.createCustomer(this.state.CardFirstName + " " + this.state.CardLastName, this.User.Email, 'ProsBE Premium subscriber', this.state.CardCountryName, this.state.StateName, this.state.CityName, this.state.CardZipCode, this.state.CardBillingAddress).then((customer) => {
                            isNew = true;
                            if (this.User && this.User.Profile && this.User.Profile.StripeCustomer) {
                                stripeCustomer.customer = customer;
                                this.User.Profile.StripeCustomer = JSON.stringify(stripeCustomer);
                            }

                            resolve(customer);
                        });
                    }
                    else {
                        resolve(customer);
                    }
                });
            }
            else if (createIfNotExists) {
                this._stripe.createCustomer(this.state.CardFirstName + " " + this.state.CardLastName, this.User.Email, 'ProsBE Premium subscriber', this.state.CardCountryName, this.state.StateName, this.state.CityName, this.state.CardZipCode, this.state.CardBillingAddress).then((customer) => {
                    isNew = true;
                    if (this.User && this.User.Profile && this.User.Profile.StripeCustomer)
                        this.User.Profile.StripeCustomer.customer = customer;

                    resolve(customer);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private updateUserCache() {
        this.db.getUser(this.LoginName, this.AppRole).then((result) => {
            if (result) {
                this.User = result;
                let _cached = JSON.stringify(result);
                sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(_cached));
            }
        });
    }

    private async handleBillingInfoSave(mode, elements: StripeElements) {
        let isValid = this.validator.validateForm(this.billingFormValidators);
        let cardElement: any = elements?.getElement(CardElement);

        if (isValid && cardElement) {
            this.setState({ showSubscriptionProgress: true });

            let stripePrice = await this._stripe.getPrice(Constants.Stripe.PriceID);
            if (stripePrice && stripePrice.active) {
                let customer = await this.getCustomer(false);
                if (!customer)
                    customer = await this.getCustomer(true);

                if (customer) {
                    let { ItemId, ProfileId, CardFirstName, CardLastName, CardBillingAddress, CardZipCode, CardCountry, IsDefaultCard, CardCompanyName } = this.state;
                    let _strip = await stripePromise;

                    let newPaymentMethod = await _strip?.createPaymentMethod({ type: 'card', card: cardElement, billing_details: { address: CardBillingAddress, email: this.User.Email, name: CardFirstName + (CardLastName ? " " + CardLastName : "") } });
                    if (newPaymentMethod?.paymentMethod) {
                        // let attached = await this._stripe.attachPaymentMethod(customer.id, newPaymentMethod?.paymentMethod?.id);
                        // if (attached) {
                        // let isDefaultUpdated = true;
                        // if (IsDefaultCard) {
                        //     let setDefaultPaymentMethod = await this._stripe.updateCustomerDefaultPaymentMethod(customer.id, newPaymentMethod?.paymentMethod?.id);
                        //     if (!setDefaultPaymentMethod) {
                        //         isDefaultUpdated = false;
                        //         this.errorMessage(`The customer's default payment method could not be updated.`);
                        //     }
                        // }

                        let updatedPaymentMethod = await this.db.updatePaymentMethod(ItemId, ProfileId, newPaymentMethod?.paymentMethod?.card?.last4, newPaymentMethod?.paymentMethod?.card?.brand, newPaymentMethod?.paymentMethod?.card?.exp_month, newPaymentMethod?.paymentMethod?.card?.exp_year, CardFirstName, CardLastName, CardBillingAddress, CardZipCode, CardCountry, IsDefaultCard, CardCompanyName, newPaymentMethod?.paymentMethod?.id);
                        if (updatedPaymentMethod) {
                            this.successMessage('The new card details have been added to your account.' + (IsDefaultCard ? ' This card will be used as default for all payments in future.' : ''));

                            if (mode === 'B')
                                this.resetPaymentForm();
                            else
                                this.resetCardForm();

                            this.getBillingInfo();
                            this.setState({ showSubscriptionProgress: false });
                        }
                        else {
                            this.errorMessage('The new card details could not be added.');
                            this.setState({ showSubscriptionProgress: false });
                        }
                        // }
                        // else {
                        //     this.errorMessage('The payment method could not be attached to stripe customer.');
                        //     this.setState({ showSubscriptionProgress: false });
                        // }
                    }
                    else {
                        this.errorMessage('The stripe payment method could not be created.');
                        this.setState({ showSubscriptionProgress: false });
                    }
                }
                else {
                    this.errorMessage('The stripe customer details are not available.');
                    this.setState({ showSubscriptionProgress: false });
                }
            }
            else {
                this.errorMessage('The stripe product details are not available.');
                this.setState({ showSubscriptionProgress: false });
            }
        }
        else if (!cardElement) {
            this.errorMessage('The card details are not valid.');
            this.setState({ showSubscriptionProgress: false });
        }
    }

    // private handleEditBill(bill) {
    //     this.setState({
    //         showPaymentDialog: true, DialogMode: 'E', ItemId: bill.Id, CardNumber: bill.CardNumber, CardExpiryMonth: bill.ExpiryMonth.toString(),
    //         CardExpiryMonthTitle: bill.ExpiryMonth.toString(), CardExpiryYear: bill.ExpiryYear.toString(), CardFirstName: bill.FirstName, CardLastName: bill.LastName, CardZipCode: bill.PinCode.toString(),
    //         CardBillingAddress: bill.Address, CardCountryName: bill.Country ? bill.Country.Name : '', CardCountry: bill.Country ? bill.Country.Id.toString() : '',
    //         CardCompanyName: bill.CompanyName, IsDefaultCard: bill.IsDefault, CardType: bill.CardType
    //     });
    // }

    // private handleDeleteBill(bill) {
    //     this.setState({
    //         showConfirmation: true, ConfirmationMessage: 'Are you sure you want to delete this payment method from your profile?', callBackMethod: this.deleteBillCard.bind(this), ItemId: bill.Id
    //     });
    // }

    // private handleSetBillDefault(bill) {
    //     this.setState({
    //         showConfirmation: true, ConfirmationMessage: 'Are you sure you want to change the default payment method in your profile?', callBackMethod: this.changeDefaultPaymentMethod.bind(this), ItemId: bill.Id
    //     });
    // }

    // private deleteBillCard() {
    //     this.db.deletePaymentMethod(this.state.ItemId).then((deleted) => {
    //         if (deleted) {
    //             this.successMessage('The payment option has been deleted.');
    //             this.setState({ showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null }, () => {
    //                 this.getBillingInfo();
    //             });
    //         }
    //         else {
    //             this.errorMessage('The payment option could not be deleted.');
    //         }
    //     });
    // }

    // private changeDefaultPaymentMethod() {
    //     this.db.setDefaultPayment(this.state.ItemId, this.state.ProfileId).then((updated) => {
    //         if (updated) {
    //             this.successMessage('The default payment option has been changed.');
    //             this.setState({ showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null }, () => {
    //                 this.getBillingInfo();
    //             });
    //         }
    //         else {
    //             this.errorMessage('The default payment option could not be changed.');
    //         }
    //     });
    // }

    // private getCardType(cardNumber: string) {
    //     if (cardNumber) {
    //         if (cardNumber.startsWith('3'))
    //             return 'amex';
    //         else if (cardNumber.startsWith('4'))
    //             return 'visa';
    //         else if (cardNumber.startsWith('5'))
    //             return 'mastercard';
    //         else if (cardNumber.startsWith('6'))
    //             return 'discover';
    //         else
    //             return '';
    //     }
    //     else {
    //         return '';
    //     }
    // }

    // private checkExpiryMonth(month, year) {
    //     if (month && year) {
    //         let _currentYear = new Date().getFullYear();
    //         let _currentMonth = new Date().getMonth() + 1;
    //         if (Number(year) === _currentYear) {
    //             if (Number(month) < _currentMonth)
    //                 return '';
    //         }
    //     }

    //     return '1';
    // }

    private resetPaymentForm() {
        this.billingFormValidators = new Array<Validator>();

        this.setState({
            showPaymentDialog: false, showBillingEdit: false, CVC: '', PaymentMethodID: '', showNewForm: false,
            ItemId: 0, DialogMode: '', CardNumber: '', CardExpiryMonth: '', CardExpiryMonthTitle: '', CardType: '',
            CardExpiryYear: '', CardFirstName: '', CardLastName: '', CardZipCode: '', CardBillingAddress: '', CardCountryName: '', CardCountry: '', CardCompanyName: '', IsDefaultCard: false, CVV: ''
        });
    }

    private resetCardForm() {
        this.billingFormValidators = new Array<Validator>();

        this.setState({
            showNewPaymentMethod: false, showBillingEdit: true, CVC: '',
            ItemId: 0, DialogMode: '', CardNumber: '', CardExpiryMonth: '', CardExpiryMonthTitle: '', CardType: '',
            CardExpiryYear: '', CardFirstName: '', CardLastName: '', CardZipCode: '', CardBillingAddress: '', CardCountryName: '', CardCountry: '', CardCompanyName: '', IsDefaultCard: false, CVV: ''
        });
    }

    private handleAskForReviewsClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid && this.state.Recipients && this.state.Recipients.length > 0) {
            // let _emails: string = '';

            let emailTemplate = Constants.Email.AskForReviews;
            let subject = emailTemplate.Subject.replace('{0}', this.state.BusinessName);
            let body = emailTemplate.Body;

            let counter = 0;

            this.state.Recipients.forEach((cust, indx) => {
                body = body.replace("[username]", cust.Profile.User.FirstName);
                body = body.replace("[businessname]", this.state.BusinessName);
                body = body.replace("[rid]", this.state.ProfileId.toString());
                body = body.replace("[em]", this.state.Email);

                if (cust.Profile.User.Email) {
                    this.db.sendEmail(cust.Profile.User.Email, subject, body).then((emailSent) => {
                        counter++;
                        if (counter === this.state.Recipients.length) {
                            this.successMessage('Review request sent successfully.');
                            this.formValidators = new Array<Validator>();
                            this.setState({ showAskForReviewDialog: false, Recipients: [] });
                        }
                    });
                }
            });

            // this.state.Recipients.forEach((cust, indx) => {
            //     if (indx < this.state.Recipients.length - 1)
            //         _emails += cust.Profile.User.Email + ",";
            //     else
            //         _emails += cust.Profile.User.Email;
            // });

            // this.db.askForReviews(_emails, this.state.ProfileId).then((asked) => {
            //     if (asked) {
            //         this.successMessage('Review request sent successfully.');
            //         this.formValidators = new Array<Validator>();
            //         this.setState({ showAskForReviewDialog: false, Recipients: [], ReviewRecipients: [] });
            //     }
            //     else {
            //         this.errorMessage('Review request could not be sent.');
            //     }
            // });
        }
    }

    private checkCardExpired(month, year) {
        let currYear = new Date().getFullYear();
        let currMonth = new Date().getMonth() + 1;

        if (Number(year) < currYear) {
            return true;
        }
        else if (Number(year) === currYear && Number(month) < currMonth) {
            return true;
        }

        return false;
    }

    // private getFormattedCardNumber(number): string {
    //     if (number) {
    //         if (number.length === 4 || number.length === 9 || number.length === 14)
    //             return number + '-';
    //         else return number;
    //     }

    //     return '';
    // }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    private handleSetAsDefault(item) {
        this.setState({
            showConfirmation: true, ConfirmationMessage: 'This will replace current default payment method (if any) used for the ProsBE subscription renewal in future. Are you sure you want to proceed?',
            ConfirmationType: 'C', ItemId: item.Id, callBackMethod: this.setDefaultPaymentMethod.bind(this), Item: item
        });
    }

    private async setDefaultPaymentMethod() {
        const { ItemId, Item, ProfileId } = this.state;
        this.setState({ showBillingProgress: true, showConfirmation: false, ConfirmationMessage: '', ConfirmationType: '', callBackMethod: null });

        let customer = await this.getCustomer(false);
        if (customer) {
            let paymentMethod = await this._stripe.getPaymentMethodById(Item.StripePaymentMethodID);
            if (paymentMethod) {
                let setDefaultPaymentMethod = await this._stripe.updateCustomerDefaultPaymentMethod(customer.id, Item.StripePaymentMethodID);
                if (!setDefaultPaymentMethod) {
                    this.errorMessage(`The customer's default payment method could not be updated.`);
                }
                else {
                    let updatedPaymentMethod = await this.db.updatePaymentMethod(ItemId, ProfileId, paymentMethod?.card?.last4, paymentMethod?.card?.brand, paymentMethod?.card?.exp_month, paymentMethod?.card?.exp_year, Item.FirstName, Item.LastName, Item.Address, Item.PinCode, Item.Country.Id, true, Item.CompanyName, Item.StripePaymentMethodID);
                    if (updatedPaymentMethod) {
                        this.successMessage('The card ending with ' + paymentMethod?.card?.last4 + ' has been set as a default payment method for your account and will be used as default for all payments in future.');

                        this.getBillingInfo();
                        this.setState({ showBillingProgress: false });
                    }
                    else {
                        this.errorMessage(`This card could not be updated to use as default payment method.`);
                        this.setState({ showBillingProgress: false });
                    }
                }
            }
            else {
                this.errorMessage('This card is not registered as a valid payment method on ProsBE.');
                this.setState({ showBillingProgress: false });
            }
        }
        else {
            this.errorMessage('The Stripe customer details are not available for your profile.');
            this.setState({ showBillingProgress: false });
        }
    }

    private handleDeleteCard(item) {
        this.setState({
            showConfirmation: true, ConfirmationMessage: 'Are you sure you want to delete this card?',
            ConfirmationType: 'C', ItemId: item.Id, callBackMethod: this.deleteCardDetails.bind(this), Item: item
        });
    }

    private async deleteCardDetails() {
        const { ItemId, Item } = this.state;
        this.setState({ showBillingProgress: true, showConfirmation: false, ConfirmationMessage: '', ConfirmationType: '', callBackMethod: null });

        let customer = await this.getCustomer(false);
        if (customer) {
            let paymentMethod = await this._stripe.getPaymentMethodById(Item.StripePaymentMethodID);
            if (paymentMethod) {
                let detached = await this._stripe.detachPaymentMethod(Item.StripePaymentMethodID);
                if (detached) {
                    let deleted = await this.db.deletePaymentMethod(ItemId);
                    if (deleted) {
                        this.successMessage('The card ending with ' + Item.CardNumber + ' has been deleted from your account.');

                        this.getBillingInfo();
                        this.setState({ showBillingProgress: false });
                    }
                    else {
                        this.errorMessage(`The payment method could not be deleted.`);
                        this.setState({ showBillingProgress: false });
                    }
                }
                else {
                    this.errorMessage(`The payment method could not be detached.`);
                    this.setState({ showBillingProgress: false });
                }
            }
            else {
                this.errorMessage(`The payment method details are not available.`);
                this.setState({ showBillingProgress: false });
            }
        }
        else {
            this.errorMessage(`The customer details are not available.`);
            this.setState({ showBillingProgress: false });
        }
    }

    // private updateStripePay(stripe, elements) {
    //     let isValid = this.validator.validateForm(this.billingFormValidators);
    //     let cardElement: any = elements?.getElement(CardElement);

    //     if (isValid && cardElement) {
    //         this.setState({ showSubscriptionProgress: true }, () => {
    //             if (this.state.IsDefaultCard) {
    //                 this.getCustomer(false).then((customer) => {
    //                     if (customer) {
    //                         this.getPaymentMethod(customer, false).then((paymentMethod) => {
    //                             this.updatePaymentMethod(customer, paymentMethod).then((updated) => {
    //                                 //this.setState({ showSubscriptionProgress: false });
    //                             });
    //                         });
    //                     }
    //                     else {
    //                         this.handleBillingInfoSave('S', elements);
    //                         this.setState({ showSubscriptionProgress: false });
    //                     }
    //                 });
    //             }
    //             else {
    //                 this.handleBillingInfoSave('S', elements);
    //                 this.setState({ showSubscriptionProgress: false });
    //             }
    //         });
    //     }
    //     else if (!cardElement) {
    //         this.errorMessage('The card details are not valid.');
    //     }
    // }

    // private updatePaymentMethod(customer, paymentMethod): Promise<boolean> {
    //     return new Promise((resolve, reject) => {
    //         //let _strip = await stripePromise;

    //         this.getPaymentMethod(customer, true).then((newPaymentMethod) => {
    //             if (newPaymentMethod) {
    //                 this.getSubscription(customer, false).then((subscription) => {
    //                     if (subscription) {
    //                         this._stripe.detachPaymentMethod(paymentMethod.id).then((pMethod) => {
    //                             if (pMethod) {
    //                                 this.updateUserPaymentDetails(customer, newPaymentMethod, subscription);
    //                             }
    //                             else {
    //                                 this._stripe.detachPaymentMethod(newPaymentMethod.id).then((nPMDetached) => {
    //                                     this.errorMessage('Payment method could not be detached from user`s Stripe subscription.');
    //                                     this.setState({ showSubscriptionProgress: false });
    //                                 });
    //                             }
    //                         });
    //                     }
    //                     else {
    //                         this.getSubscription(customer, true).then((subscription) => {
    //                             if (subscription) {
    //                                 this._stripe.detachPaymentMethod(paymentMethod.id).then((pMethod) => {
    //                                     if (pMethod) {
    //                                         this.updateUserPaymentDetails(customer, newPaymentMethod, subscription);
    //                                     }
    //                                     else {
    //                                         this._stripe.cancelSubscription(subscription.id).then((cancelled) => {
    //                                             this.errorMessage('Payment method could not be detached from user`s Stripe subscription.');
    //                                             this.setState({ showSubscriptionProgress: false });
    //                                         });
    //                                     }
    //                                 });
    //                             }
    //                             else {
    //                                 this.errorMessage('Stripe subscription not found.');
    //                                 this.setState({ showSubscriptionProgress: false });
    //                             }
    //                         });
    //                     }
    //                 });
    //             }
    //             else {
    //                 this.errorMessage('The payment method could not be updated. Please check if your card details are correct or the card is active.');
    //                 this.setState({ showSubscriptionProgress: false });
    //             }
    //         });
    //     });
    // }

    // private updateUserPaymentDetails(customer: any, paymentMethod: any, subscription: any) {
    //     let stripe = { customer: customer, paymentMethod: paymentMethod, subscription: subscription };
    //     this.db.updateProfileSubscription(this.state.ProfileId, this.User.Profile.ActiveSubscription, JSON.stringify(stripe)).then((result) => {
    //         if (result) {
    //             let { ItemId, ProfileId, CardNumber, CardType, CardExpiryMonth, CardExpiryYear, CardFirstName, CardLastName, CardBillingAddress, CardZipCode, CardCountry, IsDefaultCard, CardCompanyName } = this.state;
    //             this.db.updatePaymentMethod(ItemId, ProfileId, CardNumber, CardType, CardExpiryMonth, CardExpiryYear, CardFirstName, CardLastName, CardBillingAddress, CardZipCode, CardCountry, IsDefaultCard, CardCompanyName).then((updated) => {
    //                 if (updated) {
    //                     this.successMessage('The payment option has been updated.');
    //                     this.sendSubscriptionEmail('U').then((sent) => {
    //                         if (sent) {
    //                             this.getBillingInfo();
    //                             this.resetPaymentForm();

    //                             this.getUserProfile().then((profile) => {
    //                                 let stripeCustomer = profile && profile.StripeCustomer ? JSON.parse(profile.StripeCustomer) : null;
    //                                 this.setState({ AccountProfile: profile, showSubscriptionProgress: false, ActiveSubscription: profile.ActiveSubscription, Subscription: (stripeCustomer ? stripeCustomer.subscription : null) }, () => { this.updateUserCache(); });
    //                             });
    //                         }
    //                         else {
    //                             this.errorMessage('The notification could not be sent.');
    //                             this.setState({ showSubscriptionProgress: false });
    //                         }
    //                     });
    //                 }
    //                 else {
    //                     this.errorMessage('The payment option could not be updated.');
    //                     this.setState({ showSubscriptionProgress: false });
    //                 }
    //             });
    //         }
    //         else {
    //             this.errorMessage('Subscription details could not be updated.');
    //             this.setState({ showSubscriptionProgress: false });
    //         }
    //     });
    // }

    private sendSubscriptionEmail(mode, subscription?): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate: any = null;
            let subject = '';
            let body = '';

            if (mode === 'U') {
                const { CardType, CardNumber } = this.state;

                emailTemplate = Constants.Email.UpgradePlan;
                subject = emailTemplate.Subject;
                body = emailTemplate.Body;

                body = body.replace("[username]", this.User.Profile.BusinessName);
                body = body.replace("[cardnumber]", CardType.toUpperCase() + " ending with " + CardNumber);

                if (subscription) {
                    body = body.replace("[amount]", "$" + Number(subscription.plan.amount / 100));
                    body = body.replace("[mode]", (subscription.plan.interval === "month") ? "Monthly" : "Yearly");
                    body = body.replace("[nextpayment]", this.getFormattedDate(new Date(subscription.current_period_end * 1000), Constants.IDateTimeFormat.MMMddyyyy, ' '));
                }
            }
            else {
                emailTemplate = Constants.Email.DowngradePlan;
                subject = emailTemplate.Subject;
                body = emailTemplate.Body;
            }

            if (this.User.Email) {
                this.db.sendEmail(this.User.Email, subject, body).then((sent) => {
                    if (sent) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(false);
            }
        });
    }

    private downloadReceipt(chargeId) {
        this._stripe.getChargeDetails(chargeId).then((data) => {
            if (data && data.receipt_url) {
                window.open(data.receipt_url);
            }
            else {
                this.errorMessage("No receipt found for this transaction.");
            }
        });
    }

    public render() {

        const { loading, showPasswordProgress, showProfileProgress, showNotificationProgress, showSubscriptionProgress, showBillingProgress, showPaymentDialog,
            showConfirmation, ConfirmationMessage, Message, ShowMessage, MessageType, ActiveTab, IsActive, ProfileImage, DefaultBilling, StripePayments, DialogMode,
            DisplayName, Email, ConfirmationType, showDialog, TabIndex, Phone,
            CurrentPassword, NewPassword, ConfirmPassword, ActiveSubscription, CardNumber, Countries, showNewCard,
            CardFirstName, CardLastName, CardZipCode, CardBillingAddress, CardCountryName, CardCountry, CardCompanyName, IsDefaultCard, PaymentMethodID,
            showAskForReviewDialog, Recipients, ReviewRecipients, Subscription, showBillingEdit, OtherBilling, showNewPaymentMethod, ItemId } = this.state;

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            if (!this.IsUserValid) {
                window.location.href = '/login';
            }
            else {
                template = (<React.Fragment>
                    <Header />
                    <section className="containerMinHeight accountSettingContainer borderBtm">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    {this.props.id ? (<div className="row">
                                        <div className="col-12 col-sm-12">
                                            <div className="breadCrumb mb-1">
                                                <a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2">&gt;</span><span className="ml-2">Billing & Payments</span>
                                            </div>
                                        </div>
                                    </div>) : (null)}
                                    <h5>{this.props.id > 0 ? "Billing & Payments" : "Account Settings"}</h5>
                                    <div className="row">
                                        {this.props.id > 0 ? (null) : (
                                            <div className="col-12 col-sm-3">
                                                <div className="accountNavigation">
                                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                        <a className={"nav-link " + (ActiveTab === 0 ? "active" : "")} id="v-pills-home-tab" aria-disabled={!IsActive} onClick={(e) => { this.handleTabChange(0); }}>Account</a>
                                                        {!IsActive ? (null) : (<React.Fragment> <a className={"nav-link " + (ActiveTab === 1 ? "active" : "")} id="v-pills-profile-tab" onClick={(e) => { this.handleTabChange(1); }}>Password</a>
                                                            {this.AppRole !== Constants.UserRoles.Admin ? (<React.Fragment><a className={"nav-link " + (ActiveTab === 2 ? "active" : "")} id="v-pills-notifications-tab" onClick={(e) => { this.handleTabChange(2); }}>Notification</a>
                                                                {this.AppRole === Constants.UserRoles.Pro ? (<React.Fragment><a className={"nav-link " + (ActiveTab === 4 ? "active" : "")} id="v-pills-subscription-tab" onClick={(e) => { this.handleTabChange(4); }}>Subscription</a>
                                                                    <a className={"nav-link " + (ActiveTab === 5 ? "active" : "")} id="v-pills-billing-tab" onClick={(e) => { this.handleTabChange(5); }}>Billing & Payments</a></React.Fragment>) : (null)}</React.Fragment>) : (null)}
                                                            <a className={"nav-link " + (ActiveTab === 3 ? "active" : "")} id="v-pills-deactivate-tab" onClick={(e) => { this.handleTabChange(3); }}>Deactivate</a>
                                                        </React.Fragment>)}
                                                    </div>
                                                </div>
                                            </div>)}
                                        <div className={"col-12 " + (this.props.id > 0 ? "col-sm-12" : "col-sm-9")}>
                                            <div className="tab-content" id="v-pills-tabContent">
                                                <div className={"tab-pane fade " + (ActiveTab === 0 ? "show active" : "")} id="v-pills-home">
                                                    {ActiveTab !== 0 ? (null) : (<div className="profileContainer">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="formContainer">
                                                                    <div className="headingTxt">{this.props.id ? "Profile" : "Account"}</div>
                                                                    {!IsActive ? (<div className="switchControlContainter mt-1">
                                                                        <div className="box">
                                                                            <div className="custom-control custom-switch">
                                                                                <span>Your account has been deactivated. </span><a href='/reactivate'>Click here</a> to submit a request to re-activate your account.
                                                                            </div>
                                                                        </div>
                                                                    </div>) : (<React.Fragment>
                                                                        {showProfileProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                            <div className="tabPanel">
                                                                                {!showDialog ? (<div className="col-12 col-sm-12">
                                                                                    <div className="row">
                                                                                        <div className="col-3 col-sm-2">
                                                                                            <div className="form-group mt-3">
                                                                                                <Avatar alt={DisplayName} src={ProfileImage ? ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 80, height: 80 }}></Avatar>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-9 col-sm-10">
                                                                                            <div className="form-group mt-3">
                                                                                                <div className="row">
                                                                                                    <div className="col-10 col-sm-10">
                                                                                                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{DisplayName}</Typography>
                                                                                                    </div>
                                                                                                    <div className="col-2 col-sm-2 text-right pr-0">
                                                                                                        <IconButton onClick={(e) => { this.setState({ TabIndex: 0, showDialog: true }); }}><EditIcon fontSize="small" /></IconButton>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className="form-group mt-3">
                                                                                                <PhoneOutlinedIcon fontSize="small" ></PhoneOutlinedIcon> <label>Phone</label>
                                                                                                <div className="pl-4"><Typography variant="caption" className="commentBody">{Phone}</Typography></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className="form-group mt-3">
                                                                                                <EmailOutlinedIcon fontSize="small" ></EmailOutlinedIcon> <label>Email</label>
                                                                                                <div className="pl-4"><Typography variant="caption" className="commentBody">{Email}</Typography></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>) : (
                                                                                    <Account index={TabIndex} mode='A' onCancelClick={(e) => { this.setState({ TabIndex: 0, showDialog: false }); }} onSubmitClick={(e) => { window.location.href = window.location.href; }} />
                                                                                )}
                                                                            </div>
                                                                        </React.Fragment>)}
                                                                        {!showAskForReviewDialog ? (null) : (<React.Fragment>
                                                                            <Dialog fullWidth={true} maxWidth={"sm"} open={showAskForReviewDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { this.formValidators = new Array<Validator>(); }} aria-describedby="askforReviewsDialog" >
                                                                                <DialogTitle id={"dialogAskForReviews"} className="modalTitle">{'Ask for Reviews'}
                                                                                    <IconButton aria-label="close" onClick={(e) => { this.formValidators = new Array<Validator>(); this.setState({ showAskForReviewDialog: false, Recipients: [] }); }} className="btnCloseDialog">
                                                                                        <CloseIcon />
                                                                                    </IconButton>
                                                                                </DialogTitle>
                                                                                <DialogContent dividers={true}>
                                                                                    <div className="profileContainer">
                                                                                        <div className="formContainer">
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <Button startIcon={<img src='/images/googleIcon.png' alt='Google reviews' />}> Add reviews from Google</Button>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>Get more reviews</Typography>
                                                                                                    <Typography variant="subtitle2" color="text.secondary">Invite your customers to leave reviews</Typography>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12">
                                                                                                <div className="form-group mt-3">
                                                                                                    <Autocomplete multiple={true} id="emails" options={ReviewRecipients && ReviewRecipients.length > 0 ? ReviewRecipients : []} getOptionLabel={(option) => { return (option && option.Profile.User.DisplayName ? option.Profile.User.DisplayName : ''); }}
                                                                                                        onChange={(e, newVal) => { this.setState({ Recipients: newVal ? newVal : null }); }} defaultValue={Recipients} filterSelectedOptions={true} renderInput={(params) => (<TextField name="emails" {...params} label="" placeholder="" />)} />
                                                                                                    <Validator id="valEmails" type="required" cssClass="error" controlId="emails" errorMessage="Provide email." valueToValidate={Recipients && Recipients.length > 0 ? '1' : ''} formValidators={this.formValidators} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </DialogContent>
                                                                                <DialogActions>
                                                                                    <div className="formButtonContainer text-right">
                                                                                        <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.formValidators = new Array<Validator>(); this.setState({ showAskForReviewDialog: false, Recipients: [] }); }}>Cancel</button>
                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handleAskForReviewsClick(); }}>Invite</button>
                                                                                    </div>
                                                                                </DialogActions>
                                                                            </Dialog>
                                                                        </React.Fragment>)}
                                                                    </React.Fragment>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)}
                                                </div>
                                                {!IsActive ? (null) : (<React.Fragment>
                                                    <div className={"tab-pane fade " + (ActiveTab === 1 ? "show active" : "")} id="v-pills-profile">
                                                        {ActiveTab !== 1 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <div className="headingTxt">Change Password</div>
                                                                        {showPasswordProgress ? (<ProgressBar />) : (<div className="tabPanel">
                                                                            <div className="form-group">
                                                                                <label>Current Password</label>
                                                                                <input id="cpass" name="cpass" type="password" className="form-control" placeholder="" defaultValue={CurrentPassword} onBlur={(e) => { this.setState({ CurrentPassword: e.target.value }); }} />
                                                                                <Validator id="valCPass" type='required' cssClass="error" controlId="cpass" errorMessage="Provide current password." valueToValidate={CurrentPassword} formValidators={this.formValidators}></Validator>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label>New Password</label>
                                                                                <input id="npass" name="npass" type="password" className="form-control" placeholder="" defaultValue={NewPassword} onBlur={(e) => { this.setState({ NewPassword: e.target.value }); }} />
                                                                                <Validator id="valNPass" type='required' cssClass="error" controlId="npass" errorMessage="Provide new password." valueToValidate={NewPassword} formValidators={this.formValidators}></Validator>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label>Confirm New Password</label>
                                                                                <input id="rpass" name="rpass" type="password" className="form-control" placeholder="" defaultValue={ConfirmPassword} onBlur={(e) => { this.setState({ ConfirmPassword: e.target.value }); }} />
                                                                                <Validator id="valRPass" type='required' cssClass="error" controlId="rpass" errorMessage="Confirm new password." valueToValidate={ConfirmPassword} formValidators={this.formValidators}></Validator>
                                                                                <Validator id="valRPassMatch" type='required' cssClass="error" controlId="rpass" errorMessage="New and Confirm passwords do not match." valueToValidate={ConfirmPassword && NewPassword && ConfirmPassword === NewPassword ? '1' : ''} formValidators={this.formValidators}></Validator>
                                                                            </div>
                                                                            <div className="formButtonContainer text-right">
                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSavePassword(); }}>Save</button>
                                                                            </div>
                                                                        </div>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 2 ? "show active" : "")} id="v-pills-notifications">
                                                        {ActiveTab !== 2 ? (null) : (<div className="notificationContainer">
                                                            <div className="headingTxt">Notifications</div>
                                                            <div className="notificationMessage"><InfoIcon fontSize={'small'} className="mr-2" /> <Typography variant="caption">Choose what you'd like to be emailed about</Typography></div>
                                                            {showNotificationProgress ? (<ProgressBar />) : (<div className="tabPanel mt-2">
                                                                <div className="switchControlContainter">
                                                                    <div className="box">
                                                                        <div className="custom-control custom-switch d-flex justify-content-between">
                                                                            <span>New messages</span>
                                                                            <input type="checkbox" className="custom-control-input" id="chkNM" checked={this.getNotificationStatus('NM')} onChange={(e) => { this.handleNotificationChange(e, 'NM'); }} />
                                                                            <label className="custom-control-label" htmlFor="chkNM"></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="box">
                                                                        <div className="custom-control custom-switch d-flex justify-content-between">
                                                                            <span>New direct leads</span>
                                                                            <input type="checkbox" className="custom-control-input" id="chkNDL" checked={this.getNotificationStatus('NDL')} onChange={(e) => { this.handleNotificationChange(e, 'NDL'); }} />
                                                                            <label className="custom-control-label" htmlFor="chkNDL"></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="box">
                                                                        <div className="custom-control custom-switch d-flex justify-content-between">
                                                                            <span>New opportunities</span>
                                                                            <input type="checkbox" className="custom-control-input" id="chkNO" checked={this.getNotificationStatus('NO')} onChange={(e) => { this.handleNotificationChange(e, 'NO'); }} />
                                                                            <label className="custom-control-label" htmlFor="chkNO"></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="box">
                                                                        <div className="custom-control custom-switch d-flex justify-content-between">
                                                                            <span>New quote requests</span>
                                                                            <input type="checkbox" className="custom-control-input" id="chkNQR" checked={this.getNotificationStatus('NQR')} onChange={(e) => { this.handleNotificationChange(e, 'NQR'); }} />
                                                                            <label className="custom-control-label" htmlFor="chkNQR"></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="box">
                                                                        <div className="custom-control custom-switch d-flex justify-content-between">
                                                                            <span>New reviews</span>
                                                                            <input type="checkbox" className="custom-control-input" id="chkNR" checked={this.getNotificationStatus('NR')} onChange={(e) => { this.handleNotificationChange(e, 'NR'); }} />
                                                                            <label className="custom-control-label" htmlFor="chkNR"></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mt-3">
                                                                    <div className="formButtonContainer text-right">
                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSaveNotifications(); }}>Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>)}
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 3 ? "show active" : "")} id="v-pills-deactivate">
                                                        {ActiveTab !== 3 ? (null) : (<DeactivateAccount pid={this.User.Id} email={Email} />)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 4 ? "show active" : "")} id="v-pills-subscription">
                                                        {ActiveTab !== 4 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <div className="headingTxt">Subscription</div>
                                                                        <div className="tabPanel">
                                                                            <div className="col-12 col-sm-12">
                                                                                <div className="form-group mt-3 mb-3">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className={"subscriptionCard " + (ActiveSubscription === Constants.Subscriptions.Basic ? "active" : "")}>
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-sm-12">{ActiveSubscription === Constants.Subscriptions.Basic ? (<Typography variant="h6">Basic <Chip label="Active" color="success"></Chip></Typography>) : (<Typography variant="h6">Basic</Typography>)}</div>
                                                                                                    <div className="col-12 col-sm-12"><Typography variant="subtitle1">FREE</Typography></div>
                                                                                                </div>
                                                                                                <div className="row mt-3">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <Typography variant="subtitle1" color="text.secondary">{ActiveSubscription === Constants.Subscriptions.Basic ? "This is your current plan and includes:" : "Includes:"}</Typography>
                                                                                                        <ul>
                                                                                                            <li><Typography variant="subtitle2" color="text.secondary">Your business profile</Typography></li>
                                                                                                            <li><Typography variant="subtitle2" color="text.secondary">Unlimited leads</Typography></li>
                                                                                                        </ul>
                                                                                                        <br />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-6">
                                                                                            <div className={"subscriptionCard " + (ActiveSubscription === Constants.Subscriptions.Premium ? "active" : "")}>
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-sm-7">{ActiveSubscription === Constants.Subscriptions.Premium ? (<Typography variant="h6">Premium <Chip label="Active" color="success"></Chip></Typography>) : (<Typography variant="h6">Premium</Typography>)}</div>
                                                                                                    <div className="col-12 col-sm-5">
                                                                                                        {ActiveSubscription === Constants.Subscriptions.Basic ? (
                                                                                                            <div className="formButtonContainer mt-0 mb-0">
                                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSubscriptionUpgrade('P'); }}>Subscribe</button>
                                                                                                            </div>) : (<div className="formButtonContainer mt-0 mb-0">
                                                                                                                <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSubscriptionUpgrade('B'); }}>Unsubscribe</button>
                                                                                                            </div>)}
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-12"><Typography variant="subtitle1">$14.99/month</Typography></div>
                                                                                                </div>
                                                                                                <div className="row mt-3">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <Typography variant="subtitle1" color="text.secondary">Includes everything in Basic and also:</Typography>
                                                                                                        <ul>
                                                                                                            <li><Typography variant="subtitle2" color="text.secondary">You submit your quote</Typography></li>
                                                                                                            <li><Typography variant="subtitle2" color="text.secondary">You read receipts</Typography></li>
                                                                                                            <li><Typography variant="subtitle2" color="text.secondary">You send messages</Typography></li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {!showPaymentDialog ? (null) : (<React.Fragment>
                                                                                        <Elements stripe={stripePromise}>
                                                                                            <ElementsConsumer>
                                                                                                {({ stripe, elements }) => (
                                                                                                    <Dialog key='dlgBilling' keepMounted TransitionComponent={this.SlideDownTransition} open={showPaymentDialog} onClose={(e) => { this.billingFormValidators = new Array<Validator>(); this.setState({ showPaymentDialog: false }); }} aria-labelledby={"dialogBilling"}
                                                                                                        fullWidth={true} maxWidth={"sm"} >
                                                                                                        <DialogTitle id={"dialogBilling"} className="modalTitle">{'Activate Premium subscription'}
                                                                                                            <IconButton aria-label="close" onClick={(e) => { this.resetPaymentForm(); }} disabled={showSubscriptionProgress} className="btnCloseDialog">
                                                                                                                <CloseIcon />
                                                                                                            </IconButton>
                                                                                                        </DialogTitle>
                                                                                                        <DialogContent dividers={true}>
                                                                                                            <div className="profileContainer">
                                                                                                                <div className="formContainer">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-9 pl-0">
                                                                                                                            <Typography variant="subtitle1" className="commentBody">{showNewCard ? "Add new payment method" : "Select from existing payment methods or add a new one"}</Typography>
                                                                                                                        </div>
                                                                                                                        <div className="col-2 col-sm-3 pr-0">
                                                                                                                            <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                                                                {!showNewCard ? (<button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showNewCard: true, PaymentMethodID: '' }); }}><AddIcon /></button>) : (<>{DefaultBilling ? (<Typography variant="caption"><a href="javascript:void(0);" onClick={(e) => { this.setState({ showNewCard: false, PaymentMethodID: DefaultBilling?.StripePaymentMethodID }); }}>Select from existing</a></Typography>) : (null)}</>)}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {!showNewCard ? (<>
                                                                                                                        {showSubscriptionProgress ? (<ProgressBar />) : (<>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-12 mt-2">
                                                                                                                                    {DefaultBilling ? (<div className="row askforReviewsContainer mt-2">
                                                                                                                                        <div className="col-1 col-sm-1 align-self-center">
                                                                                                                                            {!this.checkCardExpired(DefaultBilling.ExpiryMonth, DefaultBilling.ExpiryYear) ? (<input key="rdoDefaultPM" type="radio" checked={PaymentMethodID === DefaultBilling.StripePaymentMethodID} onClick={(e) => { this.setState({ PaymentMethodID: DefaultBilling.StripePaymentMethodID }); }} />) : (null)}
                                                                                                                                        </div>
                                                                                                                                        <div className="col-3 col-sm-2 align-self-center">
                                                                                                                                            <img src={'/images/' + DefaultBilling.CardType + '.png'} alt='' className="imgCreditCard" />
                                                                                                                                        </div>
                                                                                                                                        <div className="col-6 col-sm-7">
                                                                                                                                            <label>{DefaultBilling.CardType.toUpperCase()} ending with {DefaultBilling.CardNumber && DefaultBilling.CardNumber.length === 12 ? DefaultBilling.CardNumber.substring(12) : DefaultBilling.CardNumber}</label>
                                                                                                                                            <div>
                                                                                                                                                {this.checkCardExpired(DefaultBilling.ExpiryMonth, DefaultBilling.ExpiryYear) ? (<Typography variant="subtitle2" className="error">Expired on {DefaultBilling.ExpiryMonth < 10 ? "0" + DefaultBilling.ExpiryMonth.toString() : DefaultBilling.ExpiryMonth.toString()}/{DefaultBilling.ExpiryYear} | {DefaultBilling.FirstName} {DefaultBilling.LastName}</Typography>) :
                                                                                                                                                    (<Typography variant="caption" className="commentBody">Expires {DefaultBilling.ExpiryMonth < 10 ? "0" + DefaultBilling.ExpiryMonth.toString() : DefaultBilling.ExpiryMonth.toString()}/{DefaultBilling.ExpiryYear} | {DefaultBilling.FirstName} {DefaultBilling.LastName}</Typography>)}
                                                                                                                                            </div>
                                                                                                                                            {/* {PaymentMethodID === DefaultBilling.StripePaymentMethodID ? (<div>
                                                                                                                                            <CardElement key="cardDefaultPM" options={{ disabled: true, value: { postalCode: DefaultBilling.CardZipCode } }} className="form-control" />
                                                                                                                                        </div>) : (null)} */}
                                                                                                                                        </div>
                                                                                                                                        <div className="col-2 col-sm-2 text-right pr-0">
                                                                                                                                            <Typography variant="caption"><Chip label="Default" variant="filled" /></Typography>
                                                                                                                                        </div>
                                                                                                                                    </div>) : (null)}
                                                                                                                                    {OtherBilling && OtherBilling.length > 0 ? (<React.Fragment>
                                                                                                                                        {OtherBilling.map((item, indx) => {
                                                                                                                                            let temp: any = null;
                                                                                                                                            let isCardExpired = this.checkCardExpired(item.ExpiryMonth, item.ExpiryYear);

                                                                                                                                            temp = (<div className="row askforReviewsContainer mt-2">
                                                                                                                                                <div className="col-1 col-sm-1 align-self-center">
                                                                                                                                                    {!isCardExpired ? (<input key={"rdpOtherPM_" + indx} type="radio" checked={PaymentMethodID === item.StripePaymentMethodID} onClick={(e) => { this.setState({ PaymentMethodID: item.StripePaymentMethodID }); }} />) : (null)}
                                                                                                                                                </div>
                                                                                                                                                <div className="col-3 col-sm-2 align-self-center">
                                                                                                                                                    <img src={'/images/' + item.CardType + '.png'} alt='' className="imgCreditCard" />
                                                                                                                                                </div>
                                                                                                                                                <div className="col-12 col-sm-9">
                                                                                                                                                    <label>{item.CardType.toUpperCase()} ending with {item.CardNumber && item.CardNumber.length === 12 ? item.CardNumber.substring(12) : item.CardNumber}</label>
                                                                                                                                                    <div>
                                                                                                                                                        {isCardExpired ? (<Typography variant="subtitle2" className="error">Expired on {item.ExpiryMonth < 10 ? "0" + item.ExpiryMonth.toString() : item.ExpiryMonth.toString()}/{item.ExpiryYear} | {item.FirstName} {item.LastName}</Typography>) :
                                                                                                                                                            (<Typography variant="caption" className="commentBody">Expires {item.ExpiryMonth < 10 ? "0" + item.ExpiryMonth.toString() : item.ExpiryMonth.toString()}/{item.ExpiryYear} | {item.FirstName} {item.LastName}</Typography>)}
                                                                                                                                                    </div>
                                                                                                                                                    {/* {PaymentMethodID === item.StripePaymentMethodID ? (<div>
                                                                                                                                                    <CardElement key={"cardOtherPM" + indx} options={{ disabled: true, value: { postalCode: item.CardZipCode } }} className="form-control" />
                                                                                                                                                </div>) : (null)} */}
                                                                                                                                                </div>
                                                                                                                                            </div>);

                                                                                                                                            return temp;
                                                                                                                                        })}
                                                                                                                                    </React.Fragment>) : (<>{!DefaultBilling ? (<Typography variant="caption" className="commentBody">
                                                                                                                                        There is no payment method available.
                                                                                                                                    </Typography>) : (null)}</>)}
                                                                                                                                    <Validator id="valPaymentMethod" type='required' cssClass="error" errorMessage="Select a card." controlId="cardPayment" valueToValidate={!showNewCard ? PaymentMethodID : '1'} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-12 mt-2 pl-0 pr-0">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Your Subscription will auto-renew for the same price until you downgrade your plan at any time. By clicking the Pay button, you authorize us to charge your card and you agree to our terms and conditions.</label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>)}
                                                                                                                    </>) : (<>
                                                                                                                        {showSubscriptionProgress ? (<ProgressBar />) : (<>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-12 col-sm-12">
                                                                                                                                            <div className="form-group">
                                                                                                                                                <label>Card details</label>
                                                                                                                                                <CardElement options={{ disabled: (DialogMode === 'V'), value: { postalCode: CardZipCode } }} className="form-control" onBlur={() => { let thisCard: any = elements?.getElement(CardElement); this.setState({ CardNumber: (thisCard && (thisCard._empty || thisCard._invalid) ? "" : "1") }); }} />
                                                                                                                                                <Validator id="valCardNumber" type='required' cssClass="error" errorMessage="Provide valid card details." controlId="cardNumber" valueToValidate={!showNewCard ? '1' : CardNumber} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                                            <div className="form-group">
                                                                                                                                                <label>First name</label>
                                                                                                                                                {DialogMode === 'V' ? (<div><span>{CardFirstName}</span></div>) : (
                                                                                                                                                    <React.Fragment>
                                                                                                                                                        <input id="cardFirstName" name="cardFirstName" type="text" key={'cardFirstName_' + CardFirstName} defaultValue={CardFirstName} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardFirstName: e.target.value }); }} />
                                                                                                                                                        <Validator id="valCardFirstName" type='required' cssClass="error" errorMessage="Provide first name on your card." controlId="cardFirstName" valueToValidate={!showNewCard ? '1' : CardFirstName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                        <Validator id="valCardFirstNameLength" type='max_length' cssClass="error" maxLength={50} errorMessage="First name cannot exceed 50 characters." controlId="cardFirstName" valueToValidate={!showNewCard ? '1' : CardFirstName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                    </React.Fragment>)}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                                            <div className="form-group">
                                                                                                                                                <label>Last name</label>
                                                                                                                                                {DialogMode === 'V' ? (<div><span>{CardLastName}</span></div>) : (
                                                                                                                                                    <React.Fragment>
                                                                                                                                                        <input id="cardLastName" name="cardLastName" type="text" key={'cardLastName_' + CardLastName} defaultValue={CardLastName} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardLastName: e.target.value }); }} />
                                                                                                                                                        <Validator id="valCardLastName" type='required' cssClass="error" errorMessage="Provide last name on your card." controlId="cardLastName" valueToValidate={!showNewCard ? '1' : CardLastName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                        <Validator id="valCardLastNameLength" type='max_length' cssClass="error" maxLength={50} errorMessage="Last name cannot exceed 50 characters." controlId="cardLastName" valueToValidate={!showNewCard ? '1' : CardLastName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                    </React.Fragment>)}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Billing address</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardBillingAddress}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <input id="cardBillingAddress" name="cardBillingAddress" type="text" key={'cardBillingAddress_' + CardFirstName} defaultValue={CardBillingAddress} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardBillingAddress: e.target.value }); }} />
                                                                                                                                                <Validator id="valCardBillingAddress" type='required' cssClass="error" errorMessage="Provide billing address." controlId="cardBillingAddress" valueToValidate={!showNewCard ? '1' : CardBillingAddress} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                <Validator id="valCardBillingAddressLength" type='max_length' cssClass="error" maxLength={500} errorMessage="Billing address cannot exceed 500 characters." controlId="cardBillingAddress" valueToValidate={!showNewCard ? '1' : CardBillingAddress} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                                            <div className="form-group">
                                                                                                                                                <label>Zip code</label>
                                                                                                                                                {DialogMode === 'V' ? (<div><span>{CardZipCode}</span></div>) : (
                                                                                                                                                    <React.Fragment>
                                                                                                                                                        <input id="cardZipCode" name="cardZipCode" type="text" key={'cardZipCode_' + CardZipCode} defaultValue={CardZipCode} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardZipCode: e.target.value }); }} />
                                                                                                                                                        <Validator id="valCardZipCode" type='required' cssClass="error" errorMessage="Provide zip code." controlId="cardZipCode" valueToValidate={!showNewCard ? '1' : CardZipCode} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                        <Validator id="valCardZipCodeType" type='number' cssClass="error" errorMessage="Only numeric values are allowed." controlId="cardZipCode" valueToValidate={!showNewCard ? '1' : CardZipCode} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                        <Validator id="valCardZipCodeLength" type='max_length' cssClass="error" maxLength={6} errorMessage="Zip code cannot exceed 6 characters." controlId="cardZipCode" valueToValidate={!showNewCard ? '1' : CardZipCode} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                    </React.Fragment>)}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-12 col-sm-6">
                                                                                                                                            <div className="form-group">
                                                                                                                                                <label>Country/Region</label>
                                                                                                                                                {DialogMode === 'V' ? (<div><span>{CardCountryName}</span></div>) : (
                                                                                                                                                    <React.Fragment>
                                                                                                                                                        <select id="cardCountry" name="cardCountry" key={'cardCountry_' + CardCountry} defaultValue={CardCountry} className="form-control" placeholder="" onChange={(e) => { this.setState({ CardCountry: e.target.value, CardCountryName: e.target.selectedOptions[0].text }); }}>
                                                                                                                                                            <option value=''>Select</option>
                                                                                                                                                            {Countries && Countries.map((s) => {
                                                                                                                                                                return (<option value={s.value}>{s.text}</option>);
                                                                                                                                                            })}
                                                                                                                                                        </select>
                                                                                                                                                        <Validator id="valCardCountry" type='required' cssClass="error" errorMessage="Select country/region." controlId="cardCountry" valueToValidate={!showNewCard ? '1' : CardCountry} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                    </React.Fragment>)}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Company name (optional)</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardCompanyName}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <input type='text' id="cardCompanyName" name="cardCompanyName" key={'cardCompanyName_' + CardNumber} defaultValue={CardCompanyName} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardCompanyName: e.target.value }); }} />
                                                                                                                                                <Validator id="valCardCompanyNameLength" type='max_length' maxLength={100} cssClass="error" errorMessage="Company name cannot exceed 100 characters." controlId="cardCompanyName" valueToValidate={!showNewCard ? '1' : CardCompanyName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                    <div className="form-group">
                                                                                                                                        <FormControlLabel
                                                                                                                                            control={<Checkbox checked={IsDefaultCard} disabled={DialogMode === 'V'} onChange={(e) => { this.setState({ IsDefaultCard: e.target.checked }); }} key={'check_' + IsDefaultCard} title='Check this to use this card as default for all payments in future.' />}
                                                                                                                                            label={<Typography variant='subtitle2'>Use this card as default for all payments in future.</Typography>} />
                                                                                                                                    </div>
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Your Subscription will auto-renew for the same price until you downgrade your plan at any time. By clicking the Pay button, you authorize us to charge your card and you agree to our terms and conditions.</label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>)}
                                                                                                                    </>)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </DialogContent>
                                                                                                        <DialogActions>
                                                                                                            <div className="formButtonContainer text-right">
                                                                                                                <button type="submit" disabled={showSubscriptionProgress} className="btn btn-cancel mr-3" onClick={(e) => { this.resetPaymentForm(); }}>Cancel</button>
                                                                                                                {DialogMode !== 'V' ? (<button type="submit" disabled={showSubscriptionProgress} className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handleStripePay(elements!); }}>Pay</button>) : (null)}
                                                                                                            </div>
                                                                                                        </DialogActions>
                                                                                                    </Dialog>)}
                                                                                            </ElementsConsumer>
                                                                                        </Elements>
                                                                                    </React.Fragment>)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 5 ? "show active" : "")} id="v-pills-billing">
                                                        {ActiveTab !== 5 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        {!this.props.id ? (<div className="headingTxt">Billing & Payments</div>) : (null)}
                                                                        {showBillingProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                            {showBillingEdit ? (<div className="tabPanel">
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="form-group mt-3">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-10 pl-0">
                                                                                                <Typography variant="subtitle1" className="commentBody">{showNewPaymentMethod ? (ItemId > 0 ? "Edit card details" : "Add new payment method") : "Select from existing payment methods or add a new one"}</Typography>
                                                                                            </div>
                                                                                            {showNewPaymentMethod ? (null) : (
                                                                                                <div className="col-12 col-sm-2 pl-0">
                                                                                                    <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showNewPaymentMethod: true }); }}><AddIcon /></button>
                                                                                                    </div>
                                                                                                </div>)}
                                                                                        </div>
                                                                                        {showNewPaymentMethod ? (<React.Fragment>
                                                                                            <Elements stripe={stripePromise}>
                                                                                                <ElementsConsumer>
                                                                                                    {({ stripe, elements }) => (
                                                                                                        <React.Fragment>
                                                                                                            <div className="profileContainer mt-2">
                                                                                                                <div className="formContainer">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-12">
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Card details</label>
                                                                                                                                        <CardElement options={{ disabled: (DialogMode === 'V'), value: { postalCode: CardZipCode } }} className="form-control" onBlur={() => { let thisCard: any = elements?.getElement(CardElement); this.setState({ CardNumber: (thisCard && (thisCard._empty || thisCard._invalid) ? "" : "1") }); }} />
                                                                                                                                        <Validator id="valCardNumber" type='required' cssClass="error" errorMessage="Provide valid card details." controlId="cardNumber" valueToValidate={CardNumber} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>First name</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardFirstName}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <input id="cardFirstName" name="cardFirstName" type="text" key={'cardFirstName_' + CardFirstName} defaultValue={CardFirstName} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardFirstName: e.target.value }); }} />
                                                                                                                                                <Validator id="valCardFirstName" type='required' cssClass="error" errorMessage="Provide first name on your card." controlId="cardFirstName" valueToValidate={CardFirstName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                <Validator id="valCardFirstNameLength" type='max_length' cssClass="error" maxLength={50} errorMessage="First name cannot exceed 50 characters." controlId="cardFirstName" valueToValidate={CardFirstName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Last name</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardLastName}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <input id="cardLastName" name="cardLastName" type="text" key={'cardLastName_' + CardLastName} defaultValue={CardLastName} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardLastName: e.target.value }); }} />
                                                                                                                                                <Validator id="valCardLastName" type='required' cssClass="error" errorMessage="Provide last name on your card." controlId="cardLastName" valueToValidate={CardLastName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                <Validator id="valCardLastNameLength" type='max_length' cssClass="error" maxLength={50} errorMessage="Last name cannot exceed 50 characters." controlId="cardLastName" valueToValidate={CardLastName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Billing address</label>
                                                                                                                                {DialogMode === 'V' ? (<div><span>{CardBillingAddress}</span></div>) : (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <input id="cardBillingAddress" name="cardBillingAddress" type="text" key={'cardBillingAddress_' + ItemId} defaultValue={CardBillingAddress} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardBillingAddress: e.target.value }); }} />
                                                                                                                                        <Validator id="valCardBillingAddress" type='required' cssClass="error" errorMessage="Provide billing address." controlId="cardBillingAddress" valueToValidate={CardBillingAddress} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                        <Validator id="valCardBillingAddressLength" type='max_length' cssClass="error" maxLength={500} errorMessage="Billing address cannot exceed 500 characters." controlId="cardBillingAddress" valueToValidate={CardBillingAddress} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                    </React.Fragment>)}
                                                                                                                            </div>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Zip code</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardZipCode}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <input id="cardZipCode" name="cardZipCode" type="text" key={'cardZipCode_' + CardZipCode} defaultValue={CardZipCode} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardZipCode: e.target.value }); }} />
                                                                                                                                                <Validator id="valCardZipCode" type='required' cssClass="error" errorMessage="Provide zip code." controlId="cardZipCode" valueToValidate={CardZipCode} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                <Validator id="valCardZipCodeType" type='number' cssClass="error" errorMessage="Only numeric values are allowed." controlId="cardZipCode" valueToValidate={CardZipCode} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                <Validator id="valCardZipCodeLength" type='max_length' cssClass="error" maxLength={6} errorMessage="Zip code cannot exceed 6 characters." controlId="cardZipCode" valueToValidate={CardZipCode} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Country/Region</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardCountryName}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <select id="cardCountry" name="cardCountry" key={'cardCountry_' + CardCountry} defaultValue={CardCountry} className="form-control" placeholder="" onChange={(e) => { this.setState({ CardCountry: e.target.value, CardCountryName: e.target.selectedOptions[0].text }); }}>
                                                                                                                                                    <option value=''>Select</option>
                                                                                                                                                    {Countries && Countries.map((s) => {
                                                                                                                                                        return (<option value={s.value}>{s.text}</option>);
                                                                                                                                                    })}
                                                                                                                                                </select>
                                                                                                                                                <Validator id="valCardCountry" type='required' cssClass="error" errorMessage="Select country/region." controlId="cardCountry" valueToValidate={CardCountry} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Company name (optional)</label>
                                                                                                                                {DialogMode === 'V' ? (<div><span>{CardCompanyName}</span></div>) : (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <input type='text' id="cardCompanyName" name="cardCompanyName" key={'cardCompanyName_' + CardNumber} defaultValue={CardCompanyName} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardCompanyName: e.target.value }); }} />
                                                                                                                                        <Validator id="valCardCompanyNameLength" type='max_length' maxLength={100} cssClass="error" errorMessage="Company name cannot exceed 100 characters." controlId="cardCompanyName" valueToValidate={CardCompanyName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                    </React.Fragment>)}
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <FormControlLabel
                                                                                                                                    control={<Checkbox checked={IsDefaultCard} disabled={DialogMode === 'V'} onChange={(e) => { this.setState({ IsDefaultCard: e.target.checked }); }} key={'check_' + IsDefaultCard} title='Check this to use this card as default for all payments in future.' />}
                                                                                                                                    label={<Typography variant='subtitle2'>Use this card as default for all payments in future. This will replace any existing card details used in current subscription payments.</Typography>} />
                                                                                                                            </div>
                                                                                                                            {!IsDefaultCard ? (null) : (
                                                                                                                                <div className="form-group">
                                                                                                                                    <label>Your Subscription will auto-renew for the same price until you downgrade your plan at any time. By clicking the Save button, you authorize us to charge your card and you agree to our terms and conditions.</label>
                                                                                                                                </div>)}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="formButtonContainer text-right">
                                                                                                                <button type="submit" disabled={showSubscriptionProgress} className="btn btn-cancel mr-3" onClick={(e) => { this.setState({ showNewPaymentMethod: false, ItemId: 0 }, () => { this.resetPaymentForm(); }); }}>Cancel</button>
                                                                                                                {DialogMode !== 'V' ? (<button type="submit" disabled={showSubscriptionProgress} className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleBillingInfoSave('S', elements!); }}>Save</button>) : (null)}
                                                                                                            </div>
                                                                                                        </React.Fragment>)}
                                                                                                </ElementsConsumer>
                                                                                            </Elements>
                                                                                        </React.Fragment>) : (<div className="col-12 col-sm-12 p-0">
                                                                                            {DefaultBilling ? (<div className="row askforReviewsContainer mt-2">
                                                                                                <div className="col-12 col-sm-1 align-self-center pl-0">
                                                                                                    <img src={'/images/' + DefaultBilling.CardType + '.png'} alt='' className="imgCreditCard" />
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-6">
                                                                                                    <label>{DefaultBilling.CardType.toUpperCase()} ending with {DefaultBilling.CardNumber && DefaultBilling.CardNumber.length === 12 ? DefaultBilling.CardNumber.substring(12) : DefaultBilling.CardNumber}</label>
                                                                                                    <div>
                                                                                                        {this.checkCardExpired(DefaultBilling.ExpiryMonth, DefaultBilling.ExpiryYear) ? (<Typography variant="subtitle2" className="error">Expired on {DefaultBilling.ExpiryMonth < 10 ? "0" + DefaultBilling.ExpiryMonth.toString() : DefaultBilling.ExpiryMonth.toString()}/{DefaultBilling.ExpiryYear} | {DefaultBilling.FirstName} {DefaultBilling.LastName}</Typography>) :
                                                                                                            (<Typography variant="caption" className="commentBody">Expires {DefaultBilling.ExpiryMonth < 10 ? "0" + DefaultBilling.ExpiryMonth.toString() : DefaultBilling.ExpiryMonth.toString()}/{DefaultBilling.ExpiryYear} | {DefaultBilling.FirstName} {DefaultBilling.LastName}</Typography>)}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-5 text-right pr-0">
                                                                                                    <Typography variant="caption"><Chip label="Default" variant="filled" /></Typography>
                                                                                                </div>
                                                                                            </div>) : (null)}
                                                                                            {OtherBilling && OtherBilling.length > 0 ? (<React.Fragment>
                                                                                                {OtherBilling.map((item, indx) => {
                                                                                                    let temp: any = null;
                                                                                                    temp = (<div className="row askforReviewsContainer mt-2">
                                                                                                        <div className="col-12 col-sm-1 align-self-center pl-0">
                                                                                                            <img src={'/images/' + item.CardType + '.png'} alt='' className="imgCreditCard" />
                                                                                                        </div>
                                                                                                        <div className="col-12 col-sm-6">
                                                                                                            <label>{item.CardType.toUpperCase()} ending with {item.CardNumber && item.CardNumber.length === 12 ? item.CardNumber.substring(12) : item.CardNumber}</label>
                                                                                                            <div>
                                                                                                                {this.checkCardExpired(item.ExpiryMonth, item.ExpiryYear) ? (<Typography variant="subtitle2" className="error">Expired on {item.ExpiryMonth < 10 ? "0" + item.ExpiryMonth.toString() : item.ExpiryMonth.toString()}/{item.ExpiryYear} | {item.FirstName} {item.LastName}</Typography>) :
                                                                                                                    (<Typography variant="caption" className="commentBody">Expires {item.ExpiryMonth < 10 ? "0" + item.ExpiryMonth.toString() : item.ExpiryMonth.toString()}/{item.ExpiryYear} | {item.FirstName} {item.LastName}</Typography>)}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-12 col-sm-5 text-right pr-0">
                                                                                                            {!item.IsDefault ? (<Typography variant="caption" ><EditIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleSetAsDefault(item); }} className="ml-2 mr-2" title="Set this card as a default mode of payments for ProsBE Premium subscription in future.">Set as default</a><DeleteIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleDeleteCard(item); }} className="ml-2" title="Delete card details">Delete</a></Typography>) : (null)}
                                                                                                        </div>
                                                                                                    </div>);

                                                                                                    return temp;
                                                                                                })}
                                                                                            </React.Fragment>) : (<>{!DefaultBilling ? (<Typography variant="caption" className="commentBody">
                                                                                                There is no payment method available.
                                                                                            </Typography>) : (null)}</>)}
                                                                                            <div className="formButtonContainer text-right">
                                                                                                <button type="submit" className="btn btn-cancel" onClick={(e) => { this.setState({ showBillingEdit: false }, () => { this.resetPaymentForm(); }); }}>Cancel</button>
                                                                                            </div>
                                                                                        </div>)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>) : (<div className="tabPanel">
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="form-group mt-3">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12 pl-0">
                                                                                                <Typography variant="subtitle1" className="commentBody">{this.props.id > 0 ? "Default" : "Your default"} payment method</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row askforReviewsContainer">
                                                                                            {!DefaultBilling ? (<Typography variant="caption" className="commentBody">
                                                                                                No default payment method found. {this.props.id > 0 ? (null) : (<><a href='javascript:void(0);' onClick={(e) => { this.setState({ showBillingEdit: true }); }}>Click here</a> to set the default payment method.</>)}
                                                                                            </Typography>) : (<React.Fragment>
                                                                                                <div className="col-12 col-sm-1 align-self-center pl-0">
                                                                                                    <img src={'/images/' + DefaultBilling.CardType + '.png'} alt='' className="imgCreditCard" />
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-6">
                                                                                                    <label>{DefaultBilling.CardType.toUpperCase()} ending with {DefaultBilling.CardNumber && DefaultBilling.CardNumber.length === 12 ? DefaultBilling.CardNumber.substring(12) : DefaultBilling.CardNumber}</label>
                                                                                                    <div>
                                                                                                        {this.checkCardExpired(DefaultBilling.ExpiryMonth, DefaultBilling.ExpiryYear) ? (<Typography variant="subtitle2" className="error">Expired on {DefaultBilling.ExpiryMonth < 10 ? "0" + DefaultBilling.ExpiryMonth.toString() : DefaultBilling.ExpiryMonth.toString()}/{DefaultBilling.ExpiryYear} | {DefaultBilling.FirstName} {DefaultBilling.LastName}</Typography>) :
                                                                                                            (<Typography variant="caption" className="commentBody">Expires {DefaultBilling.ExpiryMonth < 10 ? "0" + DefaultBilling.ExpiryMonth.toString() : DefaultBilling.ExpiryMonth.toString()}/{DefaultBilling.ExpiryYear} | {DefaultBilling.FirstName} {DefaultBilling.LastName}</Typography>)}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-5 p-0 text-right">
                                                                                                    {this.props.id > 0 ? (null) : (<button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showBillingEdit: true }); }}>Change</button>)}
                                                                                                </div>
                                                                                                {Subscription ? (<React.Fragment>
                                                                                                    <div className="col-12 col-sm-12 p-0">
                                                                                                        <Typography variant="caption" sx={{ fontWeight: 600 }}>{Subscription.plan.nickname} </Typography>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-12 p-0">
                                                                                                        <Typography variant="caption" className="commentBody">{Subscription.plan.interval === "month" ? "Billing monthly" : "Billing yearly"} | {"Next invoice on " + this.getFormattedDate(new Date(Subscription.current_period_end * 1000), Constants.IDateTimeFormat.MMMddyyyy, ' ') + " for $" + Number(Subscription.plan.amount / 100)}</Typography>
                                                                                                    </div>
                                                                                                </React.Fragment>) : (null)}
                                                                                            </React.Fragment>)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-group mt-3">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12  pl-0">
                                                                                                <label>Payment history</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-12 p-0">
                                                                                            {StripePayments && StripePayments.length > 0 ? (<React.Fragment>
                                                                                                {StripePayments.map((bill, indx) => {
                                                                                                    let temp: any = null;
                                                                                                    temp = (
                                                                                                        <div className="row askforReviewsContainer mt-2">
                                                                                                            <div className="col-4 col-sm-4">
                                                                                                                <Typography variant="caption" sx={{ fontWeight: 600 }}>Amount: </Typography><Typography variant="caption">${Number(bill.AmountCaptured / 100)} {bill.Currency.toUpperCase()}</Typography>
                                                                                                            </div>
                                                                                                            <div className="col-4 col-sm-4">
                                                                                                                <Typography variant="caption" sx={{ fontWeight: 600 }}>Date: </Typography><Typography variant="caption">{this.getFormattedDate(new Date(bill.Created * 1000), Constants.IDateTimeFormat.MMMddyyyy, ' ', true)}</Typography>
                                                                                                            </div>
                                                                                                            <div className="col-4 col-sm-4">
                                                                                                                <Typography variant="caption" sx={{ fontWeight: 600 }}>Status: </Typography><Typography variant="caption">{bill.Status.toUpperCase()}</Typography>
                                                                                                            </div>
                                                                                                            <div className="col-10 col-sm-10">
                                                                                                                <Typography variant="caption" sx={{ fontWeight: 600 }}>Description: </Typography><Typography variant="caption">{bill.Description}</Typography>
                                                                                                            </div>
                                                                                                            <div className="col-2 col-sm-2 text-center">
                                                                                                                <Typography variant="caption">{this.OpenToAll === "1" || ActiveSubscription === Constants.Subscriptions.Premium ? (<a href="javascript:void(0);" title="View/Download invoice/receipt" onClick={(e) => { if (this.OpenToAll === "1" || ActiveSubscription === Constants.Subscriptions.Premium) { this.downloadReceipt(bill.ChargeId); } }} ><img src="/images/pdfIcon.png" alt="View/Download invoice/receipt" /></a>) : (<img src="/images/pdfIcon.png" alt="View/Download invoice/receipt" />)}</Typography>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );

                                                                                                    return temp;
                                                                                                })}
                                                                                            </React.Fragment>) : (<Typography variant="caption" className="commentBody">
                                                                                                There is no payment history available.
                                                                                            </Typography>)}
                                                                                        </div>
                                                                                    </div>
                                                                                    {!showPaymentDialog ? (null) : (
                                                                                        <Elements stripe={stripePromise}>
                                                                                            <ElementsConsumer>
                                                                                                {({ stripe, elements }) => (
                                                                                                    <Dialog key='dlgBilling' keepMounted TransitionComponent={this.SlideDownTransition} open={showPaymentDialog}
                                                                                                        onClose={(e) => { this.billingFormValidators = new Array<Validator>(); this.setState({ showPaymentDialog: false }); }} aria-labelledby={"dialogBilling"}
                                                                                                        fullWidth={true} maxWidth={"sm"} >
                                                                                                        <DialogTitle id={"dialogBilling"} className="modalTitle">{CardNumber ? CardNumber : 'New Billing Method'}
                                                                                                            <IconButton aria-label="close" disabled={showSubscriptionProgress} onClick={(e) => { this.resetPaymentForm(); }} className="btnCloseDialog">
                                                                                                                <CloseIcon />
                                                                                                            </IconButton>
                                                                                                        </DialogTitle>
                                                                                                        <DialogContent dividers={true}>
                                                                                                            <div className="profileContainer">
                                                                                                                <div className="formContainer">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-12">
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Card details</label>
                                                                                                                                <CardElement options={{ disabled: (DialogMode === 'V'), value: { postalCode: CardZipCode } }} className="form-control" onBlur={() => { let thisCard: any = elements?.getElement(CardElement); this.setState({ CardNumber: (thisCard && (thisCard._empty || thisCard._invalid) ? "" : "1") }); }} />
                                                                                                                                <Validator id="valCardNumber" type='required' cssClass="error" errorMessage="Provide valid card details." controlId="cardNumber" valueToValidate={CardNumber} formValidators={this.billingFormValidators}></Validator>
                                                                                                                            </div>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>First name</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardFirstName}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <input id="cardFirstName" name="cardFirstName" type="text" key={'cardFirstName_' + CardFirstName} defaultValue={CardFirstName} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardFirstName: e.target.value }); }} />
                                                                                                                                                <Validator id="valCardFirstName" type='required' cssClass="error" errorMessage="Provide first name on your card." controlId="cardFirstName" valueToValidate={CardFirstName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                <Validator id="valCardFirstNameLength" type='max_length' cssClass="error" maxLength={50} errorMessage="First name cannot exceed 50 characters." controlId="cardFirstName" valueToValidate={CardFirstName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Last name</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardLastName}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <input id="cardLastName" name="cardLastName" type="text" key={'cardLastName_' + CardLastName} defaultValue={CardLastName} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardLastName: e.target.value }); }} />
                                                                                                                                                <Validator id="valCardLastName" type='required' cssClass="error" errorMessage="Provide last name on your card." controlId="cardLastName" valueToValidate={CardLastName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                <Validator id="valCardLastNameLength" type='max_length' cssClass="error" maxLength={50} errorMessage="Last name cannot exceed 50 characters." controlId="cardLastName" valueToValidate={CardLastName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Billing address</label>
                                                                                                                                {DialogMode === 'V' ? (<div><span>{CardBillingAddress}</span></div>) : (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <input id="cardBillingAddress" name="cardBillingAddress" type="text" key={'cardBillingAddress_' + CardFirstName} defaultValue={CardBillingAddress} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardBillingAddress: e.target.value }); }} />
                                                                                                                                        <Validator id="valCardBillingAddress" type='required' cssClass="error" errorMessage="Provide billing address." controlId="cardBillingAddress" valueToValidate={CardBillingAddress} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                        <Validator id="valCardBillingAddressLength" type='max_length' cssClass="error" maxLength={500} errorMessage="Billing address cannot exceed 500 characters." controlId="cardBillingAddress" valueToValidate={CardBillingAddress} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                    </React.Fragment>)}
                                                                                                                            </div>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Zip code</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardZipCode}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <input id="cardZipCode" name="cardZipCode" type="text" key={'cardZipCode_' + CardZipCode} defaultValue={CardZipCode} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardZipCode: e.target.value }); }} />
                                                                                                                                                <Validator id="valCardZipCode" type='required' cssClass="error" errorMessage="Provide zip code." controlId="cardZipCode" valueToValidate={CardZipCode} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                <Validator id="valCardZipCodeType" type='number' cssClass="error" errorMessage="Only numeric values are allowed." controlId="cardZipCode" valueToValidate={CardZipCode} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                                <Validator id="valCardZipCodeLength" type='max_length' cssClass="error" maxLength={6} errorMessage="Zip code cannot exceed 6 characters." controlId="cardZipCode" valueToValidate={CardZipCode} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-12 col-sm-6">
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label>Country/Region</label>
                                                                                                                                        {DialogMode === 'V' ? (<div><span>{CardCountryName}</span></div>) : (
                                                                                                                                            <React.Fragment>
                                                                                                                                                <select id="cardCountry" name="cardCountry" key={'cardCountry_' + CardCountry} defaultValue={CardCountry} className="form-control" placeholder="" onChange={(e) => { this.setState({ CardCountry: e.target.value, CardCountryName: e.target.selectedOptions[0].text }); }}>
                                                                                                                                                    <option value=''>Select</option>
                                                                                                                                                    {Countries && Countries.map((s) => {
                                                                                                                                                        return (<option value={s.value}>{s.text}</option>);
                                                                                                                                                    })}
                                                                                                                                                </select>
                                                                                                                                                <Validator id="valCardCountry" type='required' cssClass="error" errorMessage="Select country/region." controlId="cardCountry" valueToValidate={CardCountry} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                            </React.Fragment>)}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Company name (optional)</label>
                                                                                                                                {DialogMode === 'V' ? (<div><span>{CardCompanyName}</span></div>) : (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <input type='text' id="cardCompanyName" name="cardCompanyName" key={'cardCompanyName_' + CardNumber} defaultValue={CardCompanyName} className="form-control" placeholder="" onBlur={(e) => { this.setState({ CardCompanyName: e.target.value }); }} />
                                                                                                                                        <Validator id="valCardCompanyNameLength" type='max_length' maxLength={100} cssClass="error" errorMessage="Company name cannot exceed 100 characters." controlId="cardCompanyName" valueToValidate={CardCompanyName} formValidators={this.billingFormValidators}></Validator>
                                                                                                                                    </React.Fragment>)}
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <FormControlLabel
                                                                                                                                    control={<Checkbox checked={IsDefaultCard} disabled={DialogMode === 'V'} onChange={(e) => { this.setState({ IsDefaultCard: e.target.checked }); }} key={'check_' + IsDefaultCard} title='Check this to use this card as default for all payments in future.' />}
                                                                                                                                    label={<Typography variant='subtitle2'>Use this card as default for all payments in future.</Typography>} />
                                                                                                                            </div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label>Your Subscription will auto-renew for the same price until you downgrade your plan at any time. By clicking the Save button, you authorize us to charge your card and you agree to our terms and conditions.</label>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </DialogContent>
                                                                                                        <DialogActions>
                                                                                                            <div className="formButtonContainer text-right">
                                                                                                                <button type="submit" disabled={showSubscriptionProgress} className="btn btn-cancel mr-3" onClick={(e) => { this.resetPaymentForm(); }}>Cancel</button>
                                                                                                                {DialogMode !== 'V' ? (<button type="submit" disabled={showSubscriptionProgress} className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handleBillingInfoSave('B', elements!); }}>Save</button>) : (null)}
                                                                                                            </div>
                                                                                                        </DialogActions>
                                                                                                    </Dialog>)}
                                                                                            </ElementsConsumer>
                                                                                        </Elements>
                                                                                    )}
                                                                                </div>
                                                                            </div>)}
                                                                        </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                </React.Fragment>)}
                                            </div>
                                            <div id="map"></div>
                                        </div>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </section >
                    <Footer />
                </React.Fragment >);
            }
        }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}