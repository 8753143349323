import React from "react";
import Base from "src/common/Base";
import { Loader } from "src/common/Components";
import Constants from "src/common/Constants";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import Validator from "src/common/Validator";
import { Alert, Snackbar } from "@mui/material";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';

const initClient = () => { gapi.client.init({ clientId: Constants.Google.ClientID, scope: 'profile email' }); }

export default class CreateUser extends Base {
    private formValidators: Validator[];
    private validator: Validator;

    constructor(props) {
        super(props);

        this.state = {
            loading: true, FirstName: '', LastName: '', Email: '', Password: '', Role: (this.props.userType.toLowerCase() === 'pro' ? Constants.UserRoles.Pro : (this.props.userType === 'cust' ? Constants.UserRoles.Customer : '')),
            ShowMessage: false, Message: '', MessageType: 'success', User: null, Source: 'APP'
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
        this.createUser = this.createUser.bind(this);
        this.onGoogleSignUpSuccess = this.onGoogleSignUpSuccess.bind(this);
        this.onGoogleSignUpError = this.onGoogleSignUpError.bind(this);

        gapi.load('client:auth2', initClient);
    }

    public componentDidMount() {
        if (this.LoginName) {
            this.getUser(this.LoginName).then((user) => {
                this.setState({
                    loading: false, User: user, FirstName: user.FirstName, LastName: user.LastName
                });
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    private handleCreateUser() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.createUser();
        }
    }

    private sendEmail(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.Registration;
            let subject = emailTemplate.Subject;
            let body = emailTemplate.Body;

            body = body.replace("[username]", this.state.FirstName + (this.state.LastName ? ' ' + this.state.LastName.toString().charAt(0) + '.' : ''));

            if (this.state.Email) {
                this.db.sendEmail(this.state.Email, subject, body).then((sent) => {
                    if (sent) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(false);
            }
        });
    }

    private createUser() {
        if (!this.IsUserValid) {
            this.db.checkUser(this.state.Email).then((result) => {
                if (!result) {
                    this.db.createUser(this.state.FirstName, this.state.LastName, this.state.Email, (this.state.Source === 'APP' ? this.encodeString(this.state.Password) : ''), this.state.Role, this.state.Source).then((newId) => {
                        if (newId > 0) {
                            this.sendEmail().then((sent) => {
                                if (sent) {
                                    this.successMessage('You have successfully registered as a ' + this.state.Role);
                                    window.location.href = '/login';
                                }
                                else {
                                    this.setState({ loading: false }, () => { this.errorMessage('Your registration is successful but registration email could not be sent.'); });
                                }
                            });
                        }
                        else {
                            this.setState({ loading: false }, () => { this.errorMessage('There is some issue in registration process. Please try again later.'); });
                        }
                    });
                }
                else {
                    this.setState({ loading: false }, () => { this.errorMessage('This email is already in use.'); });
                }
            });
        }
        else {
            let itemToUpdate: any = {
                pId: 0, uId: this.state.User.Id.toString(), fName: this.state.FirstName, lName: this.state.LastName, email: this.state.User.Email,
                address: this.state.User ? this.state.User.Address : '',
                sId: this.state.User && this.state.User.State ? this.state.User.State.Id.toString() : '',
                cId: this.state.User && this.state.User.City && this.state.User.City.Id ? this.state.User.City.Id.toString() : '',
                pin: this.state.User && this.state.User.Pin ? this.state.User.Pin.toString() : '0', desc: '', phone: this.state.User.Profile ? this.state.User.Profile.Phone.toString() : '',
                siteUrl: '', year: '0', bName: '', pRole: this.state.Role, sCode: this.state.User && this.state.User.State && this.state.User.State.Code ? this.state.User.State.Code.toString() : '',
                lat: this.state.User && this.state.User.Latitude ? this.state.User.Latitude.toString() : '0', lon: this.state.User && this.state.User.Longitude ? this.state.User.Longitude.toString() : '0',
                pAddress: this.state.User ? this.state.User.PinAddress : '', pName: '', mode: 'P'
            };

            this.db.updateUserProfile(itemToUpdate).then((updated) => {
                if (updated) {
                    this.successMessage('You have successfully registered as a ' + this.state.Role);
                    this.db.getUser(this.LoginName, this.AppRole).then((user) => {
                        if (user)
                            sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(JSON.stringify(user)));

                        window.location.href = '/';
                    });
                }
                else {
                    this.setState({ loading: false }, () => { this.errorMessage('There is some issue in registration process. Please try again later.'); });
                }
            });
        }
    }

    private onGoogleSignUpSuccess(googleUser) {
        //console.log(googleUser);
        if (googleUser && googleUser.profileObj) {
            this.setState({ loading: true, Email: googleUser.profileObj.email, FirstName: googleUser.profileObj.givenName, LastName: googleUser.profileObj.familyName, Source: 'GOOGLE' }, () => {
                this.createUser();
            });
        }
        else {
            this.errorMessage('There is some issue in registration process. Please try again later.');
        }
    }

    private onGoogleSignUpError(error) {
        console.log(error.error);
        this.errorMessage('There is some issue in registration process. Please try again later.');
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {
        const { loading, Email, FirstName, LastName, Password, Role, ShowMessage, Message, MessageType } = this.state;

        let template: any = <Loader open={loading} onClick={(e) => { }} />;
        if (!loading) {
            template = (<React.Fragment>
                <Header />
                <section className="loginContainer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="formContainer">
                                    <h3>Create your {Role} account</h3>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>First name</label>
                                                <input id="fname" name="fname" type="text" className="form-control" defaultValue={FirstName} placeholder="" onBlur={(e) => { this.setState({ FirstName: e.target.value }); }} />
                                                <Validator id="valFName" type='required' cssClass="error" errorMessage="Provide First name." controlId="fname" valueToValidate={FirstName} formValidators={this.formValidators}></Validator>
                                                <Validator id="valFNameLength" type='max_length' cssClass="error" errorMessage="First name cannot exceed 45 characters." maxLength={45} controlId="fname" valueToValidate={FirstName} formValidators={this.formValidators}></Validator>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>Last name</label>
                                                <input id="lname" name="lname" type="text" className="form-control" defaultValue={LastName} placeholder="" onBlur={(e) => { this.setState({ LastName: e.target.value }); }} />
                                                <Validator id="valLName" type='required' cssClass="error" errorMessage="Provide Last name." controlId="lname" valueToValidate={LastName} formValidators={this.formValidators}></Validator>
                                                <Validator id="valLNameLength" type='max_length' cssClass="error" errorMessage="Last name cannot exceed 45 characters." maxLength={45} controlId="lname" valueToValidate={LastName} formValidators={this.formValidators}></Validator>
                                            </div>
                                        </div>
                                    </div>
                                    {!this.IsUserValid ? (<React.Fragment>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input id="email" type="email" className="form-control" defaultValue={Email} placeholder="" onBlur={(e) => { this.setState({ Email: e.target.value }); }} />
                                            <Validator id="valEmail" type='required' cssClass="error" errorMessage="Provide Email." controlId="email" valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                            <Validator id="valEmailFormat" type='email' cssClass="error" controlId="email" errorMessage="Invalid email." valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                            <Validator id="valEmailLength" type='max_length' cssClass="error" errorMessage="Email cannot exceed 45 characters." maxLength={45} controlId="email" valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input id="pass" name="pass" type="password" className="form-control" placeholder="" onBlur={(e) => { this.setState({ Password: e.target.value }); }} />
                                            <Validator id="valPassword" type='required' cssClass="error" errorMessage="Provide Password." controlId="pass" valueToValidate={Password} formValidators={this.formValidators}></Validator>
                                        </div>
                                        <div className="termsConditionTxt">By clicking Create Account, you agree to the <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>.</div>
                                        <div className="formButtonContainer">
                                            <button type="submit" className="btn btn-primary cutmBtnLogin w-100" onClick={(e) => { this.handleCreateUser(); }}>Create Account</button>
                                            <div className="mt-3 w-100">
                                                <GoogleLogin
                                                    clientId={Constants.Google.ClientID} buttonText="Sign up with Google" onSuccess={this.onGoogleSignUpSuccess}
                                                    onFailure={this.onGoogleSignUpError} cookiePolicy={'single_host_origin'} scope="profile email" theme="dark" />
                                            </div>
                                        </div>
                                        <div className="signUpLink">Already have an account? <a href="/login">Log in</a>.</div>
                                    </React.Fragment>) : (<React.Fragment>
                                        <div className="termsConditionTxt">By clicking register button below, you agree to the <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.</div>
                                        <div className="formButtonContainer">
                                            <button type="submit" className="btn btn-primary cutmBtnLogin w-100" onClick={(e) => { this.handleCreateUser(); }}>Register as {this.state.Role}</button>
                                        </div>
                                    </React.Fragment>)}
                                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                                        <Alert severity={MessageType} sx={{ width: '100%' }}>
                                            {Message}
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>);
        }

        return template;
    }
}