import React from "react"
import DBHelper from "src/api/service";
import Constants from "./Constants";
import { TransitionProps } from "@mui/material/transitions";
import { Slide } from "@mui/material";
import Stripe from "src/api/stripe";

export default class Base extends React.Component<any, any> {

    public IsUserValid: boolean = false;
    public AppRole: any = '';
    public UserRole: any = '';
    public LoginName: any = '';
    public db: DBHelper;
    public User: any;
    public DialogContainer: any = null;
    public SlideDownTransition: any;
    public SlideUpTransition: any;
    public AlertTransition: any;
    public query: any;
    private stripe: Stripe;
    private isSubscriptionChecked = 'false';
    public OpenToAll = "1";// Change this to "0" and remove all references of this bit in all related files.

    constructor(props) {
        super(props);

        this.query = new URLSearchParams(window.location.search);

        this.SlideDownTransition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any>; }, ref: React.Ref<unknown>,) { return <Slide direction="down" ref={ref} {...props} />; });

        this.SlideUpTransition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any>; }, ref: React.Ref<unknown>,) { return <Slide direction="up" ref={ref} {...props} />; });

        this.AlertTransition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any>; }, ref: React.Ref<unknown>,) { return <Slide direction="left" ref={ref} {...props} />; });

        let userToken: any = localStorage.getItem(this.encodeString("ProsBEUser_Token"));
        if (!userToken)
            userToken = sessionStorage.getItem(this.encodeString("ProsBEUser_Token"));

        let userTokenExpiry = localStorage.getItem(this.encodeString("ProsBEUser_ExpiresOn"));
        if (!userTokenExpiry)
            userTokenExpiry = sessionStorage.getItem(this.encodeString("ProsBEUser_ExpiresOn"));

        this.isSubscriptionChecked = this.decodeString(localStorage.getItem(this.encodeString("ProsBEUser_SubscriptionChecked")));
        if (!this.isSubscriptionChecked)
            this.isSubscriptionChecked = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBEUser_SubscriptionChecked")));

        // let googleUser: any = localStorage.getItem(this.encodeString("ProsBE_GoogleUser"));
        // if (!googleUser)
        //     googleUser = sessionStorage.getItem(this.encodeString("ProsBE_GoogleUser"));

        this.db = new DBHelper();

        if (userToken && userTokenExpiry) {
            this.AppRole = this.decodeString(localStorage.getItem(this.encodeString("ProsBEUser_AppRole")));
            if (!this.AppRole)
                this.AppRole = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBEUser_AppRole")));

            this.UserRole = this.decodeString(localStorage.getItem(this.encodeString("ProsBEUser_Role")));
            if (!this.UserRole)
                this.UserRole = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBEUser_Role")));

            this.LoginName = this.decodeString(localStorage.getItem(this.encodeString("ProsBEUser_LoginName")));
            if (!this.LoginName)
                this.LoginName = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBEUser_LoginName")));

            if (new Date(Number(userTokenExpiry)) >= new Date()) {
                this.IsUserValid = true;
            }
            else {
                this.db.validateUser(encodeURI(userToken)).then((result: any) => {
                    if (result && result.IsValid) {
                        this.IsUserValid = result.IsValid;
                    }
                    else {
                        localStorage.removeItem(this.encodeString("ProsBEUser_Token"));
                        localStorage.removeItem(this.encodeString("ProsBEUser_ExpiresOn"));
                        localStorage.removeItem(this.encodeString("ProsBEUser_AppRole"));
                        localStorage.removeItem(this.encodeString("ProsBEUser_Role"));
                        localStorage.removeItem(this.encodeString("ProsBEUser_LoginName"));
                        localStorage.removeItem(this.encodeString("ProsBE_GoogleUser"));

                        sessionStorage.removeItem(this.encodeString("ProsBEUser_Token"));
                        sessionStorage.removeItem(this.encodeString("ProsBEUser_ExpiresOn"));
                        sessionStorage.removeItem(this.encodeString("ProsBEUser_Role"));
                        sessionStorage.removeItem(this.encodeString("ProsBEUser_AppRole"));
                        sessionStorage.removeItem(this.encodeString("ProsBEUser_LoginName"));
                        sessionStorage.removeItem(this.encodeString("ProsBE_GoogleUser"));

                        console.log(result.Error);
                        this.IsUserValid = false;
                    }
                });
            }

            this.getUser(this.LoginName).then((user) => {
                this.User = user;

                this.checkUserSubscription(user);
            });
        }
        else {
            this.IsUserValid = false;
        }
    }

    private checkUserSubscription(user: any) {
        if (this.isSubscriptionChecked === 'false' && user.Profile && user.Profile.ActiveSubscription === 'P') {
            let stripeCustomer = user && user.Profile && user.Profile.StripeCustomer ? JSON.parse(user.Profile.StripeCustomer) : null;
            if (stripeCustomer && stripeCustomer.subscription) {
                this.stripe.getSubscriptionById(stripeCustomer.subscription.id).then((subscription) => {
                    if (!subscription || subscription.status !== 'active') {
                        this.db.updateProfileSubscription(user.Profile.Id, 'B', '').then((result) => {
                            if (result) {
                                this.sendSubscriptionChangeEmail(user).then((sent) => {
                                    this.getProfile().then((profile) => {
                                        this.updateCache();
                                    });
                                });
                            }
                            else {
                                console.log('Error from Base.');
                            }
                        });
                    }
                });
            }
            else {
                this.db.updateProfileSubscription(user.Profile.Id, 'B', '').then((result) => {
                    if (result) {
                        this.sendSubscriptionChangeEmail(user).then((sent) => {
                            this.getProfile().then((profile) => {
                                this.updateCache();
                            });
                        });
                    }
                    else {
                        console.log('Error from Base.');
                    }
                });
            }
        }
    }

    private updateCache() {
        this.db.getUser(this.LoginName, this.AppRole).then((result) => {
            if (result) {
                this.User = result;
                let _cached = JSON.stringify(result);
                sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(_cached));
            }
        });
    }

    private getProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.LoginName) {
                this.db.getUserProfile(this.LoginName, this.AppRole).then((profile) => {
                    resolve(profile);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private sendSubscriptionChangeEmail(user): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate: any = null;
            let subject = '';
            let body = '';

            emailTemplate = Constants.Email.DowngradePlan;
            subject = emailTemplate.Subject;
            body = emailTemplate.Body;

            if (user.Email) {
                this.db.sendEmail(user.Email, subject, body).then((sent) => {
                    if (sent) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(false);
            }
        });
    }

    public getCategoriesMaster(): Promise<any> {
        return new Promise((resolve, reject) => {
            let _cached = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBE_Categories")));
            if (_cached) {
                resolve(JSON.parse(_cached));
            }
            else {
                let db = new DBHelper();
                db.getCategories().then((categories) => {
                    if (categories && categories.length > 0) {
                        let _cached = JSON.stringify(categories);
                        sessionStorage.setItem(this.encodeString("ProsBE_Categories"), this.encodeString(_cached));
                    }

                    resolve(categories);
                });
            }
        });
    }

    public getServiceMaster(): Promise<any> {
        return new Promise((resolve, reject) => {
            let _cached = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBE_Services")));
            if (_cached) {
                resolve(JSON.parse(_cached));
            }
            else {
                let db = new DBHelper();
                db.getServices().then((services) => {
                    if (services && services.length > 0) {
                        let _cached = JSON.stringify(services);
                        sessionStorage.setItem(this.encodeString("ProsBE_Services"), this.encodeString(_cached));
                    }

                    resolve(services);
                });
            }
        });
    }

    public getPinsMaster(): Promise<any> {
        return new Promise((resolve, reject) => {
            let _cached = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBE_Pins")));
            if (_cached) {
                resolve(JSON.parse(_cached));
            }
            else {
                let db = new DBHelper();
                db.getPins().then((pins) => {
                    if (pins && pins.length > 0) {
                        let _cached = JSON.stringify(pins);
                        sessionStorage.setItem(this.encodeString("ProsBE_Pins"), this.encodeString(_cached));
                    }

                    resolve(pins);
                });
            }
        });
    }

    public getStateMaster(): Promise<any> {
        return new Promise((resolve, reject) => {
            let _cached = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBE_States")));
            if (_cached) {
                resolve(JSON.parse(_cached));
            }
            else {
                let db = new DBHelper();
                db.getStates().then((result) => {
                    if (result && result.length > 0) {
                        let _cached = JSON.stringify(result);
                        sessionStorage.setItem(this.encodeString("ProsBE_States"), this.encodeString(_cached));
                    }

                    resolve(result);
                });
            }
        });
    }

    public getCityMaster(): Promise<any> {
        return new Promise((resolve, reject) => {
            let _cached = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBE_Cities")));
            if (_cached) {
                resolve(JSON.parse(_cached));
            }
            else {
                let db = new DBHelper();
                db.getCities().then((result) => {
                    if (result && result.length > 0) {
                        let _cached = JSON.stringify(result);
                        sessionStorage.setItem(this.encodeString("ProsBE_Cities"), this.encodeString(_cached));
                    }

                    resolve(result);
                });
            }
        });
    }

    public getCountries(): Promise<any> {
        return new Promise((resolve, reject) => {
            let _cached = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBE_Countries")));
            if (_cached) {
                resolve(JSON.parse(_cached));
            }
            else {
                let db = new DBHelper();
                db.getCountries().then((result) => {
                    if (result && result.length > 0) {
                        let _cached = JSON.stringify(result);
                        sessionStorage.setItem(this.encodeString("ProsBE_Countries"), this.encodeString(_cached));
                    }

                    resolve(result);
                });
            }
        });
    }

    public checkIfUserIsSubscribedToNotificationType(profileId, notificationType): Promise<any> {
        return new Promise((resolve, reject) => {
            let db = new DBHelper();
            db.getProfileNotifications(profileId).then((notifications) => {
                if (notifications) {
                    let exists = notifications.find((n) => { return (n.Name.toLowerCase() === notificationType.toLowerCase()); });
                    if (exists) {
                        resolve(true);
                    }
                    else
                        resolve(false);
                }
                else
                    resolve(false);
            });
        });
    }

    public getReviewParameterMaster(): Promise<any> {
        return new Promise((resolve, reject) => {
            let _cached = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBE_ReviewParameters")));
            if (_cached) {
                resolve(JSON.parse(_cached));
            }
            else {
                let db = new DBHelper();
                db.getReviewParameters().then((result) => {
                    if (result && result.length > 0) {
                        let _cached = JSON.stringify(result);
                        sessionStorage.setItem(this.encodeString("ProsBE_ReviewParameters"), this.encodeString(_cached));
                    }

                    resolve(result);
                });
            }
        });
    }

    public getPaymentTypes(): Promise<any> {
        return new Promise((resolve, reject) => {
            let _cached = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBE_PaymentTypes")));
            if (_cached) {
                resolve(JSON.parse(_cached));
            }
            else {
                let db = new DBHelper();
                db.getPaymentTypes().then((result) => {
                    if (result && result.length > 0) {
                        let _cached = JSON.stringify(result);
                        sessionStorage.setItem(this.encodeString("ProsBE_PaymentTypes"), this.encodeString(_cached));
                    }

                    resolve(result);
                });
            }
        });
    }

    public getUser(loginName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let _cached = this.decodeString(sessionStorage.getItem(this.encodeString("ProsBE_User")));
            if (_cached) {
                resolve(JSON.parse(_cached));
            }
            else {
                let db = new DBHelper();
                db.getUser(this.LoginName, this.AppRole).then((result) => {
                    if (result) {
                        let _cached = JSON.stringify(result);
                        sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(_cached));
                    }

                    resolve(result);
                });
            }
        });
    }

    public encodeString(val: string | null): string {
        if (val) {
            //let buff = new Buffer(val);
            //return buff.toString('base64');
            return btoa(unescape(encodeURIComponent(val)));
        }

        return '';
    }

    public decodeString(val: string | null): string {
        if (val) {
            // let buff = new Buffer(val, 'base64');
            // return buff.toString('ascii');
            return decodeURIComponent(escape(atob(val)));
        }

        return '';
    }

    public addDays(date, days) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy;
    }

    private pad2(n) {  // always returns a string
        return (n < 10 ? '0' : '') + n;
    }

    public getDateNumber(val) {
        let rawVal = val.substring(val.indexOf('(') + 1, val.indexOf(')'));
        let _val = [];

        if (rawVal.indexOf('-') > 0) {
            _val = rawVal.split('-');
            rawVal = Number(_val[0]) - Number(_val[1]);
        }
        else if (rawVal.indexOf('+') > 0) {
            _val = rawVal.split('+');
            rawVal = Number(_val[0]) + Number(_val[1]);
        }

        return rawVal;
    }

    public getYYYYMMDDHHMMSS(date: Date) {
        return date.getFullYear() +
            this.pad2(date.getMonth() + 1) +
            this.pad2(date.getDate()) +
            this.pad2(date.getHours()) +
            this.pad2(date.getMinutes()) +
            this.pad2(date.getSeconds());
    }

    public getMonths() {
        let _months: any[] = [];
        _months.push({ key: "01", text: "January", value: "1" });
        _months.push({ key: "02", text: "February", value: "2" });
        _months.push({ key: "03", text: "March", value: "3" });
        _months.push({ key: "04", text: "April", value: "4" });
        _months.push({ key: "05", text: "May", value: "5" });
        _months.push({ key: "06", text: "June", value: "6" });
        _months.push({ key: "07", text: "July", value: "7" });
        _months.push({ key: "08", text: "August", value: "8" });
        _months.push({ key: "09", text: "September", value: "9" });
        _months.push({ key: "10", text: "October", value: "10" });
        _months.push({ key: "11", text: "November", value: "11" });
        _months.push({ key: "12", text: "December", value: "12" });

        return _months;
    }

    public getFormattedDate(value: any, format: Constants.IDateTimeFormat, separator: string, includeTime?: boolean): string {
        let retVal: any = value;
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (value) {
            let objDate = new Date(value);
            if (objDate) {
                let datePart: any = objDate.getDate();
                datePart = (datePart < 10 ? '0' + datePart.toString() : datePart.toString());

                let monthPart: any = objDate.getMonth() + 1;
                monthPart = (monthPart < 10 ? '0' + monthPart.toString() : monthPart.toString());
                if (format === Constants.IDateTimeFormat.MMMddyyyy || format === Constants.IDateTimeFormat.ddMMMyyyy || format === Constants.IDateTimeFormat.MMMyyyy || format === Constants.IDateTimeFormat.ddMMM) {
                    monthPart = months[objDate.getMonth()];
                }

                let yearPart: any = objDate.getFullYear();
                if (format === Constants.IDateTimeFormat.MMddyy || format === Constants.IDateTimeFormat.ddMMyy || format === Constants.IDateTimeFormat.yyMMdd) {
                    yearPart = yearPart.toString().substring(2);
                }

                let hours: any = objDate.getHours();
                let minutes: any = objDate.getMinutes();
                //let seconds: any = objDate.getHours();
                let amPM = (hours === 0 ? ('AM') : (hours >= 12 ? 'PM' : 'AM'));
                hours = (hours > 12 ? hours - 12 : hours);
                hours = (hours < 10 ? "0" + hours : hours);
                minutes = (minutes < 10 ? "0" + minutes : minutes);

                if (format === Constants.IDateTimeFormat.MMddyyyy) {
                    retVal = monthPart + separator + datePart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.ddMMyyyy) {
                    retVal = datePart + separator + monthPart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.yyyyMMdd) {
                    retVal = yearPart + separator + monthPart + separator + datePart;
                }
                else if (format === Constants.IDateTimeFormat.MMMddyyyy) {
                    retVal = monthPart + separator + datePart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.MMddyy) {
                    retVal = monthPart + separator + datePart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.ddMMMyyyy) {
                    retVal = datePart + separator + monthPart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.ddMMyy) {
                    retVal = datePart + separator + monthPart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.yyMMdd) {
                    retVal = yearPart + separator + monthPart + separator + datePart;
                }
                else if (format === Constants.IDateTimeFormat.ddMM) {
                    retVal = datePart + separator + monthPart;
                }
                else if (format === Constants.IDateTimeFormat.ddMMM) {
                    retVal = datePart + separator + monthPart;
                }
                else if (format === Constants.IDateTimeFormat.MMyyyy) {
                    retVal = monthPart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.MMMyyyy) {
                    retVal = monthPart + separator + yearPart;
                }

                if (includeTime) {
                    retVal = retVal + ' ' + hours + ":" + minutes + ' ' + amPM;
                }

                if (format === Constants.IDateTimeFormat.hhmm) {
                    retVal = hours + separator + minutes + ' ' + amPM;
                }
            }
        }
        return retVal;
    }
}