import Base from "src/common/Base";
import React from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography, IconButton, Autocomplete, TextField, Chip, Tooltip, FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio, Avatar } from "@mui/material";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import { Loader, ProgressBar } from "src/common/Components";
import AddIcon from '@mui/icons-material/Add';
import Validator from "src/common/Validator";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Constants from "src/common/Constants";
import CloseIcon from '@mui/icons-material/Close';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import CardMembershipRoundedIcon from '@mui/icons-material/CardMembershipRounded';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export default class Admin extends Base {

    private validator: Validator;
    private formValidators: Validator[];
    private searchValidators: Validator[];

    constructor(props) {
        super(props);

        this.state = {
            loading: true, showConfirmation: false, ConfirmationMessage: '', ConfirmationType: '', ShowMessage: false, MessageType: 'success', Message: '', callBackMethod: null, noCallBackMethod: null,
            ItemId: 0, ActiveTab: 0, SearchText: '', showProgress: false, showDialogProgress: false, FilteredData: [], Data: [], FilterStatus: '', FilterRole: '', showDetailsDialog: false,
            DialogMode: '', Name: '', Description: '', IsActive: true, Categories: [], SelectedCategory: null, CategoryId: '', CategoryName: '', ControlType: '', IsRequired: false, MaxLength: '',
            MasterValue: '', AllowMultiple: false, QType: '', PageSize: 10, HasMoreRecords: false, PageNumber: 1
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        this.setState({ loading: false });
    }

    private handleTabChange(tabId) {
        this.formValidators = new Array<Validator>();
        this.searchValidators = new Array<Validator>();

        this.setState({
            ActiveTab: tabId, showConfirmation: false, ConfirmationMessage: '', ConfirmationType: '', ShowMessage: false, MessageType: 'success', Message: '', callBackMethod: null,
            ItemId: 0, SearchText: '', showProgress: false, showDialogProgress: false, FilteredData: [], Data: [], FilterStatus: '', FilterRole: '', showDetailsDialog: false,
            DialogMode: '', Name: '', Description: '', IsActive: true, Categories: [], SelectedCategory: null, CategoryId: '', CategoryName: '', ControlType: '', IsRequired: false, MaxLength: '',
            MasterValue: '', AllowMultiple: false, PageNumber: 1
        }, () => {
            if (tabId === 1) { // Users
                this.getUsers();
            }
            else if (tabId === 2) { // Categories
                this.getCategories();
            }
            else if (tabId === 3) { // Service                
                this.getServices();
            }
            else if (tabId === 4) { // Project FAQs
                this.getAllFAQs('P');
            }
            else if (tabId === 5) { // Quote FAQs
                this.getAllFAQs('Q');
            }
        });
    }

    private getUsers() {
        this.setState({ showProgress: true });
        this.db.getUsers().then((data) => {
            let _data: any[] = data;
            let hasMore = false;

            if (_data && _data.length > (this.state.PageSize * this.state.PageNumber)) {
                _data = _data.slice(0, (this.state.PageSize * this.state.PageNumber));
                hasMore = true;
            }

            this.setState({ showProgress: false, HasMoreRecords: hasMore, Data: data, FilteredData: _data });
        });
    }

    private getCategories() {
        this.setState({ showProgress: true });
        this.db.getCategories().then((data) => {
            let _data: any[] = data;
            let hasMore = false;

            if (data && data.length > (this.state.PageSize * this.state.PageNumber)) {
                _data = _data.slice(0, (this.state.PageSize * this.state.PageNumber));
                hasMore = true;
            }

            this.setState({ showProgress: false, HasMoreRecords: hasMore, Data: data, FilteredData: _data });
        });
    }

    private getServices() {
        this.setState({ showProgress: true });
        this.db.getServices().then((data) => {
            this.db.getCategories().then((categories) => {
                let _data: any[] = data;
                let hasMore = false;

                if (data && data.length > (this.state.PageSize * this.state.PageNumber)) {
                    _data = _data.slice(0, (this.state.PageSize * this.state.PageNumber));
                    hasMore = true;
                }

                this.setState({ showProgress: false, HasMoreRecords: hasMore, Data: data, FilteredData: _data, Categories: categories });
            });
        });
    }

    private getAllFAQs(type) {
        this.setState({ showProgress: true });
        this.db.getCategories().then((categories) => {
            this.setState({ showProgress: false, Categories: categories, QType: type });
        });
    }

    private handleSearchUsers() {
        // let isValid = this.validator.validateForm(this.searchValidators);
        // if (isValid) {
        this.filterUsers();
        // }
    }

    // private handleDeleteUser(user) {
    //     this.setState({ showConfirmation: true, ConfirmationMessage: 'Are you sure you want to delete this user?', ItemId: user.Id, callBackMethod: this.deleteUser.bind(this) });
    // }

    // private deleteUser() {
    //     this.setState({ showProgress: true });
    //     this.db.deactivateUserProfile(this.state.ItemId).then((deactivated) => {
    //         if (deactivated) {
    //             this.successMessage('User deleted successfully.');
    //             this.setState({ showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null });
    //             this.filterUsers();
    //         }
    //         else {
    //             this.errorMessage('User could not be deleted.');
    //             this.setState({ showProgress: false, showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null });
    //         }
    //     });
    // }

    private filterUsers() {
        let _data = this.state.Data;
        this.setState({ showProgress: true, PageNumber: 1, HasMoreRecords: false }, () => {
            if (this.state.SearchText) {
                _data = _data.filter((u) => { return ((u.BusinessName && u.BusinessName.toLowerCase().indexOf(this.state.SearchText.toLowerCase()) >= 0) || (u.User && u.User.DisplayName && (u.User.DisplayName.toLowerCase().indexOf(this.state.SearchText.toLowerCase()) >= 0 || u.User.Email.toLowerCase().indexOf(this.state.SearchText.toLowerCase()) >= 0))); });
            }

            if (this.state.FilterStatus) {
                _data = _data.filter((u) => { return (u.IsActive === (this.state.FilterStatus === '0' ? false : true)); });
            }

            if (this.state.FilterRole) {
                _data = _data.filter((u) => { return (u.User && (u.User.DefaultRole === this.state.FilterRole || u.User.Role2 === this.state.FilterRole)); });
            }

            let hasMore = false;

            if (_data && _data.length > (this.state.PageSize * this.state.PageNumber)) {
                _data = _data.slice(0, (this.state.PageSize * this.state.PageNumber));
                hasMore = true;
            }

            this.setState({ FilteredData: _data, showProgress: false, HasMoreRecords: hasMore });
        });
    }

    private handleAccountActivation(status, user) {
        if (!status) {
            this.setState({
                showConfirmation: true, ConfirmationMessage: 'Are you sure you want to deactivate this user?', ItemId: user.User.Id,
                callBackMethod: this.deactivateUser.bind(this), noCallBackMethod: this.noCallBackMethod.bind(this)
            });
        }
        else {
            this.setState({ showProgress: true });
            this.db.reactivateUser(user.User.Id).then((activated) => {
                if (activated) {
                    this.successMessage('User activated successfully.');
                    this.db.getUsers().then((data) => {
                        this.setState({ showProgress: false, Data: data, FilteredData: data }, () => { this.filterUsers(); });
                    });
                }
                else {
                    this.errorMessage('User could not be activated.');
                    this.setState({ showProgress: false });
                }
            });
        }
    }

    private deactivateUser() {
        this.setState({ showProgress: true });
        this.db.deactivateUser(this.state.ItemId).then((deactivated) => {
            if (deactivated) {
                this.successMessage('User deactivated successfully.');
                this.setState({ showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null, noCallBackMethod: null });
                this.db.getUsers().then((data) => {
                    this.setState({ showProgress: false, Data: data, FilteredData: data }, () => { this.filterUsers(); });
                });
            }
            else {
                this.errorMessage('User could not be deactivated.');
                this.setState({ showProgress: false, showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null, noCallBackMethod: null });
            }
        });
    }

    private noCallBackMethod() {
        this.filterUsers();
    }

    private handleSearchCategories() {
        // let isValid = this.validator.validateForm(this.searchValidators);
        // if (isValid) {
        let _data = this.state.Data;
        this.setState({ showProgress: true, PageNumber: 1, HasMoreRecords: false }, () => {
            if (this.state.SearchText) {
                _data = _data.filter((d) => { return (d.Name.toLowerCase().indexOf(this.state.SearchText.toLowerCase()) >= 0 || d.Description.toLowerCase().indexOf(this.state.SearchText.toLowerCase()) >= 0); });
            }

            let hasMore = false;

            if (_data && _data.length > (this.state.PageSize * this.state.PageNumber)) {
                _data = _data.slice(0, (this.state.PageSize * this.state.PageNumber));
                hasMore = true;
            }

            this.setState({ FilteredData: _data, showProgress: false, HasMoreRecords: hasMore });
        });
        // }
    }

    private handleDeleteCatgory(item) {
        this.setState({
            showConfirmation: true,
            ConfirmationMessage: (<React.Fragment><div>This will delete all associated services and other data of this category.</div> <div className="mt-3">Are you sure you want to delete this category?</div></React.Fragment>),
            ItemId: item.Id, callBackMethod: this.deleteCategory.bind(this)
        });
    }

    private deleteCategory() {
        this.setState({ showConfirmation: false, showProgress: true });
        this.db.deleteCategory(this.state.ItemId).then((deleted) => {
            if (deleted) {
                this.successMessage('Category deleted successfully.');
                this.setState({ ConfirmationMessage: '', ItemId: 0, callBackMethod: null });
                this.getCategories();
            }
            else {
                this.errorMessage('Category could not be deleted.');
                this.setState({ showProgress: false, showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null });
            }
        });
    }

    private handleEditCatgory(item) {
        this.setState({ showDetailsDialog: true, DialogMode: 'E', ItemId: item.Id, Name: item.Name, Description: item.Description, IsActive: item.IsActive });
    }

    private handleCategorySaveClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showDialogProgress: true });
            let { ItemId, Name, Description, IsActive, Data } = this.state;

            if (Data && Data.length > 0) {
                let _service = Data.find((d: any) => { return (d.Name.toLowerCase() === Name.toLowerCase() && ((ItemId && ItemId > 0 && d.Id !== ItemId) || !ItemId || ItemId <= 0)); });
                if (_service) {
                    this.errorMessage('Another Service with this name already exists. Please provide another name.');
                    this.setState({ showDialogProgress: false });
                    return;
                }
            }

            this.db.updateCategory(ItemId, Name, Description, IsActive).then((itemId) => {
                if (itemId > 0) {
                    this.successMessage('Service details updated successfully.');
                    this.setState({ showDialogProgress: false, showDetailsDialog: false, ItemId: 0, DialogMode: '', Name: '', Description: '', IsActive: true });

                    this.getCategories();
                }
                else {
                    this.errorMessage('Service details could not be updated.');
                    this.setState({ showDialogProgress: false });
                }
            });
        }
    }

    private handleSearchServices() {
        // let isValid = this.validator.validateForm(this.searchValidators);
        // if (isValid) {
        let _data = this.state.Data;
        this.setState({ showProgress: true, PageNumber: 1, HasMoreRecords: false }, () => {
            if (this.state.SearchText) {
                _data = _data.filter((d) => { return (d.Name.toLowerCase().indexOf(this.state.SearchText.toLowerCase()) >= 0 || d.Description.toLowerCase().indexOf(this.state.SearchText.toLowerCase()) >= 0); });
            }

            let hasMore = false;

            if (_data && _data.length > (this.state.PageSize * this.state.PageNumber)) {
                _data = _data.slice(0, (this.state.PageSize * this.state.PageNumber));
                hasMore = true;
            }

            this.setState({ FilteredData: _data, showProgress: false, HasMoreRecords: hasMore });
        });
        // }
    }

    private handleDeleteService(item) {
        this.setState({
            showConfirmation: true,
            ConfirmationMessage: (<React.Fragment><div>This will delete all associated data of this service.</div> <div className="mt-3">Are you sure you want to delete this service?</div></React.Fragment>),
            ItemId: item.Id, callBackMethod: this.deleteService.bind(this)
        });
    }

    private deleteService() {
        this.setState({ showConfirmation: false, showProgress: true });
        this.db.deleteService(this.state.ItemId).then((deleted) => {
            if (deleted) {
                this.successMessage('Service deleted successfully.');
                this.setState({ ConfirmationMessage: '', ItemId: 0, callBackMethod: null });
                this.getCategories();
            }
            else {
                this.errorMessage('Service could not be deleted.');
                this.setState({ showProgress: false, showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null });
            }
        });
    }

    private handleEditService(item) {
        let selectedCategory = this.state.Categories.find((c) => { return (c.Id.toString() === item.Category.Id.toString()); });

        this.setState({
            showDetailsDialog: true, DialogMode: 'E', ItemId: item.Id, Name: item.Name, Description: item.Description, IsActive: item.IsActive,
            CategoryId: selectedCategory.Id.toString(), CategoryName: selectedCategory.Name, SelectedCategory: selectedCategory
        });
    }

    private handleServiceSaveClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showDialogProgress: true });
            let { ItemId, Name, Description, IsActive, CategoryId } = this.state;
            this.db.updateService(ItemId, Name, Description, IsActive, CategoryId).then((itemId) => {
                if (itemId > 0) {
                    this.successMessage('Service details updated successfully.');
                    this.setState({
                        showDialogProgress: false, showDetailsDialog: false, ItemId: 0, DialogMode: '', Name: '', Description: '', IsActive: true,
                        SelectedCategory: null, CategoryId: '', CategoryName: ''
                    });

                    this.getServices();
                }
                else {
                    this.errorMessage('Service details could not be updated.');
                    this.setState({ showDialogProgress: false });
                }
            });
        }
    }

    private filterFAQs() {
        let { CategoryId, QType } = this.state;
        this.setState({ showProgress: true });
        if (CategoryId) {
            this.db.getCategoryFAQs(CategoryId, QType).then((data) => {
                this.setState({ FilteredData: data, showProgress: false });
            });
        }
        else {
            this.setState({ FilteredData: [], showProgress: false });
        }
    }

    private getAnswerType(item, indx) {
        let aType = item.ControlType;

        if (aType === "TXTB") {
            return (<React.Fragment>
                <input type='text' name={"txtBAnswer_" + indx} id={"txtBAnswer_" + indx} className="form-control" />
            </React.Fragment>);
        }
        else if (aType === "TXTA") {
            return (<React.Fragment>
                <textarea name={"txtAAnswer_" + indx} id={"txtAAnswer_" + indx} className="form-control" rows={3} />
            </React.Fragment>);
        }
        else if (aType === "CHKB") {
            return (<React.Fragment>
                <div className="custom-control custom-switch d-flex justify-content-between">
                    <input type="checkbox" className="custom-control-input" id="chkBAnswer" />
                    <label className="custom-control-label" htmlFor="chkBAnswer">{item.MasterValue}</label>
                </div>
            </React.Fragment>);
        }
        else if (aType === "CHKL") {
            if (item.MasterValue) {
                let _mVals = item.MasterValue.split(/\r?\n/);

                return (<React.Fragment>
                    <FormGroup id={"chkListAnswer_" + indx} key={"chkListAnswer_" + indx}>
                        {_mVals && _mVals.map((val, idx) => {
                            return (<FormControlLabel control={<Checkbox />} label={val} />);
                        })}
                    </FormGroup>
                </React.Fragment>);
            }
            else
                return (null);
        }
        else if (aType === "RBTL") {
            if (item.MasterValue) {
                let _mVals = item.MasterValue.split(/\r?\n/);

                return (<React.Fragment>
                    <RadioGroup id={"rbtnListAnswer_" + indx} name={"rbtnListAnswer_" + indx}>
                        {_mVals && _mVals.map((val, idx) => {
                            return (<FormControlLabel control={<Radio checked={false} />} label={val} />);
                        })}
                    </RadioGroup>
                </React.Fragment>);
            }
            else
                return (null);
        }
        else if (aType === "DDL") {
            if (item.MasterValue) {
                let _mVals = item.MasterValue.split(/\r?\n/);

                return (<React.Fragment>
                    <select id={"ddlAnswer" + indx} name={"ddlAnswer" + indx} className="form-control commentBody">
                        <option value="">Select</option>
                        {_mVals && _mVals.map((val, idx) => {
                            return (<option value={val}>{val}</option>);
                        })}
                    </select>
                </React.Fragment>);
            }
            else
                return (null);
        }
        // else if (aType === "ATCF") {

        // }
        else {
            return null;
        }
    }

    private handleNewQuestionClick() {
        let isValid = this.validator.validateForm(this.searchValidators);
        if (isValid) {
            this.setState({ showDetailsDialog: true, DialogMode: '', ItemId: 0, Name: '', ControlType: '', IsRequired: false, MaxLength: '', MasterValue: '', AllowMultiple: false, IsActive: true });
        }
    }

    private handleEditQuestion(item) {
        this.setState({
            showDetailsDialog: true, ItemId: item.Id, DialogMode: 'E', Name: item.Name, ControlType: item.ControlType, IsRequired: item.IsRequired,
            MaxLength: item.MaxLength > 0 ? item.MaxLength : '', AllowMultiple: item.allowMultiple, MasterValue: item.MasterValue
        });
    }

    private handleDeleteQuestion(item) {
        this.setState({ showConfirmation: true, ConfirmationMessage: 'Are you sure you want to delete this question?', ConfirmationType: 'C', callBackMethod: this.deleteQuestion.bind(this), ItemId: item.Id });
    }

    private deleteQuestion() {
        this.setState({ showProgress: true, showConfirmation: false });
        this.db.deleteQuestion(this.state.ItemId).then((deleted) => {
            if (deleted) {
                this.successMessage('Question deleted successfully.');
                this.setState({ ConfirmationMessage: '', ItemId: 0, callBackMethod: null });
                this.filterFAQs();
            }
            else {
                this.errorMessage('Question could not be deleted.');
                this.setState({ showProgress: false, showConfirmation: false, ConfirmationMessage: '', ItemId: 0, callBackMethod: null });
            }
        });
    }

    private handleQuestionSaveClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showDialogProgress: true });
            const { ItemId, CategoryId, Name, ControlType, MasterValue, IsRequired, MaxLength, AllowMultiple, IsActive, QType } = this.state;

            this.db.updateQuestion(ItemId, Name, CategoryId, ControlType, MasterValue, IsRequired, MaxLength, AllowMultiple, IsActive, QType).then((updated) => {
                if (updated > 0) {
                    this.successMessage('Question updated successfully.');
                    this.setState({ showDetailsDialog: false, showDialogProgress: false, ItemId: 0, Name: '', ControlType: '', MasterValue: '', IsRequired: false, MaxLength: '', AllowMultiple: false, IsActive: true });
                    this.filterFAQs();
                }
                else {
                    this.errorMessage('Question could not be updated.');
                    this.setState({ showDialogProgress: false });
                }
            });
        }
    }

    private handleKeyDown(e, src) {
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();

            if (src = 'U')
                this.setState({ SearchText: e.target.value }, () => { this.filterUsers() });
            else if (src = 'C')
                this.setState({ SearchText: e.target.value }, () => { this.handleSearchCategories() });
            else if (src = 'S')
                this.setState({ SearchText: e.target.value }, () => { this.handleSearchServices() });
        }
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    private getPagedData(): any {
        let retVal: any = null;

        const { ActiveTab, Data, SearchText, FilterStatus, FilterRole } = this.state;
        let _data = Data;

        if (ActiveTab === 1) {
            if (SearchText) {
                _data = _data.filter((u) => { return ((u.BusinessName && u.BusinessName.toLowerCase().indexOf(SearchText.toLowerCase()) >= 0) || (u.User && u.User.DisplayName && (u.User.DisplayName.toLowerCase().indexOf(SearchText.toLowerCase()) >= 0 || u.User.Email.toLowerCase().indexOf(SearchText.toLowerCase()) >= 0))); });
            }

            if (FilterStatus) {
                _data = _data.filter((u) => { return (u.IsActive === (FilterStatus === '0' ? false : true)); });
            }

            if (FilterRole) {
                _data = _data.filter((u) => { return (u.User && (u.User.DefaultRole === FilterRole || u.User.Role2 === FilterRole)); });
            }

            retVal = _data;
        }
        else if (ActiveTab === 2 || ActiveTab === 3) {
            if (SearchText) {
                _data = _data.filter((d) => { return (d.Name.toLowerCase().indexOf(SearchText.toLowerCase()) >= 0 || d.Description.toLowerCase().indexOf(SearchText.toLowerCase()) >= 0); });
            }

            retVal = _data;
        }

        return retVal;
    }

    private loadMore() {
        let pageNumber = (this.state.PageNumber + 1);
        let hasMore = false;

        let _data = this.getPagedData();
        if (_data && _data.length > (this.state.PageSize * pageNumber)) {
            _data = _data.slice(0, (this.state.PageSize * pageNumber));
            hasMore = true;
        }

        this.setState({ FilteredData: _data, HasMoreRecords: hasMore, PageNumber: pageNumber });
    }

    private handleUserDownload() {
        const Header = ["User Name", "Email", "Default Role", "Other Role", "Active Subscription", "Account Status"];

        var exportListData: any = [];
        this.state.Data.forEach((item: any) => {
            exportListData.push([item.User.DisplayName, item.User.Email, item.User.DefaultRole, item.User.Role2,
            item.ActiveSubscription === Constants.Subscriptions.Premium ? "Premium" : "Basic", item.User.IsActive ? "Active" : "Inactive"]);
        });

        const workbook = new Workbook();
        workbook.created = new Date();
        const worksheet = workbook.addWorksheet("ProsBE_UserReport");
        const headerRow = worksheet.addRow(Header);

        headerRow.eachCell((cell, index) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: "F013464" },
                bgColor: { argb: "F013464" },
            };

            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };

            cell.font = { size: 12, bold: true, color: { argb: "FFFFFFF" } };
            worksheet.getColumn(index).width = Header[index - 1].length < 20 ? 20 : Header[index - 1].length;
        });

        exportListData.forEach((_data: any) => {
            var dataRow = worksheet.addRow(_data);
            dataRow.eachCell((cell, index) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };

                cell.font = { size: 11, bold: false };
            });
        });

        workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
            const blob = new Blob([data], { type: fileType });
            saveAs(blob, "ProsBE_UserReport_" + this.getFormattedDate(new Date().toString(), Constants.IDateTimeFormat.ddMMyyyy, "_") + fileExtension);
        });
    }

    public render() {

        const { loading, showConfirmation, ConfirmationMessage, ConfirmationType, ShowMessage, MessageType, Message, ActiveTab, SearchText, showProgress, FilteredData,
            showDetailsDialog, showDialogProgress, DialogMode, ItemId, Name, Description, IsActive, Categories, CategoryName, CategoryId, SelectedCategory,
            ControlType, IsRequired, MaxLength, AllowMultiple, MasterValue, HasMoreRecords } = this.state;

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            if (!this.IsUserValid) {
                window.location.href = '/login';
            }
            else {
                if (this.AppRole !== Constants.UserRoles.Admin) {

                }
                else {
                    template = (<React.Fragment>
                        <Header />
                        <section className="containerMinHeight accountSettingContainer borderBtm">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className="col-12 col-sm-3">
                                                <div className="accountNavigation">
                                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                        <a className={"nav-link " + (ActiveTab === 0 ? "active" : "")} id="v-pills-admin-tab" onClick={(e) => { this.handleTabChange(0); }}>Administration</a>
                                                        <a className={"nav-link " + (ActiveTab === 1 ? "active" : "")} id="v-pills-users-tab" onClick={(e) => { this.handleTabChange(1); }}>Users</a>
                                                        <a className={"nav-link " + (ActiveTab === 2 ? "active" : "")} id="v-pills-categories-tab" onClick={(e) => { this.handleTabChange(2); }}>Services</a>
                                                        {/* <a className={"nav-link " + (ActiveTab === 3 ? "active" : "")} id="v-pills-services-tab" onClick={(e) => { this.handleTabChange(3); }}>Services</a> */}
                                                        <a className={"nav-link " + (ActiveTab === 4 ? "active" : "")} id="v-pills-faqs-tab" onClick={(e) => { this.handleTabChange(4); }}>Project FAQs</a>
                                                        <a className={"nav-link " + (ActiveTab === 5 ? "active" : "")} id="v-pills-templates-tab" onClick={(e) => { this.handleTabChange(5); }}>Quote FAQs</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-9">
                                                <div className="tab-content" id="v-pills-tabContent">
                                                    <div className={"tab-pane fade " + (ActiveTab === 0 ? "show active" : "")} id="v-pills-dashboard">
                                                        {ActiveTab !== 0 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <h5>Administration</h5>
                                                                        {/* <div className="row">
                                                                            <div className="col-12 col-sm-12"> */}
                                                                        <div className="form-group">
                                                                            <div className="tabPanel">
                                                                                <div className="headingTxt">Manage Users</div>
                                                                                <Typography variant="body1" className="commentBody" >Manage all ProsBE users, account activation requests and much more.</Typography>
                                                                                <div className="text-right">
                                                                                    <Typography variant="caption"><a href='#' onClick={(e) => { this.handleTabChange(1); }}>Start</a></Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* </div>
                                                                            <div className="col-12 col-sm-12"> */}
                                                                        <div className="form-group">
                                                                            <div className="tabPanel">
                                                                                <div className="headingTxt">Manage Services</div>
                                                                                <Typography variant="body1" className="commentBody" >Manage the Services used in the application. You can add new services, modify the existing ones, delete the services for which there is no related content, and activate/deactive them.</Typography>
                                                                                <div className="text-right">
                                                                                    <Typography variant="caption"><a href='#' onClick={(e) => { this.handleTabChange(2); }}>Start</a></Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <div className="tabPanel">
                                                                                <div className="headingTxt">Manage Services</div>
                                                                                <Typography variant="body1" className="commentBody" >Manage the Services used in the application. You can add new services, modify the existing ones, delete the services for which there is no related content, and activate/deactive them.</Typography>
                                                                                <div className="text-right">
                                                                                    <Typography variant="caption"><a href='#' onClick={(e) => { this.handleTabChange(3); }}>Start</a></Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                            <div className="col-12 col-sm-12"> */}
                                                                        <div className="form-group">
                                                                            <div className="tabPanel">
                                                                                <div className="headingTxt">Manage Templates</div>
                                                                                <Typography variant="body1" className="commentBody" >Manage the Quote and Project templates associated with the categories used in the application. You can add new templates, modify the existing ones, delete the templates for which there is no related content, and activate/deactive them.</Typography>
                                                                                <div className="text-right">
                                                                                    <Typography variant="caption"><a href='#' onClick={(e) => { this.handleTabChange(4); }}>Start</a></Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* </div>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 1 ? "show active" : "")} id="v-pills-users">
                                                        {ActiveTab !== 1 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <h5>Manage Users</h5>
                                                                        <div className="row">
                                                                            <div className="col-9 col-sm-10">
                                                                                <Typography variant="subtitle2" color="text.secondary">Manage all ProsBE users, account activation requests and much more.</Typography>
                                                                            </div>
                                                                            <div className="col-3 col-sm-2">
                                                                                <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                    <Typography variant="caption"><a href="javascript:void(0);" title="Download user report" onClick={(e) => { this.handleUserDownload(); }} ><img src="/images/downloadExcel.png" alt="Download user report" style={{ width: '25px' }} /></a></Typography>
                                                                                    {/* <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showDetailsDialog: true, DialogMode: '', ItemId: 0 }); }}><AddIcon /></button> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <hr />
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-9 col-sm-4">
                                                                                <div className="form-group">
                                                                                    <input type='text' id='search' name="search" className="form-control" onChange={(e) => { this.setState({ SearchText: e.target.value }); }} placeholder="Search users by name or email" onKeyDown={(e) => { this.handleKeyDown(e, 'U'); }} />
                                                                                    {/* <Validator id="valSearchUser" type="required" errorMessage="Provide user name or email to search." controlId="search" cssClass="error" valueToValidate={SearchText} formValidators={this.searchValidators} /> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-sm-2">
                                                                                <div className="form-group text-right">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSearchUsers(); }}>Search</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-sm-3">
                                                                                <div className="form-group">
                                                                                    <select id="filterStatus" name="filterStatus" className="form-control commentBody" defaultValue={''} placeholder='Filter by Status' onChange={(e) => { this.setState({ FilterStatus: e.target.value }, () => { this.filterUsers(); }); }}>
                                                                                        <option value="">Filter by Status</option>
                                                                                        <option value="0">Inactive</option>
                                                                                        <option value="1">Active</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-sm-3">
                                                                                <div className="form-group">
                                                                                    <select id="filterRole" name="filterRole" className="form-control commentBody" defaultValue={''} placeholder='Filter by Role' onChange={(e) => { this.setState({ FilterRole: e.target.value }, () => { this.filterUsers(); }); }}>
                                                                                        <option value="">Filter by Role</option>
                                                                                        <option value="Customer">Customer</option>
                                                                                        <option value="Pro">Pro</option>
                                                                                        <option value="Admin">Admin</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relatedProjDetailsContainer">
                                                                            {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<Typography variant="body2" color="text.secondary" className="mt-3">There are no users available.</Typography>) : (<React.Fragment>
                                                                                    {FilteredData.map((item, indx) => {
                                                                                        return (<div className={"tabPanel" + (indx > 0 ? " mt-2" : "")}>
                                                                                            <div className="form-group">
                                                                                                <div className="row">
                                                                                                    <div className="col-3 col-sm-2">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <Avatar alt={item.User.DisplayName} src={item.ProfileImage ? item.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 80, height: 80 }}></Avatar>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-7 col-sm-8 p-0">
                                                                                                        <div className="form-group mt-3">
                                                                                                            <div className="row">
                                                                                                                <div className="col-12 col-sm-12">
                                                                                                                    <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{item.User.DisplayName}</Typography>
                                                                                                                    {item.ActiveSubscription === Constants.Subscriptions.Premium ? (<Typography variant='caption' className="mt-2"><WorkspacePremiumOutlinedIcon /> Premium <a href={'/account?id=' + (item.User.DefaultRole === Constants.UserRoles.Pro ? item.User.DefaultRoleProfileId : (item.User.Role2 === Constants.UserRoles.Pro ? item.User.Role2ProfileId : ''))}>View payment history</a></Typography>) : (<Typography variant='caption' className="mt-2"><CardMembershipRoundedIcon /> Basic</Typography>)}
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-sm-4">
                                                                                                                            <Typography variant="caption" color="text.secondary" >{item.User.Email}</Typography>
                                                                                                                        </div>
                                                                                                                        <div className="col-6 col-sm-4">
                                                                                                                            <Chip label={item.User.DefaultRole} onClick={(e) => { window.location.href = "/profile?id=" + (item.User.DefaultRoleProfileId) + "&role=" + item.User.DefaultRole; }} />{item.User.Role2 ? (<Chip label={item.User.Role2} className="ml-2" onClick={(e) => { window.location.href = "/profile?id=" + (item.User.Role2ProfileId) + "&role=" + item.User.Role2; }} />) : (null)}
                                                                                                                        </div>
                                                                                                                        <div className="col-6 col-sm-4">
                                                                                                                            <div className="custom-control custom-switch d-flex justify-content-between">
                                                                                                                                <input type="checkbox" className="custom-control-input" id="accountStatus" checked={item.User.IsActive} onChange={(e) => { this.handleAccountActivation(e.target.checked, item); }} />
                                                                                                                                <label className="custom-control-label" htmlFor="accountStatus"><Typography variant="caption" className="commentBody">{item.User.IsActive ? 'Active' : 'Inactive'}</Typography></label>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {!item.User.IsActive && item.HasUserRequestedActivation ? (<div className="col-12 col-sm-12 mt-1">
                                                                                                                    <Typography variant="caption" color="text.primary"><i>This user has requested for account activation.</i></Typography>
                                                                                                                </div>) : (null)}
                                                                                                                {/* <div className="col-12 col-sm-3 text-right mt-2">
                                                                                                            <IconButton onClick={(e) => { this.handleDeleteUser(item); }} className="pl-2" title="Delete user"><DeleteIcon /></IconButton>
                                                                                                        </div> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-2 col-sm-2 p-0">
                                                                                                        <div className="form-group mt-3">
                                                                                                            {item.User.DefaultRole === Constants.UserRoles.Customer || item.User.Role2 === Constants.UserRoles.Customer ? (<div><Typography variant="caption" className="mt-3">
                                                                                                                <AccountTreeOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { window.location.href = '/projects?id=' + (item.User.DefaultRole === Constants.UserRoles.Customer ? item.User.DefaultRoleProfileId : (item.User.Role2 === Constants.UserRoles.Customer ? item.User.Role2ProfileId : '')); }} title="View Customer's Projects" className="ml-2">View Projects</a></Typography></div>) : (null)}
                                                                                                            {item.User.DefaultRole === Constants.UserRoles.Pro || item.User.Role2 === Constants.UserRoles.Pro ? (<div><Typography variant="caption" className="mt-3">
                                                                                                                <MiscellaneousServicesOutlinedIcon /><a href="javascript:void(0);" onClick={(e) => { window.location.href = '/services?id=' + (item.User.DefaultRole === Constants.UserRoles.Pro ? item.User.DefaultRoleProfileId : (item.User.Role2 === Constants.UserRoles.Pro ? item.User.Role2ProfileId : '')); }} title="View Pro's Services" className="ml-2">View Services</a></Typography></div>) : (null)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>);
                                                                                    })}
                                                                                </React.Fragment>)}
                                                                                {HasMoreRecords ? (<div className="d-flex align-items-center justify-content-center pt-3">
                                                                                    <button className='btn btn-dark' title="Load more results" onClick={(e) => { this.loadMore(); }}>Load more</button>
                                                                                </div>) : (null)}
                                                                            </React.Fragment>)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 2 ? "show active" : "")} id="v-pills-categories">
                                                        {ActiveTab !== 2 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <h5>Manage Services</h5>
                                                                        <div className="row">
                                                                            <div className="col-9 col-sm-10">
                                                                                <Typography variant="subtitle2" color="text.secondary">Manage the Services used in the application. You can add new services, modify the existing ones, delete the service for which there is no related content, and activate/deactive them.</Typography>
                                                                            </div>
                                                                            <div className="col-3 col-sm-2">
                                                                                <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showDetailsDialog: true, DialogMode: '', ItemId: 0 }); }}><AddIcon /></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <hr />
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-9 col-sm-10">
                                                                                <div className="form-group">
                                                                                    <input type='text' id='search' name="search" className="form-control" onChange={(e) => { this.setState({ SearchText: e.target.value }); }} placeholder="Search service by name" onKeyDown={(e) => { this.handleKeyDown(e, 'C'); }} />
                                                                                    <Validator id="valSearch" type="required" errorMessage="Provide service name to search." controlId="search" cssClass="error" valueToValidate={SearchText} formValidators={this.searchValidators} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-sm-2">
                                                                                <div className="form-group text-right">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSearchCategories(); }}>Search</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relatedProjDetailsContainer">
                                                                            {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<Typography variant="body2" color="text.secondary" className="mt-3">There are no services available.</Typography>) : (<React.Fragment>
                                                                                    {FilteredData.map((item, indx) => {
                                                                                        return (<div className={"tabPanel" + (indx > 0 ? " mt-2" : "")}>
                                                                                            <div className="form-group">
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{item.Name}</Typography>
                                                                                                        <Typography variant='caption'>{item.Description}</Typography>
                                                                                                        <div className="row mt-2">
                                                                                                            <div className="col-9 col-sm-9">
                                                                                                                <Chip label={item.IsActive ? 'Active' : 'Inactive'} />{item.TotalProjects ? <Chip label={item.TotalProjects + " projects"} className="ml-1" /> : (null)}
                                                                                                                {/* {item.TotalServices > 0 ? <Chip label={item.TotalServices + " services"} className="ml-1" /> : (null)} */}
                                                                                                            </div>
                                                                                                            <div className="col-3 col-sm-3 text-right pr-0">
                                                                                                                <Tooltip title="Edit service"><IconButton onClick={(e) => { this.handleEditCatgory(item); }} className="pl-2"><EditIcon /></IconButton></Tooltip> <Tooltip title={item.TotalProjects > 0 ? "This service cannot be deleted as it has a project dependency on it." : "Delete service"}><IconButton onClick={(e) => { this.handleDeleteCatgory(item); }} className="pl-2" disabled={item.TotalProjects > 0}><DeleteIcon /></IconButton></Tooltip>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>);
                                                                                    })}
                                                                                </React.Fragment>)}
                                                                                {HasMoreRecords ? (<div className="d-flex align-items-center justify-content-center pt-3">
                                                                                    <button className='btn btn-dark' title="Load more results" onClick={(e) => { this.loadMore(); }}>Load more</button>
                                                                                </div>) : (null)}
                                                                            </React.Fragment>)}
                                                                            {!showDetailsDialog ? (null) : (<React.Fragment>
                                                                                <Dialog open={showDetailsDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                                                    <DialogTitle id={"detailsDialog"} className="modalTitle">{ItemId > 0 ? (DialogMode === 'E' ? 'Edit Category Details' : 'Service Details') : 'New Service'}
                                                                                        <IconButton aria-label="close" onClick={(e) => { this.setState({ showDetailsDialog: false, ItemId: 0, DialogMode: '', Name: '', Description: '', IsActive: true }); }} className="btnCloseDialog">
                                                                                            <CloseIcon />
                                                                                        </IconButton>
                                                                                    </DialogTitle>
                                                                                    <DialogContent dividers={true}>
                                                                                        <div className="profileContainer pt-1">
                                                                                            <div className="formContainer">
                                                                                                <div className="row mt-2">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Name</label>
                                                                                                            {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{Name}</Typography>) : (
                                                                                                                <React.Fragment>
                                                                                                                    <input id="name" name="name" type="text" className="form-control" defaultValue={Name} placeholder='Provide service name' onBlur={(e) => { this.setState({ Name: e.target.value }); }} />
                                                                                                                    <Validator id="valName" type='required' cssClass="error" errorMessage="Provide service name." controlId="name" valueToValidate={Name} formValidators={this.formValidators}></Validator>
                                                                                                                    <Validator id="valNameLength" type="max_length" maxLength={100} cssClass="error" errorMessage="Service name cannot exceed 100 characters." controlId="name" valueToValidate={Name} formValidators={this.formValidators}></Validator>
                                                                                                                </React.Fragment>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Description (optional)</label>
                                                                                                            {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{Description}</Typography>) : (
                                                                                                                <React.Fragment>
                                                                                                                    <textarea id="desc" name="desc" key="desc" rows={4} className="form-control" placeholder="Provide service description" defaultValue={Description} onBlur={(e) => { this.setState({ Description: e.target.value }); }} />
                                                                                                                    {/* <Validator id="valDesc" type='required' cssClass="error" errorMessage="Provide description." controlId="desc" valueToValidate={Description} formValidators={this.formValidators}></Validator> */}
                                                                                                                    <Validator id="valDescLength" type='max_length' cssClass="error" errorMessage="Description cannot exceed 500 characters." maxLength={500} controlId="desc" valueToValidate={Description} formValidators={this.formValidators}></Validator>
                                                                                                                </React.Fragment>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Is Active</label>
                                                                                                            <div className="custom-control custom-switch d-flex justify-content-between">
                                                                                                                <input type="checkbox" className="custom-control-input" id="isActive" disabled={DialogMode === 'V'} checked={IsActive} onChange={(e) => { this.setState({ IsActive: e.target.checked }); }} />
                                                                                                                <label className="custom-control-label" htmlFor="isActive">{IsActive ? "Yes" : "No"}</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </DialogContent>
                                                                                    <DialogActions>
                                                                                        <div className="formButtonContainer ">
                                                                                            <button type="submit" className="btn btn-cancel mr-3" disabled={showDialogProgress} onClick={(e) => { this.setState({ showDetailsDialog: false, ItemId: 0, DialogMode: '', Name: '', Description: '', IsActive: true }); }}>Cancel</button>
                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" disabled={showDialogProgress} onClick={(e) => { this.handleCategorySaveClick(); }}>Save</button>
                                                                                        </div>
                                                                                    </DialogActions>
                                                                                </Dialog>
                                                                            </React.Fragment>)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 3 ? "show active" : "")} id="v-pills-services">
                                                        {ActiveTab !== 3 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <h5>Manage Services</h5>
                                                                        <div className="row">
                                                                            <div className="col-9 col-sm-10">
                                                                                <Typography variant="subtitle2" color="text.secondary">Manage the Services used in the application. You can add new services, modify the existing ones, delete the services for which there is no related content, and activate/deactive them.</Typography>
                                                                            </div>
                                                                            <div className="col-3 col-sm-2">
                                                                                <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showDetailsDialog: true, DialogMode: '', ItemId: 0 }); }}><AddIcon /></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <hr />
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-9 col-sm-10">
                                                                                <div className="form-group">
                                                                                    <input type='text' id='search' name="search" className="form-control" onChange={(e) => { this.setState({ SearchText: e.target.value }); }} placeholder="Search service by name" onKeyDown={(e) => { this.handleKeyDown(e, 'S'); }} />
                                                                                    <Validator id="valSearch" type="required" errorMessage="Provide service name to search." controlId="search" cssClass="error" valueToValidate={SearchText} formValidators={this.searchValidators} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-sm-2">
                                                                                <div className="form-group text-right">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSearchServices(); }}>Search</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relatedProjDetailsContainer">
                                                                            {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<Typography variant="body2" color="text.secondary" className="mt-3">There are no services available.</Typography>) : (<React.Fragment>
                                                                                    {FilteredData.map((item, indx) => {
                                                                                        return (<div className={"tabPanel" + (indx > 0 ? " mt-2" : "")}>
                                                                                            <div className="form-group">
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{item.Name}</Typography>
                                                                                                        <Typography variant='caption'>{item.Description}</Typography>
                                                                                                        <div className="row mt-2">
                                                                                                            <div className="col-9 col-sm-9">
                                                                                                                {item.Category ? (<Chip label={item.Category.Name} />) : (null)}<Chip label={item.IsActive ? 'Active' : 'Inactive'} className="ml-1" />{item.TotalProjects ? (<Chip label={item.TotalProjects + " projects"} className="ml-1" />) : (null)}
                                                                                                            </div>
                                                                                                            <div className="col-3 col-sm-3 text-right pr-0">
                                                                                                                <Tooltip title="Edit service"><IconButton onClick={(e) => { this.handleEditService(item); }} className="pl-2"><EditIcon /></IconButton></Tooltip> <Tooltip title={item.TotalProjects > 0 ? "This service cannot be deleted as it has a project dependency on it." : "Delete service"}><IconButton onClick={(e) => { this.handleDeleteService(item); }} className="pl-2" disabled={item.TotalProjects > 0}><DeleteIcon /></IconButton></Tooltip>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>);
                                                                                    })}
                                                                                </React.Fragment>)}
                                                                                {HasMoreRecords ? (<div className="d-flex align-items-center justify-content-center pt-3">
                                                                                    <button className='btn btn-dark' title="Load more results" onClick={(e) => { this.loadMore(); }}>Load more</button>
                                                                                </div>) : (null)}
                                                                            </React.Fragment>)}
                                                                            {!showDetailsDialog ? (null) : (<React.Fragment>
                                                                                <Dialog open={showDetailsDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                                                    <DialogTitle id={"detailsDialog"} className="modalTitle">{ItemId > 0 ? (DialogMode === 'E' ? 'Edit Service Details' : 'Service Details') : 'New Service'}
                                                                                        <IconButton aria-label="close" onClick={(e) => { this.setState({ showDetailsDialog: false, ItemId: 0, DialogMode: '', Name: '', Description: '', IsActive: true, SelectedCategory: null, CategoryId: '', CategoryName: '' }); }}
                                                                                            className="btnCloseDialog">
                                                                                            <CloseIcon />
                                                                                        </IconButton>
                                                                                    </DialogTitle>
                                                                                    <DialogContent dividers={true}>
                                                                                        <div className="profileContainer pt-1">
                                                                                            <div className="formContainer">
                                                                                                <div className="row mt-2">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Category</label>
                                                                                                            {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{CategoryName}</Typography>) : (
                                                                                                                <React.Fragment>
                                                                                                                    <Autocomplete multiple={false} id="category" disabled={DialogMode === 'V'} className="autoComplete" options={Categories} getOptionLabel={(option) => { return (option && option.Name ? option.Name : ""); }}
                                                                                                                        onChange={(e, newVal) => { this.setState({ SelectedCategory: newVal, CategoryId: newVal ? newVal.Id.toString() : '', CategoryName: newVal ? newVal.Name : '' }); }}
                                                                                                                        defaultValue={SelectedCategory} filterSelectedOptions={true} renderInput={(params) => (<TextField name="category" {...params} label="" placeholder="" />)} />
                                                                                                                    <Validator id="valCategory" type='required' cssClass="error" errorMessage="Provide category." controlId="category" valueToValidate={CategoryId} formValidators={this.formValidators}></Validator>
                                                                                                                </React.Fragment>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Name</label>
                                                                                                            {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{Name}</Typography>) : (
                                                                                                                <React.Fragment>
                                                                                                                    <input id="name" name="name" type="text" className="form-control" defaultValue={Name} placeholder='Provide service name' onBlur={(e) => { this.setState({ Name: e.target.value }); }} />
                                                                                                                    <Validator id="valName" type='required' cssClass="error" errorMessage="Provide service name." controlId="name" valueToValidate={Name} formValidators={this.formValidators}></Validator>
                                                                                                                    <Validator id="valNameLength" type="max_length" maxLength={100} cssClass="error" errorMessage="Category name cannot exceed 100 characters." controlId="name" valueToValidate={Name} formValidators={this.formValidators}></Validator>
                                                                                                                </React.Fragment>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Description</label>
                                                                                                            {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{Description}</Typography>) : (
                                                                                                                <React.Fragment>
                                                                                                                    <textarea id="desc" name="desc" key="desc" rows={4} className="form-control" placeholder="Provide service description" defaultValue={Description} onBlur={(e) => { this.setState({ Description: e.target.value }); }} />
                                                                                                                    <Validator id="valDesc" type='required' cssClass="error" errorMessage="Provide description." controlId="desc" valueToValidate={Description} formValidators={this.formValidators}></Validator>
                                                                                                                    <Validator id="valDescLength" type='max_length' cssClass="error" errorMessage="Description cannot exceed 500 characters." maxLength={500} controlId="desc" valueToValidate={Description} formValidators={this.formValidators}></Validator>
                                                                                                                </React.Fragment>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Is Active</label>
                                                                                                            <div className="custom-control custom-switch d-flex justify-content-between">
                                                                                                                <input type="checkbox" className="custom-control-input" id="isActive" disabled={DialogMode === 'V'} checked={IsActive} onChange={(e) => { this.setState({ IsActive: e.target.checked }); }} />
                                                                                                                <label className="custom-control-label" htmlFor="isActive">{IsActive ? "Yes" : "No"}</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </DialogContent>
                                                                                    <DialogActions>
                                                                                        <div className="formButtonContainer ">
                                                                                            <button type="submit" className="btn btn-cancel mr-3" disabled={showDialogProgress} onClick={(e) => { this.setState({ showDetailsDialog: false, DialogMode: '', ItemId: 0, Name: '', Description: '', IsActive: true, SelectedCategory: null, CategoryId: '', CategoryName: '' }); }}>Cancel</button>
                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" disabled={showDialogProgress} onClick={(e) => { this.handleServiceSaveClick(); }}>Save</button>
                                                                                        </div>
                                                                                    </DialogActions>
                                                                                </Dialog>
                                                                            </React.Fragment>)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 4 ? "show active" : "")} id="v-pills-faqs">
                                                        {ActiveTab !== 4 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <h5>Manage Project FAQs</h5>
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12">
                                                                                <Typography variant="subtitle2" color="text.secondary">Manage the list of frequently asked questions shown at the time a customer creates a new project. You can add new questions, modify the existing ones, delete the questions for which there is no related content, and activate/deactive them.</Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <hr />
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-9 col-sm-10">
                                                                                <div className="form-group">
                                                                                    <label>Service</label>
                                                                                    <Autocomplete multiple={false} id="category" disabled={DialogMode === 'V'} placeholder="Select a service" style={{ backgroundColor: '#fff' }} options={Categories} getOptionLabel={(option) => { return (option && option.Name ? option.Name : ""); }}
                                                                                        onChange={(e, newVal) => { this.setState({ SelectedCategory: newVal, CategoryId: newVal ? newVal.Id.toString() : '', CategoryName: newVal ? newVal.Name : '' }, () => { this.filterFAQs(); }); }}
                                                                                        defaultValue={SelectedCategory} filterSelectedOptions={true} renderInput={(params) => (<TextField name="category" {...params} label="" placeholder="Select a service" />)} />
                                                                                    <Validator id="valCategory" type='required' cssClass="error" errorMessage="Select a service." controlId="category" valueToValidate={CategoryId} formValidators={this.searchValidators}></Validator>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-sm-2">
                                                                                <div className="form-group text-right">
                                                                                    <label>&nbsp;</label>
                                                                                    <div>
                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" disabled={!CategoryId} title="Click to add new question." onClick={(e) => { this.handleNewQuestionClick(); }}><AddIcon /></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relatedProjDetailsContainer">
                                                                            {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<Typography variant="body2" color="text.secondary" className="mt-3">{!CategoryId ? ("Select a service to view all related questions.") : ("There are no questions available.")}</Typography>) : (<React.Fragment>
                                                                                    {FilteredData.map((item, indx) => {
                                                                                        return (<div className={"tabPanel" + (indx > 0 ? " mt-2" : "")}>
                                                                                            <div className="form-group">
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <Typography variant='subtitle1'>{item.Name}</Typography>
                                                                                                        <div className="row mt-2">
                                                                                                            <div className="col-9 col-sm-10">
                                                                                                                {this.getAnswerType(item, indx)}
                                                                                                            </div>
                                                                                                            <div className="col-3 col-sm-2 text-right pr-0">
                                                                                                                <Tooltip title="Edit question"><IconButton onClick={(e) => { this.handleEditQuestion(item); }} className="pl-2"><EditIcon /></IconButton></Tooltip> <Tooltip title="Delete question"><IconButton onClick={(e) => { this.handleDeleteQuestion(item); }} className="pl-2"><DeleteIcon /></IconButton></Tooltip>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>);
                                                                                    })}
                                                                                </React.Fragment>)}
                                                                            </React.Fragment>)}
                                                                            {!showDetailsDialog ? (null) : (<React.Fragment>
                                                                                <Dialog open={showDetailsDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                                                    <DialogTitle id={"detailsDialog"} className="modalTitle">{ItemId > 0 ? (DialogMode === 'E' ? 'Edit Question' : 'Question Details') : 'New Question'}
                                                                                        <IconButton aria-label="close" onClick={(e) => { this.setState({ showDetailsDialog: false, ItemId: 0, DialogMode: '', Name: '', ControlType: '', IsRequired: false, MaxLength: '', MasterValue: '', AllowMultiple: false, IsActive: true }); }}
                                                                                            className="btnCloseDialog">
                                                                                            <CloseIcon />
                                                                                        </IconButton>
                                                                                    </DialogTitle>
                                                                                    <DialogContent dividers={true}>
                                                                                        <div className="profileContainer pt-1">
                                                                                            <div className="formContainer">
                                                                                                <div className="row mt-2">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Question text</label>
                                                                                                            {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{Name}</Typography>) : (
                                                                                                                <React.Fragment>
                                                                                                                    <input id="name" name="name" type="text" className="form-control" defaultValue={Name} placeholder='Provide question text' onBlur={(e) => { this.setState({ Name: e.target.value }); }} />
                                                                                                                    <Validator id="valName" type='required' cssClass="error" errorMessage="Provide question text." controlId="name" valueToValidate={Name} formValidators={this.formValidators}></Validator>
                                                                                                                    <Validator id="valNameLength" type="max_length" maxLength={500} cssClass="error" errorMessage="Question text cannot exceed 500 characters." controlId="name" valueToValidate={Name} formValidators={this.formValidators}></Validator>
                                                                                                                </React.Fragment>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Answer type</label>
                                                                                                            {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{ControlType}</Typography>) : (
                                                                                                                <React.Fragment>
                                                                                                                    <select id="controlType" name="controlType" key="controlType" defaultValue={ControlType} disabled={ItemId > 0 && DialogMode === 'E'} className="form-control" onChange={(e) => { this.setState({ ControlType: e.target.value }); }}>
                                                                                                                        <option value="">Select answer type</option>
                                                                                                                        <option value="TXTB">Single line of text</option>
                                                                                                                        <option value="TXTA">Multiple lines of text</option>
                                                                                                                        <option value="CHKB">Single checkbox</option>
                                                                                                                        <option value="CHKL">Multi-selection checkbox list</option>
                                                                                                                        <option value="RBTL">Single-choice (radiobuttons)</option>
                                                                                                                        <option value="DDL">Single-choice (dropdown)</option>
                                                                                                                        {/* <option value="ATCF">File attachment</option> */}
                                                                                                                    </select>
                                                                                                                    <Validator id="valControlType" type='required' cssClass="error" errorMessage="Select answer type." controlId="controlType" valueToValidate={ControlType} formValidators={this.formValidators}></Validator>
                                                                                                                </React.Fragment>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {ControlType !== 'CHKB' ? (
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Is answer mandatory?</label>
                                                                                                                <div className="custom-control custom-switch d-flex justify-content-between">
                                                                                                                    <input type="checkbox" className="custom-control-input" id="isRequired" disabled={DialogMode === 'V'} checked={IsRequired} onChange={(e) => { this.setState({ IsRequired: e.target.checked }); }} />
                                                                                                                    <label className="custom-control-label" htmlFor="isRequired">{IsRequired ? "Yes" : "No"}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>) : (null)}
                                                                                                    {ControlType === 'TXTB' || ControlType === 'TXTA' ? (
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Maximum answer length</label>
                                                                                                                {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{MaxLength}</Typography>) : (
                                                                                                                    <React.Fragment>
                                                                                                                        <input id="maxLength" name="maxLength" type="text" className="form-control" defaultValue={MaxLength} placeholder={'Provide maximum characters allowed. Default is ' + (ControlType === 'TXTB' ? '100' : '500')} onBlur={(e) => { this.setState({ MaxLength: e.target.value }); }} />
                                                                                                                        <Validator id="valMaxLength" type='number' cssClass="error" errorMessage="Only numeric values are allowed." controlId="maxLength" valueToValidate={MaxLength} formValidators={this.formValidators}></Validator>
                                                                                                                        <Validator id="valMaxLengthSize" type='compare' cssClass="error" errorMessage={"Value cannot be greater than " + (ControlType === 'TXTB' ? '500' : '1000')} controlId="maxLength" valueToValidate={MaxLength} valueToCompareWith={(ControlType === 'TXTB' ? 500 : 1000)} formValidators={this.formValidators}></Validator>
                                                                                                                    </React.Fragment>)}
                                                                                                            </div>
                                                                                                        </div>) : (null)}
                                                                                                    {ControlType === 'RBTL' || ControlType === 'DDL' || ControlType === 'CHKL' ? (
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Answer choices</label>
                                                                                                                <textarea id="masterValue" name="masterValue" rows={6} className="form-control" defaultValue={MasterValue} placeholder='Provide answer choices. Use multiple lines for more values.' onBlur={(e) => { this.setState({ MasterValue: e.target.value }); }} />
                                                                                                                <Validator id="valMasterValue" type='required' cssClass="error" errorMessage="Provide answer choices." controlId="masterValue" valueToValidate={MasterValue} formValidators={this.formValidators}></Validator>
                                                                                                            </div>
                                                                                                        </div>) : (null)}
                                                                                                    {ControlType === 'CHKB' ? (
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Checkbox caption (optional)</label>
                                                                                                                <input type='text' id="masterValue" name="masterValue" className="form-control" defaultValue={MasterValue} placeholder='Provide caption.' onBlur={(e) => { this.setState({ MasterValue: e.target.value }); }} />
                                                                                                                <Validator id="valMasterValueLength" type="max_length" maxLength={100} cssClass="error" errorMessage="Caption cannot exceed 100 characters." controlId="masterValue" valueToValidate={MasterValue} formValidators={this.formValidators}></Validator>
                                                                                                            </div>
                                                                                                        </div>) : (null)}
                                                                                                    {ControlType === 'ATCF' ? (<React.Fragment>
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Allow multiple file selection</label>
                                                                                                                <div className="custom-control custom-switch d-flex justify-content-between">
                                                                                                                    <input type="checkbox" className="custom-control-input" id="allowMulti" disabled={DialogMode === 'V'} checked={AllowMultiple} onChange={(e) => { this.setState({ AllowMultiple: e.target.checked }); }} />
                                                                                                                    <label className="custom-control-label" htmlFor="allowMulti">{AllowMultiple ? "Yes" : "No"}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </React.Fragment>) : (null)}
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Is active?</label>
                                                                                                            <div className="custom-control custom-switch d-flex justify-content-between">
                                                                                                                <input type="checkbox" className="custom-control-input" id="isActive" disabled={DialogMode === 'V'} checked={IsActive} onChange={(e) => { this.setState({ IsActive: e.target.checked }); }} />
                                                                                                                <label className="custom-control-label" htmlFor="isActive">{IsActive ? "Yes" : "No"}</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </DialogContent>
                                                                                    <DialogActions>
                                                                                        <div className="formButtonContainer ">
                                                                                            <button type="submit" className="btn btn-cancel mr-3" disabled={showDialogProgress} onClick={(e) => { this.setState({ showDetailsDialog: false, DialogMode: '', ItemId: 0, Name: '', ControlType: '', IsRequired: false, MaxLength: '', MasterValue: '', AllowMultiple: false, IsActive: true }); }}>Cancel</button>
                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" disabled={showDialogProgress} onClick={(e) => { this.handleQuestionSaveClick(); }}>Save</button>
                                                                                        </div>
                                                                                    </DialogActions>
                                                                                </Dialog>
                                                                            </React.Fragment>)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 5 ? "show active" : "")} id="v-pills-qtemplates">
                                                        {ActiveTab !== 5 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <h5>Manage Quote FAQs</h5>
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12">
                                                                                <Typography variant="subtitle2" color="text.secondary">Manage the frequently asked questions shown at the time a pro summits a new quote. You can add new questions, modify the existing ones, delete the questions for which there is no related content, and activate/deactive them.</Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <hr />
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-9 col-sm-10">
                                                                                <div className="form-group">
                                                                                    <label>Service</label>
                                                                                    <Autocomplete multiple={false} id="category" disabled={DialogMode === 'V'} placeholder="Select a service" style={{ backgroundColor: '#fff' }} options={Categories} getOptionLabel={(option) => { return (option && option.Name ? option.Name : ""); }}
                                                                                        onChange={(e, newVal) => { this.setState({ SelectedCategory: newVal, CategoryId: newVal ? newVal.Id.toString() : '', CategoryName: newVal ? newVal.Name : '' }, () => { this.filterFAQs(); }); }}
                                                                                        defaultValue={SelectedCategory} filterSelectedOptions={true} renderInput={(params) => (<TextField name="category" {...params} label="" placeholder="Select a service" />)} />
                                                                                    <Validator id="valCategory" type='required' cssClass="error" errorMessage="Select a service." controlId="category" valueToValidate={CategoryId} formValidators={this.searchValidators}></Validator>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3 col-sm-2">
                                                                                <div className="form-group text-right">
                                                                                    <label>&nbsp;</label>
                                                                                    <div>
                                                                                        <button type="submit" className="btn btn-primary cutmBtnLogin" disabled={!CategoryId} title="Click to add new question." onClick={(e) => { this.handleNewQuestionClick(); }}><AddIcon /></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relatedProjDetailsContainer">
                                                                            {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<Typography variant="body2" color="text.secondary" className="mt-3">{!CategoryId ? ("Select a service to view all related questions.") : ("There are no questions available.")}</Typography>) : (<React.Fragment>
                                                                                    {FilteredData.map((item, indx) => {
                                                                                        return (<div className={"tabPanel" + (indx > 0 ? " mt-2" : "")}>
                                                                                            <div className="form-group">
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <Typography variant='subtitle1'>{item.Name}</Typography>
                                                                                                        <div className="row mt-2">
                                                                                                            <div className="col-9 col-sm-10">
                                                                                                                {this.getAnswerType(item, indx)}
                                                                                                            </div>
                                                                                                            <div className="col-3 col-sm-2 text-right pr-0">
                                                                                                                <Tooltip title="Edit question"><IconButton onClick={(e) => { this.handleEditQuestion(item); }} className="pl-2"><EditIcon /></IconButton></Tooltip> <Tooltip title="Delete question"><IconButton onClick={(e) => { this.handleDeleteQuestion(item); }} className="pl-2"><DeleteIcon /></IconButton></Tooltip>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>);
                                                                                    })}
                                                                                </React.Fragment>)}
                                                                            </React.Fragment>)}
                                                                            {!showDetailsDialog ? (null) : (<React.Fragment>
                                                                                <Dialog open={showDetailsDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                                                    <DialogTitle id={"detailsDialog"} className="modalTitle">{ItemId > 0 ? (DialogMode === 'E' ? 'Edit Question' : 'Question Details') : 'New Question'}
                                                                                        <IconButton aria-label="close" onClick={(e) => { this.setState({ showDetailsDialog: false, ItemId: 0, DialogMode: '', Name: '', ControlType: '', IsRequired: false, MaxLength: '', MasterValue: '', AllowMultiple: false, IsActive: true }); }}
                                                                                            className="btnCloseDialog">
                                                                                            <CloseIcon />
                                                                                        </IconButton>
                                                                                    </DialogTitle>
                                                                                    <DialogContent dividers={true}>
                                                                                        <div className="profileContainer pt-1">
                                                                                            <div className="formContainer">
                                                                                                <div className="row mt-2">
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Question text</label>
                                                                                                            {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{Name}</Typography>) : (
                                                                                                                <React.Fragment>
                                                                                                                    <input id="name" name="name" type="text" className="form-control" defaultValue={Name} placeholder='Provide question text' onBlur={(e) => { this.setState({ Name: e.target.value }); }} />
                                                                                                                    <Validator id="valName" type='required' cssClass="error" errorMessage="Provide question text." controlId="name" valueToValidate={Name} formValidators={this.formValidators}></Validator>
                                                                                                                    <Validator id="valNameLength" type="max_length" maxLength={500} cssClass="error" errorMessage="Question text cannot exceed 500 characters." controlId="name" valueToValidate={Name} formValidators={this.formValidators}></Validator>
                                                                                                                </React.Fragment>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Answer type</label>
                                                                                                            {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{ControlType}</Typography>) : (
                                                                                                                <React.Fragment>
                                                                                                                    <select id="controlType" name="controlType" key="controlType" defaultValue={ControlType} disabled={ItemId > 0 && DialogMode === 'E'} className="form-control" onChange={(e) => { this.setState({ ControlType: e.target.value }); }}>
                                                                                                                        <option value="">Select answer type</option>
                                                                                                                        <option value="TXTB">Single line of text</option>
                                                                                                                        <option value="TXTA">Multiple lines of text</option>
                                                                                                                        <option value="CHKB">Single checkbox</option>
                                                                                                                        <option value="CHKL">Multi-selection checkbox list</option>
                                                                                                                        <option value="RBTL">Single-choice (radiobuttons)</option>
                                                                                                                        <option value="DDL">Single-choice (dropdown)</option>
                                                                                                                        {/* <option value="ATCF">File attachment</option> */}
                                                                                                                    </select>
                                                                                                                    <Validator id="valControlType" type='required' cssClass="error" errorMessage="Select answer type." controlId="controlType" valueToValidate={ControlType} formValidators={this.formValidators}></Validator>
                                                                                                                </React.Fragment>)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {ControlType !== 'CHKB' ? (
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Is answer mandatory?</label>
                                                                                                                <div className="custom-control custom-switch d-flex justify-content-between">
                                                                                                                    <input type="checkbox" className="custom-control-input" id="isRequired" disabled={DialogMode === 'V'} checked={IsRequired} onChange={(e) => { this.setState({ IsRequired: e.target.checked }); }} />
                                                                                                                    <label className="custom-control-label" htmlFor="isRequired">{IsRequired ? "Yes" : "No"}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>) : (null)}
                                                                                                    {ControlType === 'TXTB' || ControlType === 'TXTA' ? (
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Maximum answer length</label>
                                                                                                                {DialogMode === 'V' ? (<Typography variant="body2" color="text.secondary">{MaxLength}</Typography>) : (
                                                                                                                    <React.Fragment>
                                                                                                                        <input id="maxLength" name="maxLength" type="text" className="form-control" defaultValue={MaxLength} placeholder={'Provide maximum characters allowed. Default is ' + (ControlType === 'TXTB' ? '100' : '500')} onBlur={(e) => { this.setState({ MaxLength: e.target.value }); }} />
                                                                                                                        <Validator id="valMaxLength" type='number' cssClass="error" errorMessage="Only numeric values are allowed." controlId="maxLength" valueToValidate={MaxLength} formValidators={this.formValidators}></Validator>
                                                                                                                        <Validator id="valMaxLengthSize" type='compare' cssClass="error" errorMessage={"Value cannot be greater than " + (ControlType === 'TXTB' ? '500' : '1000')} controlId="maxLength" valueToValidate={MaxLength} valueToCompareWith={(ControlType === 'TXTB' ? 500 : 1000)} formValidators={this.formValidators}></Validator>
                                                                                                                    </React.Fragment>)}
                                                                                                            </div>
                                                                                                        </div>) : (null)}
                                                                                                    {ControlType === 'RBTL' || ControlType === 'DDL' || ControlType === 'CHKL' ? (
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Answer choices</label>
                                                                                                                <textarea id="masterValue" name="masterValue" rows={6} className="form-control" defaultValue={MasterValue} placeholder='Provide answer choices. Use multiple lines for more values.' onBlur={(e) => { this.setState({ MasterValue: e.target.value }); }} />
                                                                                                                <Validator id="valMasterValue" type='required' cssClass="error" errorMessage="Provide answer choices." controlId="masterValue" valueToValidate={MasterValue} formValidators={this.formValidators}></Validator>
                                                                                                            </div>
                                                                                                        </div>) : (null)}
                                                                                                    {ControlType === 'CHKB' ? (
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Checkbox caption (optional)</label>
                                                                                                                <input type='text' id="masterValue" name="masterValue" className="form-control" defaultValue={MasterValue} placeholder='Provide caption.' onBlur={(e) => { this.setState({ MasterValue: e.target.value }); }} />
                                                                                                                <Validator id="valMasterValueLength" type="max_length" maxLength={100} cssClass="error" errorMessage="Caption cannot exceed 100 characters." controlId="masterValue" valueToValidate={MasterValue} formValidators={this.formValidators}></Validator>
                                                                                                            </div>
                                                                                                        </div>) : (null)}
                                                                                                    {ControlType === 'ATCF' ? (<React.Fragment>
                                                                                                        <div className="col-12 col-sm-12">
                                                                                                            <div className="form-group">
                                                                                                                <label>Allow multiple file selection</label>
                                                                                                                <div className="custom-control custom-switch d-flex justify-content-between">
                                                                                                                    <input type="checkbox" className="custom-control-input" id="allowMulti" disabled={DialogMode === 'V'} checked={AllowMultiple} onChange={(e) => { this.setState({ AllowMultiple: e.target.checked }); }} />
                                                                                                                    <label className="custom-control-label" htmlFor="allowMulti">{AllowMultiple ? "Yes" : "No"}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </React.Fragment>) : (null)}
                                                                                                    <div className="col-12 col-sm-12">
                                                                                                        <div className="form-group">
                                                                                                            <label>Is active?</label>
                                                                                                            <div className="custom-control custom-switch d-flex justify-content-between">
                                                                                                                <input type="checkbox" className="custom-control-input" id="isActive" disabled={DialogMode === 'V'} checked={IsActive} onChange={(e) => { this.setState({ IsActive: e.target.checked }); }} />
                                                                                                                <label className="custom-control-label" htmlFor="isActive">{IsActive ? "Yes" : "No"}</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </DialogContent>
                                                                                    <DialogActions>
                                                                                        <div className="formButtonContainer ">
                                                                                            <button type="submit" className="btn btn-cancel mr-3" disabled={showDialogProgress} onClick={(e) => { this.setState({ showDetailsDialog: false, DialogMode: '', ItemId: 0, Name: '', ControlType: '', IsRequired: false, MaxLength: '', MasterValue: '', AllowMultiple: false, IsActive: true }); }}>Cancel</button>
                                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin" disabled={showDialogProgress} onClick={(e) => { this.handleQuestionSaveClick(); }}>Save</button>
                                                                                        </div>
                                                                                    </DialogActions>
                                                                                </Dialog>
                                                                            </React.Fragment>)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </React.Fragment >);
                }
            }
        }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>ProsBE Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.state.noCallBackMethod(); this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null }); }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}