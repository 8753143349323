import { Autocomplete, TextField, Typography } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import { IAutoCompleteOption } from "src/common/Components";
import Constants from "src/common/Constants";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import Validator from "src/common/Validator";

export default class FindPros extends Base {
    public validator: Validator;
    public formValidators: Validator[];

    constructor(props) {
        super(props);

        this.state = { loading: true, RootCategories: [], Categories: [], PinAddress: '', Category: '', Pins: [], CategoryId: '', PinCode: '', MapScriptTrials: 0, PlaceScriptTrials: 0 };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        this.addGoogleMapScriptTag();

        this.db.getCategories().then((categories) => {
            this.getPinsMaster().then((pins) => {
                let _categories: IAutoCompleteOption[] = [];

                if (categories && categories.length > 0) {
                    categories.forEach((cat) => {
                        _categories.push({ key: cat.Name, text: cat.Name, value: cat.Id });
                    });
                }

                let _pins: IAutoCompleteOption[] = [];

                if (pins && pins.length > 0) {
                    pins.forEach((pin) => {
                        _pins.push({ key: pin.Name, text: pin.Name, value: pin.Pin });
                    });
                }

                this.setState({ loading: false, Categories: _categories, RootCategories: categories, Pins: _pins }, () => {
                    this.initializePlacesAutoComplete();
                });
            });
        });
    }


    public initializePlacesAutoComplete() {
        try {
            const input = document.getElementById("zip") as HTMLInputElement;
            const autoCompleteOptions = {
                fields: ["formatted_address", "address_components", "geometry", "name", "place_id"],
                // strictBounds: false,
                types: ["(regions)"],
                componentRestrictions: { country: "us" }
            };

            const autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);

            autocomplete.addListener("place_changed", () => {
                let place: any = autocomplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                    this.setState({ showConfirmation: true, ConfirmationMessage: "No details available for input: '" + place.name + "'", ConfirmationType: 'A' });
                    return;
                }

                let hasPostalCode = false;
                let zipCode = '';
                // let state = '';
                // let sCode = '';
                // let city = '';

                if (place.address_components) {
                    for (var i = 0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types) {
                            for (var j = 0; j < place.address_components[i].types.length; j++) {
                                if (place.address_components[i].types[j] === "postal_code") {
                                    if (!hasPostalCode) {
                                        hasPostalCode = true;
                                        zipCode = place.address_components[i].long_name;
                                    }
                                }

                                // if (place.address_components[i].types[j] === "administrative_area_level_1") {
                                //     if (!state) {
                                //         state = place.address_components[i].long_name;
                                //         sCode = place.address_components[i].short_name;
                                //     }
                                // }

                                // if (place.address_components[i].types[j] === "locality") {
                                //     if (!city) {
                                //         city = place.address_components[i].long_name;
                                //     }
                                // }
                            }
                        }
                    }
                }

                if (!hasPostalCode) {
                    this.setState({ showConfirmation: true, ConfirmationMessage: "Invalid Zip code. Please start your search with a Zip code.", ConfirmationType: 'A' });
                    return;
                }

                let formatted_address = place.formatted_address;
                // let placeId = place.place_id;
                // let lat = place.geometry?.location?.lat();
                // let lon = place.geometry?.location?.lng();

                this.setState({ PinCode: zipCode, PinAddress: formatted_address });
            });
        }
        catch (ex) {
            if (this.state.PlaceScriptTrials === 0) {
                this.setState({ PlaceScriptTrials: 1 }, () => {
                    this.initializePlacesAutoComplete();
                });
            }
            else {
                console.log(ex)
            }
        }
    }

    private addGoogleMapScriptTag() {
        try {
            let scriptElems = document.getElementsByTagName("script");
            let elemExists = false;
            for (var i = 0; i < scriptElems.length; i++) {
                if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
                    elemExists = true;
            }

            if (!elemExists) {
                const script = document.createElement("script");
                script.src = Constants.Google.PlacesScriptPath + "&callback=initializePlacesAutoComplete";
                //script.id = "scriptGoogleMaps";
                script.setAttribute('async', '');
                script.setAttribute('defer', '');

                document.getElementsByTagName("head")[0].appendChild(script);
            }
        }
        catch (e) {
            if (this.state.MapScriptTrials === 0) {
                this.setState({ MapScriptTrials: 1 }, () => {
                    this.addGoogleMapScriptTag();
                });
            }
            else {
                console.log("Google Map references could not be added to the page. Refresh the page to try again.")
            }
        }
    }

    private handleSearch() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            window.location.href = '/search?c=' + this.state.CategoryId + '&p=' + this.state.PinCode + '&add=' + this.state.PinAddress;
        }
    }

    public render() {
        const { loading, RootCategories, Categories, PinAddress, Category } = this.state;

        let template = (<React.Fragment>
            <Header />
            <section className="containerMinHeight borderBtm">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="becomeLayer5">
                            <div className="row">
                                <div className="col-12 col-sm-12 p-5">
                                    <div className="searchResultContainerForm">
                                        <div className="searchSection">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 pr-0">
                                                    <div className="height50">
                                                        <Autocomplete multiple={false} id="category" className="autoComplete" options={Categories && Categories.length > 0 ? Categories : []} getOptionLabel={(option) => { return (option && option.text ? option.text : ''); }}
                                                            onChange={(e, newVal) => { this.setState({ Category: newVal ? newVal : null, CategoryId: newVal ? newVal.value.toString() : '' }); }} defaultValue={Category} filterSelectedOptions={true} renderInput={(params) => (<TextField name="category" {...params} label="" placeholder="" />)} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4 p-0">
                                                    <div className="height50">
                                                        <input id="zip" name="zip" type="text" className="zipCodeGoogle iconMap" defaultValue={PinAddress} />
                                                        <div id="map"></div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-2 pl-0">
                                                    <div className="height50">
                                                        <button className="btn-search" disabled={loading} type="button" onClick={(e) => { this.handleSearch(); }}>Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Validator id="valSearch" type="required" cssClass="error" errorMessage="Provide a service or pin to search the professionals." valueToValidate={(!Category && !PinAddress ? '' : '1')} formValidators={this.formValidators} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 pt-0 pb-0 pl-5 pr-5">
                                    Discover Your Perfect Match for Every Service You Need! From home improvement to event planning, we've got you covered!
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 p-5">
                                    {RootCategories && RootCategories.length > 0 ? (<>
                                        {RootCategories.map((cat: any, indx: number) => {
                                            return (<div className={"mt-3"}>
                                                <Typography variant="body2">{cat.Name}</Typography>
                                                <div className="mt-3"><Typography variant="caption" >{cat.Description}</Typography></div>
                                            </div>);
                                        })}
                                    </>) : (null)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>);

        return template;
    }
}