import React from "react";

export default class Footer extends React.Component<any, any>{

    public render() {
        let template = (
            <section className="footer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-2 col-md-2">
                            <div className="footerLogo">
                                <a href='/' title='ProsBE'><img src="/images/logo.png" alt="" className="img-fluid" /></a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2">
                            <div className="footerLinksSection">
                                <div className="heading">Customers</div>
                                <ul>
                                    <li><a href="/findpros">Find Pros</a></li>
                                    <li><a href="/use">How to use ProsBe</a></li>
                                    <li><a href="/users/new?m=cust">Sign Up/Login</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2">
                            <div className="footerLinksSection">
                                <div className="heading">Pros</div>
                                <ul>
                                    <li><a href="/works">How it works</a></li>
                                    <li><a href="/pricing">Pricing</a></li>
                                    <li><a href="/becomepro">Join as a Pro</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2">
                            <div className="footerLinksSection">
                                <div className="heading">Resources</div>
                                <ul>
                                    <li><a href="http://help.prosbe.com" target="_blank">Help</a></li>
                                    <li><a href="/terms">Terms of Use</a></li>
                                    <li><a href="/privacy">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2">
                            <div className="footerLinksSection">
                                <div className="heading">Company</div>
                                <ul>
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="/contact">Contact Us</a></li>
                                    <li><a href="http://blog.prosbe.com" target="_blank">Blog</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>);

        return template;
    }
}