import { Alert, Autocomplete, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import { Archive, Reply, MoveUp } from '@mui/icons-material';
import { Loader, ProgressBar } from "src/common/Components";
import Constants from "src/common/Constants";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import { Attachment } from "@mui/icons-material";
import Validator from "src/common/Validator";
// import { convertToRaw } from 'draft-js';
// import MUIRichTextEditor from 'mui-rte';
// import { createTheme, ThemeProvider } from '@mui/material/styles';

// const myTheme = createTheme({
//     // Set up your custom MUI theme here
// });

export default class Inbox extends Base {
    private validator: Validator;
    private formValidators: Validator[];

    constructor(props) {
        super(props);

        this.state = {
            loading: true, Items: [], ActiveTab: 0, Data: [], FilteredData: [], showDataProgress: false, SearchText: '', showComposeDialog: false,
            showDialogProgress: false, To: '', Subject: '', Body: '', DialogMode: '', ParentId: '0', RootId: '0', File: null, FileName: '', ItemId: 0, TimeStamp: '', showEmailChain: false, EmailChain: [],
            showMessageDetails: false, SelectedTo: null, Recipients: [], showConfirmation: false, ConfirmationMessage: '', ShowMessage: false, Message: '', MessageType: 'error', ConfirmationType: ''
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        this.db.getRecipients(this.User && this.User.Profile ? this.User.Profile.Id : 0, this.AppRole).then((users) => {
            if (users) {
                users.forEach((u) => {
                    if (u.Profile.ProfileRole === Constants.UserRoles.Pro)
                        u.text = u.Profile.BusinessName;
                    else
                        u.text = u.Profile.User.DisplayName;
                });
            }

            this.setState({ Recipients: users }, () => { this.handleTabChange(0); });
        });
    }

    private handleTabChange(tabId) {
        this.setState({ ActiveTab: tabId, loading: false, showDataProgress: true }, () => {
            this.resetMessage();
            this.resetMessageNew();
            if (tabId === 0) {
                this.db.getInboxMessages(this.User && this.User.Profile ? this.User.Profile.Id : 0).then((messages) => {
                    if (messages) {
                        messages.forEach((m) => {
                            if (m.SentOn)
                                m.SentOn = new Date(this.getDateNumber(m.SentOn));

                            if (m.ViewedOn)
                                m.ViewedOn = new Date(this.getDateNumber(m.ViewedOn));

                            if (m.ModifiedOn)
                                m.ModifiedOn = new Date(this.getDateNumber(m.ModifiedOn));
                        });
                    }

                    this.setState({ showDataProgress: false, showMessageDetails: false, Data: messages, FilteredData: messages });
                });
            }
            else if (tabId === 1) {
                this.db.getStarredMessages(this.User && this.User.Profile ? this.User.Profile.Id : 0).then((messages) => {
                    if (messages) {
                        messages.forEach((m) => {
                            if (m.SentOn)
                                m.SentOn = new Date(this.getDateNumber(m.SentOn));

                            if (m.ViewedOn)
                                m.ViewedOn = new Date(this.getDateNumber(m.ViewedOn));

                            if (m.ModifiedOn)
                                m.ModifiedOn = new Date(this.getDateNumber(m.ModifiedOn));
                        });
                    }

                    this.setState({ showDataProgress: false, showMessageDetails: false, Data: messages, FilteredData: messages });
                });
            }
            else if (tabId === 2) {
                this.db.getArchivedMessages(this.User && this.User.Profile ? this.User.Profile.Id : 0).then((messages) => {
                    if (messages) {
                        messages.forEach((m) => {
                            if (m.SentOn)
                                m.SentOn = new Date(this.getDateNumber(m.SentOn));

                            if (m.ViewedOn)
                                m.ViewedOn = new Date(this.getDateNumber(m.ViewedOn));

                            if (m.ModifiedOn)
                                m.ModifiedOn = new Date(this.getDateNumber(m.ModifiedOn));
                        });
                    }

                    this.setState({ showDataProgress: false, showMessageDetails: false, Data: messages, FilteredData: messages });
                });
            }
            else if (tabId === 3) {
                this.db.getSentMessages(this.User && this.User.Profile ? this.User.Profile.Id : 0).then((messages) => {
                    if (messages) {
                        messages.forEach((m) => {
                            if (m.SentOn)
                                m.SentOn = new Date(this.getDateNumber(m.SentOn));

                            if (m.ViewedOn)
                                m.ViewedOn = new Date(this.getDateNumber(m.ViewedOn));

                            if (m.ModifiedOn)
                                m.ModifiedOn = new Date(this.getDateNumber(m.ModifiedOn));
                        });
                    }

                    this.setState({ showDataProgress: false, showMessageDetails: false, Data: messages, FilteredData: messages });
                });
            }
        });
    }

    private getDays(createdOn) {
        let currentDate = new Date();
        let createdDate = new Date(createdOn);

        const diffTime = currentDate.getTime() - createdDate.getTime();
        let diffDays: any = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    }

    private handleSearch() {
        let { SearchText, Data } = this.state;

        this.setState({ showDataProgress: true });
        if (SearchText) {
            let filteredData = Data.filter((d) => { return ((d.SenderProfile && (d.SenderProfile.BusinessName.indexOf(SearchText) >= 0 || d.SenderProfile.User.DisplayName.indexOf(SearchText) >= 0)) || d.Subject.indexOf(SearchText) >= 0 || d.Body.indexOf(SearchText) >= 0); })
            this.setState({ showDataProgress: false, FilteredData: filteredData })
        }
        else {
            this.setState({ showDataProgress: false, FilteredData: Data })
        }
    }

    private handleKeyDown(e) {
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();

            this.setState({ SearchText: e.target.value }, () => { this.handleSearch() });
        }
    }

    private sendEmail(toEmail, subject, body,): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let emailTemplate = Constants.Email.NewMessage;
            //let subject = this.state.Subject;//emailTemplate.Subject;
            let _body = emailTemplate.Body;

            _body = _body.replace("[username]", (this.AppRole === Constants.UserRoles.Pro ? this.User.Profile.BusinessName : this.User.DisplayName));
            _body = _body.replace("[message]", body);

            if (toEmail) {
                this.db.sendEmail(toEmail, subject, _body).then((sent) => {
                    if (sent) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(false);
            }
        });
    }

    private handleSaveClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showDialogProgress: true });
            this.db.addMessage(this.User.Profile.Id, this.state.To.Id, this.state.Subject, this.state.Body, this.state.ParentId, this.state.RootId, this.AppRole, this.state.To.ProfileRole).then((added) => {
                if (added > 0) {
                    this.sendEmail(this.state.To.User.Email, this.state.Subject, this.state.Body).then((sent) => {
                        if (sent) {
                            this.setState({ SelectedTo: null, To: null, From: null, Subject: '', Body: '', ParentId: '0', RootId: '0', DialogMode: '', showDialogProgress: false, showComposeDialog: false }, () => {
                                this.handleTabChange(this.state.ActiveTab);
                            });

                            this.successMessage('Message sent successfully.');
                        }
                        else {
                            this.setState({ showDialogProgress: false });
                            this.errorMessage('Message email could not be sent.');
                        }
                    });
                }
                else {
                    this.setState({ showDialogProgress: false });
                    this.errorMessage('Message could not be sent.');
                }
            });
        }
    }

    private handleSaveClickNew() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showDialogProgressNew: true });
            this.db.addMessage(this.User.Profile.Id, this.state.ToNew.Id, this.state.SubjectNew, this.state.BodyNew, this.state.ParentIdNew, this.state.RootIdNew, this.AppRole, this.state.ToNew.ProfileRole).then((added) => {
                if (added > 0) {
                    this.sendEmail(this.state.ToNew.User.Email, this.state.SubjectNew, this.state.BodyNew).then((sent) => {
                        if (sent) {
                            this.setState({ SelectedTo: null, ToNew: null, SubjectNew: '', BodyNew: '', ParentIdNew: '0', RootIdNew: '0', DialogMode: '', showDialogProgress: false, showComposeDialogNew: false }, () => {
                                this.handleTabChange(this.state.ActiveTab);
                            });

                            this.successMessage('Message sent successfully.');
                        }
                        else {
                            this.setState({ showDialogProgress: false });
                            this.errorMessage('Message email could not be sent.');
                        }
                    });
                }
                else {
                    this.setState({ showDialogProgress: false });
                    this.errorMessage('Message could not be sent.');
                }
            });
        }
    }

    private replyMessage(item) {
        if (this.OpenToAll === "1" || (this.AppRole === Constants.UserRoles.Pro && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) || this.AppRole === Constants.UserRoles.Customer || this.AppRole === Constants.UserRoles.Admin) {
            this.setState({
                showComposeDialog: true, SelectedTo: item.SenderProfile,
                To: (this.state.ActiveTab === 0 ? item.SenderProfile : item.ReceiverProfile),
                Subject: item.Subject, ParentId: item.Id, RootId: item.RootId, DialogMode: 'R'
            });
        }
    }

    private replyMessageNew(item) {
        if (this.OpenToAll === "1" || (this.AppRole === Constants.UserRoles.Pro && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) || this.AppRole === Constants.UserRoles.Customer || this.AppRole === Constants.UserRoles.Admin) {
            this.setState({
                showComposeDialogNew: true, SelectedTo: item.SenderProfile,
                ToNew: (this.state.ActiveTab === 0 ? item.SenderProfile : item.ReceiverProfile),
                SubjectNew: item.Subject, ParentIdNew: item.Id, RootIdNew: item.RootId, DialogMode: 'R'
            });
        }
    }

    private readMessage(item) {
        this.db.readMessage(item.Id, this.User.Profile.Id).then((read) => { });
    }

    private handleArchiveMessage(item) {
        this.setState({ showConfirmation: true, ConfirmationMessage: 'Are you sure you want to archive this message?', callBackMethod: this.archiveMessage.bind(this), ItemId: item.Id });
    }

    private archiveMessage() {
        this.db.archiveMessage(this.state.ItemId, this.User.Profile.Id).then((archived) => {
            if (archived) {
                this.setState({ showConfirmation: false, ConfirmationMessage: '', callBackMethod: null }, () => {
                    this.handleTabChange(this.state.ActiveTab);
                });

                this.successMessage('Message archived successfully.');
            }
            else {
                this.setState({ showConfirmation: false, ConfirmationMessage: '', callBackMethod: null });
                this.errorMessage('Message could not be archived.');
            }
        });
    }

    private handleMoveMessage(item) {
        this.setState({ showConfirmation: true, ConfirmationMessage: 'Are you sure you want to move this message to Inbox?', callBackMethod: this.moveMessage.bind(this), ItemId: item.Id });
    }

    private moveMessage(item) {
        this.db.moveMessage(this.state.ItemId, this.User.Profile.Id).then((moved) => {
            if (moved) {
                this.setState({ showConfirmation: false, ConfirmationMessage: '', callBackMethod: null }, () => {
                    this.handleTabChange(this.state.ActiveTab);
                });

                this.successMessage('Message moved to Inbox successfully.');
            }
            else {
                this.setState({ showConfirmation: false, ConfirmationMessage: '', callBackMethod: null });
                this.errorMessage('Message could not be moved.');
            }
        });
    }

    private viewMessage(item) {
        this.db.getRelatedMessages(item.Id).then((relatedMessages) => {
            let timeStamp = '';
            let days = this.getDays(item.SentOn);
            timeStamp = (item.SentOn.getFullYear() !== new Date().getFullYear() ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.MMMyyyy, ' ')) : (days > 0 ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.ddMMM, '-')) : (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.hhmm, ':'))));

            if (relatedMessages) {
                relatedMessages.forEach((m) => {
                    if (m.SentOn)
                        m.SentOn = new Date(this.getDateNumber(m.SentOn));

                    if (m.ViewedOn)
                        m.ViewedOn = new Date(this.getDateNumber(m.ViewedOn));

                    if (m.ModifiedOn)
                        m.ModifiedOn = new Date(this.getDateNumber(m.ModifiedOn));
                });
            }

            this.setState({ showMessageDetails: true, EmailItem: item, From: item.SenderProfile, Subject: item.Subject, Body: item.Body, ParentId: item.ParentId, RootId: item.RootId, TimeStamp: timeStamp, EmailChain: relatedMessages });
            if (item.Status === 'NEW' || item.Status === 'UNREAD') {
                this.readMessage(item);
            }
        });
    }

    private showEmailChain() {
        this.setState({ showEmailChain: true });
    }

    private hideEmailChain() {
        this.setState({ showEmailChain: false });
    }

    private resetMessage() {
        this.formValidators = new Array<Validator>();
        this.setState({
            showComposeDialog: false, showMessageDetails: false, SelectedTo: null, To: null, From: null, Subject: '', Body: '', DialogMode: '', ParentId: '0', RootId: '0',
            TimeStamp: '', showEmailChain: false, EmailChain: []
        });
    }

    private resetMessageNew() {
        this.formValidators = new Array<Validator>();
        this.setState({
            showComposeDialogNew: false, SelectedTo: null, ToNew: null, FromNew: null, SubjectNew: '', BodyNew: '', DialogMode: '', ParentIdNew: '0', RootIdNew: '0',
            TimeStampNew: ''
        });
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {

        const { loading, ActiveTab, showDataProgress, FilteredData, showComposeDialog, showDialogProgress, From, SelectedTo, Recipients, To, Subject, Body, DialogMode,
            showMessageDetails, showConfirmation, ConfirmationMessage, ConfirmationType, ShowMessage, Message, MessageType, TimeStamp, ParentId, EmailItem, showEmailChain, EmailChain,
            showComposeDialogNew, BodyNew, SubjectNew, ToNew } = this.state;

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            if (!this.IsUserValid) {
                window.location.href = '/login';
            }
            else {
                template = (<React.Fragment>
                    <Header />
                    <section className="containerMinHeight accountSettingContainer borderBtm">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-12 col-sm-3">
                                            <div className="accountNavigation">
                                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                    <a className={"nav-link " + (ActiveTab === 0 ? "active" : "")} id="v-pills-inbox-tab" onClick={(e) => { if (ActiveTab !== 0) { this.handleTabChange(0); } }}>Inbox</a>
                                                    {/* <a className={"nav-link " + (ActiveTab === 1 ? "active" : "")} id="v-pills-starred-tab" onClick={(e) => { if (ActiveTab !== 1) { this.handleTabChange(1); } }}>Starred</a> */}
                                                    <a className={"nav-link " + (ActiveTab === 2 ? "active" : "")} id="v-pills-archived-tab" onClick={(e) => { if (ActiveTab !== 2) { this.handleTabChange(2); } }}>Archived</a>
                                                    <a className={"nav-link " + (ActiveTab === 3 ? "active" : "")} id="v-pills-sent-tab" onClick={(e) => { if (ActiveTab !== 3) { this.handleTabChange(3); } }}>Sent</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-9">
                                            <div className="tab-content" id="v-pills-tabContent">
                                                {!showMessageDetails ? (<React.Fragment>
                                                    <div className={"tab-pane fade " + (ActiveTab === 0 ? "show active" : "")} id="v-pills-inbox">
                                                        {ActiveTab !== 0 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-2">
                                                                                <div className="headingTxt">Inbox</div>
                                                                            </div>
                                                                            <div className="col-12 col-sm-7">
                                                                                <div className="form-group">
                                                                                    <input type='text' id='search' name="search" className="form-control" onChange={(e) => { this.setState({ SearchText: e.target.value }); }} placeholder="Search messages by keyword in sender name, subject or body" onKeyDown={(e) => { this.handleKeyDown(e); }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-2">
                                                                                <div className="form-group">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSearch(); }}>Search</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-1">
                                                                                <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showComposeDialog: true }); }}><AddIcon /></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <React.Fragment>
                                                                            {showDataProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<Typography variant="body2" className="mt-2">There are no messages in your inbox.</Typography>) : (
                                                                                    <React.Fragment>
                                                                                        {FilteredData.map((item, indx) => {
                                                                                            let days = this.getDays(item.SentOn);
                                                                                            let timeStamp = '';
                                                                                            timeStamp = (item.SentOn.getFullYear() !== new Date().getFullYear() ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.MMMyyyy, ' ')) : (days > 0 ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.ddMMM, '-')) : (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.hhmm, ':'))));
                                                                                            let userName = item.SenderProfile.ProfileRole === Constants.UserRoles.Pro ? item.SenderProfile.BusinessName : item.SenderProfile.User.DisplayName;

                                                                                            return (<div className={"tabPanel " + (indx === 0 ? "mt-2" : "mt-1")}>
                                                                                                <div className="col-12 col-sm-12 message">
                                                                                                    <div className="row" >
                                                                                                        <div className="col-2 col-sm-1 align-self-center">
                                                                                                            <Avatar alt={userName} src={item.SenderProfile.ProfileImage ? item.SenderProfile.ProfileImage : '/images/profile.png'} sx={{ width: 30, height: 30 }} variant="circular"></Avatar>
                                                                                                        </div>
                                                                                                        <div className="col-6 col-sm-8 p-0 align-self-center">
                                                                                                            <div className="row" onClick={(e) => { this.viewMessage(item); }}>
                                                                                                                <div className="col-12 col-sm-4">
                                                                                                                    <Typography variant="subtitle1">{userName}</Typography>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-sm-8">
                                                                                                                    {item.Status === 'NEW' || item.Status === 'UNREAD' ? (<Typography variant="caption" className="truncate" sx={{ fontWeight: 600 }}>{item.Subject} </Typography>) : (<Typography variant="caption" className="truncate">{item.Subject}</Typography>)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-4 col-sm-3">
                                                                                                            <div className="text-right">
                                                                                                                <Typography variant="caption" className="commentBody">{timeStamp}</Typography><IconButton onClick={(e) => { this.handleArchiveMessage(item); }} title="Archive" className="ml-2"><Archive /></IconButton><IconButton onClick={(e) => { this.replyMessage(item); }} disabled={!(this.OpenToAll === "1" || (this.AppRole === Constants.UserRoles.Pro && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) || this.AppRole === Constants.UserRoles.Customer || this.AppRole === Constants.UserRoles.Admin)} title={this.OpenToAll === "1" || (this.AppRole === Constants.UserRoles.Pro && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) || this.AppRole === Constants.UserRoles.Customer || this.AppRole === Constants.UserRoles.Admin ? "Reply" : "Upgrade to Premium subscription to start a conversation with the sender."} className="ml-1"><Reply /></IconButton>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>);
                                                                                        })}
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </React.Fragment>)}
                                                                        </React.Fragment>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 1 ? "show active" : "")} id="v-pills-starred">
                                                        {ActiveTab !== 1 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-2">
                                                                                <div className="headingTxt">Starred</div>
                                                                            </div>
                                                                            <div className="col-12 col-sm-7">
                                                                                <div className="form-group">
                                                                                    <input type='text' id='search' name="search" className="form-control" onChange={(e) => { this.setState({ SearchText: e.target.value }); }} placeholder="Search messages by keyword in sender name, subject or body" onKeyDown={(e) => { this.handleKeyDown(e); }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-2">
                                                                                <div className="form-group">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSearch(); }}>Search</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-1">
                                                                                <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showComposeDialog: true }); }}><AddIcon /></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <React.Fragment>
                                                                            {showDataProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<Typography variant="body2" className="mt-2">There are no starred messages.</Typography>) : (
                                                                                    <React.Fragment>
                                                                                        {FilteredData.map((item, indx) => {
                                                                                            let days = this.getDays(item.SentOn);
                                                                                            let timeStamp = '';
                                                                                            timeStamp = (item.SentOn.getYear() < new Date().getFullYear() ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.MMyyyy, ' ')) : (days > 0 ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.ddMM, ' ')) : (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.hhmm, ' '))));

                                                                                            return (<div className="tabPanel mt-2">
                                                                                                <div className="form-group">
                                                                                                    <div className="row">
                                                                                                        <div className="col-2 col-sm-1">
                                                                                                            <div className="form-group mt-3">
                                                                                                                <Avatar alt={item.SenderProfile.User.DisplayName} src={item.SenderProfile.ProfileImage ? item.SenderProfile.ProfileImage : '/images/profile.png'} variant="circular"></Avatar>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-6 col-sm-8">
                                                                                                            <div className="form-group mt-3">
                                                                                                                {item.Status === 'NEW' || item.Status === 'UNREAD' ? (item.Subject.toLowerCase().endsWith(Constants.Email.ContactUser.Subject.toLowerCase()) ? (<Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{item.Subject}</Typography>) : (<Typography variant="subtitle1" sx={{ fontWeight: 600 }}><a href="#" onClick={(e) => { this.setState({ showMessageDetails: true, EmailItem: item, From: item.SenderProfile.User.DisplayName, Subject: item.Subject, Message: item.Body }) }}>{item.SenderProfile.User.DisplayName}</a></Typography>)) : (
                                                                                                                    <Typography variant="subtitle1">{item.SenderProfile.BusinessName}</Typography>)}
                                                                                                                <Typography variant="subtitle1" className="truncate">{item.Subject}</Typography>
                                                                                                                <Typography variant="subtitle2" color="text.secondary" className="commentBody truncate"><div dangerouslySetInnerHTML={{ __html: item.Body }}></div></Typography>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-4 col-sm-3">
                                                                                                            <div className="form-group mt-3 text-right">
                                                                                                                <Typography variant="caption" className="commentBody">{timeStamp}</Typography><IconButton onClick={(e) => { this.handleArchiveMessage(item); }} title="Archive" className="ml-2"><Archive /></IconButton><IconButton onClick={(e) => { this.replyMessage(item); }} disabled={!(this.OpenToAll === "1" || (this.AppRole === Constants.UserRoles.Pro && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) || this.AppRole === Constants.UserRoles.Customer || this.AppRole === Constants.UserRoles.Admin)} title={this.OpenToAll === "1" || (this.AppRole === Constants.UserRoles.Pro && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) || this.AppRole === Constants.UserRoles.Customer || this.AppRole === Constants.UserRoles.Admin ? "Reply" : "Upgrade to Premium subscription to start a conversation with the sender."} className="ml-1"><Reply /></IconButton>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>);
                                                                                        })}
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </React.Fragment>)}
                                                                        </React.Fragment>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 2 ? "show active" : "")} id="v-pills-archived">
                                                        {ActiveTab !== 2 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-2">
                                                                                <div className="headingTxt">Archived</div>
                                                                            </div>
                                                                            <div className="col-12 col-sm-7">
                                                                                <div className="form-group">
                                                                                    <input type='text' id='search' name="search" className="form-control" onChange={(e) => { this.setState({ SearchText: e.target.value }); }} placeholder="Search messages by keyword in sender name, subject or body" onKeyDown={(e) => { this.handleKeyDown(e); }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-2">
                                                                                <div className="form-group">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSearch(); }}>Search</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-1">
                                                                                <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showComposeDialog: true }); }}><AddIcon /></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <React.Fragment>
                                                                            {showDataProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<Typography variant="body2" className="mt-2">There are no archived messages.</Typography>) : (
                                                                                    <React.Fragment>
                                                                                        {FilteredData.map((item, indx) => {
                                                                                            let days = this.getDays(item.SentOn);
                                                                                            let timeStamp = '';
                                                                                            timeStamp = (item.SentOn.getFullYear() !== new Date().getFullYear() ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.MMMyyyy, ' ')) : (days > 0 ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.ddMMM, '-')) : (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.hhmm, ':'))));
                                                                                            let userName = item.SenderProfile.ProfileRole === Constants.UserRoles.Pro ? item.SenderProfile.BusinessName : item.SenderProfile.User.DisplayName;

                                                                                            return (<div className={"tabPanel " + (indx === 0 ? "mt-2" : "mt-1")}>
                                                                                                <div className="col-12 col-sm-12 message">
                                                                                                    <div className="row" >
                                                                                                        <div className="col-2 col-sm-1 align-self-center">
                                                                                                            <Avatar alt={userName} src={item.SenderProfile.ProfileImage ? item.SenderProfile.ProfileImage : '/images/profile.png'} sx={{ width: 30, height: 30 }} variant="circular"></Avatar>
                                                                                                        </div>
                                                                                                        <div className="col-6 col-sm-8 p-0 align-self-center">
                                                                                                            <div className="row" onClick={(e) => { this.viewMessage(item); }}>
                                                                                                                <div className="col-12 col-sm-4">
                                                                                                                    <Typography variant="subtitle1">{userName}</Typography>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-sm-8">
                                                                                                                    {item.Status === 'NEW' || item.Status === 'UNREAD' ? (<Typography variant="subtitle1" className="truncate" sx={{ fontWeight: 600 }}>{item.Subject}</Typography>) : (<Typography variant="subtitle1" className="truncate">{item.Subject}</Typography>)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-4 col-sm-3">
                                                                                                            <div className="text-right">
                                                                                                                <Typography variant="caption" className="commentBody">{timeStamp}</Typography><IconButton onClick={(e) => { this.handleMoveMessage(item); }} title="Move" className="ml-2"><MoveUp /></IconButton>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>);
                                                                                        })}
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </React.Fragment>)}
                                                                        </React.Fragment>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                    <div className={"tab-pane fade " + (ActiveTab === 3 ? "show active" : "")} id="v-pills-sent">
                                                        {ActiveTab !== 3 ? (null) : (<div className="profileContainer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="formContainer">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-2">
                                                                                <div className="headingTxt">Sent</div>
                                                                            </div>
                                                                            <div className="col-12 col-sm-7">
                                                                                <div className="form-group">
                                                                                    <input type='text' id='search' name="search" className="form-control" onChange={(e) => { this.setState({ SearchText: e.target.value }); }} placeholder="Search messages by keyword in sender name, subject or body" onKeyDown={(e) => { this.handleKeyDown(e); }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-2">
                                                                                <div className="form-group">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.handleSearch(); }}>Search</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-1">
                                                                                <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                    <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showComposeDialog: true }); }}><AddIcon /></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <React.Fragment>
                                                                            {showDataProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                {!FilteredData || FilteredData.length <= 0 ? (<Typography variant="body2" className="mt-2">There are no sent messages.</Typography>) : (
                                                                                    <React.Fragment>
                                                                                        {FilteredData.map((item, indx) => {
                                                                                            let days = this.getDays(item.SentOn);
                                                                                            let timeStamp = '';
                                                                                            timeStamp = (item.SentOn.getFullYear() !== new Date().getFullYear() ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.MMMyyyy, ' ')) : (days > 0 ? (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.ddMMM, '-')) : (this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.hhmm, ':'))));
                                                                                            let userName = item.ReceiverProfile.ProfileRole === Constants.UserRoles.Pro ? item.ReceiverProfile.BusinessName : item.ReceiverProfile.User.DisplayName;

                                                                                            return (<div className={"tabPanel " + (indx === 0 ? "mt-2" : "mt-1")}>
                                                                                                <div className="col-12 col-sm-12 message">
                                                                                                    <div className="row" >
                                                                                                        <div className="col-2 col-sm-1 align-self-center">
                                                                                                            <Avatar alt={userName} src={item.ReceiverProfile.ProfileImage ? item.ReceiverProfile.ProfileImage : '/images/profile.png'} sx={{ width: 30, height: 30 }} variant="circular"></Avatar>
                                                                                                        </div>
                                                                                                        <div className="col-6 col-sm-8 p-0 align-self-center">
                                                                                                            <div className="row" onClick={(e) => { this.viewMessage(item); }}>
                                                                                                                <div className="col-12 col-sm-5">
                                                                                                                    <Typography variant="subtitle1">To: {userName}</Typography>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-sm-7">
                                                                                                                    <Typography variant="caption" className="truncate">{item.Subject}</Typography>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-4 col-sm-3">
                                                                                                            <div className="text-right">
                                                                                                                <Typography variant="caption" className="commentBody">{timeStamp}</Typography>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>);
                                                                                        })}
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </React.Fragment>)}
                                                                        </React.Fragment>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>
                                                </React.Fragment>) : (<React.Fragment>
                                                    <div className="profileContainer">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="formContainer messageCard">
                                                                    <div className="row">
                                                                        <div className="col-2 col-sm-1">
                                                                            <IconButton onClick={(e) => { this.resetMessage(); this.handleTabChange(ActiveTab); }}><ArrowBackIcon /></IconButton>
                                                                        </div>
                                                                        <div className="col-10 col-sm-9 align-self-center">
                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{Subject}</Typography>
                                                                        </div>
                                                                        <div className="col-12 col-sm-2 align-self-center">
                                                                            <div className="text-right">
                                                                                <Typography variant="caption" className="commentBody">{TimeStamp}</Typography>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-1">
                                                                        <div className="col-2 col-sm-1 pr-0 pl-4">
                                                                            <Avatar alt={From.ProfileRole === Constants.UserRoles.Pro ? From.BusinessName : From.User.DisplayName} src={From.ProfileImage ? From.ProfileImage : '/images/profile.png'} sx={{ width: 30, height: 30 }} variant="circular"></Avatar>
                                                                        </div>
                                                                        <div className="col-8 col-sm-9 pl-0">
                                                                            <Typography variant="subtitle2">{From.ProfileRole === Constants.UserRoles.Pro ? From.BusinessName : From.User.DisplayName} <Typography variant="caption" color="text.secondary" className="commentBody">{"<" + From.User.Email + ">"}</Typography></Typography>
                                                                            <div>
                                                                                <Typography variant="subtitle2">To: {EmailItem.ReceiverProfile.ProfileRole === Constants.UserRoles.Pro ? EmailItem.ReceiverProfile.BusinessName : EmailItem.ReceiverProfile.User.DisplayName} <Typography variant="caption" color="text.secondary" className="commentBody">{"<" + EmailItem.ReceiverProfile.User.Email + ">"}</Typography></Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2 col-sm-2">
                                                                            <div className="formButtonContainer text-right mt-0 mb-0">
                                                                                <IconButton onClick={(e) => { this.replyMessageNew(EmailItem); }} disabled={!(this.OpenToAll === "1" || (this.AppRole === Constants.UserRoles.Pro && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) || this.AppRole === Constants.UserRoles.Customer || this.AppRole === Constants.UserRoles.Admin)} title={this.OpenToAll === "1" || (this.AppRole === Constants.UserRoles.Pro && this.User.Profile.ActiveSubscription === Constants.Subscriptions.Premium) || this.AppRole === Constants.UserRoles.Customer || this.AppRole === Constants.UserRoles.Admin ? "Reply" : "Upgrade to Premium subscription to start a conversation with the sender."} className="ml-1"><Reply /></IconButton>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <Typography variant="subtitle2" sx={{ whiteSpace: "pre-wrap" }}><div dangerouslySetInnerHTML={{ __html: Body }}></div></Typography>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="form-group mt-1">
                                                                        <Attachment /> {FileName}
                                                                    </div> */}
                                                                    {ParentId && Number(ParentId) > 0 && EmailChain && EmailChain.length > 0 ? (<React.Fragment>
                                                                        <div className="col-12 col-sm-12">
                                                                            <IconButton onClick={(e) => { if (!showEmailChain) { this.showEmailChain(); } else { this.hideEmailChain(); } }}><MoreHorizIcon /></IconButton>
                                                                        </div>
                                                                        {showEmailChain ? (
                                                                            <React.Fragment>
                                                                                {EmailChain && EmailChain.map((item, indx) => {
                                                                                    let timeStamp = this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.ddMMMyyyy, ' ') + ' ' + this.getFormattedDate(item.SentOn, Constants.IDateTimeFormat.hhmm, ':');

                                                                                    return (<div className="col-12 col-sm-12">
                                                                                        <hr />
                                                                                        <div className="row">
                                                                                            <div className="col-2 col-sm-1">
                                                                                                <Typography variant="caption" sx={{ fontWeight: 600 }}>From: </Typography>
                                                                                            </div>
                                                                                            <div className="col-10 col-sm-11">
                                                                                                <Typography variant="subtitle2">{item.SenderProfile.ProfileRole === Constants.UserRoles.Pro ? item.SenderProfile.BusinessName : item.SenderProfile.User.DisplayName} <Typography variant="caption" color="text.secondary" className="commentBody">{"<" + item.SenderProfile.User.Email + ">"}</Typography></Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-2 col-sm-1">
                                                                                                <Typography variant="caption" sx={{ fontWeight: 600 }}>Sent: </Typography>
                                                                                            </div>
                                                                                            <div className="col-10 col-sm-11">
                                                                                                <Typography variant="subtitle2">{timeStamp}</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-2 col-sm-1">
                                                                                                <Typography variant="caption" sx={{ fontWeight: 600 }}>To: </Typography>
                                                                                            </div>
                                                                                            <div className="col-10 col-sm-11">
                                                                                                <Typography variant="subtitle2">{item.ReceiverProfile.ProfileRole === Constants.UserRoles.Pro ? item.ReceiverProfile.BusinessName : item.ReceiverProfile.User.DisplayName} <Typography variant="caption" color="text.secondary" className="commentBody">{"<" + item.ReceiverProfile.User.Email + ">"}</Typography></Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-2 col-sm-1">
                                                                                                <Typography variant="caption" sx={{ fontWeight: 600 }}>Subject: </Typography>
                                                                                            </div>
                                                                                            <div className="col-10 col-sm-11">
                                                                                                <Typography variant="subtitle2">{item.Subject}</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-12 mt-3">
                                                                                                <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-wrap' }}>
                                                                                                    <div dangerouslySetInnerHTML={{ __html: item.Body }}></div>
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>);
                                                                                })}
                                                                            </React.Fragment>
                                                                        ) : (null)}
                                                                    </React.Fragment>) : (null)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>)}
                                                {!showComposeDialog ? (null) : (
                                                    <React.Fragment>
                                                        <Dialog fullWidth={true} open={showComposeDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                            <DialogTitle id={"detailsDialog"} className="modalTitle">{DialogMode === 'R' ? 'Reply' : 'Compose message'}
                                                                <IconButton aria-label="close" onClick={(e) => { this.resetMessage(); }} className="btnCloseDialog" disabled={showDialogProgress} >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </DialogTitle>
                                                            <DialogContent dividers={true}>
                                                                <div className="profileContainer">
                                                                    <div className="formContainer">
                                                                        <div className="col-12 col-sm-12 p-0">
                                                                            <div className="minHeightDialogContainer">
                                                                                {showDialogProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                    <div className="form-group">
                                                                                        <label>To</label>
                                                                                        {DialogMode === 'R' ? (<div className="row" >
                                                                                            <div className="col-2 col-sm-1 align-self-center">
                                                                                                <Avatar alt={To.ProfileRole === Constants.UserRoles.Pro ? To.BusinessName : To.User.DisplayName} src={To.ProfileImage ? To.ProfileImage : '/images/profile.png'} sx={{ width: 30, height: 30 }} variant="circular"></Avatar>
                                                                                            </div>
                                                                                            <div className="col-10 col-sm-11 align-self-center">
                                                                                                <Typography variant="subtitle2">{To.ProfileRole === Constants.UserRoles.Pro ? To.BusinessName : To.User.DisplayName} <Typography variant="caption" color="text.secondary" className="commentBody">{"<" + To.User.Email + ">"}</Typography></Typography>
                                                                                            </div>
                                                                                        </div>) : (<React.Fragment>
                                                                                            <Autocomplete multiple={false} id="to" className="autoComplete" options={Recipients && Recipients.length > 0 ? Recipients : []} getOptionLabel={(option) => { return (option && option.text ? option.text : ''); }}
                                                                                                onChange={(e, newVal) => { this.setState({ SelectedTo: newVal ? newVal : null, To: newVal ? newVal.Profile : null }); }} renderOption={(props, option) => (
                                                                                                    <Box component="li" {...props}>
                                                                                                        <Avatar alt={option.text} src={option.Profile.ProfileImage ? option.Profile.ProfileImage : '/images/profile.png'} variant="circular" /><Typography variant="subtitle2" className="ml-2">{option.text}</Typography>
                                                                                                    </Box>
                                                                                                )} defaultValue={SelectedTo} filterSelectedOptions={true} renderInput={(params) => (<TextField name="to" {...params} label="" placeholder="" />)} />
                                                                                            <Validator id="valTo" type='required' cssClass="error" errorMessage="Select a recipient." controlId="to" valueToValidate={SelectedTo ? '1' : ''} formValidators={this.formValidators}></Validator>
                                                                                        </React.Fragment>)}
                                                                                    </div>
                                                                                    <div className="form-group mt-3">
                                                                                        <label>Subject</label>
                                                                                        {DialogMode === 'R' ? (<Typography variant="subtitle2">{Subject}</Typography>) : (<React.Fragment>
                                                                                            <input id="subject" name="subject" key="subject" type="text" className="form-control" placeholder="" defaultValue={Subject} onBlur={(e) => { this.setState({ Subject: e.target.value }); }} />
                                                                                            <Validator id="valSubject" type='required' cssClass="error" errorMessage="Provide subject." controlId="subject" valueToValidate={Subject} formValidators={this.formValidators}></Validator>
                                                                                            <Validator id="valSubjectMaxLength" type='max_length' cssClass="error" errorMessage="Subject cannot exceed 100 characters." maxLength={100} controlId="subject" valueToValidate={Subject} formValidators={this.formValidators}></Validator>
                                                                                        </React.Fragment>)}
                                                                                    </div>
                                                                                    <div className="form-group mt-3">
                                                                                        <label>Message</label>
                                                                                        {/* <ThemeProvider theme={myTheme}>
                                                                                                <MUIRichTextEditor id="message" key="message" controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "numberList", "bulletList"]} maxLength={1000} defaultValue={Message}
                                                                                                    onChange={(state) => { this.setState({ Message: JSON.stringify(convertToRaw(state.getCurrentContent())) }); }} />
                                                                                            </ThemeProvider> */}
                                                                                        <textarea id="message" name="message" key="message" rows={6} className="form-control" placeholder="" defaultValue={Body} onBlur={(e) => { this.setState({ Body: e.target.value }); }} />
                                                                                        <Validator id="valMessage" type='required' cssClass="error" errorMessage="Provide message." controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                        <Validator id="valMessageLength" type='max_length' cssClass="error" errorMessage="Message cannot exceed 1000 characters." maxLength={1000} controlId="message" valueToValidate={Body} formValidators={this.formValidators}></Validator>
                                                                                    </div>
                                                                                    {/* <div className="form-group">
                                                                                            <label>Attachment</label>
                                                                                            <div>
                                                                                                <input id="fAttach" name="fAttach" type="file" key={'att_' + FileName} disabled={FileName !== null && FileName !== ''} className="inputfile" multiple={false} onChange={(e) => { if (e.target.files && e.target.files.length > 0) { let file: any = e.target.files[0]; this.setState({ File: file, FileName: file.name }); e.target.value = ''; } }} />
                                                                                                <label htmlFor="fAttach">
                                                                                                    <span aria-disabled={FileName !== null && FileName !== ''} className="btn btn-primary cutmBtnLogin">Choose file</span>
                                                                                                </label>
                                                                                                <div>
                                                                                                    <Validator id="valfAttachFileNameLength" type='max_length' maxLength={50} cssClass="error" errorMessage="File name (including extension) cannot exceed 50 characters." controlId="fAttach" valueToValidate={FileName} formValidators={this.formValidators}></Validator>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {FileName ? (
                                                                                            <div className="form-group mt-1">
                                                                                                <DeleteIcon onClick={(e) => { this.setState({ File: null, FileName: '' }); }} /> {FileName}
                                                                                            </div>) : (null)} */}
                                                                                </React.Fragment>)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="form-group text-right">
                                                                        <div className="formButtonContainer mt-3">
                                                                            <button type="submit" className="btn btn-cancel" disabled={showDialogProgress} onClick={(e) => { this.resetMessage(); }}>Cancel</button>
                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin ml-2" disabled={showDialogProgress} onClick={(e) => { this.handleSaveClick(); }}>Send</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </React.Fragment>
                                                )}
                                                {!showComposeDialogNew ? (null) : (
                                                    <React.Fragment>
                                                        <Dialog fullWidth={true} open={showComposeDialogNew} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="detailsDialog" >
                                                            <DialogTitle id={"detailsDialog"} className="modalTitle">{DialogMode === 'R' ? 'Reply' : 'Compose message'}
                                                                <IconButton aria-label="close" onClick={(e) => { this.resetMessageNew(); }} className="btnCloseDialog" disabled={showDialogProgress} >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </DialogTitle>
                                                            <DialogContent dividers={true}>
                                                                <div className="profileContainer">
                                                                    <div className="formContainer">
                                                                        <div className="col-12 col-sm-12 p-0">
                                                                            <div className="minHeightDialogContainer">
                                                                                {showDialogProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                    <div className="form-group">
                                                                                        <label>To</label>
                                                                                        {DialogMode === 'R' ? (<div className="row" >
                                                                                            <div className="col-2 col-sm-1 align-self-center">
                                                                                                <Avatar alt={ToNew.ProfileRole === Constants.UserRoles.Pro ? ToNew.BusinessName : ToNew.User.DisplayName} src={ToNew.ProfileImage ? ToNew.ProfileImage : '/images/profile.png'} sx={{ width: 30, height: 30 }} variant="circular"></Avatar>
                                                                                            </div>
                                                                                            <div className="col-10 col-sm-11 align-self-center">
                                                                                                <Typography variant="subtitle2">{ToNew.ProfileRole === Constants.UserRoles.Pro ? ToNew.BusinessName : ToNew.User.DisplayName} <Typography variant="caption" color="text.secondary" className="commentBody">{"<" + ToNew.User.Email + ">"}</Typography></Typography>
                                                                                            </div>
                                                                                        </div>) : (<React.Fragment>
                                                                                            <Autocomplete multiple={false} id="to" className="autoComplete" options={Recipients && Recipients.length > 0 ? Recipients : []} getOptionLabel={(option) => { return (option && option.text ? option.text : ''); }}
                                                                                                onChange={(e, newVal) => { this.setState({ SelectedTo: newVal ? newVal : null, ToNew: newVal ? newVal.Profile : null }); }} renderOption={(props, option) => (
                                                                                                    <Box component="li" {...props}>
                                                                                                        <Avatar alt={option.text} src={option.Profile.ProfileImage ? option.Profile.ProfileImage : '/images/profile.png'} variant="circular" /><Typography variant="subtitle2" className="ml-2">{option.text}</Typography>
                                                                                                    </Box>
                                                                                                )} defaultValue={SelectedTo} filterSelectedOptions={true} renderInput={(params) => (<TextField name="to" {...params} label="" placeholder="" />)} />
                                                                                            <Validator id="valTo" type='required' cssClass="error" errorMessage="Select a recipient." controlId="to" valueToValidate={SelectedTo ? '1' : ''} formValidators={this.formValidators}></Validator>
                                                                                        </React.Fragment>)}
                                                                                    </div>
                                                                                    <div className="form-group mt-3">
                                                                                        <label>Subject</label>
                                                                                        {DialogMode === 'R' ? (<Typography variant="subtitle2">{SubjectNew}</Typography>) : (<React.Fragment>
                                                                                            <input id="subject" name="subject" key="subject" type="text" className="form-control" placeholder="" defaultValue={SubjectNew} onBlur={(e) => { this.setState({ SubjectNew: e.target.value }); }} />
                                                                                            <Validator id="valSubject" type='required' cssClass="error" errorMessage="Provide subject." controlId="subject" valueToValidate={SubjectNew} formValidators={this.formValidators}></Validator>
                                                                                            <Validator id="valSubjectMaxLength" type='max_length' cssClass="error" errorMessage="Subject cannot exceed 100 characters." maxLength={100} controlId="subject" valueToValidate={SubjectNew} formValidators={this.formValidators}></Validator>
                                                                                        </React.Fragment>)}
                                                                                    </div>
                                                                                    <div className="form-group mt-3">
                                                                                        <label>Message</label>
                                                                                        <textarea id="message" name="message" key="message" rows={6} className="form-control" placeholder="" defaultValue={BodyNew} onBlur={(e) => { this.setState({ BodyNew: e.target.value }); }} />
                                                                                        <Validator id="valMessage" type='required' cssClass="error" errorMessage="Provide message." controlId="message" valueToValidate={BodyNew} formValidators={this.formValidators}></Validator>
                                                                                        <Validator id="valMessageLength" type='max_length' cssClass="error" errorMessage="Message cannot exceed 1000 characters." maxLength={1000} controlId="message" valueToValidate={BodyNew} formValidators={this.formValidators}></Validator>
                                                                                    </div>
                                                                                </React.Fragment>)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="form-group text-right">
                                                                        <div className="formButtonContainer mt-3">
                                                                            <button type="submit" className="btn btn-cancel" disabled={showDialogProgress} onClick={(e) => { this.resetMessageNew(); }}>Cancel</button>
                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin ml-2" disabled={showDialogProgress} onClick={(e) => { this.handleSaveClickNew(); }}>Send</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div >
                        </div >
                    </section >
                    <Footer />
                </React.Fragment >);
            }
        }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>ProsBE Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.state.callBackMethod(); this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null }); }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}