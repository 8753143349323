import { Alert, Snackbar } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import { Loader } from "src/common/Components";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import Validator from "src/common/Validator";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import Constants from "src/common/Constants";

const initClient = () => { gapi.client.init({ clientId: Constants.Google.ClientID, scope: 'profile email' }); }

export default class Login extends Base {

    private formValidators: Validator[];
    private validator: Validator;

    constructor(props) {
        super(props);

        this.state = { loading: true, Email: '', Password: '', RememberMe: false, Message: '', MessageType: 'success', ShowMessage: false };
        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();

        this.onGoogleSignInSuccess = this.onGoogleSignInSuccess.bind(this);
        this.onGoogleSignInError = this.onGoogleSignInError.bind(this);

        gapi.load('client:auth2', initClient);
    }

    public componentDidMount() {
        this.setState({ loading: false });
    }

    private handleLogin() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ loading: true });

            this.db.authenticateUser(this.state.Email, this.encodeString(this.state.Password)).then((result: any) => {
                if (!result || !result.IsValid || !result.Token) {
                    this.setState({ loading: false, ShowMessage: true, Message: 'The Email or Password is invalid.', MessageType: 'error' });
                }
                else {
                    let expiresOn = this.getDateNumber(result.ExpiresOn);
                    this.db.getUser(this.state.Email, this.AppRole).then((userDetails) => {
                        if (userDetails) {
                            if (userDetails.IsActive) {
                                if (this.state.RememberMe === true) {
                                    localStorage.setItem(this.encodeString("ProsBEUser_Token"), result.Token);
                                    localStorage.setItem(this.encodeString("ProsBEUser_ExpiresOn"), expiresOn);
                                    localStorage.setItem(this.encodeString("ProsBEUser_LoginName"), this.encodeString(userDetails.LoginName));
                                    localStorage.setItem(this.encodeString("ProsBEUser_Role"), this.encodeString(userDetails.DefaultRole));
                                    localStorage.setItem(this.encodeString("ProsBEUser_AppRole"), this.encodeString(userDetails.DefaultRole));
                                    localStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(JSON.stringify(userDetails)));
                                }
                                else {
                                    sessionStorage.setItem(this.encodeString("ProsBEUser_Token"), result.Token);
                                    sessionStorage.setItem(this.encodeString("ProsBEUser_ExpiresOn"), expiresOn);
                                    sessionStorage.setItem(this.encodeString("ProsBEUser_LoginName"), this.encodeString(userDetails.LoginName));
                                    sessionStorage.setItem(this.encodeString("ProsBEUser_Role"), this.encodeString(userDetails.DefaultRole));
                                    sessionStorage.setItem(this.encodeString("ProsBEUser_AppRole"), this.encodeString(userDetails.DefaultRole));
                                    sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(JSON.stringify(userDetails)));
                                }
                            }
                            else {
                                window.location.href = '/reactivate';
                            }
                        }

                        if (this.query.get('src'))
                            window.location.href = this.query.get('src');
                        else
                            window.location.href = '/';
                    });
                }
            });
        }
    }

    private onGoogleSignInSuccess(googleUser) {
        if (googleUser && googleUser.profileObj) {
            this.setState({ loading: true });

            // this.db.validateGoogleUser(googleUser.tokenObj.id_token).then((validation) => {
            //     if (validation && new Date(Number(validation.exp)) > new Date()) {
            this.db.authenticateGoogleUser(googleUser.profileObj.email, googleUser.tokenObj.id_token).then((result: any) => {
                if (!result || !result.IsValid || !result.Token) {
                    this.setState({ loading: false, ShowMessage: true, Message: 'The user is invalid.', MessageType: 'error' });
                }
                else {
                    let expiresOn = this.getDateNumber(result.ExpiresOn);

                    sessionStorage.setItem(this.encodeString("ProsBEUser_Token"), result.Token);
                    sessionStorage.setItem(this.encodeString("ProsBEUser_ExpiresOn"), expiresOn);

                    this.db.getUser(googleUser.profileObj.email, this.AppRole).then((userDetails) => {
                        if (userDetails) {
                            sessionStorage.setItem(this.encodeString("ProsBEUser_LoginName"), this.encodeString(userDetails.LoginName));
                            sessionStorage.setItem(this.encodeString("ProsBEUser_Role"), this.encodeString(userDetails.DefaultRole));
                            sessionStorage.setItem(this.encodeString("ProsBEUser_AppRole"), this.encodeString(userDetails.DefaultRole));
                            sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(JSON.stringify(userDetails)));

                            sessionStorage.setItem(this.encodeString("ProsBE_GoogleUser"), this.encodeString(JSON.stringify(googleUser)));
                        }

                        if (this.query.get('src'))
                            window.location.href = this.query.get('src');
                        else
                            window.location.href = '/';
                    });
                }
            });
            //     }
            //     else {
            //         this.setState({ loading: false, ShowMessage: true, Message: 'The user is invalid.', MessageType: 'error' });
            //     }
            // });
        }
    }

    private onGoogleSignInError(error) {
        this.setState({ loading: false, ShowMessage: true, Message: 'There is some issue in user validation. Please try again later.', MessageType: 'error' });
    }

    private handleKeyDown(e, src) {
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();

            if (src === 'E')
                this.setState({ Email: e.target.value }, () => { this.handleLogin() });
            else if (src === 'P')
                this.setState({ Password: e.target.value }, () => { this.handleLogin() });
        }
    }

    public render() {

        const { loading, Email, Password, ShowMessage, Message, MessageType } = this.state;
        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {

            template = (<React.Fragment>
                <Header />
                <section className="containerMinHeight loginContainer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="formContainer">
                                    {this.IsUserValid ? (<h3>You are already logged in</h3>) : (<React.Fragment>
                                        <h3>Log in</h3>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input id="email" name="email" type="email" className="form-control" placeholder="" defaultValue={Email} onBlur={(e) => { this.setState({ Email: e.target.value }); }} onKeyDown={(e) => { this.handleKeyDown(e, 'E'); }} />
                                            <Validator id="valEmail" type='required' cssClass="error" errorMessage="Provide Email." controlId="email" valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                            <Validator id="valEmailFormat" type='email' cssClass="error" controlId="email" errorMessage="Invalid email." valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="pass">Password</label>
                                            <input id="pass" name="pass" type="password" className="form-control" placeholder="" defaultValue={Password} onBlur={(e) => { this.setState({ Password: e.target.value }); }} onKeyDown={(e) => { this.handleKeyDown(e, 'P'); }} />
                                            <Validator id="valPass" type='required' cssClass="error" controlId="pass" errorMessage="Provide Password." valueToValidate={Password} formValidators={this.formValidators}></Validator>
                                        </div>
                                        <div className="form-check">
                                            <input name="remember" type="checkbox" className="form-check-input" onChange={(e) => { this.setState({ RememberMe: e.target.checked }); }} />
                                            <div className="d-flex justify-content-between pt-1">
                                                <label htmlFor="remember" className="form-check-label">Remember me</label>
                                                <span><a href="/passreset" className="forgotPass">Forgot password?</a></span>
                                            </div>
                                        </div>
                                        <div className="formButtonContainer">
                                            <button type="submit" className="btn btn-primary cutmBtnLogin w-100" onClick={(e) => { this.handleLogin(); }}>Log in</button>
                                            <div className="mt-3">
                                                <GoogleLogin
                                                    clientId={Constants.Google.ClientID} buttonText="Sign in with Google" onSuccess={this.onGoogleSignInSuccess}
                                                    onFailure={this.onGoogleSignInError} cookiePolicy={'single_host_origin'} theme="dark" />
                                            </div>
                                        </div>
                                        <div className="signUpLink">Don’t have an account? <a href="/users/new?m=cust">Sign up</a>.</div>
                                    </React.Fragment>)}
                                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                                        <Alert severity={MessageType} sx={{ width: '100%' }}>
                                            {Message}
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>);
        }

        return template;
    }
}