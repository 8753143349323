import Constants from 'src/common/Constants';
//import { loadStripe } from '@stripe/stripe-js'
//const stripe = require('stripe');

// const objStripe = new stripe(Constants.Stripe.SecretKey, {
//     apiVersion: "2022-08-01"
// });

import Axios, { AxiosRequestConfig } from 'axios';

export default class Stripes {
    constructor() {
        //loadStripe(Constants.Stripe.PublishKey, { stripeAccount: Constants.Stripe.Account });
    }

    private restConfig: AxiosRequestConfig = {
        headers: {
            "Authorization": "Bearer " + Constants.Stripe.SecretKey,
            'stripe-version': '2020-08-27',
            'access-control-allow-credentials': true,
            'Content-Type': 'application/json',
            'access-control-allow-origin': "*"
        }
    };

    private restPOSTConfig: AxiosRequestConfig = {
        headers: {
            "Authorization": "Bearer " + Constants.Stripe.SecretKey,
            'Content-Type': 'application/x-www-form-urlencoded',
            'access-control-allow-origin': "*"
        }
    };

    private servicePath = "https://api.stripe.com/v1";

    public getProduct(prodId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/products/" + prodId, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getPrice(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/prices/" + id, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public createCustomer(name, email, description, country, state, city, zip, address1): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params = { name: name, email: email, description: description };

                Axios.post(this.servicePath + "/customers?name=" + name + "&email=" + email + "&description=" + description + "&address[country]=" + country + "&address[state]=" + state + "&address[city]=" + city + "&address[postal_code]=" + zip + "&address[line1]=" + address1, params, this.restPOSTConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public createPaymentMethod(pType, cardNum?: string, expMonth?, expYear?, cvv?, customerId?, isDefault?): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params: any = {
                    type: pType,// 'card'                    
                };

                let url = "/payment_methods?type=" + pType;

                if (cardNum && expMonth && expYear && cvv) {
                    params.card = {
                        number: cardNum,//'4242424242424242',
                        exp_month: expMonth,// 10,
                        exp_year: expYear,// 2023,
                        cvc: cvv//'314',
                    };

                    url += "&card[number]=" + cardNum + "&card[exp_month]=" + expMonth + "&card[exp_year]=" + expYear + "&card[cvc]=" + cvv;
                }

                Axios.post(this.servicePath + url, params, this.restPOSTConfig).then((response: any) => {
                    if (response) {
                        this.attachPaymentMethod(customerId, response.data.id).then((paymentMethod) => {
                            if (isDefault) {
                                this.updateCustomerDefaultPaymentMethod(customerId, paymentMethod.id).then((customer) => {
                                    if (customer) {
                                        resolve(paymentMethod);
                                    }
                                    else {
                                        resolve(null);
                                    }
                                });
                            }
                            else {
                                resolve(paymentMethod);
                            }
                        });
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public attachPaymentMethod(customerId, paymentMethodId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params = { customer: customerId };

                Axios.post(this.servicePath + "/payment_methods/" + paymentMethodId + "/attach?customer=" + customerId, params, this.restPOSTConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public updateCustomerDefaultPaymentMethod(customerId, paymentMethodId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params = { invoice_settings: { default_payment_method: paymentMethodId } };

                Axios.post(this.servicePath + "/customers/" + customerId + "?invoice_settings[default_payment_method]=" + paymentMethodId, params, this.restPOSTConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public createSubscription(customerId, priceId, quantity, defaultPaymentMethod?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params: any = {
                    customer: customerId, description: "ProsBE Premium Subscription", items: [{ price: priceId, quantity: quantity }], payment_behavior: 'default_incomplete', //trial_period_days: 30,
                    payment_settings: { save_default_payment_method: 'on_subscription' }, expand: ['latest_invoice.payment_intent', 'pending_setup_intent']
                };

                if (defaultPaymentMethod)
                    params.default_payment_method = defaultPaymentMethod;

                Axios.post(this.servicePath + "/subscriptions?customer=" + customerId + "&description=ProsBE Premium Subscription&items[0][price]=" + priceId + "&items[0][quantity]=" + quantity + "&payment_behavior=default_incomplete&payment_settings[save_default_payment_method]=on_subscription&expand[]=latest_invoice.payment_intent&expand[]=pending_setup_intent", params, this.restPOSTConfig).then((response: any) => { //&trial_period_days=30
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public createPaymentIntent(amount, customerId, desc, currency, email, paymentMethod): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params: any = {
                    amount, description: desc, currency,
                    setup_future_usage: 'off_session', receipt_email: email,
                    payment_method_types: ['card'],
                };

                let url: any = "/payment_intents?amount=" + amount + "&description=" + desc + "&currency=" + currency + "&receipt_email=" + email + "&payment_method_types[]=card";
                if (customerId) {
                    params.customer = customerId;
                    url += "&customer=" + customerId;
                }

                if (paymentMethod) {
                    params.payment_method = paymentMethod.id;
                    url += "&payment_method=" + paymentMethod.id;
                }

                Axios.post(this.servicePath + url, params, this.restPOSTConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public confirmPaymentIntent(clientSecret, receipt_email, payment_method): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params = { receipt_email, off_session: true, payment_method, setup_future_usage: 'off_session' };

                Axios.post(this.servicePath + "/payment_intents/" + clientSecret + "/confirm?payment_method=&off_session=true&setup_future_usage=off_session&receipt_email=" + receipt_email, params, this.restPOSTConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getSubscriptionById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/subscriptions/" + id, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCustomerPaymentMethods(customerId, type): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/payment_methods?type=" + type + "&customer=" + customerId, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getAllPaymentMethods(type): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/payment_methods?type=" + type, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getPaymentMethodById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/payment_methods/" + id, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCustomerById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/customers/" + id, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public deleteCustomer(customerId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.delete(this.servicePath + "/customers/" + customerId, this.restPOSTConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public cancelSubscription(subscriptionId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.delete(this.servicePath + "/subscriptions/" + subscriptionId, this.restPOSTConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public detachPaymentMethod(paymentMethodId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params = { paymentMethodId: paymentMethodId };

                Axios.post(this.servicePath + "/payment_methods/" + paymentMethodId + "/detach", params, this.restPOSTConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCustomerInvoices(customerId, status?): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let url = "/invoices?customer=" + customerId;
                if (status) {
                    url += "&status=" + status;
                }

                Axios.get(this.servicePath + url, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCustomerPayments(customerId, status?): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let url = "/payment_intents?customer=" + customerId + "&limit=100";
                if (status) {
                    url += "&status=" + status;
                }

                Axios.get(this.servicePath + url, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCustomerSubscriptions(customerId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let url = "/subscriptions?customer=" + customerId;
                Axios.get(this.servicePath + url, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getPaymentIntent(clientSecret): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let url = "/payment_intents/" + clientSecret;

                Axios.get(this.servicePath + url, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getChargeDetails(chargeId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let url = "/charges/" + chargeId;
                Axios.get(this.servicePath + url, this.restConfig).then((response: any) => {
                    if (response) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    // public createPaymentMethod(pType, cardNum, expMonth, expYear, cvv, customerId): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.paymentMethods.create({
    //             type: pType,// 'card',
    //             customer: customerId,
    //             card: {
    //                 number: cardNum,//'4242424242424242',
    //                 exp_month: expMonth,// 10,
    //                 exp_year: expYear,// 2023,
    //                 cvc: cvv//'314',
    //             }
    //         }).then((paymentMethod) => {
    //             resolve(paymentMethod);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public createSubscription(customerId, priceId, quantity): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.subscriptions.create({ customer: customerId, items: [{ price: priceId, quantity: quantity }], collection_method: 'charge_automatically', }).then((subscription) => {
    //             resolve(subscription);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public createPaymentIntent(amount, customerId, desc, currency, paymentMethodId): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.paymentIntents.create({
    //             amount, customer: customerId, description: desc, currency,
    //             off_session: true, confirm: true, setup_future_usage: 'off_session',
    //             payment_method: paymentMethodId
    //         }).then((paymentIntent) => {
    //             resolve(paymentIntent);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public cardPayment(intentClientSecret, cardElement): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.paymentIntents.confirm(intentClientSecret, { payment_method: 'card' }).then((customer) => {
    //             resolve(customer);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public getCustomerById(id): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.customers.retrieve(id).then((customer) => {
    //             resolve(customer);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public getSubscriptionById(id): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.subscriptions.retrieve(id).then((subscription) => {
    //             resolve(subscription);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public getAllPaymentMethods(customerId, type): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.paymentMethods.list({ customer: customerId, type }).then((paymentMethods) => {
    //             resolve(paymentMethods);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public getAllSubscriptions(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.subscriptions.list().then((subscriptions) => {
    //             resolve(subscriptions);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public getPriceById(id): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.prices.retrieve(id).then((price) => {
    //             resolve(price);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public getAllPrices(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.prices.list().then((prices) => {
    //             resolve(prices);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public getAllProducts(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.products.list().then((products) => {
    //             resolve(products);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public getProductById(id): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.products.retrieve(id).then((product) => {
    //             resolve(product);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }

    // public cancelSubscription(subscriptionId): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.objStripe.subscriptions.del(subscriptionId).then((subscription) => {
    //             resolve(subscription);
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     });
    // }
}