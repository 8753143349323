import { Alert, Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, TextField, Typography } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import { ProgressBar } from "src/common/Components";
import Constants from "src/common/Constants";
import Validator from "src/common/Validator";

export default class AskForReview extends Base {
    public state: any;
    private validator: Validator;
    private formValidators: Validator[];

    private map: google.maps.Map;
    private baseMarker: google.maps.Marker;

    constructor(props) {
        super(props);

        this.state = { loading: true, Profile: null, Recipients: [], ReviewRecipients: [], showGoogleReview: false, BusinessLocation: null };
        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        this.addGoogleMapScriptTag();

        this.getCurrentLocation().then((pos) => {
            this.getUserProfile().then((profile) => {
                if (profile) {
                    let bLocation: any = profile.GoogleBusinessLocation;
                    if (bLocation)
                        bLocation = JSON.parse(bLocation);
                    else
                        bLocation = { lat: pos ? pos.lat : '', lng: pos ? pos.lng : '' };

                    this.db.getRecipients(profile.Id, 'P').then((recipients) => {
                        this.setState({ loading: false, Profile: profile, ReviewRecipients: recipients, BusinessLocation: bLocation });
                    });
                }
                else {
                    this.setState({ loading: false });
                }
            });
        });
    }

    private addGoogleMapScriptTag() {
        let scriptElems = document.getElementsByTagName("script");
        let elemExists = false;
        for (var i = 0; i < scriptElems.length; i++) {
            if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
                elemExists = true;
        }

        if (!elemExists) {
            const script = document.createElement("script");
            script.src = Constants.Google.PlacesScriptPath;
            //script.id = "scriptGoogleMaps";
            script.setAttribute('async', '');
            script.setAttribute('defer', '');

            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }

    private getUserProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.LoginName) {
                this.db.getUserProfile(this.LoginName, this.AppRole).then((profile) => {
                    resolve(profile);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private handleAskForReviewsClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid && this.state.Recipients && this.state.Recipients.length > 0) {
            let emailTemplate = Constants.Email.AskForReviews;
            let subject = emailTemplate.Subject.replace('{0}', this.state.Profile.BusinessName);
            let body = emailTemplate.Body;

            let counter = 0;

            this.state.Recipients.forEach((cust, indx) => {
                body = body.replace("[username]", cust.Profile.User.FirstName);
                body = body.replace("[businessname]", this.state.Profile.BusinessName);
                body = body.replace("[rid]", this.state.Profile.Id.toString());
                body = body.replace("[em]", this.state.Profile.User.LoginName);

                if (cust.Profile.User.Email) {
                    this.db.sendEmail(cust.Profile.User.Email, subject, body).then((emailSent) => {
                        counter++;
                        if (counter === this.state.Recipients.length) {
                            this.successMessage('Review request sent successfully.');
                            this.props.onCancel();
                        }
                    });
                }
            });

            // let _emails: string = '';
            // this.state.Recipients.forEach((cust, indx) => {
            //     if (indx < this.state.Recipients.length - 1)
            //         _emails += cust.Profile.User.Email + ",";
            //     else
            //         _emails += cust.Profile.User.Email;
            // });

            // this.db.askForReviews(_emails, this.state.ProfileId).then((asked) => {
            //     if (asked) {
            //         this.successMessage('Review request sent successfully.');
            //         this.formValidators = new Array<Validator>();
            //         this.setState({ showAskForReviewDialog: false, Recipients: [], ReviewRecipients: [] });
            //     }
            //     else {
            //         this.errorMessage('Review request could not be sent.');
            //     }
            // });
        }
    }

    private getCurrentLocation(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
                    const pos = { lat: position.coords.latitude, lng: position.coords.longitude, };

                    resolve(pos);
                }, () => {
                    resolve(null);
                });
            }
            else {
                // Browser doesn't support Geolocation
                resolve(null);
            }
        });
    }

    private initializeMap() {
        this.setState({ showGoogleReview: true }, () => {

            let latLng: google.maps.LatLng = new google.maps.LatLng(Number(this.state.BusinessLocation.lat), Number(this.state.BusinessLocation.lng));

            this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, { center: latLng, zoom: 17, mapTypeControl: false, mapTypeId: "roadmap" });
            this.baseMarker = new google.maps.Marker({ map: this.map, anchorPoint: new google.maps.Point(0, -29) });
            this.baseMarker.setPosition(latLng);

            this.initializePlacesAutoComplete();

            this.map.addListener("click", (mapsMouseEvent) => {
                if (mapsMouseEvent.placeId) {
                    this.getPlaceByPlaceId(mapsMouseEvent.placeId).then((place) => {
                        if (place) {
                            this.baseMarker.setPosition(mapsMouseEvent.latLng);

                            if (!place.geometry || !place.geometry.location) {
                                this.setState({ showConfirmation: true, ConfirmationMessage: "No details available for input: '" + place.name + "'", ConfirmationType: 'A' });
                                return;
                            }

                            let lat = place.geometry?.location?.lat();
                            let lon = place.geometry?.location?.lng();
                            let formatted_address = place?.formatted_address;
                            let placeId = place?.place_id;

                            this.setState({ BusinessLocation: { name: place?.name, lat: lat, lng: lon, formatted_address: formatted_address, placeId: placeId } });
                        }
                        else {
                            this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The selected place details could not be fetched.' });
                        }
                    });
                }
                else {
                    this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The selected place does not have a valid identity associated with it.' });
                }
            });
        });
    }

    private getPlaceByPlaceId(placeId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let placeService = new google.maps.places.PlacesService(this.map);
            placeService.getDetails({ placeId: placeId }, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                }
                else if (status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
                    resolve(null);
                }
                else {
                    resolve(undefined);
                }
            });
        });
    }

    private initializePlacesAutoComplete() {
        const input = document.getElementById("business") as HTMLInputElement;
        const autoCompleteOptions = {
            fields: ["formatted_address", "address_components", "geometry", "name", "place_id", "reviews"],
            // strictBounds: false,
            types: ["establishment"],
            componentRestrictions: { country: "us" }
        };

        const autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);

        autocomplete.addListener("place_changed", () => {
            let place: any = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "No details available for input: '" + place.name + "'", ConfirmationType: 'A' });
                return;
            }

            let lat = place.geometry?.location?.lat();
            let lon = place.geometry?.location?.lng();
            let formatted_address = place?.formatted_address;
            let placeId = place?.place_id;

            let latLng: google.maps.LatLng = new google.maps.LatLng(Number(lat), Number(lon));
            this.baseMarker.setPosition(latLng);
            this.map.setCenter(latLng);

            this.setState({ BusinessLocation: { name: place?.name, lat: lat, lng: lon, formatted_address: formatted_address, placeId: placeId } });
        });
    }

    private saveGoogleBusinessAccount() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            let businessLocation = JSON.stringify(this.state.BusinessLocation);
            this.db.updateProfileGoogleBusinessAccount(this.state.Profile.Id.toString(), businessLocation).then((updated) => {
                if (updated) {
                    this.formValidators = new Array<Validator>();
                    this.setState({ showGoogleReview: false, BusinessLocation: null });
                    this.resetMapComponents();

                    this.successMessage('Google Business Account details saved.');
                }
                else {
                    this.errorMessage('Google Business Account details could not be saved.');
                }
            });
        }
    }

    private resetMapComponents() {
        this.baseMarker.setMap(null);
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {
        const { loading, showConfirmation, ConfirmationType, ConfirmationMessage, ShowMessage, MessageType, Message,
            ReviewRecipients, Recipients, showGoogleReview, BusinessLocation } = this.state;

        let template: any = <ProgressBar />;

        if (!loading) {
            if (!this.IsUserValid) {
                window.location.href = '/login';
            }
            else {
                template = (<React.Fragment>
                    <div className="profileContainer">
                        <div className="formContainer">
                            <div className="headingTxt">Ask for Reviews</div>
                            <div className="tabPanel">
                                <div className="col-12 col-sm-12">
                                    <div className="form-group mt-3">
                                        <Button startIcon={<img src='/images/googleIcon.png' alt='Google reviews' />} disabled={showGoogleReview} onClick={(e) => { this.formValidators = new Array<Validator>(); this.initializeMap(); }}> {showGoogleReview ? "Add Google Business name to get its reviews on ProsBE" : "Add reviews from Google"}</Button>
                                    </div>
                                </div>
                                {showGoogleReview ? (<React.Fragment>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group mt-3">
                                            <label>Search your business name</label>
                                            <input key={BusinessLocation ? BusinessLocation.placeId : "businessLocation"} id="business" name="business" type="text" className="form-control" defaultValue={BusinessLocation ? BusinessLocation.name + ", " + BusinessLocation.formatted_address : ''} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group mt-3">
                                            <label>For more accuracy, you can also set your business name on the map below</label>
                                            <div><Chip label={BusinessLocation ? BusinessLocation.name + ", " + BusinessLocation.formatted_address : ''} className={"locationChip mt-1"} color="default" /></div>
                                            <Validator id="valBusiness" type="required" cssClass="error" controlId="business" errorMessage="Provide business location." valueToValidate={BusinessLocation && BusinessLocation.placeId ? '1' : ''} formValidators={this.formValidators} />
                                        </div>
                                        <div id="map" className="mapContainer mt-3">
                                        </div>
                                    </div>
                                </React.Fragment>) : (<React.Fragment>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group mt-3">
                                            <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>Get more reviews</Typography>
                                            <Typography variant="subtitle2" color="text.secondary">Invite your customers to leave reviews</Typography>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group mt-3">
                                            <Autocomplete multiple={true} id="emails" options={ReviewRecipients && ReviewRecipients.length > 0 ? ReviewRecipients : []} getOptionLabel={(option) => { return (option && option.Profile.User.DisplayName ? option.Profile.User.DisplayName : ''); }}
                                                onChange={(e, newVal) => { this.setState({ Recipients: newVal ? newVal : null }); }} defaultValue={Recipients} filterSelectedOptions={true} renderInput={(params) => (<TextField name="emails" {...params} label="" placeholder="" />)} />
                                            <Validator id="valEmails" type="required" cssClass="error" controlId="emails" errorMessage="Provide email." valueToValidate={Recipients && Recipients.length > 0 ? '1' : ''} formValidators={this.formValidators} />
                                        </div>
                                    </div>
                                </React.Fragment>)}
                                <div className="col-12 col-sm-12">
                                    <div className="formButtonContainer text-right">
                                        <button type="submit" className="btn btn-cancel" onClick={(e) => { if (showGoogleReview) { this.formValidators = new Array<Validator>(); this.setState({ showGoogleReview: false }); this.resetMapComponents(); } else { this.props.onCancel(); } }}>Cancel</button>
                                        <button type="submit" className="btn btn-primary cutmBtnLogin ml-3" onClick={(e) => { if (!showGoogleReview) { this.handleAskForReviewsClick(); } else { this.saveGoogleBusinessAccount(); } }}>{!showGoogleReview ? "Invite" : "Submit"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>);
            }
        }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}