import { Alert, Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Radio, Snackbar, TextField, Typography } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import { Loader, ProgressBar } from "src/common/Components";
import Header from "src/common/Header";
import Footer from "src/common/Footer";
import Validator from "src/common/Validator";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExploreIcon from '@mui/icons-material/Explore';
import Constants from "src/common/Constants";
import ZipCodeAPI, { ZipCodeAPIUnits } from "src/api/zipcodeapi";
// import UserAccount from "./UserAccount";
// import { LocationOnOutlined } from "@mui/icons-material";
// import InfoIcon from '@mui/icons-material/Info';
// import Constants from "src/common/Constants";

export default class Services extends Base {

    public state: any;
    private validator: Validator;
    private formValidators: Validator[];

    private map: google.maps.Map;
    private circle: google.maps.Circle;
    private infoWindow: google.maps.InfoWindow;
    private markers: google.maps.Marker[] = [];
    private baseMarker: google.maps.Marker;

    constructor(props) {
        super(props);

        this.state = {
            loading: true, Services: [], ProfileServices: [], ServiceName: '', showServiceDialog: false, DialogMode: '', ItemId: 0, ProfileId: 0,
            showConfirmation: false, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '', showNearByProgress: false, Profile: null,
            ShowMessage: false, Message: '', MessageType: 'success', TargetArea: '', Description: '', Cities: [], OldSelectedCities: [], SelectedCities: [], SelectedService: null,
            showDistanceDialog: false, Distance: '', OldDistance: '', PinAddress: '', PinPlaceId: '', Latitude: '', Longitude: '', BaseLocation: null,
            UniquePlaces: []
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        this.addGoogleMapScriptTag();

        this.db.getCategories().then((services) => {
            this.getCityMaster().then((cities) => {
                this.getUserProfile().then((profile) => {
                    this.setState({
                        Cities: cities ? cities : [], Services: services ? services : [], Profile: profile,
                        PinAddress: profile.PinAddress, Latitude: profile.Latitude, Longitude: profile.Longitude,
                        BaseLocation: {
                            name: profile.PinName, lat: profile.Latitude, lng: profile.Longitude,
                            formatted_address: profile.PinAddress, placeId: profile.PinPlaceId, pin: profile.Pin
                        }
                    }, () => {
                        this.bindServices();
                    });
                });
            });
        });
    }

    private addGoogleMapScriptTag() {
        let scriptElems = document.getElementsByTagName("script");
        let elemExists = false;
        for (var i = 0; i < scriptElems.length; i++) {
            if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
                elemExists = true;
        }

        if (!elemExists) {
            const script = document.createElement("script");
            script.src = Constants.Google.PlacesScriptPath;
            //script.id = "scriptGoogleMaps";
            script.setAttribute('async', '');
            script.setAttribute('defer', '');

            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }

    private bindServices() {
        this.getUserProfile().then((profile) => {
            if (profile) {
                this.db.getProfileServices(profile.Id).then((services) => {
                    let serviceMaster = this.state.Services;
                    let servicesUpdated = false;

                    if (services) {
                        services.forEach((ser) => {
                            if (ser.Cities) {
                                ser.Cities = JSON.parse(ser.Cities);
                            }

                            let uniqueCities: any[] = [];
                            if (ser.Cities) {
                                ser.Cities.forEach((city, indx) => {
                                    let exists = uniqueCities.find((uP) => { return (city.name === uP); });
                                    if (!exists) {
                                        uniqueCities.push(city.name);
                                    }
                                });

                                uniqueCities.sort((a, b) => {
                                    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
                                });

                                ser.UniquePlaces = uniqueCities;
                            }

                            let serviceIndex = serviceMaster && serviceMaster.findIndex((s) => { return (s.Id === ser.Category.Id); });
                            if (serviceIndex >= 0) {
                                serviceMaster.splice(serviceIndex, 1);

                                servicesUpdated = true;
                            }
                        });
                    }

                    if (servicesUpdated) {
                        this.setState({ loading: false, ProfileId: profile.Id, ProfileServices: services, Services: serviceMaster });
                    }
                    else {
                        this.setState({ loading: false, ProfileId: profile.Id, ProfileServices: services });
                    }
                });
            }
            else {
                this.setState({ loading: false });
            }
        });
    }

    private getUserProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.props.id > 0) {
                this.db.getUserProfileById(this.props.id, Constants.UserRoles.Pro).then((profile) => {
                    resolve(profile);
                });
            }
            else if (this.LoginName) {
                this.db.getUserProfile(this.LoginName, this.AppRole).then((profile) => {
                    resolve(profile);
                });
            }
            else {
                resolve(null);
            }
        });
    }

    private handleEditService(service) {
        this.setState({
            showServiceDialog: true, DialogMode: 'E', ItemId: service.Id, SelectedService: service.Category, ServiceName: service.Category.Name.toString(),
            TargetArea: service.TargetArea.toString(), Description: service.Description, showDistanceDialog: (service.TargetArea.toString() === '0'),
            SelectedCities: service.Cities, OldSelectedCities: service.Cities,
            Distance: service.Distance, OldDistance: service.Distance,
            BaseLocation: service.BaseLocation ? JSON.parse(service.BaseLocation) : this.state.BaseLocation, UniquePlaces: service.UniquePlaces
        }, () => {
            if (service.TargetArea.toString() === '0') {
                this.initializeMap();
            }
        });
    }

    // private getCurrentLocation(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         if (navigator.geolocation) {
    //             navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
    //                 const pos = { lat: position.coords.latitude, lng: position.coords.longitude, };

    //                 resolve(pos);
    //                 // this.infoWindow.setPosition(pos);
    //                 // this.infoWindow.setContent("Location found.");
    //                 // this.infoWindow.open(this.map);
    //                 // this.map.setCenter(pos);
    //             }, () => {
    //                 this.handleLocationError(true, this.infoWindow, this.map.getCenter()!);
    //                 resolve(null);
    //             });
    //         }
    //         else {
    //             // Browser doesn't support Geolocation
    //             this.handleLocationError(false, this.infoWindow, this.map.getCenter()!);
    //             resolve(null);
    //         }
    //     });
    // }

    // private handleLocationError(browserHasGeolocation: boolean, infoWindow: google.maps.InfoWindow, pos: google.maps.LatLng) {
    //     infoWindow.setPosition(pos);
    //     infoWindow.setContent(browserHasGeolocation ? "Error: The Geolocation service failed." : "Error: Your browser doesn't support geolocation.");
    //     infoWindow.open(this.map);
    // }

    private getPlaceBasedOnLatLng(latLng): Promise<any> {
        return new Promise((resolve, reject) => {
            let geoCoder = new google.maps.Geocoder();
            geoCoder.geocode({ location: latLng }).then((response) => {
                let result = response.results;
                if (result && result.length > 0) {
                    let place = result.find((place) => {
                        if (place.address_components && place.address_components.length > 0) {
                            let addr_comp = place.address_components.find((comp) => { return (comp.types && comp.types.length > 0 && comp.types[0] === 'postal_code'); });
                            if (addr_comp)
                                return true;
                            else
                                return false;
                        }
                        else
                            return false;
                    });

                    resolve(place);
                }
                else {
                    resolve(undefined);
                }
            }).catch((e) => {
                //this.errorMessage("Geocoder failed due to: " + e);
                resolve(null);
            });
        });
    }

    private drawCircle(miles) {
        if (miles) {
            let latLng: google.maps.LatLng = new google.maps.LatLng(Number(this.state.BaseLocation.lat), Number(this.state.BaseLocation.lng));
            if (this.circle && this.circle.getMap())
                this.circle.setRadius(Number(miles) * 1609.344);
            else {
                this.circle = new google.maps.Circle({
                    strokeColor: "#FF0000", strokeOpacity: 0.8, strokeWeight: 2, fillColor: "#FF0000", fillOpacity: 0.19, clickable: false,
                    map: this.map, center: latLng, radius: (Number(miles) * 1609.344)
                });
            }
        }
        else {
            this.circle.setRadius(0);
            this.setState({ SelectedCities: [] });
            this.markers.forEach((marker) => {
                marker.setMap(null);
            });

            this.markers = [];
        }
    }

    // private isInZone(marker) {
    //     if (this.state.Distance) {
    //         var km = Number(this.state.Distance) / 1000;

    //         let circleCenter = this.circle.getCenter();
    //         if (circleCenter) {
    //             var kx = Math.cos(Math.PI * circleCenter.lat() / 180) * 111;
    //             var dx = Math.abs(circleCenter.lng() - marker.lng) * kx;
    //             var dy = Math.abs(circleCenter.lat() - marker.lat) * 111;

    //             return Math.sqrt(dx * dx + dy * dy) <= km;
    //         }
    //         else {
    //             return false;
    //         }
    //     }
    //     else {
    //         return false;
    //     }
    // }

    private getLocationDistance(lat, lng) {
        if (this.state.Distance) {
            let circleCenter = this.circle.getCenter();
            if (circleCenter) {
                var kx = Math.cos(Math.PI * circleCenter.lat() / 180) * 111;
                var dx = Math.abs(circleCenter.lng() - lng) * kx;
                var dy = Math.abs(circleCenter.lat() - lat) * 111;

                return parseInt((Math.sqrt(dx * dx + dy * dy) * 1000).toString());
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    }

    private showInfoWindow(pos, marker) {
        this.infoWindow?.close();
        let contentItem = this.state.SelectedCities.find((c) => { return (c.lat === pos.lat && c.lng === pos.lng); });
        if (contentItem) {
            this.infoWindow = new google.maps.InfoWindow({ content: (contentItem.name ? contentItem.name + ", " : "") + contentItem.formatted_address, disableAutoPan: true });
            this.infoWindow.open(this.map, marker);
        }
        else if (pos) {
            this.infoWindow = new google.maps.InfoWindow({ content: pos.formatted_address, disableAutoPan: true });
            this.infoWindow.open(this.map, marker);
        }
    }

    private initializeMap() {

        this.initializePlacesAutoComplete();

        let latLng: google.maps.LatLng = new google.maps.LatLng(Number(this.state.BaseLocation.lat), Number(this.state.BaseLocation.lng));

        this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, { center: latLng, zoom: 10, mapTypeControl: false, mapTypeId: "roadmap" });
        this.baseMarker = new google.maps.Marker({ map: this.map, anchorPoint: new google.maps.Point(0, -29) });
        this.baseMarker.setPosition(latLng);

        this.baseMarker.addListener("click", () => {
            this.showInfoWindow(this.state.BaseLocation, this.baseMarker);
        });

        if (this.state.Distance && Number(this.state.Distance) > 0) {
            this.drawCircle(this.state.Distance);
        }

        let cities = this.state.SelectedCities;
        let uniqueCities: any[] = [];
        if (cities) {
            cities.forEach((city, indx) => {
                let exists = uniqueCities.find((uP) => { return (city.name === uP); });
                if (!exists) {
                    uniqueCities.push(city.name);
                }
            });

            uniqueCities.sort((a, b) => {
                return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
            });
        }

        this.setState({ UniquePlaces: uniqueCities });

        /* Commented as per Charlie's requirement to not allow the user click on map and get all places within the circle automatically */
        // if (this.state.SelectedCities && this.state.SelectedCities.length > 0) {
        //     this.state.SelectedCities.forEach((c) => {
        //         const nMarker = new google.maps.Marker({ map: this.map, anchorPoint: new google.maps.Point(0, -29) });
        //         nMarker.setPosition(new google.maps.LatLng(Number(c.lat), Number(c.lng)));

        //         nMarker.addListener("click", () => {
        //             this.showInfoWindow(c, nMarker);
        //         });

        //         this.markers.push(nMarker);
        //     });
        // }

        // this.map.addListener("click", (mapsMouseEvent) => {
        //     if (this.state.Distance) {
        //         // Check if click details are inside the radius
        //         let isInCircle = this.isInZone({ lat: mapsMouseEvent.latLng.lat(), lng: mapsMouseEvent.latLng.lng() });
        //         if (isInCircle) {
        //             let existing = this.state.SelectedCities.find((c) => { return (c.lat === mapsMouseEvent.latLng.lat() && c.lng === mapsMouseEvent.latLng.lng()); });
        //             if (!existing) {
        //                 // if (mapsMouseEvent.placeId) {
        //                 //     this.getPlaceByPlaceId(mapsMouseEvent.placeId).then((place) => {
        //                 //         if (place) {
        //                 //             this.setLocationOnMap(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng(), place);
        //                 //         }
        //                 //         else {
        //                 //             this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The selected place details could not be fetched.' });
        //                 //         }
        //                 //     });
        //                 // }
        //                 // else {
        //                 this.getPlaceBasedOnLatLng({ lat: mapsMouseEvent.latLng.lat(), lng: mapsMouseEvent.latLng.lng() }).then((geoLocation) => {
        //                     if (geoLocation) {
        //                         if (geoLocation.place_id) {
        //                             this.getPlaceByPlaceId(geoLocation.place_id).then((place) => {
        //                                 if (place) {
        //                                     this.setLocationOnMap(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng(), place);
        //                                 }
        //                                 else {
        //                                     this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The selected place details could not be fetched.' });
        //                                 }
        //                             });
        //                         }
        //                         else {
        //                             this.setLocationOnMap(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng(), geoLocation);
        //                         }
        //                     }
        //                     else {
        //                         this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The selected place does not have a valid identity.' });
        //                     }
        //                 });
        //                 // }
        //             }
        //         }
        //         else {
        //             this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The selected place is outside the distance range.' });
        //         }
        //     }
        //     else {
        //         this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'Select a distance range.' });
        //     }
        // });

        /* Comment Ends */
    }

    // private setLocationOnMap(lat: any, lng: any, place: any) {
    //     let selCities = this.state.SelectedCities;
    //     if (!selCities)
    //         selCities = [];

    //     let zipCode = '';
    //     if (place.address_components) {
    //         let postal_code = place.address_components.find((add) => {
    //             return (add.types && add.types.length > 0 && add.types[0] === 'postal_code');
    //         });

    //         if (postal_code)
    //             zipCode = postal_code.long_name;
    //     }

    //     let distance = this.getLocationDistance(lat, lng);
    //     selCities.push({ name: place.vicinity ? place.vicinity : place.name, lat, lng, formatted_address: place?.formatted_address, placeId: place?.place_id, distance: distance, pin: zipCode });
    //     this.setState({ SelectedCities: selCities });

    //     const nMarker = new google.maps.Marker({ map: this.map, anchorPoint: new google.maps.Point(0, -29) });
    //     nMarker.setPosition({ lat, lng });

    //     nMarker.addListener("click", () => {
    //         this.showInfoWindow({ lat, lng }, nMarker);
    //     });

    //     this.markers.push(nMarker);
    // }

    private getZipCodesInCircle(geoLocations, currIndex, selCities, counter, delay) {
        let plc = geoLocations[currIndex];

        if (plc) {
            let lat = plc.lat;
            let lon = plc.lng;

            let zipCode = '';

            this.getPlaceBasedOnLatLng({ lat: lat, lng: lon }).then((geoLocation) => {
                if (geoLocation) {
                    if (geoLocation.address_components) {
                        counter++;

                        let postal_code = geoLocation.address_components.find((add) => {
                            return (add.types && add.types.length > 0 && add.types[0] === 'postal_code');
                        });

                        if (postal_code) {
                            zipCode = postal_code.long_name;

                            let existingZip = selCities.find((ct) => { return (ct.pin.toString() === zipCode.toString()); });
                            if (!existingZip) {
                                let distance = this.getLocationDistance(lat, lon);
                                selCities.push({ name: geoLocation.vicinity ? geoLocation.vicinity : zipCode, lat, lon, formatted_address: geoLocation?.formatted_address, placeId: geoLocation?.place_id, distance: distance, pin: zipCode });

                                if (counter === geoLocations.length) {
                                    this.setState({ showNearByProgress: false, SelectedCities: selCities });

                                    if (selCities.length <= 0) {
                                        this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                                    }
                                }
                                else if (counter < geoLocations.length) {
                                    this.getZipCodesInCircle(geoLocations, (currIndex + 1), selCities, counter, delay);
                                }
                            }
                            else {
                                if (counter === geoLocations.length) {
                                    this.setState({ showNearByProgress: false, SelectedCities: selCities });

                                    if (selCities.length <= 0) {
                                        this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                                    }
                                }
                                else if (counter < geoLocations.length) {
                                    this.getZipCodesInCircle(geoLocations, (currIndex + 1), selCities, counter, delay);
                                }
                            }
                        }
                        else {
                            if (counter === geoLocations.length) {
                                this.setState({ showNearByProgress: false, SelectedCities: selCities });

                                if (selCities.length <= 0) {
                                    this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                                }
                            }
                            else if (counter < geoLocations.length) {
                                this.getZipCodesInCircle(geoLocations, (currIndex + 1), selCities, counter, delay);
                            }
                        }
                    }
                    else {
                        if (plc.place_id) {
                            this.getPlaceByPlaceId(plc.place_id).then((place) => {
                                if (place) {
                                    counter++;

                                    if (place.address_components) {
                                        let postal_code = place.address_components.find((add) => {
                                            return (add.types && add.types.length > 0 && add.types[0] === 'postal_code');
                                        });

                                        if (postal_code) {
                                            zipCode = postal_code.long_name;

                                            let existingZip = selCities.find((ct) => { return (ct.pin.toString() === zipCode.toString()); });
                                            if (!existingZip) {
                                                let distance = this.getLocationDistance(lat, lon);
                                                selCities.push({ name: place.vicinity ? place.vicinity : place.name, lat, lon, formatted_address: place?.formatted_address, placeId: place?.place_id, distance: distance, pin: zipCode });

                                                if (counter === geoLocations.length) {
                                                    this.setState({ showNearByProgress: false, SelectedCities: selCities });

                                                    if (selCities.length <= 0) {
                                                        this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                                                    }
                                                }
                                                else if (counter < geoLocations.length) {
                                                    this.getZipCodesInCircle(geoLocations, (currIndex + 1), selCities, counter, delay);
                                                }
                                            }
                                            else {
                                                if (counter === geoLocations.length) {
                                                    this.setState({ showNearByProgress: false, SelectedCities: selCities });

                                                    if (selCities.length <= 0) {
                                                        this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                                                    }
                                                }
                                                else if (counter < geoLocations.length) {
                                                    this.getZipCodesInCircle(geoLocations, (currIndex + 1), selCities, counter, delay);
                                                }
                                            }
                                        }
                                        else {
                                            if (counter === geoLocations.length) {
                                                this.setState({ showNearByProgress: false, SelectedCities: selCities });

                                                if (selCities.length <= 0) {
                                                    this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                                                }
                                            }
                                            else if (counter < geoLocations.length) {
                                                this.getZipCodesInCircle(geoLocations, (currIndex + 1), selCities, counter, delay);
                                            }
                                        }
                                    }
                                    else {
                                        if (counter === geoLocations.length) {
                                            this.setState({ showNearByProgress: false, SelectedCities: selCities });

                                            if (selCities.length <= 0) {
                                                this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                                            }
                                        }
                                        else if (counter < geoLocations.length) {
                                            this.getZipCodesInCircle(geoLocations, (currIndex + 1), selCities, counter, delay);
                                        }
                                    }
                                }
                                else {
                                    if (counter === geoLocations.length) {
                                        this.setState({ showNearByProgress: false, SelectedCities: selCities });

                                        if (selCities.length <= 0) {
                                            this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                                        }
                                    }
                                    else if (counter < geoLocations.length) {
                                        this.sleep((delay + 100)).then((ready) => {
                                            this.getZipCodesInCircle(geoLocations, (place === null ? currIndex : currIndex + 1), selCities, counter, delay);
                                        });
                                    }
                                }
                            });
                        }
                        else {
                            counter++;
                            if (counter === geoLocations.length) {
                                this.setState({ showNearByProgress: false, SelectedCities: selCities });

                                if (selCities.length <= 0) {
                                    this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                                }
                            }
                            else if (counter < geoLocations.length) {
                                this.getZipCodesInCircle(geoLocations, (currIndex + 1), selCities, counter, delay);
                            }
                        }
                    }
                }
                else {
                    if (counter === geoLocations.length) {
                        this.setState({ showNearByProgress: false, SelectedCities: selCities });

                        if (selCities.length <= 0) {
                            this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                        }
                    }
                    else if (counter < geoLocations.length) {
                        this.sleep((delay + 100)).then((ready) => {
                            this.getZipCodesInCircle(geoLocations, (geoLocation === null ? currIndex : currIndex + 1), selCities, counter, delay);
                        });
                    }
                }
            });
        }
        else {
            counter++;
            if (counter === geoLocations.length) {
                this.setState({ showNearByProgress: false, SelectedCities: selCities });

                if (selCities.length <= 0) {
                    this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The place details could not be fetched within the given distance.' });
                }
            }
            else if (counter < geoLocations.length) {
                this.getZipCodesInCircle(geoLocations, (currIndex + 1), selCities, counter, delay);
            }
        }
    }

    private sleep(delay): Promise<boolean> {
        return new Promise((resolve, reject) => {
            window.setTimeout(() => { resolve(true); }, delay);
        });
    }

    private getZipCodesInRadius() {
        if (this.state.BaseLocation.pin && this.state.Distance) {
            let zipAPI = new ZipCodeAPI();
            zipAPI.getZipCodesInRadius(this.state.BaseLocation.pin, this.state.Distance, ZipCodeAPIUnits.Miles).then((zipCodes) => {
                if (zipCodes && zipCodes.zip_codes && zipCodes.zip_codes.length > 0) {
                    let zips = zipCodes.zip_codes;
                    let selCities: any = [];
                    let uniquePlaces: any = [];

                    zips.forEach((zip, indx) => {
                        selCities.push({
                            name: zip.city, lat: '', lon: '',
                            formatted_address: zip.city + ", " + zip.state + " " + zip.zip_code + ", USA",
                            placeId: '', distance: zip.distance, pin: zip.zip_code
                        });

                        let exists = uniquePlaces.find((uP) => { return (zip.city === uP); });
                        if (!exists) {
                            uniquePlaces.push(zip.city);
                        }
                    });

                    uniquePlaces.sort((a, b) => {
                        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
                    });

                    this.setState({ showNearByProgress: false, SelectedCities: selCities, UniquePlaces: uniquePlaces });

                    // zipAPI.getMultipleZipCodeLocations(commaSeparatedZips).then((locations) => {
                    //     if (locations) {
                    //         zips.forEach((zp, ind) => {
                    //             let location = locations[zp];

                    //             if (location) {
                    //                 selCities.push({
                    //                     name: zp.city, lat: location.lat, lon: location.lon,
                    //                     formatted_address: zp.zip_code + ", " + zp.city + ", " + zp.state + ", USA",
                    //                     placeId: '', distance: zp.distance, pin: zp.zip_code
                    //                 });
                    //             }
                    //         });
                    //     }

                    //     this.setState({ showNearByProgress: false, SelectedCities: selCities });
                    // });
                }
                else {
                    this.setState({ showNearByProgress: false, showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The search query to get places within the given distance returned no records.' });
                }
            });
        }
        else {
            this.setState({ showNearByProgress: false, SelectedCities: [], UniquePlaces: [] });
        }
    }

    // private getNearByPlaces() {
    //     let latLng: google.maps.LatLng = new google.maps.LatLng(Number(this.state.BaseLocation.lat), Number(this.state.BaseLocation.lng));
    //     let placeService = new google.maps.places.PlacesService(this.map);

    //     let selCities = [];

    //     placeService.nearbySearch({ location: latLng, radius: this.state.Distance, type: '(cities)' }, (result, stats, pagination) => {
    //         if (stats === google.maps.places.PlacesServiceStatus.OK) {
    //             if (result && result.length > 0) {

    //                 let counter = 0;
    //                 let itemIndex = 0;
    //                 let delay = 200;

    //                 this.getZipCodesInCircle(result, itemIndex, selCities, counter, delay);

    //                 if (pagination && pagination.hasNextPage) {
    //                     pagination.nextPage();
    //                 }
    //             }
    //             else {
    //                 this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The search query to get places within the given distance returned no records.' });
    //             }
    //         }
    //         else {
    //             this.setState({ showConfirmation: true, ConfirmationType: 'A', ConfirmationMessage: 'The search query to get places within the given distance returned no records. EXCEPTION:: ' + stats });
    //         }
    //     });
    // }

    private getPlaceByPlaceId(placeId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let placeService = new google.maps.places.PlacesService(this.map);
            placeService.getDetails({ placeId: placeId }, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                }
                else if (status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
                    resolve(null);
                }
                else {
                    resolve(undefined);
                }
            });
        });
    }

    private initializePlacesAutoComplete() {
        const input = document.getElementById("zip") as HTMLInputElement;
        const autoCompleteOptions = {
            fields: ["formatted_address", "address_components", "geometry", "name", "place_id"],
            // strictBounds: false,
            types: ["(regions)"],
            componentRestrictions: { country: "us" }
        };

        const autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);

        autocomplete.addListener("place_changed", () => {
            let place: any = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "No details available for input: '" + place.name + "'", ConfirmationType: 'A' });
                return;
            }

            let lat = place.geometry?.location?.lat();
            let lon = place.geometry?.location?.lng();
            let formatted_address = place?.formatted_address;
            let placeId = place?.place_id;
            let zipCode = '';

            if (place.address_components) {
                let postal_code = place.address_components.find((add) => {
                    return (add.types && add.types.length > 0 && add.types[0] === 'postal_code');
                });

                if (postal_code)
                    zipCode = postal_code.long_name;

                // for (var i = 0; i < place.address_components.length; i++) {
                //     if (place.address_components[i].types) {
                //         for (var j = 0; j < place.address_components[i].types.length; j++) {
                //             if (place.address_components[i].types[j] === "postal_code") {
                //                 if (!hasPostalCode) {
                //                     hasPostalCode = true;
                //                     zipCode = place.address_components[i].long_name;
                //                 }
                //             }
                //         }
                //     }
                // }
            }

            if (!zipCode) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "No zip code information available for input: '" + place.name + "'. Try using a Zip code to get more accurate results.", ConfirmationType: 'A' });
                return;
            }

            this.resetMapComponents();
            this.baseMarker.setMap(null);

            let latLng: google.maps.LatLng = new google.maps.LatLng(Number(lat), Number(lon));
            this.map.setCenter(latLng);

            this.baseMarker = new google.maps.Marker({ map: this.map, anchorPoint: new google.maps.Point(0, -29) });
            this.baseMarker.setPosition(latLng);

            this.baseMarker.addListener("click", () => {
                this.showInfoWindow({ name: place.name, lat: lat, lng: lon, formatted_address: formatted_address, placeId: placeId }, this.baseMarker);
            });

            let distance = this.getLocationDistance(lat, lon);
            this.setState({ showNearByProgress: true, SelectedCities: [], UniquePlaces: [], BaseLocation: { name: place?.name, lat: lat, lng: lon, formatted_address: formatted_address, placeId: placeId, distance: distance, pin: zipCode } }, () => {
                if (this.state.Distance && Number(this.state.Distance) > 0) {
                    this.drawCircle(this.state.Distance);
                    //this.getNearByPlaces();
                    this.getZipCodesInRadius();
                }
            });
        });
    }

    // private handleLocationDeleted(location) {
    //     if (location) {
    //         let selCities: any[] = this.state.SelectedCities;
    //         let uniquePlaces = this.state.UniquePlaces;

    //         let filteredCities = selCities && selCities.filter((city) => { return (city.name !== location); });
    //         let filtereduniqueCities = uniquePlaces && uniquePlaces.filter((city) => { return (city !== location); });

    //         this.setState({ SelectedCities: filteredCities, UniquePlaces: filtereduniqueCities });

    //         // if (selCities) {

    //         // let cityToDelete = selCities.findIndex((c) => { return (c.lat === location.lat && c.lng === location.lng); });
    //         // if (cityToDelete >= 0) {
    //         //     selCities.splice(cityToDelete, 1);

    //         //     this.setState({ SelectedCities: selCities });
    //         // }
    //         // }

    //         // if (this.markers && this.markers.length > 0) {
    //         //     let markerIndex = this.markers.findIndex((m) => {
    //         //         let pos = m.getPosition();
    //         //         if (pos) {
    //         //             return (pos.lat().toString() === location.lat.toString() && pos.lng().toString() === location.lng.toString());
    //         //         }

    //         //         return false;
    //         //     });

    //         //     if (markerIndex >= 0) {
    //         //         this.markers[markerIndex].setMap(null);
    //         //         this.markers.splice(markerIndex, 1);
    //         //     }
    //         // }
    //     }
    // }

    private saveServiceDetails() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            let { ItemId, ProfileId, SelectedService, TargetArea, SelectedCities, Description, Distance, BaseLocation } = this.state;

            let _selectedCities = (TargetArea === '0' && SelectedCities ? JSON.stringify(SelectedCities) : '');
            let _baseLocation = BaseLocation ? JSON.stringify(BaseLocation) : '';

            let _baseLocationPin = BaseLocation.pin;
            let _profileServiceLocations: any = [];

            SelectedCities.forEach((city) => {
                _profileServiceLocations.push(_baseLocationPin + "~" + city.pin + "~" + city.distance);
            });

            let _profileServiceLocation = JSON.stringify(_profileServiceLocations);

            this.db.updateProfileService(ItemId, ProfileId, SelectedService.Id.toString(), TargetArea, _selectedCities, Description, Distance, _baseLocation, _profileServiceLocation).then((saved) => {
                if (saved) {
                    this.successMessage('Service details saved successfully.');
                    this.resetServiceForm();
                    this.bindServices();
                }
                else {
                    this.errorMessage('Service details could not be saved.');
                }
            });
        }
    }

    private handleDeleteService(service) {
        this.setState({ ItemId: service.Id, showConfirmation: true, ConfirmationMessage: 'Are you sure you want to delete this service?', callBackMethod: this.deleteProfileService.bind(this) });
    }

    private deleteProfileService() {
        this.db.deleteProfileService(this.state.ItemId).then((deleted) => {
            if (deleted) {
                this.successMessage('Service deleted successfully.');
                this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null }, () => {
                    this.bindServices();
                });
            }
            else {
                this.errorMessage('Service could not be deleted.');
            }
        });
    }

    private resetMapComponents() {
        this.markers.forEach((marker) => {
            marker.setMap(null);
        });

        this.markers = [];
        if (this.circle)
            this.circle.setMap(null);
    }

    private resetServiceForm() {
        this.formValidators = new Array<Validator>();

        this.resetMapComponents();

        this.setState({
            showServiceDialog: false, showDistanceDialog: false, DialogMode: '', ItemId: 0, SelectedCities: [], SelectedService: '', OldSelectedCities: [],
            TargetArea: '', Description: ''
        });
    }

    // private getCities(cities) {
    //     if (cities && cities.length > 0) {
    //         let retVal = cities.map((c, indx) => {
    //             return (<Chip label={c} className="m-1" />);
    //         });

    //         return retVal;
    //     }

    //     return null;
    // }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {
        const { loading, ProfileServices, showConfirmation, ConfirmationMessage, ShowMessage, Message, MessageType, showServiceDialog, DialogMode, ConfirmationType, showNearByProgress,
            TargetArea, Description, OldSelectedCities, Services, SelectedService, showDistanceDialog, Distance, OldDistance, BaseLocation,
            UniquePlaces, SelectedCities, Profile } = this.state; // Cities, SelectedCities

        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            if (!this.IsUserValid) {
                window.location.href = '/login';
            }
            else {
                if (this.AppRole !== Constants.UserRoles.Pro && this.AppRole !== Constants.UserRoles.Admin) {

                }
                else {
                    template = (<React.Fragment>
                        <Header />
                        <section className="containerMinHeight accountSettingContainer borderBtm">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        {!showServiceDialog ? (<React.Fragment>
                                            {this.props.id ? (<div className="row">
                                                <div className="col-12 col-sm-12">
                                                    <div className="breadCrumb mb-1">
                                                        <a href="javascript:void(0);" onClick={(e) => { window.location.href = '/admin'; }}>Administration</a><span className="ml-2">&gt;</span><span className="ml-2">Pro's Services</span>
                                                    </div>
                                                </div>
                                            </div>) : (null)}
                                            <h5>{!this.props.id || this.User.Profile.Id === this.props.id ? "My Services" : (Profile ? Profile.User.DisplayName + "'s Services" : "Pro Services")}</h5>
                                            <div className="profileContainer">
                                                <div className="formContainer">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <div className="form-group">
                                                                {!this.props.id || this.User.Profile.Id === this.props.id ? (<div className="row">
                                                                    <div className="col-9 col-sm-9">
                                                                        <Typography variant="subtitle2" color="text.secondary" className="commentBody">Mange your profile services. Add more services to add more visibility to your profile.</Typography>
                                                                    </div>
                                                                    <div className="col-3 col-sm-3">
                                                                        <div className="formButtonContainer text-right mt-0 mb-0">
                                                                            <button type="submit" className="btn btn-primary cutmBtnLogin" onClick={(e) => { this.setState({ showServiceDialog: true, DialogMode: '', ItemId: 0 }); }}><AddIcon /></button>
                                                                        </div>
                                                                    </div>
                                                                </div>) : (null)}
                                                                {!ProfileServices || ProfileServices.length <= 0 ? (<div className="tabPanel mt-2">
                                                                    <Typography variant="subtitle2" color="text.secondary" className="commentBody">There are no services avaliable. {!this.props.id ? "Click + to add new services." : ""}</Typography>
                                                                </div>) : (<div className="row mt-2">
                                                                    {ProfileServices.map((item) => {
                                                                        return (<div className="col-12 col-sm-12">
                                                                            <div className="form-group">
                                                                                <div className="tabPanel">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12">
                                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{item.Category ? item.Category.Name : ''}</Typography>
                                                                                            <div className="row mt-2">
                                                                                                <div className="col-8 col-sm-10">
                                                                                                    {/* {item.TargetArea === 0 ? (this.getCities(item.UniquePlaces)) : (<Chip label="Nationwide" />)} */}
                                                                                                    {item.TargetArea === 0 ? (<Chip label={item.UniquePlaces.length + " cities with " + item.Cities.length + " zip codes"} className={"locationChip mt-1"} color="default" />) : (<Chip label="Nationwide" />)}
                                                                                                </div>
                                                                                                {!this.props.id || this.User.Profile.Id === this.props.id ? (<div className="col-4 col-sm-2">
                                                                                                    <Typography variant="caption"><EditIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleEditService(item); }} className="ml-2 mr-2">Edit</a><DeleteIcon /><a href="javascript:void(0);" onClick={(e) => { this.handleDeleteService(item); }} className="ml-2">Delete</a></Typography>
                                                                                                </div>) : (null)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <hr />
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-sm-12">
                                                                                            <Typography variant="subtitle2" color="text.secondary">{item.Description}</Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>);
                                                                    })}
                                                                </div>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>) :
                                            (<React.Fragment>
                                                <h5>{DialogMode === 'E' ? "Edit Service" : (DialogMode === 'V' ? 'Service Details' : 'New Service')}</h5>
                                                <div className="profileContainer">
                                                    <div className="formContainer">
                                                        <div className="tabPanel p-4">
                                                            <div className="form-group mt-3">
                                                                <label>What service are you adding?</label>
                                                                <Autocomplete multiple={false} id="services" disabled={DialogMode === 'V'} className="autoComplete" options={Services} getOptionLabel={(option) => { return (option && option.Name ? option.Name : ""); }}
                                                                    onChange={(e, newVal) => { this.setState({ SelectedService: newVal }); }} defaultValue={SelectedService} filterSelectedOptions={true} renderInput={(params) => (<TextField name="service" {...params} />)} />
                                                                <Validator id="valService" type='required' cssClass="error" errorMessage="Provide service." controlId="services" valueToValidate={!SelectedService ? '' : '1'} formValidators={this.formValidators}></Validator>
                                                            </div>
                                                            <div className="form-group mt-3">
                                                                <label>Service description</label>
                                                                {DialogMode === 'V' ? (<div><span>{Description}</span></div>) : (
                                                                    <React.Fragment>
                                                                        <textarea rows={3} id="description" name="description" key={'description'} defaultValue={Description} className="form-control" placeholder="" onBlur={(e) => { this.setState({ Description: e.target.value }); }} />
                                                                        <Validator id="valDescription" type='required' cssClass="error" errorMessage="Provide service description." controlId="description" valueToValidate={Description} formValidators={this.formValidators}></Validator>
                                                                        <Validator id="valDescriptionLength" type='max_length' cssClass="error" maxLength={500} errorMessage="Service description cannot exceed 500 characters." controlId="description" valueToValidate={Description} formValidators={this.formValidators}></Validator>
                                                                    </React.Fragment>)}
                                                            </div>
                                                            <div className="form-group mt-3">
                                                                <label>Target area</label>
                                                                <div className="col-12 col-sm-12">
                                                                    <div className="row mt-3">
                                                                        <div className="col-2 col-sm-1 align-self-center p-0">
                                                                            <Radio disabled={DialogMode === 'V'} id="rdoDistance" name="rdoDistance" className="p-0" value="0" checked={TargetArea === '0'} onChange={(e) => { this.setState({ TargetArea: e.target.value, SelectedCities: OldSelectedCities, Distance: OldDistance, showDistanceDialog: true }, () => { this.initializeMap(); }); }} /><LocationOnIcon className="ml-3" />
                                                                        </div>
                                                                        <div className="col-10 col-sm-11 align-self-center p-0">
                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>Distance</Typography>
                                                                            <Typography variant="subtitle2" color="text.secondary">Choose places at the distance around your business location where you can provide your services</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-3">
                                                                        <div className="col-2 col-sm-1 align-self-center p-0">
                                                                            <Radio disabled={DialogMode === 'V'} id="rdoNation" name="rdoNation" className="p-0" value="1" checked={TargetArea === '1'} onChange={(e) => { this.setState({ TargetArea: e.target.value, SelectedCities: [], UniquePlaces: [], Distance: '', showDistanceDialog: false }, () => { this.resetMapComponents(); }); }} /><ExploreIcon className="ml-3" />
                                                                        </div>
                                                                        <div className="col-10 col-sm-11 align-self-center p-0">
                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>Nationwide</Typography>
                                                                            <Typography variant="subtitle2" color="text.secondary">Choose the nationwide location if you provide services across the entire country.</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <Validator id="valTargetArea" type='required' cssClass="error" errorMessage="Select target area." controlId="targetArea" valueToValidate={TargetArea} formValidators={this.formValidators}></Validator>
                                                                </div>
                                                                {showDistanceDialog ? (<React.Fragment>
                                                                    <div id="mapCard" className="form-group mt-3">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-6">
                                                                                {/* <label>Your business location</label>
                                                                                <Typography variant="subtitle2" color="text.secondary"><LocationOnOutlined className="mr-3" />{this.User.Profile ? this.User.Profile.PinAddress : ''}</Typography> */}
                                                                                <label>Zip code/City</label>
                                                                                <input id="zip" name="zip" type="text" className="form-control" defaultValue={BaseLocation.formatted_address} />
                                                                            </div>
                                                                            <div className="col-12 col-sm-6">
                                                                                <label>Distance (miles)</label>
                                                                                <select id="distance" name="distance" className="form-control" disabled={DialogMode === 'V'} defaultValue={Distance} onChange={(e) => { this.setState({ showNearByProgress: true, Distance: e.target.value }, () => { this.drawCircle(e.target.value); this.getZipCodesInRadius(); }); }}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="5">5 miles</option>
                                                                                    <option value="10">10 miles</option>
                                                                                    <option value="15">15 miles</option>
                                                                                    <option value="20">20 miles</option>
                                                                                    <option value="25">25 miles</option>
                                                                                    <option value="30">30 miles</option>
                                                                                    <option value="35">35 miles</option>
                                                                                    <option value="40">40 miles</option>
                                                                                    <option value="45">45 miles</option>
                                                                                    <option value="50">50 miles</option>
                                                                                </select>
                                                                                <Validator id="valDistance" type='required' cssClass="error" errorMessage="Select distance." controlId="distance" valueToValidate={showDistanceDialog ? Distance.toString() : '1'} formValidators={this.formValidators}></Validator>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        {Distance ? (<React.Fragment><Typography variant="subtitle2" color="text.secondary">The location(s) within the circle where you can provide your service.</Typography>
                                                                            <React.Fragment>
                                                                                {showNearByProgress ? (<ProgressBar />) : (<React.Fragment>
                                                                                    {UniquePlaces && UniquePlaces.length > 0 ? (<div className="locations">
                                                                                        <Chip label={UniquePlaces.length + " cities with " + SelectedCities.length + " zip codes"} className={"locationChip mt-1"} color="default" />
                                                                                        {/* {UniquePlaces.map((c, ind) => {
                                                                                            return (<Chip label={c} className={"locationChip mt-1 mr-2"} onDelete={(e) => { this.handleLocationDeleted(c); }} color="default" title={c} />);
                                                                                        })} */}
                                                                                    </div>) : (null)}
                                                                                </React.Fragment>)}
                                                                            </React.Fragment>
                                                                            <Validator id="valLocations" type='required' cssClass="error" errorMessage="Select location(s)." valueToValidate={showDistanceDialog && Distance ? (UniquePlaces && UniquePlaces.length <= 0 ? '' : '1') : '1'} formValidators={this.formValidators}></Validator>
                                                                        </React.Fragment>) : (null)}
                                                                        <div id="map" className="mapContainer mt-3">
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>) : (null)}
                                                            </div>
                                                            <div className="form-group mt-3">
                                                                <hr />
                                                                <div className="formButtonContainer text-right">
                                                                    <button type="submit" className="btn btn-cancel" disabled={showNearByProgress} onClick={(e) => { this.resetServiceForm(); }}>Cancel</button>
                                                                    {DialogMode !== 'V' ? (<button type="submit" className="btn btn-primary cutmBtnLogin ml-3" disabled={showNearByProgress} onClick={(e) => { this.saveServiceDetails(); }}>Save</button>) : (null)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>)}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </React.Fragment >);
                }
            }
        }

        return (<React.Fragment>
            {template}
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }) }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}