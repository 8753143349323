import React from "react";
import Base from "src/common/Base";
import Footer from "src/common/Footer";
import Header from "src/common/Header";

export default class Privacy extends Base {
    constructor(props) {
        super(props);

    }

    public componentDidMount() {

    }

    public render() {

        let template = (<React.Fragment>
            <Header />
            <section className="containerMinHeight borderBtm staticContent">
                <div className="becomeLayer5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12 p-5">
                                <h2>Privacy Policy</h2>
                                <div className="mt-3">Last Updated: August 1, 2023</div>
                                <div className="mt-3">
                                    <h4>1. Introduction</h4>
                                    <div className="mt-3">Welcome to prosbe.com! We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your personal data when you use our website or services. By accessing or using our website, you consent to the practices described in this policy.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>2. Information We Collect</h4>
                                    <div className="mt-3">a. Personal Information: When you sign up or use our services, we may collect personal information such as your name, email address, postal address, phone number, and other relevant details.</div>
                                    <div className="mt-2">b. Usage Data: We may collect information about your interactions with our website, including IP address, browser type, referring/exit pages, operating system, and date/time stamps.</div>
                                    <div className="mt-2">c. Cookies and Similar Technologies: Like many websites, we use cookies and similar technologies to enhance your experience and gather information about your usage patterns. You can manage your cookie preferences through your browser settings.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>3. How We Use Your Information</h4>
                                    <div className="mt-3">a. Provide and Improve Services: We use your information to deliver and maintain our services, customize your experience, and respond to your inquiries and requests.</div>
                                    <div className="mt-2">b. Communication: We may use your email address to send you important notifications, updates, promotional materials, or newsletters. You can opt-out of marketing communications at any time.</div>
                                    <div className="mt-2">c. Analytics: We use your data to analyze website traffic and improve our services, including user experience, content, and features.</div>
                                    <div className="mt-2">d. Compliance: We may use your information to comply with legal obligations, enforce our terms of service, or protect our rights and the rights of others.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>4. Data Sharing and Disclosure</h4>
                                    <div className="mt-3">a. Third-Party Service Providers: We may share your information with third-party service providers who assist us in operating our website and providing services to you (e.g., hosting, analytics).</div>
                                    <div className="mt-2">b. Legal Requirements: We may disclose your information if required by law, to enforce our policies, or to protect the rights, property, or safety of our users or others.</div>
                                    <div className="mt-2">c. Business Transactions: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>5. Your Rights and Choices</h4>
                                    <div className="mt-3">a. Access and Rectification: You have the right to access and update your personal information through your account settings or by contacting us.</div>
                                    <div className="mt-2">b. Opt-Out: You can opt-out of receiving marketing communications by following the instructions provided in our emails or by contacting us.</div>
                                    <div className="mt-2">c. Data Deletion: You can request the deletion of your account and personal information, subject to legal and contractual obligations.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>6. Data Security</h4>
                                    <div className="mt-3">We implement appropriate technical and organizational measures to safeguard your personal information from unauthorized access, disclosure, or alteration. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>7. Privacy of Minors</h4>
                                    <div className="mt-3">Our website is not intended for individuals under the age of 18. We do not knowingly collect or solicit personal information from minors. If we become aware of any such data, we will take steps to delete it.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>8. Changes to this Privacy Policy</h4>
                                    <div className="mt-3">We may update this Privacy Policy from time to time. The latest version will be posted on this page, along with the effective date.</div>
                                </div>
                                <div className="mt-3">
                                    <h4>9. Contact Us</h4>
                                    <div className="mt-3">If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:info@prosbe.com">info@prosbe.com</a>.</div>
                                </div>
                                <div className="mt-5">
                                    <h4>End Notes</h4>
                                    <div className="mt-3">This privacy policy is a general template and may need to be adjusted according to the specific practices and services of the thumbtack-like website in question. For legal compliance and to address regional variations, it's essential to seek professional legal advice to ensure the policy aligns with applicable laws and regulations.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>);

        return template;
    }
}