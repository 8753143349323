import * as React from 'react';

export type CompareMode = 'integer' | 'text' | 'date';
export type CompareOperator = '>' | '>=' | '<' | '<=' | '==';
export type ValidatorType = 'required' | 'only_alpha' | 'only_alpha_num' | 'no_whitespace' | 'number' | 'email' | 'phone' | 'url' | 'max_length' | 'min_length' | 'compare';

export interface ValidatorProps {
    controlId?: string;
    valueToCompareWith?: any;
    compareMode?: CompareMode;
    type: ValidatorType;
    errorMessage: string;
    valueToValidate: any;
    compareOperator?: CompareOperator;
    maxLength?: number;
    minLength?: number;
    id: string;
    initialValue?: any;
    formValidators: any[];
    cssClass: string;
    url?: string;
    showErrorMessage?: boolean;
}

// const erroControlCSS: React.CSSProperties = {
//     color: '#bc0000',
//     backgroundColor: '#ffcccc',
//     borderColor: '#ff9999 !important',
//     outline: '0'
// };

export interface ValidatorState {
    valueToValidate: any;
    isValid: boolean;
    errorMessage: string;
    id: string;
}

export default class Validator extends React.Component<ValidatorProps, ValidatorState> {

    constructor(props) {
        super(props);

        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.validateEmailFormat = this.validateEmailFormat.bind(this);
        this.validateMaximumLength = this.validateMaximumLength.bind(this);
        this.validateMinimumLength = this.validateMinimumLength.bind(this);
        this.validateNoWhiteSpace = this.validateNoWhiteSpace.bind(this);
        this.validateOnlyAlphabets = this.validateOnlyAlphabets.bind(this);
        this.validateOnlyAlphaNumeric = this.validateOnlyAlphaNumeric.bind(this);
        this.validateOnlyNumeric = this.validateOnlyNumeric.bind(this);
        this.validatePhoneFormat = this.validatePhoneFormat.bind(this);
        this.validateValueIsNotEmpty = this.validateValueIsNotEmpty.bind(this);
        this.validate = this.validate.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.state = { valueToValidate: this.props.valueToValidate, isValid: true, errorMessage: this.props.errorMessage, id: 'hsValidator_' + this.props.id };
    }

    public componentDidUpdate() {
        if (this.state.valueToValidate !== this.props.valueToValidate) {
            //this.setState({ valueToValidate: this.props.valueToValidate });

            this.validate(this);
        }
    }

    public render() {
        var { isValid } = this.state;

        let template: any = null;

        this.props.formValidators.forEach((val: Validator, index: number) => {
            if (val.state.id === this.state.id) {
                this.props.formValidators.splice(index, 1);
            }
        });

        this.props.formValidators.push(this);

        if (!isValid) {//&& this.props.showErrorMessage
            template = <span className={this.props.cssClass}>{this.props.errorMessage}</span>;

            // Add the control highlighter in case the control Id is available
            if (this.props.controlId) {
                let control = document.getElementById(this.props.controlId);
                if (control) {
                    // if (isValid) {
                    //     control.focus();
                    // }

                    let existingClass = control.className;
                    if (existingClass.indexOf('errorControl') < 0)
                        control.setAttribute('class', existingClass + " errorControl");

                    //control.setAttribute('placeholder', val.props.errorMessage);
                }
            }
        }
        else {
            // Remove the control highlighter in case the control Id is available
            if (this.props.controlId) {
                let otherValidators = this.props.formValidators.find((v) => { return (v.props.id !== this.props.id && v.props.controlId === this.props.controlId && !v.state.isValid); });
                if (!otherValidators || otherValidators.length <= 0) {
                    let control = document.getElementById(this.props.controlId);
                    if (control) {
                        let existingClass = control.className;
                        control.setAttribute("class", existingClass.replace(" errorControl", ""));
                    }
                }
            }
        }

        return (<div>{template}</div>);
    }

    private validate(validator: Validator): boolean {
        var isValid: boolean = true;
        var { type } = validator.props;
        if (type) {
            switch (type) {
                case 'required':
                    isValid = validator.validateValueIsNotEmpty(validator.props.valueToValidate, validator.props.initialValue);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'email':
                    isValid = validator.validateEmailFormat(validator.props.valueToValidate);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'url':
                    isValid = validator.validateURLFormat(validator.props.valueToValidate);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'max_length':
                    isValid = validator.validateMaximumLength(validator.props.valueToValidate, validator.props.maxLength);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'min_length':
                    isValid = validator.validateMinimumLength(validator.props.valueToValidate, validator.props.minLength);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'only_alpha':
                    isValid = validator.validateOnlyAlphabets(validator.props.valueToValidate);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'only_alpha_num':
                    isValid = validator.validateOnlyAlphaNumeric(validator.props.valueToValidate);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'no_whitespace':
                    isValid = validator.validateNoWhiteSpace(validator.props.valueToValidate);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'number':
                    isValid = validator.validateOnlyNumeric(validator.props.valueToValidate);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'phone':
                    isValid = validator.validatePhoneFormat(validator.props.valueToValidate);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                case 'compare':
                    isValid = validator.validateComparison(validator.props.valueToValidate, validator.props.valueToCompareWith, validator.props.compareMode, validator.props.compareOperator);
                    if (validator.state.isValid !== isValid)
                        validator.setState({ isValid: isValid, valueToValidate: validator.props.valueToValidate });
                    break;
                default:
                    break;
            }
        }

        return isValid;
    }

    public validateForm(formValidators: Validator[]): boolean {
        if (formValidators) {
            let isValid = true;
            formValidators.forEach((val: Validator, index: number) => {
                if (!val.validate(val)) {
                    isValid = false;
                }
            });

            return isValid;
        }

        return true;
    }

    private validateValueIsNotEmpty(val: string, initialValue?: any): boolean {
        if (initialValue && initialValue !== '' && initialValue !== undefined && val !== initialValue)// initialValue !== '0')
            return (val.toString().trim() !== '' && initialValue !== val.toString().trim());

        return (val !== undefined && val !== null && val.toString().trim() !== '');
    }

    private validateEmailFormat(val: string) {
        if (val !== '' && val !== null && val !== undefined) {
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return (emailRegex.test(val));
        }

        return true;
    }

    private validateURLFormat(val: string) {
        if (val !== '' && val !== null && val !== undefined) {
            let urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/;

            return (urlRegex.test(val));
        }

        return true;
    }

    private validateMaximumLength(val: string, maxLength?: number) {
        if (val !== '' && val !== null && val !== undefined && maxLength !== undefined && parseInt(maxLength.toString()) > 0) {
            return (val.toString().trim().length <= parseInt(maxLength.toString()));
        }

        return true;
    }

    private validateMinimumLength(val: string, minLength?: number) {
        if (val !== '' && val !== null && val !== undefined && minLength !== undefined && parseInt(minLength.toString()) > 0) {
            return (val.toString().trim().length >= parseInt(minLength.toString()));
        }

        return true;
    }

    private validateOnlyAlphabets(val: string) {
        if (val !== '' && val !== null && val !== undefined) {
            let regex = /^[a-zA-Z ]*$/;

            return (regex.test(val.toString().trim()));
        }

        return true;
    }

    private validateOnlyAlphaNumeric(val: string) {
        if (val !== '' && val !== null && val !== undefined) {
            let regex = /^[0-9a-zA-Z ]*$/;

            return (regex.test(val.toString().trim()));
        }

        return true;
    }

    private validateNoWhiteSpace(val: string) {
        if (val !== '' && val !== null && val !== undefined) {
            let regex = /^\S+$/g;

            return (regex.test(val));
        }

        return true;
    }

    private validateOnlyNumeric(val: string) {
        if (val !== '' && val !== null && val !== undefined) {
            let regex = /^[0-9.]*$/;

            return (regex.test(val));
        }

        return true;
    }

    private validatePhoneFormat(val: string) {
        if (val !== '' && val !== null && val !== undefined) {
            let regex = /^[\d+ -]*$/;

            return (regex.test(val.toString().trim()));
        }

        return true;
    }

    private validateComparison(val: string, valToCompareWith: any, compareMode?: CompareMode, compareOperator?: CompareOperator) {
        if (val !== '' && val !== null && val !== undefined && valToCompareWith !== '' && valToCompareWith !== null && valToCompareWith !== undefined) {
            let isValid = true;
            switch (compareOperator) {
                case "==":
                    if (compareMode === "integer")
                        isValid = (parseInt(val) === parseInt(valToCompareWith));
                    else if (compareMode === "date")
                        isValid = (new Date(val) === new Date(valToCompareWith));
                    else if (compareMode === "text")
                        isValid = (val === valToCompareWith);
                    break;
                case ">=":
                    if (compareMode === "integer")
                        isValid = (parseInt(val) >= parseInt(valToCompareWith));
                    else if (compareMode === "date")
                        isValid = (new Date(val) >= new Date(valToCompareWith));
                    break;
                case "<=":
                    if (compareMode === "integer")
                        isValid = (parseInt(val) <= parseInt(valToCompareWith));
                    else if (compareMode === "date")
                        isValid = (new Date(val) <= new Date(valToCompareWith));
                    break;
                case ">":
                    if (compareMode === "integer")
                        isValid = (parseInt(val) > parseInt(valToCompareWith));
                    else if (compareMode === "date")
                        isValid = (new Date(val) > new Date(valToCompareWith));
                    break;
                case "<":
                    if (compareMode === "integer")
                        isValid = (parseInt(val) < parseInt(valToCompareWith));
                    else if (compareMode === "date")
                        isValid = (new Date(val) < new Date(valToCompareWith));
                    break;
                default:
                    break;
            }

            return isValid;
        }

        return true;
    }
}