import React from "react";
import Base from "src/common/Base";
import { ProgressBar } from "src/common/Components";
import Constants from "src/common/Constants";

export default class Insights extends Base {
    public state: any;

    constructor(props) {
        super(props);

        this.state = { loading: true, ProfileViews: 0, Opportunities: 0, Leads: 0, QuotesSubmitted: 0 };
    }

    public componentDidMount() {
        this.db.getUserProfile(this.LoginName, Constants.UserRoles.Pro).then((profile) => {
            if (profile) {
                if (this.AppRole === Constants.UserRoles.Pro) {
                    this.db.getLeads(profile.Id).then((leads) => {
                        this.db.getOpportunities(profile.Id).then((opps) => {
                            this.db.getMyQuotes(profile.Id).then((quotes) => {
                                this.setState({ loading: false, ProfileViews: profile.Views, Leads: (leads ? leads.length : 0), Opportunities: (opps ? opps.length : 0), QuotesSubmitted: (quotes ? quotes.length : 0) });
                            });
                        });
                    });
                }
                else if (this.AppRole === Constants.UserRoles.Customer) {
                    this.db.getRequestedQuotes(profile.Id).then((quotes) => {
                        this.db.getMyHires(profile.Id).then((hires) => {
                            this.setState({ loading: false, ProfileViews: profile.Views, Leads: (hires ? hires.length : 0), QuotesSubmitted: (quotes ? quotes.length : 0) });
                        });
                    });
                }
                else {
                    this.setState({ loading: false });
                }
            }
            else {
                this.setState({ loading: false });
            }
        });
    }

    public render() {
        const { loading, Opportunities, Leads, QuotesSubmitted } = this.state; //ProfileView

        let template: any = <ProgressBar />;

        if (!loading) {
            if (this.AppRole === Constants.UserRoles.Pro) {
                template = (
                    <div className="tabPanel">
                        {/* <div className="row">
                            <div className="col-8 col-sm-8">
                                <label>Profile Views</label>
                            </div>
                            <div className="col-4 col-sm-4 text-right">
                                <label>{ProfileView}</label>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-8 col-sm-8">
                                <label>Leads</label>
                            </div>
                            <div className="col-4 col-sm-4 text-right">
                                <label>{Leads}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 col-sm-8">
                                <label>Opportunities</label>
                            </div>
                            <div className="col-4 col-sm-4 text-right">
                                <label>{Opportunities}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 col-sm-8">
                                <label>Quotes Submitted</label>
                            </div>
                            <div className="col-4 col-sm-4 text-right">
                                <label>{QuotesSubmitted}</label>
                            </div>
                        </div>
                    </div>);
            }
            else if (this.AppRole === Constants.UserRoles.Customer) {
                template = (
                    <div className="tabPanel mt-2">
                        {/* <div className="row">
                            <div className="col-8 col-sm-8">
                                <label>Profile Views</label>
                            </div>
                            <div className="col-4 col-sm-4 text-right">
                                <label>{ProfileView}</label>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-8 col-sm-8">
                                <label>Quotes Requested</label>
                            </div>
                            <div className="col-4 col-sm-4 text-right">
                                <label>{QuotesSubmitted}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 col-sm-8">
                                <label>Hires</label>
                            </div>
                            <div className="col-4 col-sm-4 text-right">
                                <label>{Leads}</label>
                            </div>
                        </div>
                    </div>);
            }
            else {
                template = null;
            }
        }

        return template;
    }
}