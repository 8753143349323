import Axios, { AxiosRequestConfig } from 'axios';

export default class DBHelper {
    private restConfig: AxiosRequestConfig = {};

    private servicePath = window.location.protocol + "//" + window.location.host + "/api";

    public authenticateUser(userName: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params = { userName: userName, password: password };
                Axios.post(this.servicePath + "/authenticateUser", params).then((response: any) => {
                    let result = response.data;
                    if (result) {
                        resolve(result.AuthenticateUserResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public authenticateGoogleUser(userName: string, token): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let params = { userName: userName, token };
                Axios.post(this.servicePath + "/authenticateGoogleUser", params).then((response: any) => {
                    let result = response.data;
                    if (result) {
                        resolve(result.AuthenticateGoogleUserResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public validateUser(userToken: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/validateUser/" + userToken, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.ValidateUserResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public validateGoogleUser(userToken: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get("https://oauth2.googleapis.com/tokeninfo?id_token=" + userToken, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public checkUser(email: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/checkUser/" + email, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.CheckUserResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public signOutUser(token: string | null): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let params = { email: token };
                Axios.post(this.servicePath + "/signOutUser", params).then((response: any) => {
                    let result = response.data;
                    if (result) {
                        resolve(result.SignOutUserResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getUser(email: string, role): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let item = { login: email, role };
                Axios.post(this.servicePath + "/getUser", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetUserResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getUserById(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let item = { uId: id };
                Axios.post(this.servicePath + "/getUserById", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetUserByIdResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getUserByEmail(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let item = { email };
                Axios.post(this.servicePath + "/getUserByEmail", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetUserByEmailResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public createUser(firstName: string, lastName: string, email: string, password: string, role: string, source): Promise<number> {
        return new Promise((resolve, reject) => {
            try {
                let params = { firstName: firstName, lastName: lastName, email: email, password: password, role: role, source };
                Axios.post(this.servicePath + "/createUser", params).then((response: any) => {
                    if (response) {
                        resolve(response.data.CreateUserResult);
                    }
                    else {
                        resolve(0);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(0);
                });
            }
            catch (er) {
                console.log(er);
                resolve(0);
            }
        });
    }

    public getCategories(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getCategories", this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetCategoriesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getServices(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getServices", this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetServicesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getPins(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getPins", this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetPinsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getStates(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getStates", this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetStatesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCities(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getCities", this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetCitiesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCountries(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getCountries", this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetCountriesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getPaymentTypes(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getPaymentTypes", this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetPaymentTypesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getUserProfile(email: any, role): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getUserProfile/" + email + "/" + role, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetUserProfileResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getUserProfileById(id: any, role): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getUserProfileById/" + id + "/" + role, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetUserProfileByIdResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getPaymentModes(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getPaymentModes/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetPaymentModesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getSocialMediaLinks(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getSocialMediaLinks/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetSocialMediaLinksResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getProfileMedia(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getprofileMedia/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetProfileMediaResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getLicenses(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getLicenses/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetLicensesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCertificates(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getCertificates/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetCertificatesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getFAQs(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getFAQs/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetFAQsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getReviewParameters(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getReviewParameters", this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetReviewParametersResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getProfileRatings(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getProfileRatings/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetProfileRatingsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getProfileComments(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getProfileComments/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetProfileCommentsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getPortfolio(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getPortfolio/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetPortfolioResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getFeaturedProjects(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getFeaturedProjects/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetFeaturedProjectsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public searchUserProfiles(category: string, pin: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let input: any = { category, pin, count: 500 };
                Axios.post(this.servicePath + "/searchUserProfiles", input).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.SearchUserProfilesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public updateUserProfile(itemToUpdate: any): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                Axios.post(this.servicePath + "/updateUserProfile", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateUserProfileResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updatePaymentMode(itemId: number, profileId: number, mode: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { id: itemId, pId: profileId, mode: mode };
                Axios.post(this.servicePath + "/updatePaymentMode", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdatePaymentModeResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updateSocialMediaLink(itemId: number, profileId: number, type: string, url: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { id: itemId, pId: profileId, type: type, url: url };
                Axios.post(this.servicePath + "/updateSocialMediaLink", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateSocialMediaLinkResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updateProfileImage(itemId: number, profileId: number, url: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { id: itemId, pId: profileId, url: url };
                Axios.post(this.servicePath + "/updateProfileImage", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateProfileImageResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updatePortfolioMedia(profileId: number, url: string, type: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { pId: profileId, url: url, type: type };
                Axios.post(this.servicePath + "/updatePortfolioMedia", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdatePortfolioMediaResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updateLicense(itemId: number, profileId: number, name: string, desc: string, sDate: Date, eDate: Date, institution: string, url: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { id: itemId, pId: profileId, name: name, desc: desc, sDate: sDate, eDate: eDate, inst: institution, url: url };
                Axios.post(this.servicePath + "/updateLicense", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateLicenseResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updateProfileMedia(itemId: number, profileId: number, name: string, url: string, type: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { itemId: itemId, profileId: profileId, name: name, url: url, type: type };
                Axios.post(this.servicePath + "/updateProfileMedia", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateProfileMediaResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deletePaymentMode(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deletePaymentMode", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeletePaymentModeResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteSocialMediaLink(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deleteSocialMediaLink", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteSocialMediaLinkResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deactivateUserProfile(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { pId: itemId };
                Axios.post(this.servicePath + "/deactivateUserProfile", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeactivateUserProfileResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deactivateUser(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { pId: itemId };
                Axios.post(this.servicePath + "/deactivateUser", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeactivateUserResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteLicense(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deleteLicense", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteLicenseResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deletePortfolioMedia(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deletePortfolioMedia", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeletePortfolioMediaResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteProfileMedia(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deleteProfileMedia", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteProfileMediaResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public changePassword(uId: number, nPassword: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { uId: uId, nPass: nPassword };
                Axios.post(this.servicePath + "/changePassword", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.ChangePasswordResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public resetPassword(email: string, nPass): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { email: email, nPass };
                Axios.post(this.servicePath + "/resetPassword", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.ResetPasswordResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public reactivateUser(login: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { login };
                Axios.post(this.servicePath + "/reactivateUser", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.ReactivateUserResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public uploadFile(file, key, fileName, type): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (file) {
                try {
                    Axios.post(this.servicePath + "/uploadFile?key=" + key + "&fileName=" + fileName + "&type=" + type, file, { headers: { 'Content-Type': 'application/octet-stream' } }).then((data: any) => {
                        let result = data.data;
                        if (result) {
                            resolve(result.UploadFileResult);
                        }
                        else {
                            resolve(false);
                        }
                    }).catch((er) => {
                        console.log(er);
                        resolve(false);
                    });
                }
                catch (er) {
                    console.log(er);
                    resolve(false);
                }
            }
            else
                resolve(false);
        });
    }

    public addFeaturedProject(itemToAdd): Promise<number> {
        return new Promise((resolve, reject) => {
            if (itemToAdd) {
                try {
                    Axios.post(this.servicePath + "/addFeaturedProject", itemToAdd).then((data: any) => {
                        let result = data.data;
                        if (result) {
                            resolve(result.AddFeaturedProjectResult);
                        }
                        else {
                            resolve(0);
                        }
                    }).catch((er) => {
                        console.log(er);
                        resolve(0);
                    });
                }
                catch (er) {
                    console.log(er);
                    resolve(0);
                }
            }
            else
                resolve(0);
        });
    }

    public uploadProjectPhoto(projectId: number, name: string, url: string, file): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                Axios.post(this.servicePath + "/uploadProjectPhoto?itemId=0&projectId=" + projectId + "&name=" + name + "&url=" + url, file, { headers: { 'Content-Type': 'application/octet-stream' } }).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UploadProjectPhotoResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteProjectPhoto(projectId: number, fileId: number, url: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { projectId: projectId, fileId: fileId, url: url };
                Axios.post(this.servicePath + "/deleteProjectPhoto", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteProjectPhotoResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteFeaturedProject(itemId: number, path: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { projectId: itemId, folderPath: path };
                Axios.post(this.servicePath + "/deleteFeaturedProject", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteFeaturedProjectResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updateFeaturedProject(itemToUpdate): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (itemToUpdate) {
                try {
                    Axios.post(this.servicePath + "/updateFeaturedProject", itemToUpdate).then((data: any) => {
                        let result = data.data;
                        if (result) {
                            resolve(result.UpdateFeaturedProjectResult);
                        }
                        else {
                            resolve(false);
                        }
                    }).catch((er) => {
                        console.log(er);
                        resolve(false);
                    });
                }
                catch (er) {
                    console.log(er);
                    resolve(false);
                }
            }
            else
                resolve(false);
        });
    }

    public addProfileRating(profileId, rating, userName, userEmail, paramsRating): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { pId: profileId, rating: rating, uName: userName, uEmail: userEmail, pRatings: paramsRating };
            try {
                Axios.post(this.servicePath + "/addProfileRating", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.AddProfileRatingResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public addProfileComment(profileId, comment, parentId, userName, userEmail, rating): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { pId: profileId, comment: comment, cPId: parentId, uName: userName, uEmail: userEmail, rating: rating };
            try {
                Axios.post(this.servicePath + "/addProfileComment", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.AddProfileCommentResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteProfileRating(itemId: number, path: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { projectId: itemId, folderPath: path };
                Axios.post(this.servicePath + "/deleteProfileRating", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteProfileRatingResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteProfileComment(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { pId: itemId };
                Axios.post(this.servicePath + "/deleteProfileComment", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteProfileCommentResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getProfileNotifications(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getProfileNotifications/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetProfileNotificationsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getProfileBillingInfo(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getProfileBillingInfo/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetProfileBillingInfoResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public addProfileNotification(profileId, name, status): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { profileId: profileId, name: name, status: status };
            try {
                Axios.post(this.servicePath + "/addProfileNotification", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.AddProfileNotificationResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updateProfileSubscription(profileId, mode, stripeObject): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { profileId: profileId, mode: mode, stripe: stripeObject };
            try {
                Axios.post(this.servicePath + "/updateProfileSubscription", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateProfileSubscriptionResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deletePaymentMethod(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deletePaymentMethod", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeletePaymentMethodResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public setDefaultPayment(itemId: number, pId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId, pId: pId };
                Axios.post(this.servicePath + "/setDefaultPayment", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.SetDefaultPaymentResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updatePaymentMethod(itemId, profileId, cardNumber, cardType, cardExpiryMonth, cardExpiryYear, cardFirstName, cardLastName, cardBillingAddress, cardZipCode, cardCountry, cardIsDefault, cardCompany, paymentMethodId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = {
                itemId: !itemId ? 0 : itemId, pId: profileId, cNumber: cardNumber, cType: cardType, cEMonth: cardExpiryMonth, cEYear: cardExpiryYear, cFName: cardFirstName, cLName: cardLastName,
                cBAddress: cardBillingAddress, cZCode: cardZipCode, cCountry: cardCountry, cIsDefault: cardIsDefault, cCompany: cardCompany, paymentMethodId: paymentMethodId
            };

            try {
                Axios.post(this.servicePath + "/updatePaymentMethod", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdatePaymentMethodResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getProfileServices(pId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getProfileServices/" + pId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetProfileServicesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public updateProfileService(itemId: number, profileId: number, serviceId: number, targetArea, cities: any, description: string, distance, baseLocation, profileServiceLocation): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { itemId: itemId, pId: profileId, sId: serviceId, tArea: targetArea, cities: cities, desc: description, distance, baseLocation, profileServiceLocation };

            try {
                Axios.post(this.servicePath + "/updateProfileService", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateProfileServiceResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteProfileService(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deleteProfileService", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteProfileServiceResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getLeads(pId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getLeads/" + pId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetLeadsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getOpportunities(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getOpportunities/" + profileId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetOpportunitiesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public passProject(itemId: number, profileId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { itemId: itemId, pId: profileId };

            try {
                Axios.post(this.servicePath + "/passProject", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.PassProjectResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getProjects(pId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getProjects/" + pId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetProjectsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCategoryFAQs(cId: any, type): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getCategoryFAQs/" + cId + "/" + type, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetCategoryFAQsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public updateProfileProject(itemId: number, profileId: number, pCategoryId, pName, pDescription, pBudget, pCity, pType, pLocation, pState, pSCode, pPin): Promise<number> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { itemId, pId: profileId, pCategoryId, pName, pDescription, pBudget, pCity, pType, pLocation, pState, pSCode, pPin };

            try {
                Axios.post(this.servicePath + "/updateProfileProject", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateProfileProjectResult);
                    }
                    else {
                        resolve(0);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(0);
                });
            }
            catch (er) {
                console.log(er);
                resolve(0);
            }
        });
    }

    public updateProfileProjectFAQs(projId, profileId, faqString: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { projectId: projId, profileId: profileId, faqs: faqString };

            try {
                Axios.post(this.servicePath + "/updateProfileProjectFAQs", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateProfileProjectFAQsResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteProfileProject(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deleteProfileProject", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteProfileProjectResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public cancelProfileProject(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/cancelProfileProject", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.CancelProfileProjectResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public openProfileProject(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToUpdate: any = { pId: itemId, status: 'Open' };
                Axios.post(this.servicePath + "/updateProjectStatus", itemToUpdate).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateProjectStatusResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public closeProfileProject(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/closeProfileProject", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.CloseProfileProjectResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getRelatedPros(itemId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getRelatedPros/" + itemId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetRelatedProsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getSubmittedQuotes(itemId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getSubmittedQuotes/" + itemId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetSubmittedQuotesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getUsers(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getUsers", this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetUsersResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public updateCategory(itemId: number, name: string, desc: string, active): Promise<number> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { itemId, name, desc, active };

            try {
                Axios.post(this.servicePath + "/updateCategory", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateCategoryResult);
                    }
                    else {
                        resolve(0);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(0);
                });
            }
            catch (er) {
                console.log(er);
                resolve(0);
            }
        });
    }

    public deleteCategory(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deleteCategory", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteCategoryResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updateService(itemId: number, name: string, desc: string, active, category): Promise<number> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { itemId, name, desc, active, category };

            try {
                Axios.post(this.servicePath + "/updateService", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateServiceResult);
                    }
                    else {
                        resolve(0);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(0);
                });
            }
            catch (er) {
                console.log(er);
                resolve(0);
            }
        });
    }

    public deleteService(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deleteService", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteServiceResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public deleteQuestion(itemId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let itemToDelete: any = { itemId: itemId };
                Axios.post(this.servicePath + "/deleteQuestion", itemToDelete).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteQuestionResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updateQuestion(itemId: number, name, category, controlType, masterValue, isRequired, maxLength, allowMultiple, isActive, type): Promise<number> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { itemId, name, category, controlType, masterValue, isRequired, maxLength, allowMultiple, isActive, type };

            try {
                Axios.post(this.servicePath + "/updateQuestion", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateQuestionResult);
                    }
                    else {
                        resolve(0);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(0);
                });
            }
            catch (er) {
                console.log(er);
                resolve(0);
            }
        });
    }

    public getProjectFAQs(itemId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getProjectFAQs/" + itemId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetProjectFAQsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getCategoryRelatedProjects(category, pin, profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getCategoryRelatedProjects/" + (category ? category : 0) + "/" + (pin ? pin : 0) + "/" + (profileId ? profileId : 0), this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetCategoryRelatedProjectsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getQuoteTemplate(cId: any, pId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getQuoteTemplate/" + (cId ? cId : 0) + "/" + (pId ? pId : 0), this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetQuoteTemplateResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getRequestedQuotes(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getRequestedQuotes/" + (profileId ? profileId : 0), this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetRequestedQuotesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public requestQuote(cId: number, projectId, cProfileId, cEmail, pProfileId, pEmail): Promise<number> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { cId, projectId, cProfileId, cEmail, pProfileId, pEmail };

            try {
                Axios.post(this.servicePath + "/requestQuote", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.RequestQuoteResult);
                    }
                    else {
                        resolve(0);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(0);
                });
            }
            catch (er) {
                console.log(er);
                resolve(0);
            }
        });
    }

    public getInboxMessages(pId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getInboxMessages/" + (pId ? pId : 0), this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetInboxMessagesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getSentMessages(pId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getSentMessages/" + (pId ? pId : 0), this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetSentMessagesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getArchivedMessages(pId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getArchivedMessages/" + (pId ? pId : 0), this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetArchivedMessagesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getDeletedMessages(pId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getDeletedMessages/" + (pId ? pId : 0), this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetDeletedMessagesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getStarredMessages(pId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getStarredMessages/" + (pId ? pId : 0), this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetStarredMessagesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getNewMessageCount(pId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getNewMessageCount/" + (pId ? pId : 0), this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetNewMessageCountResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public deleteMessage(itemId: number, pId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let item: any = { itemId, pId };
                Axios.post(this.servicePath + "/deleteMessage", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.DeleteMessageResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public addMessage(sId, rId, subject, body, parentId, rootId, sRole, rRole): Promise<number> {
        return new Promise((resolve, reject) => {
            try {
                let item: any = { sId, rId, subject, body, parentId, rootId, sRole, rRole };
                Axios.post(this.servicePath + "/addMessage", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.AddMessageResult);
                    }
                    else {
                        resolve(0);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(0);
                });
            }
            catch (er) {
                console.log(er);
                resolve(0);
            }
        });
    }

    public updateMessage(itemId, status, pId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let item: any = { itemId, status, pId };
                Axios.post(this.servicePath + "/updateMessage", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateMessageResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public submitQuote(projectId, proId, qPrice, qType, qMessage): Promise<number> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { projectId, proId, qPrice, qType, qMessage };

            try {
                Axios.post(this.servicePath + "/submitQuote", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.SubmitQuoteResult);
                    }
                    else {
                        resolve(0);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(0);
                });
            }
            catch (er) {
                console.log(er);
                resolve(0);
            }
        });
    }

    public updateQuoteFAQs(projId, proId, faqString: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let itemToAdd: any = { projectId: projId, profileId: proId, faqs: faqString };

            try {
                Axios.post(this.servicePath + "/updateQuoteFAQs", itemToAdd).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateQuoteFAQsResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public uploadQuoteFile(quoteId: number, name: string, url: string, file): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                Axios.post(this.servicePath + "/uploadQuoteFile?quoteId=" + quoteId + "&name=" + name + "&url=" + url, file, { headers: { 'Content-Type': 'application/octet-stream' } }).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UploadQuoteFileResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getMyQuotes(pId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getMyQuotes/" + pId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetMyQuotesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getQuoteDetails(qId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getQuoteDetails/" + qId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetQuoteDetailsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getQuoteFAQs(qId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getQuoteFAQs/" + qId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetQuoteFAQsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public hirePro(proId, proEmail, projectId, custEmail): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let item = { proId, proEmail, projectId, custEmail };
                Axios.post(this.servicePath + "/hirePro", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.HireProResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public submitReview(sProId, proId, rating, comment): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let item = { sProId, proId, rating, comment };
                Axios.post(this.servicePath + "/submitReview", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.SubmitReviewResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getMyHires(pId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getMyHires/" + pId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetMyHiresResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public getRecipients(pId, role): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getRecipients/" + pId + "/" + role, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetRecipientsResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public archiveMessage(itemId, pId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let item = { itemId, pId };
                Axios.post(this.servicePath + "/archiveMessage", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.ArchiveMessageResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public readMessage(itemId, pId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let item = { itemId, pId };
                Axios.post(this.servicePath + "/readMessage", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.ReadMessageResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public moveMessage(itemId, pId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let item = { itemId, pId };
                Axios.post(this.servicePath + "/moveMessage", item).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.MoveMessageResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getRelatedMessages(itemId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getRelatedMessages/" + itemId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetRelatedMessagesResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public sendEmailType(mType, iParams): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let param = { mType, iParams };
                Axios.post("https://prosbe.com/api/sendEmailType", param).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.SendEmailTypeResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public sendEmail(to, sub, bod): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let param = { to, sub: sub, body: bod };
                Axios.post(this.servicePath + "/sendEmail", param).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.SendEmailResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public updateProfileGoogleBusinessAccount(pId, bLocation): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let param = { pId, bLocation };
                Axios.post(this.servicePath + "/updateProfileGoogleBusinessAccount", param).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateProfileGoogleBusinessAccountResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }

    public getUserPaymentHistory(pId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(this.servicePath + "/getUserPaymentHistory/" + pId, this.restConfig).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.GetUserPaymentHistoryResult);
                    }
                    else {
                        resolve(null);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(null);
                });
            }
            catch (er) {
                console.log(er);
                resolve(null);
            }
        });
    }

    public updateUserPaymentHistory(pId: number, customerId: string, currency: string, chargeId: string, paymentId: string, paymentMethodId: string, invoiceId: string, amount: string, amountCaptured: string, date: string, status: string, description: string, receiptUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let param = { pId, customerId, currency, chargeId, paymentId, paymentMethodId, invoiceId, amount, amountCaptured, date, status, description, receiptUrl };
                Axios.post(this.servicePath + "/updateUserPaymentHistory", param).then((data: any) => {
                    let result = data.data;
                    if (result) {
                        resolve(result.UpdateUserPaymentHistoryResult);
                    }
                    else {
                        resolve(false);
                    }
                }).catch((er) => {
                    console.log(er);
                    resolve(false);
                });
            }
            catch (er) {
                console.log(er);
                resolve(false);
            }
        });
    }
}