import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import { IAutoCompleteOption, Loader } from "src/common/Components";
import Constants from "src/common/Constants";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import Validator from "src/common/Validator";

export default class Dashboard extends Base {

    public validator: Validator;
    public formValidators: Validator[];

    constructor(props) {
        super(props);

        this.state = { loading: true, Category: '', PinCode: '', PinAddress: '', CategoryId: '', Categories: [], Pins: [] };
        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        // get saved categories and pins
        this.db.getCategories().then((categories) => {
            this.getPinsMaster().then((pins) => {
                let _categories: IAutoCompleteOption[] = [];
                if (categories && categories.length > 0) {
                    categories.forEach((cat) => {
                        _categories.push({ key: cat.Name, text: cat.Name, value: cat.Id });
                    });
                }

                let _pins: IAutoCompleteOption[] = [];
                if (pins && pins.length > 0) {
                    pins.forEach((pin) => {
                        _pins.push({ key: pin.Name, text: pin.Name, value: pin.Pin });
                    });
                }

                this.addGoogleMapScriptTag();

                this.setState({ loading: false, Categories: _categories, Pins: _pins }, () => {
                    window.setTimeout(this.initializePlacesAutoComplete.bind(this), 1000);
                });
            });
        });
    }

    private handleSearch() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            window.location.href = '/search?c=' + this.state.CategoryId + '&p=' + this.state.PinCode + '&add=' + this.state.PinAddress;
        }
    }

    private handleSwitchRoleClick() {
        this.db.getUser(this.LoginName, Constants.UserRoles.Pro).then((user) => {
            if (user)
                sessionStorage.setItem(this.encodeString("ProsBE_User"), this.encodeString(JSON.stringify(user)));

            sessionStorage.setItem(this.encodeString("ProsBEUser_AppRole"), this.encodeString(Constants.UserRoles.Pro));
            window.location.href = '/';
        });
    }

    private addGoogleMapScriptTag() {
        let scriptElems = document.getElementsByTagName("script");
        let elemExists = false;
        for (var i = 0; i < scriptElems.length; i++) {
            if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
                elemExists = true;
        }

        if (!elemExists) {
            const script = document.createElement("script");
            script.src = Constants.Google.PlacesScriptPath;
            //script.id = "scriptGoogleMaps";
            // script.setAttribute('async', '');
            //script.setAttribute('defer', '');

            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }

    private initializePlacesAutoComplete() {

        const input = document.getElementById("zip") as HTMLInputElement;
        const autoCompleteOptions = {
            fields: ["formatted_address", "address_components", "geometry", "name", "place_id"],
            // strictBounds: false,
            types: ["(regions)"],
            componentRestrictions: { country: "us" }
        };

        const autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);

        autocomplete.addListener("place_changed", () => {
            let place: any = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "No details available for input: '" + place.name + "'", ConfirmationType: 'A' });
                return;
            }

            let hasPostalCode = false;
            let zipCode = '';
            // let state = '';
            // let sCode = '';
            // let city = '';

            if (place.address_components) {
                for (var i = 0; i < place.address_components.length; i++) {
                    if (place.address_components[i].types) {
                        for (var j = 0; j < place.address_components[i].types.length; j++) {
                            if (place.address_components[i].types[j] === "postal_code") {
                                if (!hasPostalCode) {
                                    hasPostalCode = true;
                                    zipCode = place.address_components[i].long_name;
                                }
                            }

                            // if (place.address_components[i].types[j] === "administrative_area_level_1") {
                            //     if (!state) {
                            //         state = place.address_components[i].long_name;
                            //         sCode = place.address_components[i].short_name;
                            //     }
                            // }

                            // if (place.address_components[i].types[j] === "locality") {
                            //     if (!city) {
                            //         city = place.address_components[i].long_name;
                            //     }
                            // }
                        }
                    }
                }
            }

            if (!hasPostalCode) {
                this.setState({ showConfirmation: true, ConfirmationMessage: "Invalid Zip code. Please start your search with a Zip code.", ConfirmationType: 'A' });
                return;
            }

            let formatted_address = place.formatted_address;
            // let placeId = place.place_id;
            // let lat = place.geometry?.location?.lat();
            // let lon = place.geometry?.location?.lng();

            this.setState({ PinCode: zipCode, PinAddress: formatted_address });
        });
    }

    public render() {

        const { loading, Category, Categories, PinAddress } = this.state;
        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            template = (<React.Fragment>
                <Header />
                <section className="containerMinHeight searchContainer homepageBgContainer">
                    <div className="searchContainerForm">
                        <h4>Find the professionals for your project</h4>
                        <div className="searchSection">
                            <div className="row">
                                <div className="col-12 col-sm-6 pr-0">
                                    <div className="height50">
                                        <Autocomplete multiple={false} id="category" className="autoComplete" options={Categories && Categories.length > 0 ? Categories : []} getOptionLabel={(option) => { return (option && option.text ? option.text : ''); }}
                                            onChange={(e, newVal) => { this.setState({ Category: newVal ? newVal : null, CategoryId: newVal ? newVal.value.toString() : '' }); }} defaultValue={Category} filterSelectedOptions={true} renderInput={(params) => (<TextField name="category" {...params} label="" placeholder="" />)} />
                                        {/* <AutoComplete id="category" onChange={(e) => { if (e && e.key) { this.setState({ Category: e.key, CategoryId: e.value }) } else { this.setState({ Category: '', CategoryId: '' }); } }} key="search_category" placeholder="What's on your to-do list?" defaultValue={Category} data={Categories && Categories.length > 0 ? Categories : []} /> */}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 p-0">
                                    <div className="height50">
                                        <input id="zip" name="zip" type="text" className="zipCodeGoogle iconMap" defaultValue={PinAddress} />
                                        <div id="map"></div>
                                        {/* <Autocomplete multiple={false} id="pins" className="autoComplete" options={Pins && Pins.length > 0 ? Pins : []} getOptionLabel={(option) => { return (option && option.value ? option.value : ''); }}
                                    onChange={(e, newVal) => { this.setState({ Pin: newVal ? newVal : null, PinCode: newVal ? newVal.value.toString() : '' }); }} renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                            <Typography variant="subtitle2" >{option.value}</Typography>
                                            <Typography variant="caption" color="text.secondary" className="commentBody mr-2">{option.text}</Typography>
                                        </Box>
                                    )} defaultValue={Pin} filterSelectedOptions={true} renderInput={(params) => (<TextField name="pins" {...params} label="" placeholder="" />)} /> */}
                                        {/* <AutoComplete id="pin" onChange={(e) => { if (e && e.key) { this.setState({ Pin: e.key, PinCode: e.value }); } else { this.setState({ Pin: '', PinCode: '' }); } }} className="iconMap" key="search_pin" defaultValue={Pin} data={Pins && Pins.length > 0 ? Pins : []} /> */}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-2 pl-0">
                                    <div className="height50">
                                        <button className="btn-search" type="button" onClick={(e) => { this.handleSearch(); }}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Validator id="valSearch" type="required" cssClass="error" errorMessage="Provide a service or pin to search the professionals." valueToValidate={(!Category && !PinAddress ? '' : '1')} formValidators={this.formValidators} />
                        {this.IsUserValid && this.AppRole === Constants.UserRoles.Customer ? (
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <a href="/projects/new" className="btnStartProj">Start Your Project</a></div>
                            </div>) : (null)}
                    </div>
                </section>
                <div className="popularServicesSection">
                    <div className="col-12 col-sm-12 text-center">
                        <div className="popularServicesText">
                            <div className="row">
                                <div className="col-6 col-sm-5 text-right">
                                    <span>POPULAR SERVICES: </span>
                                </div>
                                <div className="col-6 col-sm-7 text-left">
                                    <span className="popularServicesSpan2">Home Improvement, Handyman, Moving, Cleaning, Personal Trainers</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <button type="submit" className="popularServicesViewAllButton" onClick={(e)=>{window.location.href='/findpros';}}>View all</button>
                        </div>
                    </div>
                </div>
                <div className="becomeAProSection text-center">
                    <div className="row">
                        <div className="col-6 col-sm-6">
                            <div className="layerOne">
                                Are you looking for work?
                            </div>
                            <div className="layerTwo mt-2">
                                Find out how ProsBe can help your business.
                            </div>
                            <div className="mt-3">
                                {this.IsUserValid && this.User ? (<React.Fragment>
                                    {this.User.DefaultRole !== Constants.UserRoles.Pro && this.User.Role2 != Constants.UserRoles.Pro ?
                                        (<button type="submit" className="signUp" onClick={(e) => { window.location.href = '/becomepro'; }}>Sign Up as a Pro</button>) :
                                        (this.AppRole === Constants.UserRoles.Customer ? (<button type="submit" className="signUp" onClick={(e) => { this.handleSwitchRoleClick(); }}>Switch to Pro role</button>) : (null))
                                    }
                                </React.Fragment>) : (<button type="submit" className="signUp" onClick={(e) => { window.location.href = '/becomepro'; }}>Sign Up as a Pro</button>)}
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 text-left">
                            <div className="become_layer4">
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment >);
        }

        return template;
    }
}