import { Alert, Snackbar } from "@mui/material";
import React from "react";
import Base from "src/common/Base";
import { Loader, ProgressBar } from "src/common/Components";
import Constants from "src/common/Constants";
import Footer from "src/common/Footer";
import Header from "src/common/Header";
import Validator from "src/common/Validator";

export default class ReactivateRequest extends Base {

    private formValidators: Validator[];
    private validator: Validator;

    constructor(props) {
        super(props);

        this.state = { loading: true, showProgress: false, Email: '', ShowMessage: false, Message: '', MessageType: 'success', IsValidUser: false };
        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
    }

    public componentDidMount() {
        if (this.props.pid) {
            this.db.getUserById(this.props.pid).then((user) => {
                if (!user) {
                    this.errorMessage('This user is not registered with ProsBE.');
                    this.setState({ loading: false });
                }
                else {
                    this.db.reactivateUser(this.props.pid).then((activated) => {
                        if (activated) {
                            this.successMessage('The user is reactivated. Please try logging into ProsBE.');
                            this.setState({ loading: false, IsValidUser: true });
                            window.location.href = '/login';
                        }
                        else {
                            this.errorMessage('The user could not be activated. Please try again later.');
                            this.setState({ loading: false, IsValidUser: true });
                        }
                    });
                }
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    private sendEmail(uid) {
        let emailTemplate = Constants.Email.ReactivateAccount;
        let subject = emailTemplate.Subject;
        let body = emailTemplate.Body;

        body = body.replace('[em]', uid);

        if (this.state.Email) {
            this.db.sendEmail(this.state.Email, subject, body).then((sent) => {
                if (sent) {
                    this.successMessage('An email with reactivation link has been sent on your email id <strong>' + this.state.Email + '</strong>. Plesae follow the instructions in it to reactivate your account.');
                    this.setState({ showProgress: false, Email: '' });
                }
                else {
                    this.errorMessage('Account activation request could not be sent. Please try again later.');
                    this.setState({ showProgress: false, Email: '' });
                }
            });
        }
        else {
            this.setState({ showProgress: false, Email: '' });
        }
    }

    private handleReset() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            this.setState({ showProgress: true });
            this.db.getUserByEmail(this.state.Email).then((user) => {
                if (!user) {
                    this.errorMessage('This email is not registered with a valid user account.');
                    this.setState({ showProgress: false });
                }
                else {
                    this.sendEmail(user.Id);
                }
            });
        }
    }

    private handleKeyDown(e, src) {
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();

            if (src === 'E')
                this.setState({ Email: e.target.value }, () => { this.handleReset() });
        }
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    public render() {

        const { loading, Email, ShowMessage, Message, MessageType, showProgress } = this.state;
        let template: any = <Loader open={loading} onClick={(e) => { }} />;

        if (!loading) {
            template = (<React.Fragment>
                <Header />
                <section className="containerMinHeight loginContainer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="formContainer">
                                    <h3>Request Account Reactivation</h3>
                                    <div className="tabPanel mt-3">
                                        {showProgress ? (<ProgressBar />) : (<React.Fragment>
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input id="email" name="email" type="email" className="form-control" placeholder="" defaultValue={Email} onBlur={(e) => { this.setState({ Email: e.target.value }); }} onKeyDown={(e) => { this.handleKeyDown(e, 'E'); }} />
                                                <Validator id="valEmail" type='required' cssClass="error" errorMessage="Provide email." controlId="email" valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                                <Validator id="valEmailFormat" type='email' cssClass="error" controlId="email" errorMessage="Invalid email." valueToValidate={Email} formValidators={this.formValidators}></Validator>
                                            </div>
                                            <div className="formButtonContainer">
                                                <button type="submit" className="btn btn-primary cutmBtnLogin w-100" onClick={(e) => { this.handleReset(); }}>Request Re-activation</button>
                                            </div>
                                            {!this.IsUserValid ? (null) : (<React.Fragment>
                                                <div className="signUpLink pb-2">Already have an account? <a href="/login">Log in</a>.</div>
                                                <div className="signUpLink">Don’t have an account? <a href="/users/new?m=cust">Sign up</a>.</div>
                                            </React.Fragment>)}
                                        </React.Fragment>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>);
        }

        return (<React.Fragment>
            {template}
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment>);
    }
}